import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import {
  AttendanceType,
  EmployeeProfileType,
  PremiumsType,
  RepaymentScheduleType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { MisconductType } from '@erp_core/erp-types/dist/types/modules/disciplinary/mis-conduct';
import { EmployeeProfileDetailType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import { LoanFilter } from '@erp_core/erp-types/dist/types/modules/hrd/loan';
import { SalaryAdvanceType } from '@erp_core/erp-types/dist/types/modules/hrd/salary-advance';
import { RepaymentScheduleFilter } from '@erp_core/erp-types/dist/types/modules/loan/repayment-schedule';
import _ from 'lodash';
import moment from 'moment';
import { calculateShiftDuration } from '../../attendance/util/calculate-shift-duration';
import { isEmployeeTrackable } from '../../attendance/util/trackable-punches';
import { LoanV2Type } from '../../loan-management/types/loan-v2-type';
import { AttendanceData, DynamicData } from '../components/generate-salary';
import {
  calculateApprenticeCategory,
  getHighestQualification,
  getJoiningInfo,
} from './apprentice-category';
import { enrolledForPTEC } from './enrolled-for-pt';
import { getESICApplicablility } from './esic-applicability';

export async function calculateSalaryAdvances({
  details,
  month,
  year,
  salaryAdvances,
  empId,
}: {
  empId: string;
  details: EmployeeProfileDetailType;
  month: number;
  year: number;
  salaryAdvances: SalaryAdvanceType[];
}): Promise<number> {
  const filtered = salaryAdvances.filter(
    (x) =>
      x.employee.id === empId &&
      ['approved', 'settled'].includes(x.status) &&
      moment(x.date).format('YYYY-MM') ===
        `${year}-${month < 10 ? `0${month}` : month}`
  );

  let amt = 0;

  filtered.forEach((x) => {
    amt += x.amount;
  });

  return amt;

  // const sal = details.advanceSalaries?.filter((x) => {
  //   return (
  //     x.status === 'paid' &&
  //     moment(x.dateApplied, 'YYYY-MM-DD').month() + 1 === month &&
  //     moment(x.dateApplied, 'YYYY-MM-DD').year() === year
  //   );
  // });

  // if (sal?.length) {
  //   return sal.reduce((prev: number, curr) => {
  //     return prev + (parseInt('' + curr.amountSanctioned) || 0);
  //   }, 0);
  // }

  // return 0;
}

export async function calculateLoanRepayment({
  year,
  month,
  details,
  getLoans,
  empId,
  getRepaymentSchedules,
}: {
  empId: string;
  year: number;
  month: number;
  details: EmployeeProfileDetailType;
  getLoans: (filter?: LoanFilter) => Promise<LoanV2Type[]>;
  getRepaymentSchedules: (
    filter?: RepaymentScheduleFilter
  ) => Promise<RepaymentScheduleType[]>;
}): Promise<{
  principle: number;
  interest: number;
  total: number;
}> {
  const loans = details.companyLoans?.filter(
    (x) =>
      x.status === 'pending' &&
      x.repaymentSchedule.find(
        (r) =>
          r.status === 'pending' &&
          moment(r.monthYear, 'YYYY-MM').month() + 1 === month &&
          moment(r.monthYear).year() === year
      )
  );

  if (loans && loans.length) {
    let principle = 0;
    let interest = 0;
    loans.forEach((l) => {
      const rep = l.repaymentSchedule.find(
        (x) =>
          x.status === 'pending' &&
          moment(x.monthYear, 'YYYY-MM').month() + 1 === month &&
          moment(x.monthYear).year() === year
      );

      if (rep) {
        principle += parseInt('' + rep.principal) || 0;
        interest += parseInt('' + rep.interest) || 0;
      }
    });

    return {
      principle: principle,
      interest: interest,
      total: principle + interest,
    };
  }

  const finalLoan = {
    principle: 0,
    interest: 0,
    total: 0,
  };

  const empLoans = (
    await getLoans({
      employeeId: empId,
    })
  ).filter((x) =>
    ['approved', 'notify-to-disburse', 'partially-disbursed'].includes(x.status)
  );

  if (empLoans.length) {
    for (const loan of empLoans) {
      const repayments = await getRepaymentSchedules({
        loanId: loan.id,
      });
      const rep = repayments.find(
        (x) =>
          moment(x.monthYear).month() + 1 === month &&
          moment(x.monthYear).year() === year
      );
      if (rep) {
        finalLoan.interest += parseFloat(`${rep.interest}`);
        finalLoan.principle += parseFloat(`${rep.principal}`);
        finalLoan.total +=
          parseFloat(`${rep.interest}`) + parseFloat(`${rep.principal}`);
      }
    }
  }
  // console.log(empLoans);

  return finalLoan;
}

export function calculateAdhocDeductions(
  details: EmployeeProfileDetailType,
  month: number,
  year: number
): number {
  const deductions = (details as any).adhocPayments?.filter((x) => {
    return (
      x.type === 'deduction' &&
      x.status === 'approved' &&
      moment(x.monthYear, 'MM/YYYY').month() + 1 === month &&
      moment(x.monthYear, 'MM/YYYY').year() === year
    );
  });

  if (!deductions || deductions?.length === 0) {
    return 0;
  }

  let fine = 0;
  deductions.forEach((l) => {
    fine += parseInt(l.amount + '');
  });

  return fine;
}

export function calculateAdhocEarnings(
  details: EmployeeProfileDetailType,
  month: number,
  year: number
): number {
  console.log(details);
  const earnings = (details as any).adhocPayments?.filter((x) => {
    return (
      x.type === 'earning' &&
      x.status === 'approved' &&
      moment(x.monthYear, 'MM/YYYY').month() + 1 === month &&
      moment(x.monthYear, 'MM/YYYY').year() === year
    );
  });

  console.log(earnings);

  if (!earnings || earnings?.length === 0) {
    return 0;
  }

  let fine = 0;
  earnings.forEach((l) => {
    fine += parseInt(l.amount + '');
  });

  return fine;
}

export async function calculateFines({
  details,
  month,
  year,
  empId,
  date,
  misconducts,
}: {
  details: EmployeeProfileDetailType;
  month: number;
  year: number;
  empId: string;
  date: string;
  misconducts: MisconductType[];
}): Promise<number> {
  // const fines = details.employeeFinePenalty?.filter((x) => {
  //   // console.log(
  //   //   x,
  //   //   moment(x.monthYear, 'MM/YYYY').format('YYYY-MM'),
  //   //   month,
  //   //   moment(x.monthYear, 'MM/YYYY').month() + 1 === month,
  //   //   moment(x.monthYear).year(),
  //   //   moment(x.monthYear).year() === year);

  //   return (
  //     x.type === 'fine' &&
  //     moment(x.monthYear, 'MM/YYYY').month() + 1 === month &&
  //     moment(x.monthYear, 'MM/YYYY').year() === year
  //   );
  // });

  const monthDate = `${year}-${month > 9 ? month : `0${month}`}`;

  const empMisconducts = misconducts.filter(
    (x) =>
      x.employee.id === empId &&
      moment(x.date).format('YYYY-MM') === monthDate &&
      ['approved', 'approved', 'settled', 'deducted'].includes(x.status)
  );
  let fine = 0;

  empMisconducts.forEach((m) => {
    if (m.amount) {
      fine += parseInt(`${m.amount}`);
    }
  });

  // if (!fines || fines?.length === 0) {
  //   return 0;
  // }

  // let fine = 0;
  // fines.forEach((l) => {
  //   fine += parseInt(l.amount + '');
  // });

  // console.log('fine', fine, misconducts, empId, date);
  return fine;
}

export async function calculateInsurancePremium({
  details,
  month,
  year,
  premiums,
  empId,
}: {
  details: EmployeeProfileDetailType;
  month: number;
  year: number;
  premiums: PremiumsType[];
  empId: string;
}): Promise<number> {
  const monthDate = `${year}-${month > 9 ? month : `0${month}`}`;

  const empPremiums = premiums.filter(
    (x) =>
      (x.policy as any).employee_id === empId &&
      x.monthYear === monthDate &&
      ['pending'].includes(x.status)
  );
  let prem = 0;

  empPremiums.forEach((p) => {
    prem += p.amount;
  });

  return prem;

  // const sal = details.employeeInsurance?.filter(
  //   (x) =>
  //     x.status === 'pending' && // TODO: This has to be only paid ones
  //     moment(x.monthYear, 'YYYY-MM').month() === month &&
  //     moment(x.monthYear, 'YYYY-MM').year() === year
  // );

  // if (sal?.length) {
  //   return sal.reduce((prev: number, curr) => {
  //     return prev + (parseInt('' + curr.amount) || 0);
  //   }, 0);
  // }

  // return 0;
}

async function calculateAttendanceDetails({
  employee,
  month,
  year,
  attendanceData,
}: {
  employee: EmployeeProfileType;
  month: string;
  year: string;
  attendanceData: AttendanceType[];
}): Promise<AttendanceData> {
  let payableDays = 0;
  let payableHours = 0;
  let payableDaysRatio = 0;
  let payableOvertimeHours = 0;
  let absentDays = 0;
  let presentDays = 0;
  let weeklyOffs: Array<string> = [];
  let companyOffs: Array<string> = [];
  let leaves: any = {
    takenThisMonth: [],
    pending: { casual: 0, compOff: 0, sick: 0, privilege: 0, unpaid: 0 },
    currentRedeemed: {
      casual: 0,
      compOff: 0,
      sick: 0,
      privilege: 0,
      onJobAccident: 0,
      unpaid: 0,
    },
  };
  const shiftHours = calculateShiftDuration({ employee });
  attendanceData.forEach((e) => {
    const shiftDur = e.details?.summary?.shiftDuration || shiftHours;
    const payableDay = e.details?.summary?.payableDay;

    payableHours += e.details?.summary?.payableWorkHours || 0;

    payableDays += payableDay ? 1 : 0;

    payableDaysRatio += parseFloat(
      ((e.details?.summary?.payableWorkHours || 0) / shiftDur).toFixed(2)
    );

    payableOvertimeHours +=
      e.details?.summary?.addSalaryForOvertime?.overtimeHours || 0;

    if (e.status === 'absent') {
      absentDays = absentDays + 1;
    }

    if (
      [
        'present',
        'present-with-late',
        'worked-on-location-off',
        'work-from-home',
        'worked-overtime',
      ].includes(e.status)
    ) {
      presentDays += 1;
    }

    if (e.status === 'present-half-day') {
      presentDays += 0.5;
    }

    if (
      e.status === 'week-off' ||
      e.status === 'worked-on-week-off' ||
      e.status === 'week-off-leave'
    ) {
      weeklyOffs.push(e.date);
    }

    if (e.status === 'location-off') {
      companyOffs.push(e.date);
    }

    if (e.details?.summary?.leaves) {
      console.log(e.details?.summary?.leaves);
      e.details?.summary.leaves.forEach((lv) => {
        if (!leaves.takenThisMonth.find((x) => x.id === lv.id)) {
          leaves.takenThisMonth.push({
            id: lv.id,
            type: lv.type,
            status: lv.status,
          });
        }
      });
      // 'sick-leave' | 'privilege-leave' | 'unpaid-leave' | 'on-job-accident-leave' | 'casual-leave' | 'maternity-leave' | 'compensatory-off' | 'special-leave' | 'retrenchment-leave';

      leaves.currentRedeemed.casual =
        leaves.takenThisMonth.filter((x) => x.type === 'casual-leave').length /
        2;
      leaves.currentRedeemed.compOff =
        leaves.takenThisMonth.filter((x) => x.type === 'compensatory-off')
          .length / 2;
      leaves.currentRedeemed.sick =
        leaves.takenThisMonth.filter((x) => x.type === 'sick-leave').length / 2;
      leaves.currentRedeemed.privilege =
        leaves.takenThisMonth.filter((x) => x.type === 'privilege-leave')
          .length / 2;
      leaves.currentRedeemed.unpaid =
        leaves.takenThisMonth.filter((x) => x.type === 'unpaid-leave').length /
        2;
      leaves.currentRedeemed.onJobAccident =
        leaves.takenThisMonth.filter((x) => x.type === 'on-job-accident-leave')
          .length / 2;
    }
  });
  return {
    payableDaysRatio: payableDaysRatio,
    payableDays: payableDays,
    absentDays: absentDays,
    presentDays: presentDays,
    payableHours: payableHours,
    payableOvertimeHours: payableOvertimeHours,
    scheduledWeeklyOffCount: weeklyOffs.length,
    holidays: {
      weeklyOffs: weeklyOffs,
      company: companyOffs,
      public: [],
    },
    leaves: leaves,
  };
}

export async function computeDynamicData({
  employee,
  salaryRev,
  date,
  attendanceData,
  companyGroupSetting,
  simulate,
  monthDetails,
  misconducts,
  salaryAdvances,
  premiums,
  getLoans,
  getRepaymentSchedules,
}: {
  employee: EmployeeProfileType;
  getLoans: (filter?: LoanFilter | undefined) => Promise<LoanV2Type[]>;
  getRepaymentSchedules: (
    filter?: RepaymentScheduleFilter
  ) => Promise<RepaymentScheduleType[]>;
  salaryRev: any;
  date: string;
  attendanceData: AttendanceType[];
  companyGroupSetting: CompanyGroupSetting;
  simulate?: boolean;
  monthDetails: {
    month: number;
    year: number;
    monthName: string;
    totalDays: number;
    totalOfficialDays: number;
  };
  misconducts: MisconductType[];
  salaryAdvances: SalaryAdvanceType[];
  premiums: PremiumsType[];
}): Promise<DynamicData> {
  const attendanceDetails = !simulate
    ? await calculateAttendanceDetails({
        employee: employee,
        month:
          monthDetails.month > 9
            ? monthDetails.month.toString()
            : `0${monthDetails.month}`,
        year: monthDetails.year.toString(),
        attendanceData,
      })
    : {
        payableDays: 30,
        absentDays: 0,
        payableDaysRatio: 30,
        presentDays: 30,
        payableHours: 0,
        payableOvertimeHours: 0,
        scheduledWeeklyOffCount: 0,
        holidays: {
          weeklyOffs: [],
          company: [],
          public: [],
        },
        leaves: {
          takenThisMonth: [],
          pending: {
            casual: 0,
            compOff: 0,
            sick: 0,
            privilege: 0,
            unpaid: 0,
            'on-job-accident-leave': 0,
          },
          currentRedeemed: {
            casual: 0,
            compOff: 0,
            sick: 0,
            privilege: 0,
            unpaid: 0,
            'on-job-accident-leave': 0,
          },
        },
      };

  const trackPunches = await isEmployeeTrackable({
    employee: employee,
    companyGroupSetting,
  });

  const bankDetails =
    employee.details.bankDetails &&
    employee.details.bankDetails.find((x) => x.active === 'yes');

  return {
    salaryRevision: salaryRev,
    salaryRules: companyGroupSetting.details.hrd?.salaryRules || {},
    includePayableDays: !simulate ? 'Yes' : 'No',
    employee: {
      apprenticeCategory: calculateApprenticeCategory(employee),
      enrolledForPTEC: enrolledForPTEC(employee),
      highestQualification: getHighestQualification(employee),
      joiningInfo: getJoiningInfo(employee),
      trackPunches: trackPunches ? 'Yes' : 'No',
      deductPT: employee.details.deductPT || 'Yes',
      location: employee.details.workLocation,
      name: employee.name,
      empId: employee.details.employeeId,
      grade: employee.details.grade.id,
      shiftType: employee.details.jobProfile.shiftType,
      pfApplicable: employee.details.pfApplicable || 'No',
      esicApplicable: !simulate
        ? getESICApplicablility(salaryRev, date)
        : getESICApplicablility(salaryRev, date) || 'No', // employee.details.esicApplicable || 'No',
      epsContributionPercent: employee.details.epsContributionPercent || 0,
      bankDetails: {
        account: bankDetails?.accountNo.toString() || '',
        branch: bankDetails?.branch || '',
        name: bankDetails?.bank || '',
      },
      pf: {
        uan:
          employee.details.employeeRegistrations?.find(
            (x) => x.regDocumentType === 'Employee Provident Fund'
          )?.accountNo || '',
        esic:
          employee.details.employeeRegistrations?.find(
            (x) => x.regDocumentType === 'ESIC'
          )?.accountNo || '',
      },
      gender: employee.details.gender as any,
      skillLevel: employee.details.jobProfile.skillLevel, // TODO Add skillevel in job profile
    },
    currentMonth: monthDetails,
    duration: 'Monthly',
    attendance: attendanceDetails,
    deductions: !simulate
      ? {
          salaryAdvances: await calculateSalaryAdvances({
            details: employee.details,
            month: monthDetails.month,
            year: monthDetails.year,
            empId: employee.id,
            salaryAdvances: salaryAdvances,
          }),
          insurancePremium: await calculateInsurancePremium({
            empId: employee.id,
            details: employee.details,
            month: monthDetails.month,
            year: monthDetails.year,
            premiums: premiums,
          }),
          loanEmi: await calculateLoanRepayment({
            details: employee.details,
            month: monthDetails.month,
            year: monthDetails.year,
            empId: employee.id,
            getLoans,
            getRepaymentSchedules,
          }),
          incomeTax: 0, // TODO This needs to be fetched from some service
          fines: await calculateFines({
            details: employee.details,
            month: monthDetails.month,
            year: monthDetails.year,
            misconducts,
            date,
            empId: employee.id,
          }),
          adhoc: calculateAdhocDeductions(
            employee.details,
            monthDetails.month,
            monthDetails.year
          ),
        }
      : {
          salaryAdvances: 0,
          insurancePremium: 0,
          loanEmi: { principle: 0, interest: 0, total: 0 },
          incomeTax: 0,
          fines: 0,
          adhoc: 0,
        },
    earnings: {
      adhoc: calculateAdhocEarnings(
        employee.details,
        monthDetails.month,
        monthDetails.year
      ),
    },
  };
}

export async function appendOuterDependencies({
  outer,
  employee,
  finalSalaryParams,
  salaryRev,
  date,
  attendanceData,
  setFinalSalaryParams,
  simulate,
  companyGroupSetting,
  monthDetails,
  misconducts,
  salaryAdvances,
  premiums,
  getLoans,
  getRepaymentSchedules,
}: {
  misconducts: MisconductType[];
  outer: any;
  employee: EmployeeProfileType;
  companyGroupSetting: CompanyGroupSetting;
  finalSalaryParams: {
    other: any;
    earning: any;
    deduction: any;
  };
  salaryRev: any;
  date: string;
  attendanceData: AttendanceType[];
  setFinalSalaryParams: React.Dispatch<
    React.SetStateAction<{
      other: any;
      earning: any;
      deduction: any;
    }>
  >;
  simulate?: boolean;
  monthDetails: {
    month: number;
    year: number;
    monthName: string;
    totalDays: number;
    totalOfficialDays: number;
  };
  salaryAdvances: SalaryAdvanceType[];
  premiums: PremiumsType[];
  getLoans: (filter?: LoanFilter | undefined) => Promise<LoanV2Type[]>;
  getRepaymentSchedules: (
    filter?: RepaymentScheduleFilter
  ) => Promise<RepaymentScheduleType[]>;
}) {
  if (outer) {
    const dd = await computeDynamicData({
      employee,
      salaryRev,
      date,
      attendanceData,
      companyGroupSetting,
      simulate,
      monthDetails,
      misconducts,
      salaryAdvances,
      premiums,
      getLoans,
      getRepaymentSchedules,
    });
    for (let key in outer) {
      if (outer[key].mapper) {
        const group = outer[key].group || 'other';
        if (_.get(dd, outer[key].mapper) !== undefined) {
          if (finalSalaryParams[group][key] !== _.get(dd, outer[key].mapper)) {
            const newFinalSalaryParams = { ...finalSalaryParams };
            newFinalSalaryParams[group][key] = _.get(dd, outer[key].mapper);
            setFinalSalaryParams(newFinalSalaryParams);
          }
          // finalSalaryParams[group][key] = _.get(dd, outer[key].mapper);
        } else {
          if (
            finalSalaryParams[group][key] ===
            ((outer[key].metric || 'number') === 'string' ? '' : 0)
          ) {
            const newFinalSalaryParams = { ...finalSalaryParams };
            newFinalSalaryParams[group][key] =
              (outer[key].metric || 'number') === 'string' ? '' : 0;
            setFinalSalaryParams(newFinalSalaryParams);
          }
          // finalSalaryParams[group][key] = (outer[key].metric || 'number') === 'string' ? '' : 0;
        }
        // console.log(key, finalSalaryParams[group][key])
      } else {
        console.log('No mapper for ', key);
      }
    }
  }
}
