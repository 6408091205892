import { Fire } from '@erp_core/erp-icons/icons/web/fire';
import { HazardStatement } from '@erp_core/erp-types/dist/types/modules/inventory/hazard';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { toast } from 'react-hot-toast';
import { UsePaginatedHazardStatement } from '../../../../hooks/admin/hazard-regulations/hazard-statements/use-hazard-paginated';
import { UseHazardStatement } from '../../../../hooks/admin/hazard-regulations/hazard-statements/use-hazard-statement';
import { UseHazardStatements } from '../../../../hooks/admin/hazard-regulations/hazard-statements/use-hazard-statements';
import { HazardStatementFilterType } from '../../../../models/interfaces/admin/hazard-regulations/hazard-statement';
import { renderAddHazardStatementsForm } from './forms/hazard-statements-add-form';

export type RenderHazardStatementProps = {
  useHazardStatements: UseHazardStatements;
  useHazardStatement: UseHazardStatement;
  usePaginatedHazardStatement: UsePaginatedHazardStatement;
};

export function renderHazardStatements({
  useHazardStatements,
  useHazardStatement,
  usePaginatedHazardStatement,
}: RenderHazardStatementProps): () => JSX.Element {
  return function HazardStatements(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      HazardStatement,
      HazardStatementFilterType
    >();

    const { syncSet: setHazardStatement } = useHazardStatement();

    // eslint-disable-next-line
    const handleHazardStatementAdd = async (data: Partial<HazardStatement>) => {
      try {
        await setHazardStatement(data as HazardStatement);
        toast('Added successfully');
      } catch (error) {
        toast((error as any).message);
      }
    };

    const HazardStatementCard = renderCardComponent();
    const HazardStatementCardHeader: CardHeader = {
      title: 'Hazard Statement',
      icon: (
        <Fire className='h-8 w-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Hazard Statement',
            behaviour: 'modal',
            modal: {
              title: 'Add Hazard Statement',
              content: ({ onClose }) => {
                const Form = renderAddHazardStatementsForm();
                return (
                  <Form
                    onSave={async (form) => {
                      handleHazardStatementAdd(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const HazardStatementTableHeader: TableHeader = [
      [{ name: 'Code' }, { name: 'Statement' }, { name: 'Chapter - Category' }],
    ];

    const bodyMapper = (r: HazardStatement) => {
      const cells: Array<TableCell> = [
        { value: r.code },
        { value: r.statement },
        {
          value: (
            <div>
              {r.hazardClasses?.map((c) => (
                <span className='mx-0.5 px-2 py-1 border border-gray-200 rounded'>
                  {c.chapterNo} - {c.categoryName}
                </span>
              ))}
            </div>
          ),
        },
      ];
      return {
        cells,
      };
    };

    const filter: Filter<HazardStatementFilterType> = {
      sortFields: [
        {
          key: 'Code',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
      ],
      filterMapper: (filterSelection: HazardStatementFilterType) => {
        const filterData: HazardStatementFilterType = {};

        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }
        return filterData as HazardStatementFilterType;
      },
    };

    const HazardStatementCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={HazardStatementTableHeader}
              bodyMapper={bodyMapper}
              useResources={useHazardStatements}
              filter={filter}
              pagination={{
                enabled: true,
                usePaginatedResources: usePaginatedHazardStatement,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <HazardStatementCard
          header={HazardStatementCardHeader}
          body={HazardStatementCardBody}
        />
      </>
    );
  };
}
