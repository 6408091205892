import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import {
  Machine,
  MachineShift,
} from '@erp_core/erp-types/dist/modules/planning';
import {
  ActionButton,
  ActionContextMenu,
  CardBody,
  CardHeader,
  Modal,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
  Toggle,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseMachine } from '../../../../../hooks/planning/production/machine/use-machine';
import { UseMachines } from '../../../../../hooks/planning/production/machine/use-machines';
import { UseShiftSchedules } from '../../../../../hooks/planning/shift-schedule/use-shift-schedules';

type RenderMachinesListProps = {
  hooks: {
    useMachines: UseMachines;
    useShiftSchedules: UseShiftSchedules;
    useMachine: UseMachine;
  };
};

// const data: Machine[] = []

function renderMachinesList({
  hooks: { useMachines, useShiftSchedules, useMachine },
}: RenderMachinesListProps) {
  return function MachinesList(): JSX.Element {
    const [modalState, setModalState] = useState({
      visible: false,
      title: '',
      content: <>Add enquiry details</>,
    });

    const [actionsState, setActionsState] = useState({
      visible: false,
      xPosition: 200,
      yPosition: 200,
      menu: ['Close'],
    });

    const { getAll, data } = useMachines();
    const { getAll: getShifts, data: shifts } = useShiftSchedules();
    const { set, data: machine } = useMachine();

    useEffect(() => {
      getAll();
      getShifts({
        // TODO: add current location
      });
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      setLoadingShiftAction('');
    }, [data, shifts]);

    useEffect(() => {
      if (machine.id) {
        getAll();
        toast('Machine updated successfully');
      }
      // eslint-disable-next-line
    }, [machine]);

    const handleActionClick = (e: any, id: string) => {
      setActionsState({
        ...actionsState,
        visible: true,
        xPosition: e.pageX,
        yPosition: e.pageY,
        menu: getActionMenu(id),
      });
    };

    // eslint-disable-next-line arrow-body-style
    const getActionMenu = (id: string) => {
      // const activeEnquiry  = data?.find((f)=>f.id===id)
      return ['Close'];
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Name', rowSpan: 2 },
        { name: 'Capacity', rowSpan: 2 },
        { name: 'location', rowSpan: 2 },
        { name: 'Actions', rowSpan: 2 },
      ],
    ];

    const tableData: TableBody =
      data?.map((item, index) => ({
        cells: [
          {
            value: item.name,
            link: `/production/scheduler/machines/${item.id}`,
          },
          { value: item.details.capacity || '--' },
          { value: item.location ? item.location.name : '' },
          {
            value: (
              <ActionButton
                handleActionClick={handleActionClick}
                id={item.id}
              />
            ),
          },
        ],
      })) || [];

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Machines List',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: <Table header={tableHeader} body={tableData} />,
    };

    const dynamicColumns = shifts
      ? shifts.map((ms) => ({
          name: `${ms.name} ${ms.startTime}`,
        }))
      : [];
    const tableShiftsHeader: TableHeader = [
      [{ name: 'Name' }, ...dynamicColumns],
    ];

    const [loadingShiftAction, setLoadingShiftAction] = useState('');

    const tableShiftData: TableBody =
      data?.map((item, index) => {
        const dynamicCells = shifts
          ? shifts.map((m) => {
              const ms = item.machineShifts.find((u) => u.shift.id === m.id);
              if (ms) {
                if (loadingShiftAction === ms.id) {
                  return {
                    value: <Recat className='h-5 inline animate-pulse mx-4' />,
                  };
                }
                return {
                  value: (
                    <Toggle
                      onChange={(state: boolean) => {
                        setLoadingShiftAction(ms.id);
                        set({
                          id: item.id,
                          machineShifts: [
                            {
                              id: ms.id,
                              operational: state,
                            } as MachineShift,
                          ],
                        } as Machine);
                      }}
                      toggle={ms.operational}
                    />
                  ),
                };
              }
              return {
                value: (
                  <Toggle
                    onChange={(state: boolean) => {
                      set({
                        id: item.id,
                        machineShifts: [
                          {
                            operational: state,
                            name: m.name,
                            details: {
                              startTime: m.startTime,
                              endTime: m.endTime,
                            },
                            shift: {
                              id: m.id,
                              name: '',
                            },
                            machine: item,
                          },
                        ],
                      } as Machine);
                    }}
                    toggle={false}
                  />
                ),
              };
            })
          : [];
        return {
          cells: [
            { value: item.name, link: `/production/machine/${item.id}` },
            ...dynamicCells,
          ],
        };
      }) || [];

    const cardShiftHeader: CardHeader = {
      title: 'Machines shift activity',
    };

    const cardShiftBody: CardBody = {
      type: 'jsx-component',
      body: <Table header={tableShiftsHeader} body={tableShiftData} />,
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
        <Card header={cardShiftHeader} body={cardShiftBody} />
        <ActionContextMenu
          actionsState={actionsState}
          setActionsState={setActionsState}
          handleActionItemClick={() => {}}
        />
        <Modal
          showModal={modalState.visible}
          setShowModal={() =>
            setModalState({ ...modalState, visible: !modalState.visible })
          }
          title={modalState.title}
          onOk={() => {}}
          style={{ width: '800px' }}
        >
          {modalState.content}
        </Modal>
      </div>
    );
  };
}

export default renderMachinesList;
