import { SalesOrderItem } from '@erp_core/erp-types/dist/modules/order';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseCurrentUser } from '../../../../hooks/admin/user-admin/use-current-user';
import { UseBatchItemAllocate } from '../../../../hooks/inventory/item/batch/use-batch-item-allocate';
import { UseBatchItemGodowns } from '../../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { UsePhysicalStock } from '../../../../hooks/inventory/item/stock/use-physical-stock';
import { UseVirtualMFStock } from '../../../../hooks/inventory/item/stock/use-virtual-manufacturing-stock';
import { UseManufacturingVouchers } from '../../../../hooks/order/work-order/mf-voucher/use-manufacturing-vouchers';
import { UseVirtuallyReserveMfVoucher } from '../../../../hooks/order/work-order/mf-voucher/use-virtually-reserve-manufacturing-voucher';
import {
  AllocateOrderFormRequestType,
  OrderAllocationForm,
} from '../forms/allocate';
import { renderManufacturingSalesItemForm } from '../forms/manufacturing';
import { AllocateVirtuallyMv } from '../forms/virtually-allocate-form';

export function renderOrderItemActions({
  usePhysicalStock,
  showModal,
  useBatchItemGodowns,
  useVirtualMfStock,
  handleSaveAllocation,
  useManufacturingVouchers,
  setModalState,
  useBatchGodownItemAllocate,
  useVirtuallyReserveMfVoucher,
  useCurrentUser,
}: {
  useCurrentUser: UseCurrentUser;
  usePhysicalStock: UsePhysicalStock;
  useBatchItemGodowns: UseBatchItemGodowns;
  showModal: (data: { title: string; content: JSX.Element }) => void;
  useVirtualMfStock: UseVirtualMFStock;
  setModalState: any;
  useManufacturingVouchers: UseManufacturingVouchers;
  handleSaveAllocation: any;
  useBatchGodownItemAllocate: UseBatchItemAllocate;
  useVirtuallyReserveMfVoucher: UseVirtuallyReserveMfVoucher;
}): ({ itemOrder }: { itemOrder: SalesOrderItem }) => JSX.Element {
  return function ItemAction({
    itemOrder,
  }: {
    itemOrder: SalesOrderItem;
  }): JSX.Element {
    const { data: physicalStock, get: getPhysicalStock } = usePhysicalStock();
    const {
      data: virtualMfStockData,
      get: getVirtualMfStock,
    } = usePhysicalStock();

    const [actionList, setActionList] = useState<
      Array<{ title: string; modalTitle?: string; content?: JSX.Element }>
    >([]);

    useEffect(() => {
      getPhysicalStock(itemOrder.item.id);
      getVirtualMfStock(itemOrder.item.id);
      // eslint-disable-next-line
    }, []);

    // const qtyBalanced = getBalancedQuantity({referenceId: bom.id, total: bom.details.rawMaterial?.quantity || 0, usePurchaseOrders, useBatchItemGodowns}) ;
    const qtyBalanced = 100; // will change this on virtual allocation
    useEffect(() => {
      const psUnallocated = physicalStock.unAllocated
        ? physicalStock.unAllocated
        : 0; // this should be via api

      const { unAllocated } = virtualMfStockData;

      const vrMfUnallocated = unAllocated || 0;

      const actions: Array<{
        title: string;
        modalTitle?: string;
        content?: JSX.Element;
      }> = [];

      if (qtyBalanced <= 0) {
        actions.push({ title: 'Mark done' });
      }

      if (psUnallocated > 0) {
        const req: AllocateOrderFormRequestType = {
          id: itemOrder.id,
          rawItem: {
            id: itemOrder.item.id,
            name: itemOrder.item.name,
            quantity: itemOrder.quantity,
            uom: 'kg',
          },
          schedule: [], // TODO: itemOrder.details.schedule
        };
        actions.push({
          title: 'Allocate',
          modalTitle: `Allocate ${itemOrder.item.name}`,
          content: (
            <OrderAllocationForm
              useCurrentUser={useCurrentUser}
              useBatchItemGodowns={useBatchItemGodowns}
              enquiryItemData={req}
              useBatchGodownItemAllocate={useBatchGodownItemAllocate}
              onSave={handleSaveAllocation}
              onCancel={() =>
                setModalState((ms) => ({ ...ms, visible: false }))
              }
            />
          ),
        });

        if (vrMfUnallocated > 0) {
          actions.push({
            title: 'Virtually Allocate',
            modalTitle: `Virtually Allocate ${itemOrder.item.name} for order ${itemOrder.name}`,
            content: (
              <AllocateVirtuallyMv
                orderItemData={itemOrder}
                onSave={(e) => {
                  // console.log(e);
                }}
                onCancel={() => {}}
                useManufacturingVouchers={useManufacturingVouchers}
                useVirtuallyReserveMfVoucher={useVirtuallyReserveMfVoucher} // Todo create VirtualMvReserve hook
              />
            ),
          });
        }

        const MFSalesItemForm = renderManufacturingSalesItemForm();

        actions.push({
          title: 'Raise Manufacturing Voucher',
          modalTitle: `Raise Manufacturing for ${itemOrder.name}`,
          content: (
            <MFSalesItemForm
              onSave={() => {
                toast('Saved Sucessfully');
                setModalState((ms) => ({ ...ms, visible: false }));
              }}
            />
          ),
        });
      }

      setActionList(actions);
      // eslint-disable-next-line
    }, [physicalStock, qtyBalanced, virtualMfStockData]);

    return (
      <div>
        {actionList.map((a, index) => (
          <div
            key={index}
            className={
              index % 2 === 0
                ? 'cursor-pointer p-2'
                : 'bg-gray-200 cursor-pointer p-2'
            }
            onClick={() => {
              if (a.content) {
                showModal({
                  title: a.modalTitle || a.title,
                  content: a.content,
                });
              }
            }}
          >
            {a.title}
          </div>
        ))}
      </div>
    );
  };
}
