import { XlsxDownloadMapper } from '@erp_core/erp-ui-components';

export const ESICMapper: XlsxDownloadMapper = [
  {
    columnName: 'IP Number',
    dataBinding: {
      property: 'esic_number',
    },
  },
  {
    columnName: 'IP Name',
    dataBinding: { property: 'name' },
  },
  {
    columnName: 'No of Days for which wages paid/payable during the month',
    dataBinding: { property: 'noofdaysforwhichwagespaid' },
  },
  {
    columnName: 'Total Monthly Wages',
    dataBinding: { property: 'totalmonthlywages' },
  },
  {
    columnName: 'Reason Code for Zero workings days',
    dataBinding: { property: 'reasoncodeforzeroworkingsdays' },
  },
  {
    columnName: 'Last Working Day',
    dataBinding: { property: 'lastworkingday' },
  },
];
