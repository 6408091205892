import {
  Client,
  ClientContact,
  VendorLocation,
} from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  FormField,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { v4 as uuid } from 'uuid';

type RenderClientContactProps = {
  client: Client;
  setClient: (s: Client) => Promise<Client>;
};

export const renderClientContact = ({
  client,
  setClient,
}: RenderClientContactProps) => {
  return function ClientContact() {
    const Card = renderCardComponent();

    const addContact = async (data: any) => {
      const cnt: ClientContact = {
        id: uuid(),
        name: data.name,
        designation: data.designation,
        location: data.location,
        phones: [data.phone],
        emails: [data.email],
      };
      const finalData = {
        id: client.id,
        details: {
          ...client.details,
          contact: client.details?.contact
            ? [...client.details.contact, cnt]
            : [cnt],
        },
      };
      await setClient(finalData as Client);
    };

    const editContact = async (data: any, index: number) => {
      const updatedContacts =
        client.details?.contact &&
        client.details?.contact.map((c, i) => {
          if (i === index)
            return {
              name: data.name || c.name,
              designation: data.designation || c.designation,
              phones: data.phone ? [data.phone] : c.phones,
              emails: data.email ? [data.email] : c.emails,
              location: data.location || c.location,
            };
          return c;
        });
      const finalData = {
        id: client.id,
        details: {
          ...client.details,
          contact: updatedContacts,
        },
      };
      await setClient(finalData as Client);
    };

    const addAddress = async (data: any) => {
      const cnt: VendorLocation = {
        type: data.type,
        id: uuid(),
        name: data.name,
        address: {
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          city: data.city,
          district: data.district,
          state: data.state,
          pincode: data.pincode,
          country: data.country,
        },
        phones: [data.phone],
        emails: [data.email],
      };
      const finalData = {
        id: client.id,
        details: {
          ...client.details,
          locations: client.details?.locations
            ? [...client.details.locations, cnt]
            : [cnt],
        },
      };
      await setClient(finalData as Client);
    };

    const editAddress = async (data: any, index: number) => {
      const addressToUpdate: VendorLocation | undefined = client.details
        ?.locations
        ? client.details.locations[index]
        : undefined;

      if (addressToUpdate) {
        addressToUpdate.name = data.name || addressToUpdate.name;
        addressToUpdate.emails = data.email
          ? [data.email]
          : addressToUpdate.emails;
        addressToUpdate.phones = data.phone
          ? [data.phone]
          : addressToUpdate.phones;
        addressToUpdate.address = {
          addressLine1:
            data.addressLine1 || addressToUpdate.address.addressLine1,
          addressLine2:
            data.addressLine2 || addressToUpdate.address.addressLine2,
          city: data.city || addressToUpdate.address.city,
          district: data.district || addressToUpdate.address.district,
          state: data.state || addressToUpdate.address.state,
          pincode: data.pincode || addressToUpdate.address.pincode,
          country: data.country || addressToUpdate.address.country,
        };
      }
      const finalData = {
        id: client.id,
        details: {
          ...client.details,
          locations: client.details.locations,
        },
      };
      await setClient(finalData as Client);
    };

    type AddressForm = {
      name: string;
      addressLine1: string;
      addressLine2: string;
      city: string;
      district: string;
      pincode: string;
      state: string;
      phone: string;
      email: string;
      country: string;
    };

    // TODO: Add Validations for this form
    const fields: Array<FormField<AddressForm>> = [
      { property: 'name', type: 'input' },
      { property: 'addressLine1', type: 'input' },
      { property: 'addressLine2', type: 'input' },
      { property: 'city', type: 'input' },
      { property: 'district', type: 'input' },
      { property: 'pincode', type: 'input' },
      { property: 'state', type: 'input' },
      { property: 'country', type: 'input' },
      { property: 'phone', type: 'input' },
      { property: 'email', type: 'input' },
    ];

    const contactCardHeader: CardHeader = {
      title: 'Contacts',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add Contact',
              content: ({ onClose }) => {
                const initialOptions = [{ value: '', text: 'Select' }];
                const fields: Array<FormField<{
                  name: string;
                  designation: string;
                  phone: string;
                  email: string;
                  location: string;
                }>> = [
                  {
                    property: 'name',
                    type: 'input',
                  },
                  {
                    property: 'designation',
                    type: 'input',
                  },
                  {
                    property: 'location',
                    type: 'select',
                    options: [
                      ...initialOptions,
                      ...(client?.details?.locations?.map((m) => ({
                        value: m.name,
                        text: m.name,
                      })) || []),
                    ],
                  },
                  {
                    property: 'phone',
                    type: 'input',
                  },
                  {
                    property: 'email',
                    type: 'input',
                  },
                ];
                const Form = renderFormV2<any>({
                  style: 'md:w-1/2',
                  fieldsData: fields,
                  initialFormState: {
                    name: '',
                    designation: '',
                    phone: '',
                    email: '',
                    location: '',
                  },
                  onSubmit: async (data) => {
                    await addContact(data);
                    onClose();
                  },
                  mapTToU: (b) => b,
                });

                return <Form />;
              },
            },
          },
        },
      ],
    };
    const Table = renderTableComponent();

    const contactCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={[
                [
                  { name: 'Name' },
                  { name: 'Designation' },
                  { name: 'Location' },
                  { name: 'Email' },
                  { name: 'Phone' },
                ],
              ]}
              body={(client.details?.contact || [])?.map((c, index) => {
                return {
                  cells: [
                    { value: c.name },
                    { value: c.designation },
                    { value: c.location },
                    { value: c.emails?.join() },
                    { value: c.phones?.join() },
                  ],
                  rowData: {
                    contact: c,
                    index,
                  },
                };
              })}
              actions={[
                {
                  name: 'Edit',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit',
                    content: ({
                      data: { contact, index },
                      onClose,
                    }: {
                      data: { contact: ClientContact; index: number };
                      onClose: () => void;
                    }) => {
                      const fields: Array<FormField<{
                        name: string;
                        designation: string;
                        phone: string;
                        email: string;
                        location: string;
                      }>> = [
                        {
                          property: 'name',
                          type: 'input',
                        },
                        {
                          property: 'designation',
                          type: 'input',
                        },
                        {
                          property: 'location',
                          type: 'select',
                          options: [
                            ...[{ value: '', text: 'Select' }],
                            ...(client?.details?.locations?.map((m) => ({
                              value: m.name,
                              text: m.name,
                            })) || []),
                          ],
                        },
                        {
                          property: 'phone',
                          type: 'input',
                        },
                        {
                          property: 'email',
                          type: 'input',
                        },
                      ];
                      const Form = renderFormV2<any>({
                        style: 'md:w-1/2',
                        fieldsData: fields,
                        initialFormState: {
                          name: contact.name || '',
                          designation: contact.designation || '',
                          phone: (contact.phones && contact.phones[0]) || '',
                          email: (contact.emails && contact.emails[0]) || '',
                          location: contact.location || '',
                        },
                        onSubmit: async (form) => {
                          await editContact(form, index);
                          onClose();
                        },
                        mapTToU: (b) => b,
                      });

                      return <Form />;
                    },
                  },
                },
                {
                  name: 'Delete',
                  show: () => true,
                  behaviour: 'confirm',
                  onConfirm: ({ contact, index }) => {
                    return {
                      type: 'warning',
                      onConfirm: async () => {
                        const updatedContacts =
                          client.details?.contact &&
                          client.details?.contact.map((c, i) => {
                            if (i === index) return null;
                            return c;
                          });
                        const finalData = {
                          id: client.id,
                          details: {
                            ...client.details,
                            contact: updatedContacts?.filter(Boolean),
                          },
                        };
                        await setClient(finalData as Client);
                      },
                      message: `Are you sure you want to delete this Contact '${contact.name}'? This cannot be undone.`,
                    };
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    const addressCardHeader: CardHeader = {
      title: 'Address',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add Address',
              content: ({ onClose }) => {
                const Form = renderFormV2<any>({
                  style: 'md:w-1/2',
                  fieldsData: fields,
                  initialFormState: {
                    name: '',
                    phone: '',
                    email: '',
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    district: '',
                    pincode: '',
                    state: '',
                    country: '',
                  },
                  onSubmit: async (data) => {
                    await addAddress(data);
                    onClose();
                  },
                  mapTToU: (b) => b,
                });

                return <Form />;
              },
            },
          },
        },
      ],
    };

    const addressCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={[
                [
                  { name: 'Name' },
                  { name: 'Address' },
                  { name: 'Email' },
                  { name: 'Phone' },
                ],
              ]}
              body={(client.details?.locations || [])?.map((c, index) => {
                return {
                  rowData: {
                    location: c,
                    index,
                  },
                  cells: [
                    { value: c.name },
                    {
                      value: (
                        <>
                          <div className='font-semibold'>
                            {c.address.addressLine1}
                          </div>
                          <div className='font-semibold'>
                            {c.address.addressLine2}
                          </div>
                          <div className='font-semibold'>
                            {c.address.city}, {c.address.district},{' '}
                            {c.address.state}
                          </div>
                          <div className='font-semibold'>
                            {c.address.country}
                          </div>
                          <div className='font-semibold'>
                            {c.address.pincode}
                          </div>
                        </>
                      ),
                    },
                    { value: c.emails?.join() },
                    { value: c.phones?.join() },
                  ],
                };
              })}
              actions={[
                {
                  name: 'Edit',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit',
                    content: ({
                      data: { location, index },
                      onClose,
                    }: {
                      data: { location: VendorLocation; index: number };
                      onClose: () => void;
                    }) => {
                      const Form = renderFormV2<any>({
                        style: 'md:w-1/2',
                        fieldsData: fields,
                        initialFormState: {
                          name: location.name || '',
                          phone: (location.phones && location.phones[0]) || '',
                          email: (location.emails && location.emails[0]) || '',
                          addressLine1: location?.address?.addressLine1 || '',
                          addressLine2: location?.address?.addressLine2 || '',
                          city: location?.address.city || '',
                          district: location?.address?.district || '',
                          pincode: location?.address?.pincode || '',
                          state: location?.address?.state || '',
                          country: location?.address.country || '',
                        },
                        onSubmit: async (form) => {
                          await editAddress(form, index);
                          onClose();
                        },
                        mapTToU: (b) => b,
                      });

                      return <Form />;
                    },
                  },
                },
                {
                  name: 'Delete',
                  show: () => true,
                  behaviour: 'confirm',
                  onConfirm: ({ location, index }) => {
                    return {
                      type: 'warning',
                      onConfirm: async () => {
                        const updatedAddresses =
                          client.details?.locations &&
                          client.details?.locations.map((c, i) => {
                            if (i === index) return null;
                            return c;
                          });
                        const finalData = {
                          id: client.id,
                          details: {
                            ...client.details,
                            locations: updatedAddresses?.filter(Boolean),
                          },
                        };
                        await setClient(finalData as Client);
                      },
                      message: `Are you sure you want to delete this Address '${location.name}'? This cannot be undone.`,
                    };
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <div className='w-full space-y-3'>
        <Card header={addressCardHeader} body={addressCardBody} />
        <div className='h-4' />
        <Card header={contactCardHeader} body={contactCardBody} />
        <div className='h-4' />
      </div>
    );
  };
};
