import { Recat } from '@erp_core/erp-icons/icons/web/recat';

export type CGConfigs = {
  settingName: string;
  settingValue?: string | JSX.Element;
  settingValueSuffix?: string;
  editAction: () => void;
};

export const CGConfigRenderer = ({
  configs,
  loading,
}: {
  configs: CGConfigs[];
  loading: boolean;
}) => {
  return (
    <div className='w-full flex flex-wrap'>
      {configs.map((c, i) => {
        return (
          <div
            key={c.settingName}
            onClick={c.editAction}
            className='md:basis-1/2 '
          >
            <div className='md:basis-1/2 hover:shadow-md m-3 p-3 hover:cursor-pointer border'>
              <div className='flex'>
                <div>{c.settingName}</div>
                <div>
                  {loading ? (
                    <Recat className='h-5 inline animate-pulse mx-4' />
                  ) : c.settingValue ? (
                    <span className='font-bold mx-2'>
                      {c.settingValue}
                      {c.settingValueSuffix}
                    </span>
                  ) : (
                    'Not yet specified.'
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
