/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { PlusIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseCurrentCompanyGroup } from '../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseCurrentUser } from '../../../hooks/admin/user-admin/use-current-user';
import { UseReport } from '../../../hooks/reporting/reports/use-report';
import { UseReports } from '../../../hooks/reporting/reports/use-reports';
import { ReportsType } from '../../../models/types/reporting/reports';
import { UserRendererInterface } from '../../common/fragments/user';
import { renderAddReportForm } from './form/add-report-form';

type RenderReport = {
  useReport: UseReport;
  useReports: UseReports;
  useCurrentUser: UseCurrentUser;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  userRendererService: UserRendererInterface;
};
export const renderReportList = ({
  useReport,
  useReports,
  useCurrentUser,
  useCurrentCompanyGroup,
  userRendererService,
}: RenderReport) => {
  return function Report() {
    const { data: reports, getAll: getReports } = useReports();
    const { syncSet: setReport } = useReport();

    const { data: currentUser } = useCurrentUser();
    const { currentCompanyGroup: companyGroup } = useCurrentCompanyGroup();

    useEffect(() => {}, [reports]);

    useEffect(() => {
      getReports();
      // eslint-disable-next-line
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Reports',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Report',
            style: 'float-right bg-green-600',
            suffix: <PlusIcon className='w-5 h-5 text-black-800 inline' />,
            behaviour: 'modal',
            modal: {
              title: 'Add Report',
              content: () => {
                const Form = renderAddReportForm();
                return <Form onSave={saveReport} />;
              },
            },
          },
        },
      ],
    };

    const saveReport = async (form: Partial<ReportsType>) => {
      try {
        const finalData = {
          ...form,
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        } as ReportsType;
        await setReport(finalData as ReportsType);
        toast('Data added sucessfully');
        getReports();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Module' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
      ],
    ];

    const tableBody: TableBody =
      reports?.map((l, idx) => ({
        cells: [
          { value: l.name, link: `/miscellaneous/reports/${l.id}` },
          { value: l.module },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={l.createdBy?.id}
                name={l.createdBy?.name}
                extraInfo={moment.utc(l.createdAt).fromNow()}
              />
            ),
          },
        ],
      })) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table header={tableHeader} body={tableBody} />
          </div>
        </div>
      ),
    };

    return (
      <>
        <div>
          <Card header={cardHeader} body={cardBody} />
        </div>
      </>
    );
  };
};
