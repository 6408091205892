import {
  RowsType,
  RowValuesType,
  StatutoryReportBuilderType,
} from '@erp_core/erp-types/dist/types/modules/hrd/statutory-returns/report-builder';
import {
  CardBody,
  LoadingButton,
  ModalV2Props,
  renderCardComponent,
  renderModal,
} from '@erp_core/erp-ui-components';
import { PencilIcon, PlayIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { UseReport } from '../../../hooks/reporting/reports/use-report';
import { ReportsType } from '../../../models/types/reporting/reports';
import { reportEdit } from './components/edit';
import { functionsStored } from './function-store';

export type StatutoryReportBuilderProps = {
  useReport: UseReport;
};

export function renderStatutoryReportBuilder({
  useReport,
}: StatutoryReportBuilderProps): () => JSX.Element {
  const Card = renderCardComponent();
  const Modal = renderModal();

  return function StatutoryReportBuilder(): JSX.Element {
    const { id } = useParams();
    const {
      data: selectedReport,
      get: getReport,
      syncSet: setReport,
    } = useReport();

    useEffect(() => {
      if (id) {
        getReport(id);
      }

      // eslint-disable-next-line
    }, [id]);

    const [statutoryReport, setStatutoryReport] = useState<
      StatutoryReportBuilderType
    >({
      description: '',
      docType: 'select',
      reportSpecs: {
        separator: 'select',
        linebreak: 'select',
        rows: [] as Array<RowsType>,
      },
    });

    useEffect(() => {
      console.log(selectedReport);
      if (selectedReport?.details) {
        setStatutoryReport({ ...statutoryReport, ...selectedReport.details });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedReport]);

    const [modal, setModal] = useState<ModalV2Props>({
      isVisible: false,
      title: '',
      onClose() {
        setModal({ ...modal, isVisible: false, body: <div />, title: '' });
      },
      body: <div />,
    });

    const rowsCardBody: CardBody =
      {
        type: 'jsx-component',
        body: (
          <>
            {statutoryReport.reportSpecs?.rows?.length ? (
              <>
                {statutoryReport.reportSpecs?.rows?.map((row, idx) => (
                  <div className='my-2' key={idx}>
                    <Card
                      header={{
                        title: <></>,
                        subheading: (
                          <>
                            <div className='rounded-lg group'>
                              {row.name ? (
                                <span className=''>Name: {row?.name}</span>
                              ) : (
                                <span className='italic text-gray-700'>
                                  Add Name
                                </span>
                              )}
                              <PencilIcon
                                className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                onClick={() =>
                                  reportEdit({
                                    modal,
                                    setModal,
                                    statutoryReport,
                                    setStatutoryReport,
                                    obj: row,
                                    propName: 'name',
                                    formType: 'text',
                                  })
                                }
                              />
                            </div>
                            <div className='group'>
                              {row.type ? (
                                <span className='bold'>Type : {row.type}</span>
                              ) : (
                                <span className='italic text-gray-700'>
                                  Add Type
                                </span>
                              )}
                              <PencilIcon
                                className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                onClick={() =>
                                  reportEdit({
                                    modal,
                                    setModal,
                                    statutoryReport,
                                    setStatutoryReport,
                                    obj: row,
                                    propName: 'type',
                                    formType: 'select',
                                    options: [
                                      'header',
                                      'details',
                                      'summary',
                                      'summary-details',
                                    ],
                                  })
                                }
                              />
                            </div>
                          </>
                        ),
                        actions: [
                          {
                            type: 'jsx',
                            jsx: (
                              <LoadingButton
                                defaultStyle='bg-green-500 text-white mx-1 p-1'
                                behaviorFn={async () => {
                                  row?.values?.length
                                    ? row?.values?.push({
                                        valueType: 'fixed',
                                      } as RowValuesType)
                                    : (row.values = [
                                        { valueType: 'fixed' } as RowValuesType,
                                      ]);
                                  setStatutoryReport({ ...statutoryReport });
                                }}
                                text='+ Add Column'
                              />
                            ),
                          },
                        ],
                      }}
                      body={{
                        type: 'jsx-component',
                        body: (
                          <>
                            {row?.values.length ? (
                              <>
                                <table className='inline-flex'>
                                  {row?.values.map((rowVal, id) => (
                                    <tbody key={id}>
                                      <tr>
                                        <td className='group border-solid border-2 border-black'>
                                          {rowVal?.valueType ||
                                            'Add Value Type'}
                                          <PencilIcon
                                            className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                            onClick={() =>
                                              reportEdit({
                                                modal,
                                                setModal,
                                                statutoryReport,
                                                setStatutoryReport,
                                                obj: rowVal,
                                                propName: 'valueType',
                                                formType: 'select',
                                                options: [
                                                  'fixed',
                                                  'formula',
                                                  'function-store',
                                                ],
                                              })
                                            }
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className='group border-solid border-2 border-black'>
                                          {rowVal?.valueType !==
                                          'function-store' ? (
                                            <>
                                              {rowVal?.value ||
                                                'Add text value'}
                                              <PencilIcon
                                                className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                                onClick={() =>
                                                  reportEdit({
                                                    modal,
                                                    setModal,
                                                    statutoryReport,
                                                    setStatutoryReport,
                                                    obj: rowVal,
                                                    propName: 'value',
                                                    formType: 'text',
                                                  })
                                                }
                                              />
                                            </>
                                          ) : (
                                            <>
                                              {rowVal?.value ||
                                                'Add function Value'}
                                              <PencilIcon
                                                className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                                onClick={() =>
                                                  reportEdit({
                                                    modal,
                                                    setModal,
                                                    statutoryReport,
                                                    setStatutoryReport,
                                                    obj: rowVal,
                                                    propName: 'value',
                                                    formType: 'select',
                                                    options: functionsStored.map(
                                                      (fcn) => fcn.name
                                                    ),
                                                  })
                                                }
                                              />
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))}
                                </table>
                              </>
                            ) : null}
                          </>
                        ),
                      }}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                {statutoryReport?.reportSpecs?.rows?.map((row, idx) => (
                  <div className='my-2' key={idx}>
                    <Card
                      header={{
                        title: <></>,
                        subheading: (
                          <>
                            <div className='rounded-lg group'>
                              {row.name ? (
                                <span className=''>Name: {row?.name}</span>
                              ) : (
                                <span className='italic text-gray-700'>
                                  Add Name
                                </span>
                              )}
                              <PencilIcon
                                className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                onClick={() =>
                                  reportEdit({
                                    modal,
                                    setModal,
                                    statutoryReport,
                                    setStatutoryReport,
                                    obj: row,
                                    propName: 'name',
                                    formType: 'text',
                                  })
                                }
                              />
                            </div>
                            <div className='group'>
                              {row.type ? (
                                <span className='bold'>Type : {row.type}</span>
                              ) : (
                                <span className='italic text-gray-700'>
                                  Add Type
                                </span>
                              )}
                              <PencilIcon
                                className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                onClick={() =>
                                  reportEdit({
                                    modal,
                                    setModal,
                                    statutoryReport,
                                    setStatutoryReport,
                                    obj: row,
                                    propName: 'type',
                                    formType: 'select',
                                    options: [
                                      'header',
                                      'details',
                                      'summary',
                                      'summary-details',
                                    ],
                                  })
                                }
                              />
                            </div>
                          </>
                        ),
                        actions: [
                          {
                            type: 'jsx',
                            jsx: (
                              <LoadingButton
                                defaultStyle='bg-green-500 text-white mx-1 p-1'
                                behaviorFn={async () => {
                                  row?.values?.length
                                    ? row?.values?.push({
                                        valueType: 'fixed',
                                      } as RowValuesType)
                                    : (row.values = [
                                        { valueType: 'fixed' } as RowValuesType,
                                      ]);
                                  setStatutoryReport({ ...statutoryReport });
                                }}
                                text='+ Add Column'
                              />
                            ),
                          },
                        ],
                      }}
                      body={{
                        type: 'jsx-component',
                        body: (
                          <>
                            {row?.values.length ? (
                              <>
                                <table className='inline-flex'>
                                  {row?.values.map((rowVal, id) => (
                                    <table key={id}>
                                      <tr>
                                        <td className='group border-solid border-2 border-black'>
                                          {rowVal?.valueType ||
                                            'Add Value Type'}
                                          <PencilIcon
                                            className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                            onClick={() =>
                                              reportEdit({
                                                modal,
                                                setModal,
                                                statutoryReport,
                                                setStatutoryReport,
                                                obj: rowVal,
                                                propName: 'valueType',
                                                formType: 'select',
                                                options: [
                                                  'fixed',
                                                  'formula',
                                                  'function-store',
                                                ],
                                              })
                                            }
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className='group border-solid border-2 border-black'>
                                          {rowVal?.valueType !==
                                          'function-store' ? (
                                            <>
                                              {rowVal?.value ||
                                                'Add text value'}
                                              <PencilIcon
                                                className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                                onClick={() =>
                                                  reportEdit({
                                                    modal,
                                                    setModal,
                                                    statutoryReport,
                                                    setStatutoryReport,
                                                    obj: rowVal,
                                                    propName: 'value',
                                                    formType: 'text',
                                                  })
                                                }
                                              />
                                            </>
                                          ) : (
                                            <>
                                              {rowVal?.value ||
                                                'Add function Value'}
                                              <PencilIcon
                                                className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                                onClick={() =>
                                                  reportEdit({
                                                    modal,
                                                    setModal,
                                                    statutoryReport,
                                                    setStatutoryReport,
                                                    obj: rowVal,
                                                    propName: 'value',
                                                    formType: 'select',
                                                    options: functionsStored.map(
                                                      (fcn) => fcn.name
                                                    ),
                                                  })
                                                }
                                              />
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    </table>
                                  ))}
                                </table>
                              </>
                            ) : null}
                          </>
                        ),
                      }}
                    />
                  </div>
                ))}
              </>
            )}
          </>
        ),
      } || {};

    const mainCardBody: CardBody =
      {
        type: 'jsx-component',
        body: (
          <>
            <Card
              header={{
                title: 'Rows',
                actions: [
                  {
                    type: 'jsx',
                    jsx: (
                      <LoadingButton
                        defaultStyle='bg-green-500 text-white mx-1 p-1'
                        behaviorFn={async () => {
                          statutoryReport.reportSpecs.rows
                            ? statutoryReport.reportSpecs.rows.push({
                                name: '',
                                type: 'header',
                                values: [],
                              })
                            : (statutoryReport.reportSpecs.rows = [
                                { name: '', type: 'header', values: [] },
                              ]);
                          setStatutoryReport({ ...statutoryReport });
                        }}
                        text='+ Add Row'
                      />
                    ),
                  },
                ],
              }}
              body={rowsCardBody}
            />
          </>
        ),
      } || {};

    return (
      <>
        <Card
          header={{
            title: (
              <>
                <div className='bold rounded-lg group'>
                  <span>Name: {selectedReport?.name || '--'}</span>
                  <PencilIcon
                    className='hidden group-hover:inline cursor-pointer text-gray-800 px-2 w-6 h-6'
                    onClick={() =>
                      reportEdit({
                        modal,
                        setModal,
                        statutoryReport,
                        setStatutoryReport,
                        obj: selectedReport,
                        propName: 'name',
                        formType: 'text',
                      })
                    }
                  />
                </div>
              </>
            ),
            subheading: (
              <>
                <div className='inline-flex'>
                  <div className='bold rounded-lg group'>
                    <span>
                      Description:{' '}
                      {selectedReport?.details?.description ||
                        statutoryReport.description ||
                        '--'}
                    </span>
                    <PencilIcon
                      className='hidden group-hover:inline cursor-pointer text-gray-800 px-2 w-6 h-6'
                      onClick={() =>
                        reportEdit({
                          modal,
                          setModal,
                          statutoryReport,
                          setStatutoryReport,
                          obj: statutoryReport,
                          propName: 'description',
                          formType: 'text',
                        })
                      }
                    />
                  </div>
                  <div className='bold rounded-lg group px-1'>
                    <span>
                      Document Type:{' '}
                      {selectedReport?.details?.docType ||
                        statutoryReport.docType ||
                        '--'}
                    </span>
                    <PencilIcon
                      className='hidden group-hover:inline cursor-pointer text-gray-800 px-2 w-6 h-6'
                      onClick={() =>
                        reportEdit({
                          modal,
                          setModal,
                          statutoryReport,
                          setStatutoryReport,
                          obj: statutoryReport,
                          propName: 'docType',
                          formType: 'select',
                          options: [
                            'select',
                            'summary',
                            'detailed',
                            'detailed-summary',
                          ],
                        })
                      }
                    />
                  </div>
                  <div className='bold rounded-lg group px-1'>
                    <span>
                      Separator:{' '}
                      {selectedReport?.details?.reportSpecs?.separator ||
                        statutoryReport?.reportSpecs?.separator ||
                        '--'}
                    </span>
                    <PencilIcon
                      className='hidden group-hover:inline cursor-pointer text-gray-800 px-2 w-6 h-6'
                      onClick={() => {
                        console.log(statutoryReport);
                        reportEdit({
                          modal,
                          setModal,
                          statutoryReport,
                          setStatutoryReport,
                          obj: statutoryReport.reportSpecs,
                          propName: 'separator',
                          formType: 'select',
                          options: [
                            'select',
                            'comma',
                            'semi-colon',
                            'hash-tilda',
                          ],
                        });
                      }}
                    />
                  </div>
                  <div className='bold rounded-lg group px-1'>
                    <span>
                      Line Break:{' '}
                      {selectedReport?.details?.reportSpecs?.linebreak ||
                        statutoryReport?.reportSpecs?.linebreak ||
                        '--'}
                    </span>
                    <PencilIcon
                      className='hidden group-hover:inline cursor-pointer text-gray-800 px-2 w-6 h-6'
                      onClick={() =>
                        reportEdit({
                          modal,
                          setModal,
                          statutoryReport,
                          setStatutoryReport,
                          obj: statutoryReport.reportSpecs,
                          propName: 'linebreak',
                          formType: 'select',
                          options: [
                            'select',
                            String.raw`\n`,
                            String.raw`\r`,
                            String.raw`\r\n`,
                          ],
                        })
                      }
                    />
                  </div>
                </div>
              </>
            ),
            actions: [
              {
                type: 'button',
                button: {
                  behaviour: 'click',
                  style: 'bg-green-600 text-white p-1 fixed w-16 right-5',
                  suffix: <PlayIcon className='inline w-4' />,
                  name: 'Save',
                  onClick: async () => {
                    const finalReport = {
                      id: selectedReport.id,
                      details: {
                        description: statutoryReport.description || '',
                        docType: statutoryReport.docType || 'select',
                        reportSpecs: {
                          separator:
                            statutoryReport.reportSpecs.separator || 'select',
                          linebreak:
                            statutoryReport.reportSpecs.linebreak || 'select',
                          rows: statutoryReport.reportSpecs.rows
                            ? statutoryReport.reportSpecs.rows
                            : [],
                        },
                      },
                    } as ReportsType;
                    setReport(finalReport);
                    toast('Report saved successfully!');
                  },
                },
              },
            ],
          }}
          body={mainCardBody}
        />
        <Modal {...modal} />
      </>
    );
  };
}
