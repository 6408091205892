import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { Company } from '@erp_core/erp-types/dist/modules/admin';
import {
  Card,
  ColumnizedContent,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';

type CreateCompanyCardType = {
  initialData: Card;
  mapperFunction: (data: any) => ColumnizedContent;
};
export function createCompanyCard({
  initialData,
  mapperFunction,
}: CreateCompanyCardType) {
  return function ({
    companyData,
    loadingItem,
  }: {
    companyData: Company;
    loadingItem: boolean;
  }) {
    useEffect(() => {}, [companyData]);

    const CardComponent = renderCardComponent();

    if (loadingItem)
      return (
        <CardComponent
          body={{
            type: 'jsx-component',
            body: (
              <div className=''>
                <Recat className='h-5 inline animate-pulse mx-4' />
              </div>
            ),
          }}
          header={initialData.header}
        />
      );

    if (!companyData)
      return (
        <CardComponent
          body={{
            type: 'jsx-component',
            body: <div className=''>No data present</div>,
          }}
          header={initialData.header}
        />
      );

    return (
      <CardComponent
        body={{ ...initialData.body, body: mapperFunction(companyData) }}
        header={initialData.header}
      />
    );
  };
}
