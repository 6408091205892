import {
  CardBody,
  CardHeader,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { renderTextEditor } from '../../../../../components/html-editor';
import { UseCurrentCompanyGroup } from '../../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';

// eslint-disable-next-line
export function renderMSDSTemplate({
  useFileTransfer,
  useCurrentCompanyGroup,
}: {
  useFileTransfer: UseFileTransfer;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
}): () => JSX.Element {
  const TextEditor = renderTextEditor();
  const Card = renderCardComponent();

  // eslint-disable-next-line
  function MSDSTemplate() {
    // eslint-disable-next-line
    const { get: getTemplate, set: setTemplate } = useFileTransfer();
    const { currentCompanyGroup } = useCurrentCompanyGroup();
    function onTemplateSave(data: any) {
      const file = new File([data], 'msds-sheet-template.html', {
        type: 'text/plain',
      });
      setTemplate(
        `${currentCompanyGroup.id}/templates/msds-sheet-template`,
        file,
        'html'
      );
    }

    const cardHeader: CardHeader = {
      title: '',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <OpenMSDSTemplate
            path={`${currentCompanyGroup.id}/templates/msds-sheet-template.html`}
            onTemplateSave={onTemplateSave}
            getTemplate={getTemplate}
          />
        </>
      ),
    };

    if (!currentCompanyGroup.id) return <div />;
    return (
      <>
        <Card body={cardBody} header={cardHeader} />
      </>
    );
  }

  function OpenMSDSTemplate({
    path,
    onTemplateSave,
    getTemplate,
  }: {
    path: string;
    onTemplateSave: (data: any) => void;
    getTemplate: (path: string) => Promise<string>;
  }): JSX.Element {
    const [data, setData] = useState<string>('');

    useEffect(() => {
      getTemplate(path)
        .then((res) => {
          setData(res);
        })
        .catch((e) => console.log(e));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <TextEditor initialHtml={data} onSave={onTemplateSave} />
      </>
    );
  }

  return MSDSTemplate;
}
