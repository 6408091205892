// TODO: Filter For Vendors, Item Name, Status when BE is migrated to postgresql

import { PurchaseEnquiry } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  downloadPdfFile,
  Filter,
  LoadingButton,
  MultiSelect,
  renderCardComponent,
  renderTableComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { Popover } from '@headlessui/react';
import {
  ArrowDownTrayIcon,
  CheckCircleIcon,
  ClockIcon,
  EnvelopeIcon,
  PaperClipIcon,
} from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseCurrentCompany } from '../../../../hooks/admin/company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseItem } from '../../../../hooks/inventory/item/use-item';
import { UsePaginatedPurchaseEnquiries } from '../../../../hooks/inventory/purchase/purchase-enquiry/use-paginated-purchase-enquiries';
import { UsePurchaseEnquiries } from '../../../../hooks/inventory/purchase/purchase-enquiry/use-purchase-enquiries';
import { UsePurchaseEnquiry } from '../../../../hooks/inventory/purchase/purchase-enquiry/use-purchase-enquiry';
import { UseVendor } from '../../../../hooks/inventory/purchase/vendors/use-vendor';
import { UseBom } from '../../../../hooks/order/work-order/bom/use-bom';
import {
  PurchaseEnquiryFilterType,
  PurchaseEnquiryInterface,
} from '../../../../models/interfaces/order/purchase/purchase-enquiry';
import { PurchaseOrderInterface } from '../../../../models/interfaces/order/purchase/purchase-order';
import { renderPRName } from '../../../../modules/common/fragments/purchase-requisition';
import { renderBOMName } from '../../../common/fragments/bom-name';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderVendor } from '../../../common/fragments/vendor';
import { downloadLatestSpec } from '../../../inventory/items/utils/item-spec-downloader';
import { renderDispatchEnquiry } from './forms/dispatch-enquiry';

export type PurchaseOrderEnquiryProps = {
  useBom: UseBom;
  usePurchaseEnquiries: UsePurchaseEnquiries;
  usePurchaseEnquiry: UsePurchaseEnquiry;
  usePaginatedPurchaseEnquiries: UsePaginatedPurchaseEnquiries;
  useUsers: UseUsers;
  useFileTransfer: UseFileTransfer;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useCurrentCompany: UseCurrentCompany;
  useItem: UseItem;
  useVendor: UseVendor;
  userRenderService: UserRendererInterface;
  purchaseEnquiryService: PurchaseEnquiryInterface;
  purchaseOrderService: PurchaseOrderInterface;
};

export function renderPurchaseOrderEnquiry({
  usePurchaseEnquiries,
  useBom,
  usePaginatedPurchaseEnquiries,
  useUsers,
  useCurrentCompanyGroup,
  useFileTransfer,
  useCurrentCompany,
  useItem,
  usePurchaseEnquiry,
  useVendor,
  userRenderService,
  purchaseEnquiryService,
  purchaseOrderService,
}: PurchaseOrderEnquiryProps): () => JSX.Element {
  return function PurchaseOrderEnquiry(): JSX.Element {
    const navigate = useNavigate();
    const PrName = renderPRName();
    const { syncSet: setPurchaseEnquiry } = usePurchaseEnquiry();

    const BOMName = renderBOMName({ useBom });
    const Vendor = renderVendor();

    // const [enquiryData, setEnquiryData] = useState<PurchaseEnquiry[]>()

    const { currentCompanyGroup } = useCurrentCompanyGroup();
    const { data: currentCompany } = useCurrentCompany();
    const { getSync: getItemDetails } = useItem();
    const { get: getPdf } = useFileTransfer();
    const { list, get: getFile } = useFileTransfer();

    const {
      getAll: getEnquiries,
      // loading: loadingData
    } = usePurchaseEnquiries();

    useEffect(() => {
      getEnquiries();
      // eslint-disable-next-line
    }, []);

    const handleEnquiryPdfDownload = async (enqId, vendorId) => {
      const path = `${currentCompanyGroup.id}/${
        currentCompany.id
      }/purchase/enquiries/${enqId.toLowerCase()}/${vendorId}.pdf`;

      const res = await getPdf(path, true);
      const fName = `enquiry-${enqId}-${vendorId}.pdf`;

      downloadPdfFile({ result: res, fileName: fName });
    };

    const handleQuoteDownload = async (path, vendorId, quoteNo) => {
      const res = await getPdf(`${path}.pdf`, true);
      const fName = `quote-${quoteNo}-${vendorId}.pdf`;
      downloadPdfFile({ result: res, fileName: fName });
    };

    const Table = renderTableWithMapperComponent<
      PurchaseEnquiry,
      PurchaseEnquiryFilterType
    >();

    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Item name' },
        { name: 'Target Date' },
        { name: 'Qty' },
        { name: 'Created By' },
        { name: 'Status' },
        { name: 'Summary', style: 'w-1/4' },
        {
          name: <PaperClipIcon className='w-5 inline text-white fill-black' />,
        },
      ],
    ];

    const bodyMapper = (item: PurchaseEnquiry) => {
      const cells: Array<TableCell> = [
        {
          value: item.name || item.id,
          link: `/purchase/registers/enquiry/${item.id}/details`,
        },
        {
          value: (
            <>
              <div className='font-semibold'>{item.grade?.name}</div>
              <div className='font-thin'>{item.grade?.item?.name}</div>
            </>
          ),
          // link: item.purchaseRequisitions[0]?.id
          //   ? `/inventory/masters/items/${item.purchaseRequisitions[0]?.id}/properties`
          //   : '',
        },

        {
          value: (
            <span>
              <ClockIcon className='w-5 inline text-blue-800' />{' '}
              {item.purchaseRequisitions[0]?.details.targetDate}
            </span>
          ),
        },
        {
          value: (
            <div>
              {item.details?.itemDetails?.quantity ||
                item.purchaseRequisitions[0]?.details.quantity}{' '}
              {item.details?.itemDetails?.uom ||
                item.purchaseRequisitions[0]?.itemDetails.uom}
            </div>
          ),
        },

        {
          value: (
            <userRenderService.userCard
              size='small'
              link={true}
              id={item.createdBy.id}
              name={item.createdBy.name}
            />
          ),
        },
        { value: item.status },
        {
          value: (
            <div>
              <div>
                PRs:
                <PrName
                  id={item.purchaseRequisitions[0]?.id}
                  name={item.purchaseRequisitions[0]?.name}
                />
              </div>
              <div>
                BOMs:{' '}
                {item.purchaseRequisitions[0]?.bomIds.map((bomId, idx) => (
                  <BOMName key={`${bomId}-${idx}`} id={bomId} />
                ))}
              </div>
              <div>
                Vendors:{' '}
                {item.details.vendors?.map((q) => {
                  return (
                    <span
                      className='inline text-purple-600 bg-purple-100 border p-0.5 m-0.5 rounded-md'
                      key={q.id}
                    >
                      <Vendor key={q.id} id={q.id} name={q.name} />
                      <span className='mx-1 text-blue-500 border rounded-full'>
                        {item.enquiryQuotes?.filter(
                          (qu) => qu.vendor.id === q.id
                        ).length || 0}
                        <EnvelopeIcon className='h-4 w-4 inline text-blue-500' />
                      </span>
                    </span>
                  );
                })}
              </div>
              {item.details?.selectedVendor ? (
                <div>
                  Selected Vendor:{' '}
                  <span className='inline text-purple-600 bg-purple-100 border p-0.5 m-0.5 rounded-md'>
                    <Vendor
                      key={item.details?.selectedVendor.id}
                      id={item.details?.selectedVendor.id}
                      name={item.details?.selectedVendor.name}
                    />
                    <CheckCircleIcon className='h-6 w-6 text-blue-500 animate-pulse inline' />
                  </span>
                </div>
              ) : null}
            </div>
          ),
        },
        {
          value: <div> </div>,
        },
        {
          value: (
            <Popover className='relative'>
              {({ open }) => (
                <>
                  <Popover.Button>
                    <ArrowDownTrayIcon className='h-6 w-6' />{' '}
                  </Popover.Button>
                  <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                    <div>
                      <div
                        className='flex cursor-pointer'
                        onClick={
                          async () => {
                            const itemDetails = await getItemDetails(
                              item?.grade?.id
                              // item.purchaseRequisitions[0]?.grade?.item?.id
                            );

                            if (itemDetails) {
                              downloadLatestSpec({
                                companyGroupId: currentCompanyGroup.id,
                                stockGroupId: itemDetails.stockgroup.id,
                                itemId: itemDetails.id,
                                itemName: itemDetails.name,
                                list: list,
                                getFile: getFile,
                              });
                            }
                          }
                          // downloadItemSpec({
                          //   companyGroupId: currentCompanyGroup.id,
                          //   stockGroupId: (
                          //     await getItemDetails(
                          //       item.purchaseRequisitions[0]?.itemDetails.id
                          //     )
                          //   )?.stockgroup.id,
                          //   item: await getItemDetails(
                          //     item.purchaseRequisitions[0]?.itemDetails.id
                          //   ),
                          //   getSpecTemplate: getPdf,
                          // })
                        }
                      >
                        {' '}
                        <ArrowDownTrayIcon className='h-6 w-6' />
                        Item spec
                      </div>
                      {item.enquiryQuotes.map((q) => {
                        if (!q.details.enquiryPdf) return <div />;
                        return (
                          <div
                            className='flex cursor-pointer'
                            onClick={() =>
                              handleEnquiryPdfDownload(item.id, q.vendor.id)
                            }
                          >
                            <ArrowDownTrayIcon className='h-6 w-6' />
                            {q.vendor.name} Enquiry
                          </div>
                        );
                      })}
                      {item?.enquiryQuotes?.map((eq) => {
                        return (
                          <div>
                            {eq?.details?.quoteDetails?.map((d) => {
                              if (!d.attachments?.[0]) return <div />;
                              return (
                                <div
                                  className='flex cursor-pointer'
                                  onClick={() =>
                                    handleQuoteDownload(
                                      d.attachments?.[0],
                                      eq.vendor.id,
                                      d.quoteNo
                                    )
                                  }
                                >
                                  <ArrowDownTrayIcon className='h-6 w-6' />
                                  {eq.vendor.name} Quote {d.quoteNo}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          ),
        },
      ];

      return {
        cells,
        rowData: {
          purchaseEnquiry: item,
        },
      };
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Purchase Enquiry Register',
    };

    const filter: Filter<PurchaseEnquiryFilterType> = {
      sortFields: [
        {
          key: 'Name',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'createdBy',
          type: 'search-select',
          value: '',
        },
        {
          key: 'lastModifiedBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: PurchaseEnquiryFilterType) => {
        const filterData: PurchaseEnquiryFilterType = {};
        if (filterSelection.lastModifiedBy) {
          filterData.lastModifiedBy = filterSelection.lastModifiedBy;
        }

        if (filterSelection.createdBy) {
          filterData.createdBy = filterSelection.createdBy;
        }

        return filterData as PurchaseEnquiryFilterType;
      },
      filterResources: {
        createdBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
        lastModifiedBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };

    const peMultiSelect: MultiSelect = {
      actions: [
        {
          name: 'Bulk Create PO',
          show: (rowData) => {
            console.log(rowData);
            return true;
          },
          behaviour: 'modal',
          modal: {
            size: 'large',
            title: 'Bulk Create PO',
            content: ({ entries, data, onClose }) => {
              console.log(entries, data);
              const filtered: Array<PurchaseEnquiry> = [];
              const invalid: Array<any> = [];
              entries?.forEach((x) => {
                if (data && data[x]) {
                  if (data[x].status === 'vendor-selected') {
                    filtered.push(data[x]);
                  } else {
                    invalid.push(data[x]);
                  }
                }
              });

              if (invalid.length) {
                return (
                  <div>
                    You have selected invalid purchase enquiries. They need to
                    be in vendor-selected state for creating PO
                    {invalid.map((x) => (
                      <div key={x.id}>{x.name}</div>
                    ))}
                  </div>
                );
              }

              const Table = renderTableComponent();

              return (
                <>
                  <Table
                    header={[
                      [
                        { name: 'Enquiry' },
                        { name: 'Status' },
                        { name: 'Quantity' },
                        { name: 'Target Date' },
                        { name: 'Selected Vendor' },
                        { name: 'Terms' },
                      ],
                    ]}
                    body={filtered.map((x) => {
                      console.log(x);
                      const selVendor = x.details?.vendors?.find(
                        (y) => y.id === x.details?.selectedVendor?.id
                      );
                      return {
                        cells: [
                          {
                            value: (
                              <>
                                <div>
                                  {x.purchaseRequisitions[0]?.itemDetails.name}
                                </div>
                                <div>{x.name}</div>
                              </>
                            ),
                          },
                          {
                            value: x.status,
                          },
                          {
                            value: (
                              <>
                                <div>
                                  {x.details?.itemDetails?.quantity ||
                                    x.purchaseRequisitions[0]?.details
                                      .quantity}{' '}
                                  {x.details?.itemDetails?.uom ||
                                    x.purchaseRequisitions[0]?.itemDetails.uom}
                                </div>
                              </>
                            ),
                          },
                          {
                            value:
                              x.purchaseRequisitions[0]?.details.targetDate,
                          },
                          {
                            value: (
                              <>
                                <div>
                                  {x.details.selectedVendor ? (
                                    <div className='flex'>
                                      <Vendor
                                        key={x.details.selectedVendor?.id}
                                        id={x.details.selectedVendor?.id}
                                        name={x.details.selectedVendor?.name}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                                {selVendor ? (
                                  <>
                                    <div>
                                      <span className='font-semibold'>
                                        Address:
                                      </span>{' '}
                                      {selVendor.location?.name}
                                    </div>
                                    <div>
                                      <span className='font-semibold'>
                                        Contact:
                                      </span>{' '}
                                      {selVendor.contact?.name} -{' '}
                                      {selVendor.contact?.phones}
                                    </div>
                                  </>
                                ) : null}
                              </>
                            ),
                          },
                          {
                            value: (
                              <>
                                {selVendor?.terms ? (
                                  <div>
                                    <div>
                                      <span className='font-semibold'>
                                        Delivery Terms:{' '}
                                      </span>
                                      {
                                        selVendor?.terms?.delivery
                                          ?.deliveryTerms?.name
                                      }{' '}
                                      -{' '}
                                      {
                                        selVendor?.terms?.delivery
                                          ?.modeOfTransport?.name
                                      }
                                    </div>
                                    <div>
                                      <span className='font-semibold'>
                                        Payment Terms:{' '}
                                      </span>
                                      {Array.isArray(
                                        selVendor?.terms?.payment?.paymentTerms
                                      )
                                        ? selVendor?.terms?.payment?.paymentTerms
                                            ?.map((x) => {
                                              return `${x.percent}% ${x.paymentType} - {if n>0 Then "${x.noOfDays}" Days}.`;
                                            })
                                            .join(', ') || '-'
                                        : '-'}
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            ),
                          },
                        ],
                      };
                    })}
                  />
                  <div className='text-center'>
                    <div className='font-semibold animate-pulse'>
                      Note: Only open-enquiries will be converted to Purchase
                      Order.
                    </div>
                    <LoadingButton
                      text={'Create Purchase Order'}
                      behaviorFn={async () => {
                        await purchaseOrderService.createBulkOrder(
                          filtered.map((x) => x.id)
                        );
                        onClose();
                      }}
                      behaviourParams={{}}
                    />
                  </div>
                </>
              );
            },
          },
        },
        {
          name: 'Bulk Dispatch Enquiry',
          show: () => true,
          behaviour: 'modal',
          modal: {
            size: 'large',
            title: 'Bulk Dispatch Enquiry',
            content: ({ entries, data, onClose }) => {
              const selected: Array<PurchaseEnquiry> = entries.map((x) => {
                if (data) {
                  return data[x];
                }
                return null;
              });
              const Table = renderTableComponent();

              return (
                <>
                  <Table
                    header={[
                      [
                        { name: 'Enquiry Items' },
                        { name: 'Status' },
                        { name: 'Quantity' },
                        { name: 'Vendor' },
                      ],
                    ]}
                    body={selected.map((x) => {
                      return {
                        cells: [
                          {
                            value: (
                              <>
                                <div>
                                  {x.purchaseRequisitions[0]?.itemDetails.name}
                                </div>
                                <div>{x.name}</div>
                              </>
                            ),
                          },
                          {
                            value: x.status,
                          },
                          {
                            value: (
                              <>
                                <div>
                                  {x.details?.itemDetails?.quantity ||
                                    x.purchaseRequisitions[0]?.details
                                      .quantity}{' '}
                                  {x.details?.itemDetails?.uom ||
                                    x.purchaseRequisitions[0]?.itemDetails.uom}
                                </div>
                              </>
                            ),
                          },
                          {
                            value: (
                              <>
                                <div>
                                  {x.details.vendors?.map((q) => {
                                    return (
                                      <div className='flex'>
                                        <Vendor
                                          key={q.id}
                                          id={q.id}
                                          name={q.name}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            ),
                          },
                        ],
                      };
                    })}
                  />
                  <div className='text-center'>
                    <div className='font-semibold animate-pulse'>
                      Note: Draft Enquiries will be marked as Open.
                    </div>
                    <LoadingButton
                      text={'Dispatch'}
                      behaviorFn={async () => {
                        await purchaseEnquiryService.sendEnquiries(
                          selected.map((x) => x.id)
                        );
                        onClose();
                      }}
                      behaviourParams={{}}
                    />
                  </div>
                </>
              );
            },
          },
        },
      ],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          multiSelect={peMultiSelect}
          header={tableHeader}
          bodyMapper={bodyMapper}
          useResources={usePurchaseEnquiries}
          filter={filter}
          actions={[
            {
              name: 'Dispatch Enquiry',
              show: (p) => p.purchaseEnquiry.status === 'enquiry-draft',
              behaviour: 'modal',
              modal: {
                title: 'Dispatch Enquiry',
                content: ({ data, onClose }) => {
                  const Form = renderDispatchEnquiry({
                    usePurchaseEnquiries,
                    setPurchaseEnquiry,
                    useVendor,
                  });
                  return (
                    <Form
                      purchaseEnquiry={data.purchaseEnquiry}
                      onClose={onClose}
                    />
                  );
                },
              },
            },
            {
              name: 'View / Add Quotes',
              show: (p) => p.purchaseEnquiry.status === 'enquiry-open',
              behaviour: 'click',
              onClick: async (p) => {
                navigate(
                  `/purchase/registers/enquiry/${p.purchaseEnquiry.id}/quotes`
                );
              },
            },
            {
              name: 'View Order',
              show: (p) =>
                Boolean(
                  p.purchaseEnquiry.status === 'raised-po' &&
                    p.purchaseEnquiry.details.orderId
                ),
              behaviour: 'click',
              onClick: async (p) => {
                navigate(
                  `/purchase/requisitions/purchase-order/${p.purchaseEnquiry.details.orderId}?tab=1`
                );
              },
            },
            {
              name: 'Close Enquiry',
              show: (p) => true,
              behaviour: 'click',
              onClick: async (p) => {},
            },
          ]}
          pagination={{
            enabled: true,
            usePaginatedResources: usePaginatedPurchaseEnquiries,
          }}
        />
      ),
    };

    const getEnquiryData = async () => {
      getEnquiries();
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      getEnquiryData();
      // eslint-disable-next-line
    }, []);

    return <Card header={cardHeader} body={cardBody} />;
  };
}
