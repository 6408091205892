import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { renderModal } from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseCompanyGroupSetting } from '../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { UseCompanyGroupSettings } from '../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { CGConfigRenderer } from '../config-renderer';
import { GenericEditPatternFormProps } from '../purchase-config';
import { renderClassifiers } from './classifiers';
import { renderEditItemBatchNamePatternForm } from './forms/edit-item-batch-name-pattern';
import { renderEditMinimumOrderQuantityForm } from './forms/edit-minimum-order-quantity';
import { renderEditMinimumSalesQuantityForm } from './forms/edit-minimum-sales-quantity';
import { renderEditMinimumStockLevelForm } from './forms/edit-minimum-stock-level';
import { renderOperations } from './operations';

type CreateInventoryConfigPage = {
  useCompanyGroupSetting: UseCompanyGroupSetting;
  useCompanyGroupSettings: UseCompanyGroupSettings;
};

export const createInventoryConfigPage = ({
  useCompanyGroupSetting,
  useCompanyGroupSettings,
}: CreateInventoryConfigPage) => {
  const Classifiers = renderClassifiers();
  const Operations = renderOperations();
  return function () {
    // const {data, get: getCompanyGroupSetting} = useCompanyGroupSetting();
    const { data: allData, getAll, loading } = useCompanyGroupSettings();
    const { syncSet: setCGS } = useCompanyGroupSetting();

    const [data, setData] = useState<CompanyGroupSetting | null>();

    useEffect(() => {
      setData(
        (allData || []).length > 0 ? (allData ? allData[0] : null) : null
      );
    }, [allData]);

    useEffect(() => {
      getAll();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [modalState, setModalState] = useState({
      title: 'Edit BOM name pattern',
      isVisible: false,
      onClose: () => closeModal(),
      body: (p?: any) => <div className='hidden' />,
    });

    const closeModal = () => {
      setModalState((ms) => ({ ...ms, isVisible: false }));
    };

    const handleEditMinimumOrderQuantity = () => {
      const EditMinimumOrderQuantityForm = renderEditMinimumOrderQuantityForm({
        comGrpSetDetails: data?.details || {},
        closeModal,
        useCompanyGroupSetting,
      });
      setModalState((ms) => ({
        ...ms,
        isVisible: true,
        title: 'Edit Minimum order Quantity',
        body: EditMinimumOrderQuantityForm,
      }));
      return null;
    };

    const handleEditMinimumSalesQuantity = () => {
      const EditMinimumSalesQuantityForm = renderEditMinimumSalesQuantityForm({
        comGrpSetDetails: data?.details || {},
        closeModal,
        useCompanyGroupSetting,
      });
      setModalState((ms) => ({
        ...ms,
        isVisible: true,
        title: 'Edit Minimum Sales Quantity',
        body: EditMinimumSalesQuantityForm,
      }));
      return null;
    };

    const closeModalAndRefresh = () => {
      setModalState((ms) => ({ ...ms, isVisible: false }));
      getAll();
    };

    const handleEditPattern = (
      genericEditPatternForm: ({
        closeModal,
        useCompanyGroupSetting,
      }: GenericEditPatternFormProps) => (data: {
        comGrpSet?: CompanyGroupSetting;
      }) => JSX.Element,
      title: string
    ) => {
      const Form = genericEditPatternForm({
        closeModal: closeModalAndRefresh,
        useCompanyGroupSetting,
      });
      setModalState((ms) => ({
        ...ms,
        title,
        isVisible: true,
        body: () => <Form comGrpSet={data || undefined} />,
      }));
    };

    const handleEditMinimumStockLevel = () => {
      const EditMinimumStockLevel = renderEditMinimumStockLevelForm({
        comGrpSetDetails: data?.details || {},
        closeModal,
        useCompanyGroupSetting,
      });
      setModalState((ms) => ({
        ...ms,
        isVisible: true,
        title: 'Edit Minimum Stock Level',
        body: EditMinimumStockLevel,
      }));
      return null;
    };

    const Modal = renderModal();

    type Configs = {
      settingName: string;
      settingValue?: string;
      settingValueSuffix?: string;
      editAction: () => void;
    };

    const configs: Configs[] = [
      {
        settingName: 'Minimum order quantity',
        settingValue: data?.details?.inventory?.item?.minimumOrderQuantity?.toString(),
        settingValueSuffix: '% of godown capacity',
        editAction: handleEditMinimumOrderQuantity,
      },
      {
        settingName: 'Minimum Sales quantity: ',
        settingValue: data?.details?.inventory?.item?.minimumSalesQuantity?.toString(),
        settingValueSuffix: '% of godown capacity',
        editAction: handleEditMinimumSalesQuantity,
      },
      {
        settingName: 'Minimum Stock Level quantity: ',
        settingValue: data?.details?.inventory?.item?.minimumStockLevel?.toString(),
        settingValueSuffix: '% of godown capacity',
        editAction: handleEditMinimumStockLevel,
      },
      {
        settingName: 'Item batch name pattern: ',
        settingValue: data?.details?.inventory?.itemBatch?.itemBatchNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: () =>
          handleEditPattern(
            renderEditItemBatchNamePatternForm,
            'Edit Item batch Name pattern'
          ),
      },
    ];

    return (
      <div className='w-full'>
        <CGConfigRenderer configs={configs} loading={loading} />
        <Classifiers config={data?.details.inventory || {}} setCGS={setCGS} />
        <Operations config={data?.details.inventory || {}} setCGS={setCGS} />
        <Modal
          title={modalState.title}
          isVisible={modalState.isVisible}
          body={<div />}
          onClose={modalState.onClose}
        >
          <modalState.body comGrpSetDetails={data?.details || {}} />
        </Modal>
      </div>
    );
  };
};
