/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { Quote } from '../types/quote';

// eslint-disable-next-line
export function renderAddQuotesForm(): (data: any) => JSX.Element {
  return function AddQuotesForm({
    vendors,
    onSave,
  }: {
    vendors: { id: string; name: string; approved: boolean }[];
    onSave: (props: any) => Promise<void>;
  }): JSX.Element {
    console.log(vendors);
    const formProps: FormProps<Partial<Quote>> = {
      fieldsData: [
        { property: 'quoteNo', type: 'input' },
        {
          property: 'vendor',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            ...vendors?.map((data) => ({
              text: data.name,
              value: data.id,
            })),
          ],
        },
        { property: 'quantity', type: 'input' },
        { property: 'UOM', type: 'input' },
        { property: 'rate', type: 'input' },
        { property: 'currency', type: 'input' },
        { property: 'discount', type: 'number' },
        { property: 'leadTime', type: 'input' },
        { property: 'termOfDelivery', type: 'input' },
        { property: 'deliveryFrom', type: 'input' },
        { property: 'paymentTerms', type: 'input' },
        { property: 'creditPeriod', type: 'input' },
        { property: 'quantityPerPackage', type: 'input' },
        // ...((stockGroup.details as any)?.specification?.fields?.filter(
        //   (fl) => fl.neededInEnquiry === 'true'
        // ) || []),
        { property: 'remarks', type: 'input' },
      ],
      initialFormState: {
        quoteNo: '',
        rate: 0,
        discount: 0,
        remarks: '',
        vendor: { id: vendors?.[0]?.id } as any,
        quantity: 0,
        UOM: '',
        currency: '',
        leadTime: '',
        termOfDelivery: '',
        deliveryFrom: '',
        paymentTerms: '',
        creditPeriod: '',
        quantityPerPackage: '',
        // ...initialItemSpecFormState,
      },
      onSubmit: async (p) => {
        await onSave(p);
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
    };

    const Form = renderFormV2<Partial<Quote>>(formProps);

    // TODO losing focus with state change
    return (
      <div>
        <Form />;
      </div>
    );

    function mapPFDToP(b: FormDataType<Partial<Quote>>): Quote {
      return {
        ...b,
        vendor: vendors.find((v) => v.id === b.vendor),
        attachments: [],
      } as any;
    }
  };
}
