import { ReactNode, useMemo } from 'react';
import { LocationContext } from '../../contexts/location';
import { UseCurrentLocation } from '../../hooks/admin/location-admin/use-current-location';

export type LocationProviderProps = {
  children: ReactNode;
  useCurrentLocation: UseCurrentLocation;
};

export function LocationProvider({
  children,
  useCurrentLocation,
}: LocationProviderProps): JSX.Element {
  const { data: currentLocation, setCurrentLocation } = useCurrentLocation();

  const value = useMemo(
    () => ({
      location: currentLocation,
      setLocation: setCurrentLocation,
    }),
    // eslint-disable-next-line
    [currentLocation]
  );
  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
}
