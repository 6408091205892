/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */

import toast from 'react-hot-toast';
import CustomAxios from '..';
import { ErpV2ApiAdapter } from '../../../../adapter/erp-v2-api';
import { NotificationLoggerResponse } from '../../../../components/notification/types';
import { ADMIN_SERVER_URI } from '../../../../config/server-uri';
import { ErpV2Api } from '../../../../models/interfaces/adapters/erp-v2-api';
import { NotificationInterface } from '../../../../models/interfaces/notification';

export class NotificationRepo implements NotificationInterface {
  private server;

  private erpV2Api: ErpV2Api;

  constructor(server: string) {
    this.server = server;
    this.erpV2Api = new ErpV2ApiAdapter({ adminApiUrl: ADMIN_SERVER_URI });
  }

  dismiss = async (logId: string): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) => {
      CustomAxios.patch(`${this.server}/notification-service/logs/dismiss`, {
        logId,
      })
        .then((response: any) => {
          resolve(response.data);
          toast('Notification dismissed');
        })
        .catch((err) => {
          toast(`${err.message}`);
        });
    });

  markSeen = async (logId: string): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) => {
      CustomAxios.patch(`${this.server}/notification-service/logs/seen`, {
        logId,
      })
        .then((response: any) => {
          resolve(response.data);
          toast('Marked seen successfully');
        })
        .catch((err) => {
          toast(`${err.message}`);
        });
    });

  acknowledge = async (logId: string): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) => {
      CustomAxios.post(`${this.server}/notification-service/logs/acknowledge`, {
        logId,
      })
        .then((response: any) => {
          resolve(response.data);
          toast('Acknowledged successfully');
        })
        .catch((err) => {
          toast(`${err.message}`);
        });
    });

  getUserNotifications = async ({
    userId,
  }: {
    userId: string;
  }): Promise<Array<NotificationLoggerResponse>> =>
    new Promise((resolve, reject) => {
      this.erpV2Api
        .getAuthorizedInstance({
          apiUrl: this.server,
        })
        .then(async (api) => {
          const res = await api.get('/notification-service/logs', {
            params: { userId },
          });
          resolve(res.data);
        });

      // CustomAxios.get(`${this.server}/notification-service/logs`, { params: { userId } }).then((response: any) => {
      //     resolve(response.data);
      //   }).catch((err) => {
      //     toast(`${err.message}`)
      //   });
    });
}
