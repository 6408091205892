import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import { LoadingButton } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useState } from 'react';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { downloadSalarySlip } from '../utils/download-salary-slip';
import { findParamType, formatParam } from '../utils/generate-salary';
import { sendSalarySlip } from '../utils/send-salary-slip-email';

export function SalaryComponents({
  salaryId,
  monthDetails,
  salaryParam,
  salaryData,
  useFileTransfer,
  currentCompanyGroupId,
  employee,
  dd,
}: {
  salaryId: string;
  monthDetails: {
    month: number;
    year: number;
    monthName: string;
    totalDays: number;
    totalOfficialDays: number;
  };
  salaryData: any;
  salaryParam: SalaryParamType;
  useFileTransfer: UseFileTransfer;
  currentCompanyGroupId: string;
  employee: EmployeeProfileType;
  dd: any;
}): JSX.Element {
  const { get: getSpecTemplate } = useFileTransfer();
  const [currTab, setCurrTab] = useState<string>('earning');

  return (
    <div className='w-full'>
      <div className=''>
        <LoadingButton
          text='Print'
          defaultStyle='float-right bg-green-500 text-white p-2 border rounded-lg'
          loadingStyle='float-right bg-gray-500 text-white p-2 border rounded-lg'
          behaviourParams={{
            salaryId,
            monthYear: `${monthDetails.year}-${
              monthDetails.month < 10 ? `0${monthDetails.month}` : monthDetails
            }`,
            companyId: employee.company?.id || '',

            companyGroupId: currentCompanyGroupId,
            salaryParamId: salaryParam.id,
            salarySlipData: {
              ...dd,
              salaryParams: salaryData,
              employeeProfile: employee,
            },
            getSpecTemplate,
          }}
          behaviorFn={downloadSalarySlip}
        />

        <LoadingButton
          text='Send Email'
          defaultStyle='float-right bg-green-500 text-white p-2 border rounded-lg'
          loadingStyle='float-right bg-gray-500 text-white p-2 border rounded-lg'
          behaviourParams={{
            companyGroupId: currentCompanyGroupId,
            salaryParamId: salaryParam.id,
            salarySlipData: {
              ...dd,
              salaryParams: salaryData,
              employeeProfile: employee,
            },
            getSpecTemplate,
          }}
          behaviorFn={sendSalarySlip}
        />
      </div>
      <div className='flex w-full'>
        <div
          onClick={() => setCurrTab('earning')}
          className={`text-center border border-gray-200 p-1 basis-1/3 ${
            currTab === 'earning'
              ? 'bg-blue-300 text-white'
              : 'bg-white text-black'
          } cursor-pointer`}
        >
          Earning
        </div>
        <div
          onClick={() => setCurrTab('deduction')}
          className={`text-center border border-gray-200 p-1 basis-1/3 ${
            currTab === 'deduction'
              ? 'bg-blue-300 text-white'
              : 'bg-white text-black'
          } cursor-pointer`}
        >
          Deduction
        </div>
        <div
          onClick={() => setCurrTab('other')}
          className={`text-center border border-gray-200 p-1 basis-1/3 ${
            currTab === 'other'
              ? 'bg-blue-300 text-white'
              : 'bg-white text-black'
          } cursor-pointer`}
        >
          Hidden
        </div>
      </div>

      <div className=''>
        <table>
          <thead>
            <tr>
              <th>Particulars</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {_.keys(salaryData[currTab]).map((key) => {
              return (
                <tr key={key}>
                  <td>{key}</td>
                  <td className='text-right'>
                    {formatParam({
                      value: salaryData[currTab][key],
                      metric: findParamType(
                        key,
                        salaryParam.details.inner,
                        'metric'
                      ),
                      scale: findParamType(
                        key,
                        salaryParam.details.inner,
                        'scale'
                      ),
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
