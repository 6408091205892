import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseCompanies } from '../../../../hooks/admin/company-admin/use-companies-admin';
import { UseCompanyAdmin } from '../../../../hooks/admin/company-admin/use-company-admin';
import { UseLocationAdmin } from '../../../../hooks/admin/location-admin/use-location-admin';
import { UseLocations } from '../../../../hooks/admin/location-admin/use-locations';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { renderLocationAdmin } from '../../../../modules/admin/location-admin';
import { renderCompanyProfile } from './company-details';
import { renderHistoryTab } from './histort-tab';
import { createCompanyRegistration } from './registration';
import { createCompanyDocumentRegistration } from './registration-doc';

export type RenderCompanyDetailsProp = {
  hooks: {
    useCompanyAdmin: UseCompanyAdmin;
    useFileTransfer: UseFileTransfer;
    useLocations: UseLocations;
    useLocationAdmin: UseLocationAdmin;
    useCompanies: UseCompanies;
  };
};

export function renderCompanyDetails({
  hooks: {
    useCompanyAdmin,
    useFileTransfer,
    useLocations,
    useLocationAdmin,
    useCompanies,
  },
}: RenderCompanyDetailsProp): () => JSX.Element {
  return function CompanyDetails(): JSX.Element {
    const { id } = useParams();
    const TabsV2 = renderTabsV2Component();
    const {
      data,
      get: getCompanyDetail,
      syncSet: setCompany,
      loading,
    } = useCompanyAdmin();
    const Card = renderCardComponent();

    useEffect(() => {
      if (id) {
        getCompanyDetail(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const header: CardHeader = {
      title: `${data.name || id}`,
    };

    const Registration = createCompanyRegistration();

    const RegistrationDocument = createCompanyDocumentRegistration();

    const Location = renderLocationAdmin({
      useCompanies,
      useLocations,
      useLocationAdmin,
    });

    const CompanyDetails = renderCompanyProfile({ loading });

    const newCategories: DataArray = [
      {
        name: 'company-profile',
        Fn: () => (
          <div>
            <CompanyDetails company={data} />
          </div>
        ),
      },
      {
        name: 'registration',
        Fn: () => (
          <div className='flex flex-col'>
            <div className='px-4 py-2 m-2'>
              <Registration company={data} setCompany={setCompany} />
            </div>
            <div className='px-4 py-2 m-2'>
              <RegistrationDocument
                company={data}
                setCompany={setCompany}
                useFileTransfer={useFileTransfer}
              />
            </div>
          </div>
        ),
      },
      {
        name: 'history',
        Fn: renderHistoryTab({ id, useCompanyAdmin }),
      },
      {
        name: 'location',
        Fn: () => (
          <div>
            <Location companyId={id} />
          </div>
        ),
      },
    ];

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <TabsV2 data={newCategories} manipulateLocation={true} />
        </>
      ),
    };

    return <Card header={header} body={body} />;
  };
}
