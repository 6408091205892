import { useEffect } from 'react';
import { UseBom } from '../../../hooks/order/work-order/bom/use-bom';

export function renderBOMName({
  useBom,
}: {
  useBom: UseBom;
}): // eslint-disable-next-line
({ id }: { id: string }) => JSX.Element {
  return function BOM({ id }: { id: string }): JSX.Element {
    const { data, get } = useBom();
    useEffect(() => {
      get(id);
      // eslint-disable-next-line
    }, []);

    return data.name ? (
      <a
        key={id}
        href={`/inventory/registers/bom/${id}/details`}
        target='_blank'
        className='border bg-green-100 rounded text-green-800 text-xs'
      >
        {data.name}
      </a>
    ) : (
      <span />
    );
  };
}
