import { ItemProperty } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { LoadingButton, ModalV2Props } from '@erp_core/erp-ui-components';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { InvEntityType } from '../../../../models/types/inventory/inventory-properties';

export function renderRuleUpdateProperty<T extends InvEntityType>({
  itemProperties,
  entity,
  setEntity,
  modal,
  setModal,
}: {
  itemProperties: ItemProperty[];
  entity: T;
  setEntity: (s: T) => Promise<T>;
  modal: ModalV2Props;
  setModal: React.Dispatch<React.SetStateAction<ModalV2Props>>;
}) {
  return function RuleUpdateProperty(idx: number) {
    const obj = itemProperties[idx];

    const indexInEntity = entity.details.properties.findIndex(
      (x) => x.name === obj.name
    );

    if (indexInEntity > -1) {
      const property: ItemProperty = entity.details.properties[indexInEntity];

      setModal({
        ...modal,
        isVisible: true,
        title: 'Rule Update for Property',
        body: (
          <RuleRenderer
            property={property}
            save={async (prop) => {
              const properties = entity.details.properties;
              properties[indexInEntity] = prop;

              await setEntity(({
                id: entity.id,
                details: {
                  properties,
                },
              } as unknown) as T);
            }}
          />
        ),
      });
    }
  };
}

function RuleRenderer({
  property,
  save,
}: {
  property: ItemProperty;
  save: (prop: ItemProperty) => Promise<void>;
}): JSX.Element {
  const [prop, setProp] = useState<ItemProperty>(property);
  return (
    <div>
      Rule Update for Property {prop.name}{' '}
      <LoadingButton
        defaultStyle='bg-green-500'
        behaviorFn={async () => {
          const newProp: ItemProperty = JSON.parse(JSON.stringify(prop));
          if (!newProp.rules) {
            newProp.rules = [];
          }
          newProp.rules.push({
            type: 'hide-property',
            condition: '',
            description: '',
          });
          setProp(newProp);
        }}
        text='Add'
      />
      <div>
        {prop.rules?.map((x, idx) => (
          <div key={idx} className='border border-gray-200 p-1 my-1 flex'>
            <div className='flex-auto'>
              <div>
                Type:{' '}
                <select
                  value={x.type}
                  onChange={(evt) => {
                    if (evt.target.value) {
                      const newProp: ItemProperty = JSON.parse(
                        JSON.stringify(prop)
                      );
                      if (newProp.rules && newProp.rules[idx]) {
                        newProp.rules[idx].type = evt.target.value as any;
                      }
                      setProp(newProp);
                    }
                  }}
                >
                  <option value='hide-property'>hide-property</option>
                  <option value='invalid-value'>invalid-value</option>
                </select>
              </div>
              <div>
                Condition:{' '}
                <input
                  defaultValue={x.condition}
                  className='border border-gray-200 w-96'
                  onBlur={(evt) => {
                    if (evt.target.value) {
                      const newProp: ItemProperty = JSON.parse(
                        JSON.stringify(prop)
                      );
                      if (newProp.rules && newProp.rules[idx]) {
                        newProp.rules[idx].condition = evt.target.value as any;
                      }
                      setProp(newProp);
                    }
                  }}
                />{' '}
              </div>
              {x.type === 'invalid-value' ? (
                <div>
                  Invalid Values:{' '}
                  <input
                    defaultValue={x.invalidValues}
                    className='border border-gray-200 w-96'
                    onBlur={(evt) => {
                      if (evt.target.value) {
                        const newProp: ItemProperty = JSON.parse(
                          JSON.stringify(prop)
                        );
                        if (newProp.rules && newProp.rules[idx]) {
                          newProp.rules[idx].invalidValues = evt.target
                            .value as any;
                        }
                        setProp(newProp);
                      }
                    }}
                  />{' '}
                </div>
              ) : null}
              <div>
                Description:{' '}
                <input
                  defaultValue={x.description}
                  className='border border-gray-200 w-96'
                  onBlur={(evt) => {
                    if (evt.target.value) {
                      const newProp: ItemProperty = JSON.parse(
                        JSON.stringify(prop)
                      );
                      if (newProp.rules && newProp.rules[idx]) {
                        newProp.rules[idx].description = evt.target
                          .value as any;
                      }
                      setProp(newProp);
                    }
                  }}
                />
              </div>
            </div>
            <div className='flex-none'>
              <TrashIcon
                className='text-red-500 w-5'
                onClick={() => {
                  const newProp: ItemProperty = JSON.parse(
                    JSON.stringify(prop)
                  );
                  newProp.rules?.splice(idx, 1);
                  setProp(newProp);
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div>
        <LoadingButton
          defaultStyle='bg-green-500'
          behaviorFn={async () => {
            await save(prop);
          }}
          text='Save'
        />
      </div>
    </div>
  );
}
