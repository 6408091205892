import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseCompanyGroupSettings } from '../../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { UseCurrentUser } from '../../../../../hooks/admin/user-admin/use-current-user';
import { UseUsers } from '../../../../../hooks/admin/user-admin/use-users-admin';
import { UseBatchItemGodown } from '../../../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UseItem } from '../../../../../hooks/inventory/item/use-item';
import { UsePurchaseOrderFulfilment } from '../../../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilment';
import { UsePurchaseOrderFulfilments } from '../../../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilments';
import { UsePurchaseOrder } from '../../../../../hooks/inventory/purchase/purchase-order/use-purchase-order';
import { UsePurchaseOrders } from '../../../../../hooks/inventory/purchase/purchase-order/use-purchase-orders';
import { UseBoms } from '../../../../../hooks/order/work-order/bom/use-boms';
import { renderPurchaseOrderProfile } from './po-flow';
import { renderPurchaseOrderFulfilmentDetails } from './purchase-fulfillment-details';

export type RenderPODetailsProp = {
  hooks: {
    usePurchaseOrder: UsePurchaseOrder;
    usePurchaseOrderFulfilment: UsePurchaseOrderFulfilment;
    usePurchaseOrders: UsePurchaseOrders;
    useBoms: UseBoms;
    useItem: UseItem;
    useBatchItemGodown: UseBatchItemGodown;
    useUsers: UseUsers;
    usePurchaseOrderFulfilments: UsePurchaseOrderFulfilments;
    useCurrentUser: UseCurrentUser;
    useCompanyGroupSettings: UseCompanyGroupSettings;
  };
};

export function renderPurchaseOrderDetails({
  hooks: {
    usePurchaseOrderFulfilment,
    usePurchaseOrder,
    usePurchaseOrders,
    useBoms,
    useItem,
    useBatchItemGodown,
    useUsers,
    usePurchaseOrderFulfilments,
    useCurrentUser,
    useCompanyGroupSettings,
  },
}: RenderPODetailsProp) {
  return function PODetails(): JSX.Element {
    const { id } = useParams();
    const { data, get: getOrder } = usePurchaseOrder();
    const NewTabs = renderTabsV2Component();
    const Card = renderCardComponent();

    const { getAllSync: getCompanyGroupSettings } = useCompanyGroupSettings();
    const [cgs, setCgs] = useState<CompanyGroupSetting | null>(null);

    const getCgs = async () => {
      const comGrpSettings = await getCompanyGroupSettings();
      if (comGrpSettings.length > 0) {
        setCgs(comGrpSettings[0]);
      }
    };

    useEffect(() => {
      if (id) {
        getOrder(id);
      }
      getCgs();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const header: CardHeader = {
      title: `${data.name || id}`,
      icon: <CubeIcon className='text-gray-800 w-24 h-full self-center' />,
      subheading: (
        <>
          <div className='text-gray-700 italic'>
            Status:{' '}
            <span className='border rounded-md bg-indigo-100 text-indigo-900 px-1 py-0.5'>
              {data.status || 'OPEN'}
            </span>{' '}
            Item:{' '}
            <a
              className='font-bold'
              href={`/inventory/masters/items/${data?.purchaseRequisition?.itemDetails?.id}/properties`}
              target='_blank'
              rel='noreferrer'
            >
              {(data?.purchaseEnquiry?.purchaseRequisitions &&
                data?.purchaseEnquiry?.purchaseRequisitions[0]?.itemDetails
                  ?.name) ||
                data?.purchaseRequisition?.itemDetails?.name}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; Quantity:{' '}
            <b>
              {(data?.purchaseEnquiry?.purchaseRequisitions &&
                data?.purchaseEnquiry?.purchaseRequisitions[0]?.details
                  ?.quantity) ||
                data?.purchaseRequisition?.details?.quantity ||
                '??'}{' '}
              {data?.purchaseRequisition?.itemDetails?.uom}
            </b>
          </div>
          <div className='text-gray-700'>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${data.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' /> {data.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${data.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {data.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(data.lastModifiedAt).fromNow()}
          </div>
        </>
      ),
    };

    const FulFillment = renderPurchaseOrderFulfilmentDetails({
      usePurchaseOrderFulfilments,
      usePurchaseOrderFulfilment,
      usePurchaseOrder,
      usePurchaseOrders,
      useBoms,
      useItem,
      useBatchItemGodown,
      useUsers,
      useCurrentUser,
    });

    const newCategories = [
      {
        name: 'details',
        Fn: renderPurchaseOrderProfile({
          id,
          usePurchaseOrder,
        }),
      },
      {
        name: 'fulfilments',
        Fn: () => {
          if (!cgs || !data) return <div />;
          return <FulFillment cgs={cgs} purchaseOrder={data} />;
        },
      },
    ];

    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={true} />,
    };

    return <Card header={header} body={body} />;
  };
}
