import {
  AttendanceType,
  EmployeeProfileType,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  MonthSelector,
  renderCardComponent,
  renderSearchBox,
  renderTableComponent,
  TableBody,
  useConfirm,
} from '@erp_core/erp-ui-components';
import { BoltIcon, BookOpenIcon, TrashIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { renderAuthWrapper } from '../../../../components/auth';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseAttendance } from '../../../../hooks/hrd/attendance/use-attendance';
import { UseAttendances } from '../../../../hooks/hrd/attendance/use-attendances';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { calculateShiftDuration } from '../util/calculate-shift-duration';
import { Chip } from '../util/segregate-punches';

export function createEmployeeAttendancePage({
  useEmployees,
  useAttendances,
  useCurrentUserRoles,
  useAttendance,
  useUserAuthorization,
}: {
  useAttendances: UseAttendances;
  useAttendance: UseAttendance;
  useEmployees: UseEmployeeProfiles;
  useUserAuthorization: UseUserAuthorization;
  useCurrentUserRoles: UseCurrentUserRoles;
}): () => JSX.Element {
  let Card = renderCardComponent();
  let Table = renderTableComponent();

  return function EmployeeAttendancePage(): JSX.Element {
    const [selectedEmployee, setSelectedEmployee] = useState<
      EmployeeProfileType
    >({} as EmployeeProfileType);
    const { getAll: getAllAttendances, data: attendances } = useAttendances();
    const { syncSet: setAttendance } = useAttendance();
    const [employeeAttendances, setEmployeeAttendances] = useState<
      Array<AttendanceType>
    >([]);

    const { data: currentUserRoles } = useCurrentUserRoles();
    const { Confirm, openConfirm } = useConfirm();
    const AuthWrapper = renderAuthWrapper({ useUserAuthorization });

    const SearchBox = renderSearchBox<EmployeeProfileType>({
      useSearchValues: useEmployees,
      extraFilter: {
        crossGroup: 'true',
      },
      onSearchValueSelect: (u) => {
        setSelectedEmployee(u);
      },
    });

    const [month, setMonth] = useState(
      localStorage.getItem('attendance-month') || moment.utc().format('YYYY-MM')
    );

    useEffect(() => {
      localStorage.setItem('attendance-month', month);
    }, [month]);

    useEffect(() => {
      if (selectedEmployee.id) {
        getAllAttendances({
          employeeId: `equal::${selectedEmployee.id}`,
          from: `more-than::${month}-01`,
          to: `less-than::${month}-31`,
        });
      }
      // eslint-disable-next-line
    }, [selectedEmployee, month]);

    useEffect(() => {
      if (attendances?.length) {
        setEmployeeAttendances(_.sortBy(attendances, 'date'));
      }
    }, [attendances]);

    function renderFinalizationDetails({
      e,
      shiftHours,
      payableDayRatio,
    }: {
      e: AttendanceType;
      shiftHours: number;
      payableDayRatio: number;
    }) {
      return (
        <AuthWrapper
          showUnAuthorizedWarning={true}
          action='UI:BLK-ATTN-FINL:VIEW'
          children={
            <div>
              {e.finalized ? (
                <>
                  <div>
                    {e.details?.summary?.autofinalized ? (
                      <div>
                        <BoltIcon className='text-amber-500 animate-bounce w-4 inline' />
                        {e.details.summary.autofinalizeReason}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    Payable Day: {e.details?.summary?.payableDay ? 'Yes' : 'No'}
                  </div>
                  <div>
                    PayableHours: {e.details?.summary?.payableWorkHours}Hrs
                  </div>
                  <div>Shift Duration: {shiftHours}</div>
                  <div>Payable Day Ratio: {payableDayRatio}</div>
                  {e.details?.summary?.payableDayPenalty ? (
                    <div>
                      Penalty: {e.details.summary.lateMarkMins || 0}Hrs.
                    </div>
                  ) : null}
                  {e.details?.summary?.overtime?.reward ? (
                    <div>
                      Overtime reward type: {e.details.summary.overtime?.reward}
                      {e.details.summary.overtime?.reward === 'salary' ? (
                        <div>
                          Overtime Hours:{' '}
                          {e.details.summary.addSalaryForOvertime?.overtimeHours?.toFixed(
                            2
                          )}
                        </div>
                      ) : null}
                      {e.details.summary.overtime?.reward === 'comp-off' ? (
                        <div>
                          Comp-offs:{' '}
                          {e.details.summary.overtime.totalCompOffEarned}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {(e.details?.summary as any)?.leaves?.length ? (
                    <div>
                      {(e.details?.summary as any).leaves[0].type} leave
                      redeemed ({(e.details?.summary?.leaves?.length || 0) / 2}{' '}
                      count)
                    </div>
                  ) : null}
                  {e.details?.summary?.payableDayPenalty
                    ? 'Payable Day Penalty'
                    : null}
                </>
              ) : (
                <>Finalization Pending</>
              )}
            </div>
          }
        />
      );
    }

    const tableBody: TableBody =
      employeeAttendances.map((e) => {
        const shiftHours =
          e.details?.summary &&
          e.details.summary.hasOwnProperty('shiftDuration')
            ? e.details?.summary?.shiftDuration
            : calculateShiftDuration({ employee: selectedEmployee });
        const payableDayRatio = parseFloat(
          ((e.details?.summary?.payableWorkHours || 0) / shiftHours).toFixed(2)
        );
        return {
          cells: [
            { value: <div>{e.date}</div> },
            {
              value: (
                <div>
                  {e.details?.punches?.map((x, tidx) => (
                    <span
                      className='text-xs mx-0.5 p-0.5 border border-gray-200 rounded-full'
                      key={tidx}
                    >
                      <Chip p={x} date={e.date} />
                    </span>
                  ))}
                </div>
              ),
            },
            {
              value: renderFinalizationDetails({
                e,
                payableDayRatio,
                shiftHours,
              }),
            },
            {
              value: (
                <>
                  {e.status}
                  {currentUserRoles?.find((x) => x.name === 'System-Admin') &&
                  employeeAttendances.filter((x) => x.date === e.date).length >
                    1 ? (
                    <TrashIcon
                      className='inline mx-5 text-red-500 w-5 cursor-pointer'
                      onClick={() => {
                        openConfirm({
                          title:
                            'Are you sure you want to delete the attendance?',
                          onConfirm: async () => {
                            await setAttendance({
                              id: e.id,
                              disabled: true,
                            } as any);
                            getAllAttendances({
                              employeeId: `equal::${selectedEmployee.id}`,
                              from: `more-than::${month}-01`,
                              to: `less-than::${month}-31`,
                            });
                          },
                        });
                      }}
                    />
                  ) : null}
                </>
              ),
            },
          ],
        };
      }) || [];

    return (
      <div className='pb-52'>
        <Card
          header={{
            title: `Employee: ${
              selectedEmployee.id ? selectedEmployee.name : 'None Selected'
            }`,
            icon: (
              <BookOpenIcon className='w-8 h-8 inline stroke-indigo-900 self-center' />
            ),
            subheading: <div className='flex items-center'></div>,
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <div className='flex w-72 p-1 space-x-2 items-center justify-between font-bolder'>
                    <span className='font-bold truncate'>Select Month</span>
                    <MonthSelector
                      format='YYYY-MM'
                      initialState={month}
                      onChange={(m) => setMonth(m)}
                    />
                  </div>
                ),
              },
              {
                type: 'jsx',
                jsx: (
                  <div className='flex p-1 w-80 space-x-2 items-center justify-between font-bolder'>
                    <div className='font-bold flex-1'>Select</div>
                    <div>
                      <SearchBox
                        currentSelected={selectedEmployee}
                        searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm'
                      />
                    </div>
                  </div>
                ),
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                {tableBody.length ? (
                  <Table
                    header={[
                      [
                        { name: 'Date' },
                        { name: 'Timestamps' },
                        { name: 'Payable Day/Hours' },
                        { name: 'Status' },
                      ],
                    ]}
                    body={tableBody}
                  />
                ) : (
                  <div className='mx-auto p-5 text-center text-xl'>
                    {selectedEmployee.id ? (
                      <>
                        <div>
                          Seems like {selectedEmployee.name} does not have any
                          Attendance
                        </div>
                      </>
                    ) : (
                      <>Select an Employee to see Attendance</>
                    )}
                  </div>
                )}
              </div>
            ),
          }}
        />
        <Confirm />
      </div>
    );
  };
}
