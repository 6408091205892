/* eslint-disable no-unused-vars */
import { PurchaseOrderFulfilment } from '@erp_core/erp-types/dist/modules/order';
import { LoadingButton } from '@erp_core/erp-ui-components';
import { CubeIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { UseBatchItemGodown } from '../../../../../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UsePurchaseOrderFulfilment } from '../../../../../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilment';
import { UsePurchaseOrder } from '../../../../../../../hooks/inventory/purchase/purchase-order/use-purchase-order';
import { UsePurchaseOrders } from '../../../../../../../hooks/inventory/purchase/purchase-order/use-purchase-orders';
import { UseBoms } from '../../../../../../../hooks/order/work-order/bom/use-boms';

function AllocateForm({
  fulfilmentData,
  onCancel,
  onSave,
  usePurchaseOrders,
  useBoms,
  useBatchItemGodown,
  usePurchaseOrder,
  usePurchaseOrderFulfilment,
}: {
  fulfilmentData: PurchaseOrderFulfilment;
  onCancel: any;
  onSave: any;
  usePurchaseOrders: UsePurchaseOrders;
  useBoms: UseBoms;
  useBatchItemGodown: UseBatchItemGodown;
  usePurchaseOrder: UsePurchaseOrder;
  usePurchaseOrderFulfilment: UsePurchaseOrderFulfilment;
}) {
  const [userAllotedErrors, setUserAllotedErrors] = useState<
    Array<{
      error: boolean;
      reason: string;
    }>
  >([]);

  const [userAllotedValues, setUserAllotedValues] = useState<
    Array<{
      id: string;
      bomId: string;
      quantity: number;
    }>
  >([]);

  const [virtualBatches, setVirtualBatches] = useState<
    { id: string; bomId: string; quantity: number }[] | []
  >([]);

  const {
    data: allocatedItems,
    getAll: getAllAllocatedItems,
  } = usePurchaseOrders();
  const {
    data: unallocatedItems,
    getAll: getUnallocatedItems,
  } = usePurchaseOrders();
  const { data: boms, getAll: getAllSelectedBoms } = useBoms();
  const { set: setFulfilments } = usePurchaseOrderFulfilment();

  const getBomName = (id: string) => {
    const name = boms?.find((b) => b.id === id)?.name;
    return name || id;
  };

  const availableQuantity = fulfilmentData.details.quantity;
  const totalAllocatedQuantity = userAllotedValues?.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  );
  const remainingQuantity = availableQuantity - totalAllocatedQuantity;

  const handleSaveClick = () => {
    // first add enteries to item-batch-godown
    const sagaData: {
      id: string;
      isActive: boolean;
      payload: {
        physical: any[];
        virtual: any;
      };
    } = {
      id: '',
      isActive: true,
      payload: {
        physical: [],
        virtual: {},
      },
    };
    userAllotedValues
      .filter((u) => u.quantity !== 0)
      .forEach((v) => {
        const bomDetails = boms?.find((b) => b.id === v.bomId);
        if (bomDetails && fulfilmentData) {
          const finalData = {
            name: bomDetails.name,
            item: { id: fulfilmentData.purchaseOrder.details.itemDetails.id },
            quantity: v.quantity,
            rate: fulfilmentData.purchaseOrder.details.rate,
            godown: { id: bomDetails.details.dispatchTo },
            referenceId: bomDetails.id,
          };
          sagaData.payload.physical.push(finalData); // add to sagaData
          // setBatchItem(finalData as unknown as BatchItemGodown)
        }
      });

    if (remainingQuantity > 0) {
      const unallocatedData = {
        name: fulfilmentData.name || 'PFULL', // To be discussed
        item: { id: fulfilmentData.purchaseOrder.details.itemDetails.id },
        quantity: remainingQuantity,
        rate: fulfilmentData.purchaseOrder.details.rate,
        godown: { id: '41AD4D08-EC0E-4E51-85CA-4B102212B0D2' }, // ToDo to get godown id from item master
      };
      sagaData.payload.physical.push(unallocatedData); // add to sagaData
      // setBatchItem(unallocatedData as unknown as BatchItemGodown)
    }

    // To delete/reduce quantity of items from virtual table purchase-order-items vi purchase-order table
    const virtualData = {
      id: fulfilmentData.purchaseOrder.id,
      purchaseOrderItems: virtualBatches.map((i) => ({
        id: i.id,
        disabled:
          i.quantity -
            (userAllotedValues.find((a) => a.id === i.id)?.quantity || 0) ===
          0,
        quantity:
          i.quantity -
          (userAllotedValues.find((a) => a.id === i.id)?.quantity || 0),
        // purchase_order_id: fulfilmentData.purchaseOrder.id
      })),
    };
    sagaData.payload.virtual = virtualData; // add to sagaData
    // setPurchaseOrder(virtualData as unknown as PurchaseOrder)

    // reduce virtual stock when balanced remaining
    if (unallocatedItems && unallocatedItems.length > 0) {
      const poItems = unallocatedItems[0].grade;
      if (poItems) {
        const unaAllocatedVirtualData = {
          id: fulfilmentData.purchaseOrder.id,
          purchaseOrderItems: [
            {
              id: poItems.id,
              disabled:
                unallocatedItems[0].details?.quantity - remainingQuantity === 0,
              quantity:
                unallocatedItems[0].details?.quantity - remainingQuantity,
            },
          ],
        };

        sagaData.payload.virtual.purchaseOrderItems.push(
          unaAllocatedVirtualData.purchaseOrderItems[0]
        ); // add to sagaData
        // setPurchaseOrder(unaAllocatedVirtualData as unknown as PurchaseOrder)
      }
    }

    // to disable allocate action in purchase-order-fulfilment
    sagaData.id = fulfilmentData.id;
    sagaData.isActive = false;

    setFulfilments((sagaData as unknown) as PurchaseOrderFulfilment);
    // console.log('sagaData', sagaData);
    onSave();
  };

  useEffect(() => {
    getAllAllocatedItems({
      itemId: fulfilmentData?.purchaseOrder?.details?.itemDetails?.id,
      id: fulfilmentData?.purchaseOrder?.id,
      referenceId: 'not null',
    });

    getUnallocatedItems({
      itemId: fulfilmentData?.purchaseOrder?.details?.itemDetails?.id,
      id: fulfilmentData?.purchaseOrder?.id,
      referenceId: 'null',
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const vb =
      allocatedItems && allocatedItems[0]
        ? [
            {
              id: allocatedItems[0].grade?.id,
              bomId: allocatedItems[0].id,
              quantity: allocatedItems[0].details?.quantity,
            },
          ]
        : [];
    setVirtualBatches(vb);
    // ToDo get only selected boms
    getAllSelectedBoms();
    // eslint-disable-next-line
  }, [allocatedItems]);

  useEffect(() => {
    const values: Array<{
      quantity: number;
      bomId: string;
      id: string;
    }> = _.times(virtualBatches.length).map((i) => ({
      id: virtualBatches[i].id,
      bomId: virtualBatches[i].bomId,
      quantity: 0,
    }));
    setUserAllotedValues(values);

    const errors: Array<{
      error: boolean;
      reason: string;
    }> = _.times(virtualBatches.length).map((i) => ({
      error: false,
      reason: '',
    }));
    setUserAllotedErrors(errors);
  }, [virtualBatches]);

  useEffect(() => {
    if (userAllotedValues.length && virtualBatches?.length) {
      const updatedErrors: Array<{
        error: boolean;
        reason: string;
      }> = _.times(virtualBatches.length).map((i) => ({
        error: false,
        reason: '',
      }));
      // eslint-disable-next-line
      let updated: boolean = false;

      userAllotedValues.forEach((val, idx) => {
        if (virtualBatches && val.quantity > virtualBatches[idx].quantity) {
          updated = true;
          updatedErrors[idx].error = true;
          updatedErrors[
            idx
          ].reason = `can't exceed ${virtualBatches[idx].quantity}`;
        }
      });
      setUserAllotedErrors(updatedErrors);
    }
    // eslint-disable-next-line
  }, [userAllotedValues]);

  return (
    <div className='p-5 flex flex-col space-y-4'>
      <div>
        {/* { bomData.rawItem.quantity === physicallyAllocated && <div className='flex space-x-2 justify-center'>
                <InformationCircleIcon className='w-6 h-6 text-green-400' />
                Total required Items are already allocated.
            </div> } */}
      </div>
      <div className='flex flex-row'>
        <div className='border p-1 m-1'>
          <label className='text-gray-800 text-md font-bold mb-2'>
            Available:&nbsp;
          </label>
          <span className='text-gray-500'>{availableQuantity}</span>
        </div>
        <div
          className={`p-1 m-1 ${
            remainingQuantity < 0 ? 'border-2 border-red-600' : 'border'
          }`}
        >
          <label className='text-gray-800 text-md font-bold mb-2'>
            Total Allocated:&nbsp;
          </label>
          <span className='text-gray-500'>{totalAllocatedQuantity}</span>
        </div>
        <div
          className={`p-1 m-1 ${
            remainingQuantity < 0 ? 'border-2 border-red-600' : 'border'
          }`}
        >
          <label className='text-gray-800 text-md font-bold mb-2'>
            Remaining:&nbsp;
          </label>
          <span className='text-gray-500'>{remainingQuantity}</span>
        </div>
      </div>

      <form>
        <div className='flex flex-row flex-wrap'>
          {virtualBatches?.map((i, index) => (
            <div key={index} className='border p-1 w-1/4'>
              <div
                className={`p-1 mx-auto text-center m-1 bg-blue-100 ${
                  userAllotedErrors[index]?.error
                    ? 'border-2 border-red-600'
                    : ''
                }`}
              >
                <CubeIcon className='inline-block w-8 h-8 text-blue-500' />
                <sup className='bg-green-200 text-green-800 rounded sups text-lg'>
                  {i.quantity}
                </sup>
                <input
                  className='mb-3 ml-1 shadow appearance-none border rounded w-14  text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  id='alloted'
                  onChange={(e) => {
                    const { value } = e.target;
                    setUserAllotedValues((val) => {
                      const res = [...val];
                      res[index].quantity = parseInt(value || '0', 10);
                      return res;
                    });
                  }}
                  type='number'
                  value={userAllotedValues[index]?.quantity || 0}
                />
                {userAllotedErrors[index]?.error ? (
                  <label className='block text-red-700 text-sm font-bold mb-2'>
                    {userAllotedErrors[index]?.reason}
                  </label>
                ) : null}
              </div>

              <div className='mx-auto text-center'>
                <label className='block text-blue-600 text-md font-bold'>
                  {getBomName(i.bomId)}
                </label>
                {/* <label className="block text-gray-800 text-md font-bold mb-2">
                            {i.godown.name}
                            </label> */}
              </div>
            </div>
          ))}
        </div>

        {remainingQuantity < 0 ? (
          <div className='mx-auto w-1/2 text-center'>
            <label className='block text-red-700 text-sm font-bold mb-2'>
              Total allocation cannot exceed {availableQuantity}
            </label>
          </div>
        ) : null}
        <div className='flex items-center mt-12 justify-end'>
          <LoadingButton
            defaultStyle='bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            behaviorFn={onCancel}
            text='Cancel'
          />
          <button
            className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            type='button'
            disabled={!!userAllotedErrors.find((a) => a.error)}
            onClick={handleSaveClick}
          >
            Allocate
          </button>
        </div>
      </form>
    </div>
  );
}

export default AllocateForm;
