import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { renderModal } from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseCompanyGroupSetting } from '../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { UseCompanyGroupSettings } from '../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { CGConfigRenderer, CGConfigs } from '../config-renderer';
import { renderEditSENamePatternForm } from './forms/edit-se-name-pattern';
import { renderEditSONamePatternForm } from './forms/edit-so-name-pattern';

type Props = {
  useCompanyGroupSetting: UseCompanyGroupSetting;
  useCompanyGroupSettings: UseCompanyGroupSettings;
};

export const createSalesConfigPage = ({
  useCompanyGroupSetting,
  useCompanyGroupSettings,
}: Props) => {
  return function () {
    const { data: allData, getAll, loading } = useCompanyGroupSettings();

    const [data, setData] = useState<CompanyGroupSetting>();

    useEffect(() => {
      setData(
        (allData || []).length > 0
          ? allData
            ? allData[0]
            : undefined
          : undefined
      );
    }, [allData]);

    useEffect(() => {
      getAll();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [modalState, setModalState] = useState({
      title: '',
      isVisible: false,
      onClose: () => setModalState((ms) => ({ ...ms, isVisible: false })),
      body: (s: { comGrpSet?: CompanyGroupSetting }) => (
        <div className='hidden' />
      ),
    });

    const closeModalAndRefresh = () => {
      setModalState((ms) => ({ ...ms, isVisible: false }));
      getAll();
    };

    type GenericEditPatternFormProps = {
      closeModal: () => void;
      useCompanyGroupSetting: UseCompanyGroupSetting;
    };

    type Props = {
      comGrpSet?: CompanyGroupSetting;
    };
    const handleEditPattern = (
      genericEditPatternForm: ({
        closeModal,
        useCompanyGroupSetting,
      }: GenericEditPatternFormProps) => (data: Props) => JSX.Element,
      title: string
    ) => {
      const Form = genericEditPatternForm({
        closeModal: closeModalAndRefresh,
        useCompanyGroupSetting,
      });
      setModalState((ms) => ({
        ...ms,
        title,
        isVisible: true,
        body: Form,
      }));
    };

    const Modal = renderModal();

    const configs: CGConfigs[] = [
      {
        settingName: 'Sales Order Name Pattern',
        settingValue: data?.details?.sales?.salesOrder?.soNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: () =>
          handleEditPattern(
            renderEditSONamePatternForm,
            'Edit Sales Order Name Pattern'
          ),
      },
      {
        settingName: 'Sales Enquiry Name Pattern',
        settingValue: data?.details?.sales?.salesEnquiry?.seNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: () =>
          handleEditPattern(
            renderEditSENamePatternForm,
            'Edit Sales Enquiry Name Pattern'
          ),
      },
    ];

    return (
      <div className='w-full'>
        <CGConfigRenderer configs={configs} loading={loading} />
        <Modal
          title={modalState.title}
          isVisible={modalState.isVisible}
          body={<div />}
          onClose={modalState.onClose}
        >
          <modalState.body comGrpSet={data} />
        </Modal>
      </div>
    );
  };
};
