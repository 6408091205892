/* eslint-disable no-unused-vars */

import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { NewCompanyLoan } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import {
  ActionContextMenu,
  CardBody,
  CardHeader,
  Modal,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import {
  EyeIcon,
  EyeSlashIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import { UseCurrentUser } from '../../../../../hooks/admin/user-admin/use-current-user';
import { createAddRepayments } from '../loan-advance-form/company-loan/add-repayments';
import { renderAddCompanyForm } from '../loan-advance-form/company-loan/company-add-form';

export function renderCompanyLoan({
  useCurrentUser,
  employee,
  setEmployee,
  actions,
  isHr,
}: {
  useCurrentUser: UseCurrentUser;
  employee: EmployeeProfileType;
  setEmployee: (s: EmployeeProfileType) => void;
  isHr: boolean;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
}): // eslint-disable-next-line
({ id }: { id: string }) => JSX.Element {
  const AddRepayments = createAddRepayments();

  return function CompanyLoan({ id }: { id: string }) {
    const { data: currentUser } = useCurrentUser();

    const [
      selectedCompanyLoan,
      setSelectedCompanyLoan,
    ] = useState<NewCompanyLoan | null>();

    const [modalState, setModalState] = useState({
      visible: false,
      title: 'Edit Company Loan ',
      content: <>Edit Company Loan form</>,
    });

    const [actionsState, setActionsState] = useState({
      visible: false,
      xPosition: 200,
      yPosition: 200,
      menu: ['Edit'],
    });

    // eslint-disable-next-line
    const [activeDataId, setActiveDataId] = useState<string>('');

    // eslint-disable-next-line
    const handleCompanyLoanAdd = async (data: NewCompanyLoan) => {
      const finalData = {
        id: employee.id,
        details: {
          companyLoans: [...(employee.details.companyLoans || []), data],
        },
      };

      setEmployee(finalData as EmployeeProfileType);
    };

    const handleActionClick = (e: any, id: string) => {
      setActionsState({
        ...actionsState,
        visible: true,
        xPosition: e.pageX,
        yPosition: e.pageY,
      });
      setActiveDataId(id);
    };

    const CompanyLoanCard = renderCardComponent();
    const CompanyLoanCardHeader: CardHeader = {
      title: 'Company Loan (Legacy)',
      actions: [
        {
          auth: 'HRD:EMP-PROFILE:EDIT',
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add Company Loan',
              content: ({ onClose }) => {
                const Form = renderAddCompanyForm();
                return (
                  <Form
                    onSave={async (form) => {
                      await handleCompanyLoanAdd(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const CompanyLoanTable = renderTableComponent();
    const companyLoanTableHeader: TableHeader = [
      [
        // { name: 'ID',  },
        { name: 'Application Date' },
        { name: 'Amount Applied' },
        { name: 'Status' },
        { name: 'Interest Rate, %' },
        { name: 'Tenure, months' },
        { name: 'Amt Sanctioned, Rs.' },
        { name: 'Authorisation Date' },
        { name: 'Loan Release Date' },
        { name: 'Action' },
        // { name: 'Balance, Rs.',  }
      ],
    ];

    function openEditCompanyLoan(idx: number) {
      if (employee.details.companyLoans) {
        const companyLoan = employee.details.companyLoans[idx];

        setModalState({
          ...modalState,
          visible: true,
          title: 'Add Company Loan',
          content: (
            <AddRepayments
              companyLoan={companyLoan}
              currentUser={currentUser}
              onSave={(c) => {
                const companyLoans = employee.details.companyLoans;
                companyLoans?.splice(idx, 1, c);
                const finalData = {
                  id: employee.id,
                  details: {
                    companyLoans: [...(companyLoans || [])],
                  },
                };

                setEmployee(finalData as EmployeeProfileType);
                setModalState((ms) => ({ ...ms, visible: false }));
              }}
            />
          ),
        });
      }
    }

    const CompanyLoanTableBody: TableBody = employee.details?.companyLoans
      ? employee.details.companyLoans.map((f, idx) => {
          const cells: Array<TableCell> = [];
          cells.push(
            // { value: f.fields?.id},
            { value: f.dateApplied },
            { value: f.amount },
            { value: f.status },
            { value: f.approval?.interestRate || '-' },
            { value: f.tenureInMonths },
            { value: f.approval?.amount?.principal || '-' },
            { value: f.approval?.dateApproved || '-' },
            { value: f.approval.disbursementDetails?.date || '-' },
            {
              value: (
                <div>
                  {isHr ? (
                    <>
                      <PencilSquareIcon
                        className='w-4 mx-1 inline'
                        onClick={() => {
                          openEditCompanyLoan(idx);
                        }}
                      />
                      {selectedCompanyLoan === f ? (
                        <EyeSlashIcon
                          className='w-4 mx-1 text-green-700 inline'
                          onClick={() => {
                            setSelectedCompanyLoan(null);
                          }}
                        />
                      ) : (
                        <EyeIcon
                          className='w-4 mx-1 inline'
                          onClick={() => {
                            setSelectedCompanyLoan(f);
                          }}
                        />
                      )}
                    </>
                  ) : null}
                </div>
              ),
            }
            // { value: f.fields?.balance }
          );

          return { cells };
        })
      : [];

    const CompanyLoanCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <CompanyLoanTable
              header={companyLoanTableHeader}
              body={CompanyLoanTableBody}
            />
            {selectedCompanyLoan ? (
              <LoanRepayment companyLoan={selectedCompanyLoan} />
            ) : null}
          </div>
        </div>
      ),
    };

    return (
      <>
        <CompanyLoanCard
          header={CompanyLoanCardHeader}
          body={CompanyLoanCardBody}
          auth={{ actions }}
        />
        {actionsState.visible && (
          <ActionContextMenu
            actionsState={actionsState}
            setActionsState={setActionsState}
            handleActionItemClick={handleActionClick}
          />
        )}
        <Modal
          showModal={modalState.visible}
          setShowModal={() =>
            setModalState({ ...modalState, visible: !modalState.visible })
          }
          title={modalState.title}
          onOk={() => {}}
          style={{ width: '800px' }}
        >
          {modalState.content}
        </Modal>
      </>
    );
  };
}

function LoanRepayment({
  companyLoan,
}: {
  companyLoan: NewCompanyLoan;
}): JSX.Element {
  return (
    <div className='border border-gray-200 rounded-lg p-2'>
      <div className='text-center text-lg font-semibold'>
        Repayment schedule
      </div>
      <div className='p-2 border border-gray-200 flex flex-wrap'>
        <div className='basis-1/3'>
          <b>Amount:</b> {companyLoan.amount}
        </div>
        <div className='basis-1/3'>
          <b>Tenure:</b> {companyLoan.tenureInMonths}
        </div>
        <div className='basis-1/3'>
          <b>Status:</b> {companyLoan.status}
        </div>
      </div>
      {companyLoan.status === 'approved' ? (
        <div className='p-1 border border-gray-200 flex flex-wrap'>
          <div className='basis-1/3'>
            <b>Interest Rate:</b> {companyLoan.approval?.interestRate}
          </div>
          <div className='basis-1/3'>
            <b>EMI Principle:</b> {companyLoan.approval?.amount?.principal}
          </div>
          <div className='basis-1/3'>
            <b>EMI Interest:</b> {companyLoan.approval?.amount?.interest}
          </div>
          <div className='basis-1/3'>
            <b>Approved By:</b> {companyLoan.approval?.approvedBy?.name}
          </div>
          <div className='basis-1/3'>
            <b>Date Approved:</b> {companyLoan.approval?.dateApproved}
          </div>
          <div className='basis-1/3'>
            <b>Date Disbursed:</b>{' '}
            {companyLoan.approval?.disbursementDetails?.date}
          </div>
          <div className='basis-1/3'>
            <b>Disbursed By:</b>{' '}
            {companyLoan.approval?.disbursementDetails?.disbursementBy?.name}
          </div>
        </div>
      ) : null}

      <table className='w-full border-collapse border-gray-100'>
        <thead>
          <tr>
            <th>Month</th>
            <th>EMI-Principle</th>
            <th>EMI-Interest</th>
            <th>Status</th>
            <th>Payment Date</th>
          </tr>
          {companyLoan.repaymentSchedule.map((e, idx) => (
            <tr key={idx} className='bg-slate-100 p-1'>
              <td>{e.monthYear}</td>
              <td>{e.principal}</td>
              <td>{e.interest}</td>
              <td>{e.status || 'pending'} </td>
              <td>{e.paymentDate || '-'}</td>
            </tr>
          ))}
        </thead>
      </table>
    </div>
  );
}
