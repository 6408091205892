import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { GodownDetails } from '@erp_core/erp-types/dist/types/modules/inventory/godown';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect, useMemo, useState } from 'react';
import { UseGodowns } from '../../../../hooks/inventory/godown-inventory/use-godowns';

// Creating is work area v2
type RenderSchedulePageV2Props = {
  useGodowns: UseGodowns;
};

type WPS = {
  employees: IdName[];
  id: string;
  name: string;
  details: GodownDetails;
};

export const renderWorkAreaV2Page = ({
  useGodowns,
}: RenderSchedulePageV2Props) => {
  return function () {
    const { data: godowns, getAll: getGodowns } = useGodowns();

    useEffect(() => {
      getGodowns();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const workPlaces = useMemo(
      () =>
        godowns?.filter(
          (g) => g.details.workArea || g.details.supervisorWorkArea
        ) || [],
      [godowns]
    );

    const [workPlacesSch, setWorkPlaceSch] = useState<WPS[]>(
      workPlaces
        .map((wps) => {
          return {
            id: wps.id,
            name: wps.name,
            details: wps.details,
            employees: [],
          };
        })
        .sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
    );

    useEffect(() => {
      setWorkPlaceSch(
        workPlaces
          .map((wps) => {
            return {
              id: wps.id,
              name: wps.name,
              details: wps.details,
              employees: wps.details.employees || [],
            };
          })
          .sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          })
      );
    }, [workPlaces]);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Work Areas List',
    };

    // Grouped Work Places Data to Show Representation
    const groupedWorkPlaces: Array<Array<WPS>> = [];
    for (let i = 0; i < workPlacesSch.length; i++) {
      // check if current wp is having any sibling which is already present in the groupedWP
      if (workPlacesSch[i].details.siblingWorkAreas?.length) {
        const siblingEntry = groupedWorkPlaces.find((r) => {
          if (
            r.find(
              (x) =>
                x.id ===
                (workPlacesSch[i].details as any).siblingWorkAreas[0].id
            )
          ) {
            return true;
          }

          return false;
        });

        if (siblingEntry) {
          siblingEntry.push(workPlacesSch[i]);
        } else {
          groupedWorkPlaces.push([workPlacesSch[i]]);
        }
      } else {
        groupedWorkPlaces.push([workPlacesSch[i]]);
      }
    }

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [[{ name: 'Work Area' }]];

    const gwps = groupedWorkPlaces
      .map((gwps, gwpIndex) => {
        return gwps;
      })
      .flat();

    const tableBody: TableBody =
      gwps.map((gwp) => ({
        cells: [
          {
            value: gwp.name,
            link: `/hrd/shift-management/work-area/${gwp.id}`,
          },
        ],
      })) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table header={tableHeader} body={tableBody} />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
};
