import { Users } from '@erp_core/erp-icons/icons/web/users';
import { Authorization, User } from '@erp_core/erp-types/dist/modules/admin';
import {
  CardBody,
  CardHeader,
  LoadingButton,
  ModalV2Props,
  renderCardComponent,
  renderModal,
  renderTableComponent,
  TableBody,
  TableHeader,
  useConfirm,
  ValidPageSize,
} from '@erp_core/erp-ui-components';
import { Popover } from '@headlessui/react';
import {
  EllipsisHorizontalIcon,
  KeyIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { UseRoles } from '../../../hooks/admin/role-admin/use-roles-admin';
import { UseUserAdmin } from '../../../hooks/admin/user-admin/use-user-admin';
import {
  UsePaginatedUsers,
  UseUsers,
} from '../../../hooks/admin/user-admin/use-users-admin';
import { PaginatedData } from '../../../models/interfaces/generic-resource';
import { renderAddNewUserForm } from './add-user-form';
import { renderUserEditForm } from './edit-user-form';
// import { ValidPageSize } from '../../../components/pagination';

export type RenderUserAdminProps = {
  useUsers: UseUsers;
  usePaginatedUsers: UsePaginatedUsers;
  useUser: UseUserAdmin;
  useRoles: UseRoles;
};

export type RowData = {
  index: number;
  user?: { name: string; span: number };
  company?: { name: string; span: number };
  location?: { name: string; span: number } | string;
  role: string;
  actions: Array<string>;
};

export function renderUserAdminV2({
  useUsers,
  useUser,
  usePaginatedUsers,
  useRoles,
}: RenderUserAdminProps): () => JSX.Element {
  return function (): JSX.Element {
    // const { data: users, getAll } = useUsers();

    const { Confirm, openConfirm } = useConfirm();

    const [pageState, setPageState] = useState<{
      pageSize: ValidPageSize;
      pageNumber: number;
    }>({ pageSize: 3, pageNumber: 1 });

    const { data: paginatedUsers, getWithPagination } = usePaginatedUsers();

    const [search, setSearch] = useState('');

    const { syncSet } = useUser();

    const saveRole = async (user: User) => {
      await syncSet(user);
      closeEditModal();
      // getAll();
    };

    useEffect(() => {
      // getAll({
      //   getAll: true
      // });
      getWithPagination(pageState);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      getWithPagination({ search, ...pageState });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const closeEditModal = () => {
      setModal({
        isVisible: false,
        title: 'Edit User Role',
        onClose: closeEditModal,
        body: <div />,
      });
    };

    const EditUserRoleForm = renderUserEditForm({ saveRole, useRoles });

    const [modal, setModal] = useState<ModalV2Props>({
      isVisible: false,
      title: 'Edit User Role',
      onClose: closeEditModal,
      body: <div />,
    });

    const Modal = renderModal();

    const editUserRole = (r: User) => {
      setModal({
        isVisible: true,
        title: `Edit ${r.name}`,
        onClose: closeEditModal,
        body: <EditUserRoleForm user={r} />,
      });
    };

    const closeAddNewUserModal = () => {
      setAddNewUserModal({
        isVisible: false,
        title: 'Add New User',
        onClose: closeAddNewUserModal,
        body: <div />,
      });
    };

    const [addNewUsermodal, setAddNewUserModal] = useState<ModalV2Props>({
      isVisible: false,
      title: 'Add New User',
      onClose: closeAddNewUserModal,
      body: <div />,
    });

    const saveNewUser = async (user: User) => {
      await syncSet(user);
      getWithPagination({ ...pageState });
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'User Admin Screen',
      icon: (
        <Users className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add New User',
            behaviour: 'modal',
            modal: {
              title: 'Add New User',
              content: ({ onClose }) => {
                const AddNewUserForm = renderAddNewUserForm({
                  saveNewUser,
                  onClose,
                });
                return <AddNewUserForm />;
              },
            },
          },
        },
      ],
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'User' },
        { name: 'companies' },
        { name: 'Roles and Actions' },
        { name: 'Actions', style: 'w-20' },
      ],
    ];

    if (!paginatedUsers) return <div>LOADING</div>;

    const tableBody: TableBody =
      paginatedUsers?.data.map((u) => {
        return {
          cells: [
            {
              value: (
                <>
                  {u.name}
                  {u.loginEnabled ? (
                    <KeyIcon
                      title='Login Enabled'
                      className='mx-1 inline w-5 text-green-500 animate-bounce'
                    />
                  ) : (
                    <LoadingButton
                      text='Create Login Account'
                      behaviourParams={{ user: u }}
                      behaviorFn={async ({ user }: { user: User }) => {
                        openConfirm({
                          title: `Are you sure you want to create Login profile for ${user.name}?`,
                          message:
                            'Login profile once created can allow this user to access. Kindly ensure the employee profile has the office email or personal email set.',
                          onConfirm: async () => {
                            await syncSet({
                              id: user.id,
                              loginEnabled: true,
                            } as User);
                          },
                        });
                      }}
                    />
                  )}
                </>
              ),
              style: 'w-52',
            },
            {
              value: (
                <div className='flex space-x-1'>
                  {u.companies.map((c) => (
                    <span className='rounded border'>{c.name}</span>
                  ))}
                </div>
              ),
            },
            {
              value: <RoleActions u={u} />,
            },
            {
              value: (
                <PencilSquareIcon
                  className='w-6 h-6 text-blue-600 hover:cursor-pointer'
                  onClick={() => editUserRole(u)}
                />
              ),
            },
          ],
        };
      }) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              pagination={{
                enabled: true,
                data: paginatedUsers as PaginatedData<any>,
                activePage: pageState.pageNumber,
                activePageSize: pageState.pageSize,
                handlePageChange(e) {
                  getWithPagination({
                    pageNumber: e.currentPage,
                    pageSize: e.pageSize,
                  });
                  setPageState({
                    pageSize: e.pageSize,
                    pageNumber: e.currentPage,
                  });
                },
              }}
            />
            <Modal {...modal} />
            <Modal {...addNewUsermodal} />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <input
          className='focus:outline-none focus:border-2 w-96'
          onChange={(e) => {
            e.preventDefault();
            setSearch(e.target.value);
          }}
          type='text'
          value={search}
          placeholder='Search user'
        />
        <Card header={cardHeader} body={cardBody} />
        <Confirm />
      </div>
    );
  };
}

const RoleActions = ({ u }: { u: User }) => {
  return (
    <div>
      {u.roles.map((c, idx) => (
        <div
          className='rounded border-gray-300 bg-white p-1 my-2 border'
          key={idx}
        >
          <div className='font-semibold ml-1'>{c.name}</div>
          <div className='flex space-x-1 m-1'>
            {!c.authorizations || c.authorizations.length === 0 ? (
              <div className='text-yellow-500'>!!Role has no actions.</div>
            ) : (
              <AuthorizationView
                authorizations={c.authorizations}
                roleName={c.name}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export const AuthorizationView = ({
  authorizations,
  roleName,
}: {
  authorizations: Authorization[];
  roleName: string;
}) => {
  let subList: Authorization[] = [];
  authorizations.length > 3
    ? (subList = authorizations.slice(0, 3))
    : (subList = [...authorizations]);
  return (
    <div className='flex space-x-2'>
      {subList.map((at, idx) => (
        <span
          key={idx}
          className='border text-center border-green-400 bg-zinc-200 rounded-md'
        >
          {at.name}
        </span>
      ))}
      {authorizations.length > 3 ? (
        <Popover className=''>
          <Popover.Button className='p-0 m-0 hover:text-blue-500'>
            <EllipsisHorizontalIcon className='w-5 h-3' />
          </Popover.Button>
          <Popover.Panel className='absolute top-1/2 translate-y-[-50%] translate-x-[-50%] tra left-1/2 max-w-2xl bg-zinc-50 p-10 rounded shadow-2xl z-50'>
            {({ open }) => (
              <>
                <div className='font-bold text-xl mb-2'>{roleName}</div>
                <div className='grid grid-cols-2 scrollbar-none max-h-96 overflow-y-auto space-x-2 space-y-2 w-full'>
                  {authorizations.map((at, idx) => (
                    <span
                      key={idx}
                      className='border p-1 border-green-400 bg-zinc-200 rounded-md'
                    >
                      {at.name}
                    </span>
                  ))}
                </div>
                <img src='/solutions.jpg' alt='' />
              </>
            )}
          </Popover.Panel>
        </Popover>
      ) : (
        <div className='hidden' />
      )}
    </div>
  );
};
