import { UserIdName } from '@erp_core/erp-types/dist/modules/common';
import {
  RotationalShiftDayType,
  ShiftScheduleType,
} from '@erp_core/erp-types/dist/modules/planning';
import { LoadingButton, renderFormV2 } from '@erp_core/erp-ui-components';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { UseEmployeeProfiles } from '../../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseRotationalShiftDays } from '../../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-days';
import { shiftSwitchTypeList } from './select-switch-weekly-off-form';

export const SelectSwitchEmployeeForm = ({
  date,
  useRotationalShiftDays,
  useEmployees,
  schedules,
  switchShift,
  emp1Shift,
}: {
  date: string;
  useRotationalShiftDays: UseRotationalShiftDays;
  useEmployees: UseEmployeeProfiles;
  schedules: ShiftScheduleType[] | undefined;
  switchShift: (
    emp1Shift: RotationalShiftDayType,
    emp2Shift: RotationalShiftDayType,
    reason: string,
    type: 'company-mandated' | 'individual'
  ) => Promise<void>;
  emp1Shift: RotationalShiftDayType;
}) => {
  const {
    data: switchEmployeeShiftDays,
    getAll: getAllSwitchEmployeeShiftDays,
  } = useRotationalShiftDays();
  const [formData, setFormData] = useState<{
    employee: UserIdName;
    date: string;
    type: 'company-mandated' | 'individual';
    reason: string;
  }>({
    employee: { id: '', name: '' },
    date: date,
    type: 'individual',
    reason: '',
  });

  const getData = (employee, date) => {
    getAllSwitchEmployeeShiftDays({
      employeeId: `equal::${employee?.id}` || '',
      date,
    });
  };

  const Form = renderFormV2<any>({
    fieldsData: [
      {
        type: 'searchable-select',
        property: 'employee',
        label: 'Select Employee to change shift',
        searchOptions: {
          useSearch: useEmployees,
          filter: { crossGroup: 'true' },
          onSearchValueSelect: () => {},
        },
      },
      {
        type: 'date',
        property: 'date',
        readonly: true,
        label: 'Date of shift switch',
      },
      {
        type: 'input',
        property: 'reason',
        required: true,
        label: 'Reason to change shift',
      },
      {
        type: 'select',
        property: 'type',
        required: true,
        label: 'Select type',
        options: [{ value: '', text: 'select' }, ...shiftSwitchTypeList],
      },
    ],
    onSubmit: async (data) => {
      setFormData(data);
      getData(data.employee, data.date);
    },
    initialFormState: formData,
    style: 'w-1/2',
    button: {
      text: 'Search',
      style: 'w-fit text-xs',
    },
    allowUnchanged: true,
    mapTToU: (t) => {
      return t as any;
    },
  });

  const sch1 = schedules?.find((x) => x.id === emp1Shift.details.shiftId?.id);
  const canWeSwitch = (
    s1: RotationalShiftDayType,
    s2: RotationalShiftDayType
  ) => {
    if (s1.employee.id === s2.employee.id)
      return '!Can not switch, Both Employees are same';
    if (s1.details.shiftId === s2.details.shiftId)
      return '!Can not switch as both are same shift';
    if (s2.attendance !== 'working')
      return '!Can not switch as employee on weekly off';
    return null;
  };

  return (
    <div>
      <Form />
      <div className='border space-x-2 space-y-2 rounded p-2'>
        <div className='flex'>
          <div className='w-1/2'>
            <div>Current Employee Shift</div>
            <div>
              <div>
                <b>Employee Name:</b> {emp1Shift.employee.name}
              </div>
              <div>
                <b>Date:</b> {emp1Shift.date}
              </div>
              <div className='flex'>
                <div>
                  <b>Shift:</b> {sch1?.name}
                </div>
                <div className='text-xs text-gray-800 font-thin'>
                  ({sch1?.startTime || '?'} to {sch1?.endTime || '?'})
                </div>
              </div>
              <div>
                <b>Attendance status:</b> {emp1Shift.attendance}
              </div>
              <div>
                <b>Work Area:</b> {emp1Shift.details.workArea?.name}
              </div>
              <div>
                <b>Roles:</b> {emp1Shift.details.roleName}
              </div>
            </div>
          </div>

          <div className='w-1/2'>
            <div>Selected Employee Shift</div>
            <div>
              {switchEmployeeShiftDays?.length ? (
                switchEmployeeShiftDays?.map((s) => {
                  const sch = schedules?.find(
                    (x) => x.id === s.details.shiftId?.id
                  );
                  return (
                    <div key={s.id}>
                      <div
                        className={`w-fit ${
                          s.employee.id === emp1Shift.employee.id
                            ? 'bg-red-100'
                            : ''
                        }`}
                      >
                        <b>Employee Name:</b> {s.employee.name}
                        {s.employee.id === emp1Shift.employee.id ? (
                          <ExclamationTriangleIcon
                            title='Selected employee and current employee are same'
                            className='w-5 h-5 inline-block text-red-500'
                          />
                        ) : null}
                      </div>

                      <div>
                        <b>Date:</b> {s.date}
                      </div>

                      <div
                        className={`flex w-fit ${
                          sch?.id === sch1?.id ? 'bg-red-100' : ''
                        }`}
                      >
                        <div>
                          <b>Shift:</b> {sch?.name}
                        </div>
                        <div className='text-xs text-gray-800 font-thin'>
                          ({sch?.startTime || '?'} to {sch?.endTime || '?'})
                        </div>
                        {sch?.id === sch1?.id ? (
                          <ExclamationTriangleIcon
                            title='Shift of both employee are same'
                            className='w-5 h-5 inline-block text-red-500'
                          />
                        ) : null}
                      </div>

                      <div
                        className={`w-fit ${
                          s.attendance !== 'working' ? 'bg-red-100' : ''
                        }`}
                      >
                        <b>Attendance status:</b> {s.attendance}
                        {s.attendance !== 'working' ? (
                          <ExclamationTriangleIcon
                            title='Selected employee id not weekly off!'
                            className='w-5 h-5 inline-block text-red-500'
                          />
                        ) : null}
                      </div>

                      <div
                        className={`w-fit ${
                          s.details.workArea?.id !==
                          emp1Shift.details.workArea?.id
                            ? 'bg-red-100'
                            : ''
                        }`}
                      >
                        <b>Work Area:</b> {s.details.workArea?.name}
                        {s.details.workArea?.id !==
                        emp1Shift.details.workArea?.id ? (
                          <ExclamationTriangleIcon
                            title='Work are not matching!'
                            className='w-5 h-5 inline-block text-red-500'
                          />
                        ) : null}
                      </div>

                      <div
                        className={`w-fit ${
                          s.details.roleName !== emp1Shift.details.roleName
                            ? 'bg-red-100'
                            : ''
                        }`}
                      >
                        <b>Roles:</b> {s.details.roleName}
                        {s.details.roleName !== emp1Shift.details.roleName ? (
                          <ExclamationTriangleIcon
                            title='Role is not matching'
                            className='w-5 h-5 inline-block text-red-500'
                          />
                        ) : null}
                      </div>

                      {canWeSwitch(emp1Shift, s) ? (
                        <div>{canWeSwitch(emp1Shift, s)}</div>
                      ) : (
                        <LoadingButton
                          defaultStyle='text-blue-400 px-2 border border-blue-500 bg-blue-100 rounded font-bold hover:text-blue-500'
                          behaviorFn={async () => {
                            await switchShift(
                              emp1Shift,
                              s,
                              formData.reason,
                              formData.type
                            );
                          }}
                          text='Request Switch for these shift'
                        />
                      )}
                    </div>
                  );
                })
              ) : (
                <div>
                  {formData.employee.id ? 'No shift found to switch' : ''}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
