import { MisconductType } from '@erp_core/erp-types/dist/types/modules/disciplinary/mis-conduct';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCurrentCompany } from '../../../../../hooks/admin/company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseCurrentUser } from '../../../../../hooks/admin/user-admin/use-current-user';
import { UseMisconductRuleBooks } from '../../../../../hooks/hrd/disciplinary/misconduct-rule-book/use-misconduct-rule-books';
import { UseMisconducts } from '../../../../../hooks/hrd/disciplinary/misconduct/use-miscounducts';
import { UseRuleRequest } from '../../../../../hooks/hrd/disciplinary/rule-request/use-rule-request';
import { UserRendererInterface } from '../../../../common/fragments/user';
import { MisconductHistory } from './misconductHistory';
import { MisconductRuleBook } from './misconductRuleBook';

export type MisconductActionFormType = {
  name: string;
  status: string;
  category: string;
  actionType: string;
  amount: string;
  warningDesc: string;
  misconductRuleId: string;
};

export const AcknowledgeFormComponent = () => {
  return function AcknowledgeForm({
    data,
    onSave,
    useMisconductRuleBooks,
    useMisconducts,
    employeeId,
    minorMisconductCnt,
    useRuleRequest,
    useCurrentUser,
    misconductId,
    useCurrentCompanyGroup,
    useCurrentCompany,
    userRendererService,
  }: {
    data?: MisconductType;
    onSave: (data: MisconductType) => Promise<void>;
    useMisconductRuleBooks: UseMisconductRuleBooks;
    useMisconducts: UseMisconducts;
    employeeId: string;
    minorMisconductCnt: Number;
    useRuleRequest: UseRuleRequest;
    useCurrentUser: UseCurrentUser;
    useCurrentCompanyGroup: UseCurrentCompanyGroup;
    useCurrentCompany: UseCurrentCompany;
    misconductId: string;
    userRendererService: UserRendererInterface;
  }) {
    const formProps: FormProps<Partial<MisconductActionFormType>> = {
      fieldsData: [
        {
          property: 'status',
          type: 'select',
          label: 'Status',
          options: [
            { value: 'select', text: 'select' },
            { value: 'approved', text: 'approved' },
            { value: 'cancelled', text: 'cancelled' },
          ],
        },
        {
          property: 'category',
          type: 'select',
          label: 'category',
          options: [
            { value: '', text: 'select' },
            { value: 'unknown', text: 'unknown' },
            { value: 'minor', text: 'minor' },
            { value: 'major', text: 'major' },
            { value: 'critical', text: 'critical' },
          ],

          dependentOn: (formData) => {
            return formData.status === 'approved';
          },
          validate: (form) => {
            if (Number(minorMisconductCnt) > 3 && form.category === 'minor') {
              return {
                category:
                  'This employee has comitted 3 minor misconucts in the past',
              };
            }
            return {} as any;
          },
        },
        {
          property: 'actionType',
          type: 'select',
          label: 'Action Type',
          options: [
            { value: '', text: 'select' },
            { value: 'penalty', text: 'penalty' },
            { value: 'warning', text: 'warning' },
          ],
          dependentOn: (formData) => {
            return formData.category === 'minor';
          },
        },
        {
          property: 'amount',
          type: 'input',
          label: 'Amount',
          dependentOn: (formData) => {
            return (
              formData.actionType === 'penalty' && formData.category === 'minor'
            );
          },
        },
        {
          property: 'misconductRuleId',
          type: 'input',
          label: 'Rule Id',
        },
        {
          property: 'warningDesc',
          type: 'input',
          label: 'Warning',
          dependentOn: (formData) => {
            return formData.actionType === 'warning';
          },
        },
      ],
      initialFormState: {
        category: data?.category || '',
        status: data?.status || '',
        actionType: data?.actionType || '',
        amount: data?.amount || '',
        warningDesc: data?.details?.warningDesc || '',
        misconductRuleId: data?.details?.misconductRuleId || '',
      },
      onSubmit: async (f) => {
        await onSave(({
          id: data?.id,
          name: f.name || data?.name,
          category: f.category || data?.category,
          status: f.status || data?.status,
          actionType: f.actionType || data?.actionType,
          amount: f.amount || data?.amount,
          details: {
            warningDesc: f.warningDesc || data?.details?.warningDesc,
            misconductRuleId:
              f.misconductRuleId || data?.details?.misconductRuleId,
            penaltyStatus: f.amount ? 'pending' : data?.details?.penaltyStatus,
          },
        } as unknown) as MisconductType);
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      button: {
        style: 'float-left',
      },
    };

    const Form = renderFormV2<Partial<MisconductActionFormType>>(formProps);

    return (
      <div className='grid grid-cols-2 divide-x'>
        <div>
          <Form />
        </div>
        <div className='grid grid-rows-2 divide-y'>
          <div className='h-96  overflow-y-scroll'>
            <MisconductHistory
              userRenderService={userRendererService}
              useMisconducts={useMisconducts}
              employeeId={employeeId}
            />
          </div>
          <div className='h-96  overflow-y-scroll'>
            <MisconductRuleBook
              useMisconductRuleBooks={useMisconductRuleBooks}
              useRuleRequest={useRuleRequest}
              useCurrentUser={useCurrentUser}
              useCurrentCompanyGroup={useCurrentCompanyGroup}
              useCurrentCompany={useCurrentCompany}
              misconductId={misconductId}
            />
          </div>
        </div>
      </div>
    );

    function mapPFDToP(
      b: FormDataType<Partial<MisconductActionFormType>>
    ): MisconductActionFormType {
      return (b as unknown) as MisconductActionFormType;
    }
  };
};
