import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { PurchaseOrder } from '@erp_core/erp-types/dist/modules/order';
import { generateName } from '@erp_core/erp-utils';
import moment from 'moment';

export const generatePoName = async ({
  getAllOrders,
  getSyncCompanySettings,
}: {
  getAllOrders: (e: any, f: any) => Promise<PurchaseOrder[]>;
  getSyncCompanySettings: (e?: any) => Promise<CompanyGroupSetting[]>;
}) => {
  const getMany = async (fil?: any) => {
    const data = await getAllOrders(fil, {
      enableLoading: false,
      enableResource: false,
    });
    return data;
  };
  const allCompanySettings = await getSyncCompanySettings();
  const companySettings = allCompanySettings[0];
  const name = await generateName({
    pattern:
      companySettings?.details.purchase?.purchaseOrder?.poNamePattern || '',
    getMany,
  });
  if (name.success && name.data) {
    return name.data;
  }

  return `PO-${moment().utc().format('YYYY-MM-DD')}`;
};
