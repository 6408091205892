import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { renderModal } from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseCurrentCompanyGroup } from '../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseCompanyGroupSetting } from '../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { UseCompanyGroupSettings } from '../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { UseLocations } from '../../../../hooks/admin/location-admin/use-locations';
import { UseLocationHoliday } from '../../../../hooks/planning/location-holiday/use-location-holiday';
import { UseLocationHolidays } from '../../../../hooks/planning/location-holiday/use-location-holidays';
import { UseShiftSchedule } from '../../../../hooks/planning/shift-schedule/use-shift-schedule';
import { UseShiftSchedules } from '../../../../hooks/planning/shift-schedule/use-shift-schedules';
import { renderLocationHolidaysList } from '../../../planning/location-holidays';
import { renderShiftScheduleV2List } from '../../../planning/shift-schedule';
import { CGConfigRenderer } from '../config-renderer';
import { renderSisterLocationForm } from './forms/sister-location-form';

type CreateInventoryTemplatesPage = {
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useCompanyGroupSettings: UseCompanyGroupSettings;
  useCompanyGroupSetting: UseCompanyGroupSetting;
  useLocations: UseLocations;
  useShiftSchedules: UseShiftSchedules;
  useShiftSchedule: UseShiftSchedule;
  useLocationHoliday: UseLocationHoliday;
  useLocationHolidays: UseLocationHolidays;
};

export const createCompanyGroupSettingPage = ({
  useCurrentCompanyGroup,
  useCompanyGroupSetting,
  useCompanyGroupSettings,
  useLocations,
  useShiftSchedule,
  useShiftSchedules,
  useLocationHoliday,
  useLocationHolidays,
}: CreateInventoryTemplatesPage) => {
  const Modal = renderModal();

  return function () {
    const [data, setData] = useState<CompanyGroupSetting | null>();
    const { getAllSync } = useCompanyGroupSettings();
    const { currentCompanyGroup } = useCurrentCompanyGroup();

    const getData = async () => {
      const cgs = await getAllSync();
      if (cgs.length > 0) setData(cgs[0]);
    };

    useEffect(() => {
      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [modalState, setModalState] = useState({
      title: 'MSDS Template',
      isVisible: false,
      onClose: () => closeModal(),
      body: (p?: any) => <div className='hidden' />,
    });

    const closeModal = () => {
      setModalState((ms) => ({ ...ms, isVisible: false }));
    };

    const handleSisterLocationSettings = () => {
      const Form = renderSisterLocationForm({
        closeModal: modalState.onClose,
        useCompanyGroupSetting,
        useLocations,
        refreshData: getData,
      });
      setModalState((ms) => ({
        ...ms,
        isVisible: true,
        title: 'Configure new sister location',
        body: () => (
          <Form
            companyGroupId={currentCompanyGroup.id}
            comGrpSetDetails={data?.details || {}}
          />
        ),
      }));

      return null;
    };

    type Configs = {
      settingName: string;
      settingValue?: string;
      editAction: () => void;
    };

    const configs: Configs[] = [
      {
        settingName: 'Sister Locations',
        settingValue:
          Object.keys(
            data?.details.companyGroup?.location?.sisterLocations || {}
          )
            .map((k) => k)
            .join(', ') || '',
        editAction: handleSisterLocationSettings,
      },
    ];

    const ShiftSchedules = renderShiftScheduleV2List({
      useShiftSchedules,
      useCompanyGroupSettings,
      useShiftSchedule,
    });

    const LocationHolidays = renderLocationHolidaysList({
      useCompanyGroupSettings,
      useLocationHolidays,
      useLocationHoliday,
    });

    return (
      <div className='w-full'>
        <CGConfigRenderer configs={configs} loading={false} />
        <ShiftSchedules />
        <LocationHolidays />
        <Modal
          title={modalState.title}
          isVisible={modalState.isVisible}
          body={<div />}
          onClose={modalState.onClose}
        >
          <modalState.body comGrpSetDetails={data} />
        </Modal>
      </div>
    );
  };
};
