import { AttendanceType, Punch } from '@erp_core/erp-types/dist/modules/hrd';

export async function updatePunch({
  d,
  updatedPunch,
  setAttendance,
  getData,
}: {
  d: AttendanceType;
  updatedPunch: Punch;
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  getData: () => Promise<void>;
}) {
  const punches: Punch[] =
    d.details?.punches.map((pp) => {
      if (pp.timeStamp === updatedPunch.timeStamp) return updatedPunch;
      return pp;
    }) || [];

  const att: AttendanceType = {
    id: d.id,
    details: {
      ...d.details,
      punches: punches,
    },
  } as AttendanceType;
  await setAttendance(att);
  getData();
}
