/* eslint-disable react-hooks/exhaustive-deps */
import { ApprovedBadge } from '@erp_core/erp-icons/icons/web/approved-badge';
import { Hourglass } from '@erp_core/erp-icons/icons/web/hourglass';
import { RejectedBadge } from '@erp_core/erp-icons/icons/web/rejected-badge';
import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderInlineInput,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { CloudIcon, CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseCurrentCompany } from '../../../../hooks/admin/company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseDeliveryTerms } from '../../../../hooks/constants/delivery-term/use-delivery-terms';
import { UsePaymentTerms } from '../../../../hooks/constants/payment-term/use-payment-terms';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../../../hooks/file-transfer/use-public-transfer';
import { UseGodowns } from '../../../../hooks/inventory/godown-inventory/use-godowns';
import { UseBatchItemGodowns } from '../../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { UseItem } from '../../../../hooks/inventory/item/use-item';
import { UseItems } from '../../../../hooks/inventory/item/use-items';
import { UseVendor } from '../../../../hooks/inventory/purchase/vendors/use-vendor';
import { UseVendorItem } from '../../../../hooks/inventory/purchase/vendors/use-vendor-item';
import { UseStockGroup } from '../../../../hooks/inventory/stockgroup/use-stockgroup';
import { ItemInterface } from '../../../../models/interfaces/inventory/item';
import { renderVendorContracts } from './contract';
import { renderHistoryTab } from './history-tab';
import { renderVendorPurchaseEnquiryList } from './purchase-enquiries';
import { renderVendorPurchaseOrderList } from './purchase-orders';
import { renderVendorGodown } from './vendor-godown';
import { renderVendorProfileTabs } from './vendor-profile-tab';

type RenderVendoeV2Profile = {
  useVendor: UseVendor;
  useGodowns: UseGodowns;
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useCurrentCompany: UseCurrentCompany;
  useItems: UseItems;
  useVendorItem: UseVendorItem;
  useUsers: UseUsers;
  useUserAuthorization: UseUserAuthorization;
  useItem: UseItem;
  useDeliveryTerms: UseDeliveryTerms;
  usePaymentTerms: UsePaymentTerms;
  useStockGroup: UseStockGroup;
  itemService: ItemInterface;
  useBatchItemGodowns: UseBatchItemGodowns;
};

export function renderVendorV2Profile({
  useVendor,
  useFileTransfer,
  usePublicTransfer,
  useCurrentCompanyGroup,
  useItems,
  useCurrentCompany,
  useVendorItem,
  useDeliveryTerms,
  usePaymentTerms,
  useUsers,
  useUserAuthorization,
  useItem,
  useStockGroup,
  itemService,
  useGodowns,
  useBatchItemGodowns,
}: RenderVendoeV2Profile): () => JSX.Element {
  const Card = renderCardComponent();

  return function VendorV2Profile(): JSX.Element {
    const { id } = useParams();
    const NewTabs = renderTabsV2Component();
    const { getAll: getGodowns, data: godowns } = useGodowns();

    const {
      data: vendor,
      get: getVendor,
      loading,
      syncSet: setVendor,
    } = useVendor();

    useEffect(() => {
      if (id) {
        getVendor(id);
      }
    }, [id]);

    useEffect(() => {
      if (vendor?.id && vendor.company?.id) {
        getGodowns({
          type: 'virtual',
          companyId: vendor.company?.id,
          search: `Vendor Godown of ${vendor.name}`,
        });
      }
    }, [vendor?.id]);

    // useEffect(() => {
    //   console.log(godowns);
    //   if (godowns?.length) {
    //   }
    // }, [godowns])

    if (!id) {
      return <div>Loading...</div>;
    }

    const InlineInput = renderInlineInput({ useResource: useVendor, id });

    const header: CardHeader = {
      menu: {
        actions: [
          {
            name: 'Approve',
            behaviour: 'confirm',
            show: () => {
              return vendor?.status !== 'approved';
            },
            onConfirm: () => ({
              title: 'Are you sure you want to approve this vendor?',
              type: 'warning',
              onConfirm: async () => {
                setVendor({
                  id: vendor.id,
                  status: 'approved',
                } as any);
              },
            }),
          },
        ],
      },
      title: (
        <div className='flex'>
          <InlineInput name={'name'} value={vendor.name || id} />
          {godowns?.length ? (
            <CloudIcon
              title='Virtual Godown Exists'
              className='w-5 h-5 inline text-indigo-900 mx-1'
            />
          ) : null}
        </div>
      ) as any,
      icon: vendor.details?.partyProfile?.logo ? (
        <img
          alt={vendor.name}
          src={vendor.details?.partyProfile?.logo}
          className='w-24 h-24 self-center'
        />
      ) : (
        <CubeIcon className='text-gray-800 w-24 h-full' />
      ),
      subheading: (
        <>
          <div className='text-gray-700 italic'>
            {vendor.description || 'Add a description for the item...'}
          </div>
          <div className='text-gray-700'>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${vendor.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {vendor.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${vendor.lastModifiedBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {vendor.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(vendor.lastModifiedAt).fromNow()}
          </div>
          <div className='flex space-x-2 my-2'>
            {vendor.status === 'approved' ? (
              <span className='border border-green-600 rounded-md px-2 py-1 text-sm text-green-600'>
                <ApprovedBadge className='inline w-5 text-green-600' />
                <span className='capitalize'>Approved</span>
              </span>
            ) : vendor.status === 'pending' ? (
              <span className='border border-orange-600 rounded-md px-2 py-1 text-sm text-orange-600'>
                <Hourglass className='inline w-5 text-red-600' />
                <span className='capitalize'>Pending</span>
              </span>
            ) : (
              <span className='border border-red-600 rounded-md px-2 py-1 text-sm text-red-600'>
                <RejectedBadge className='inline w-5 text-red-600' />
                <span className='capitalize'>Unapproved</span>
              </span>
            )}
            {vendor.details?.partyProfile?.vendorType === 'trader' ? (
              <span className='border border-blue-600 rounded-md px-2 py-1 text-sm text-blue-600'>
                <span className='inline w-5 text-blue-600' />
                <span className='capitalize'>Trader</span>
              </span>
            ) : (
              <span className='border border-yellow-600 rounded-md px-2 py-1 text-sm text-yellow-600'>
                <span className='inline w-5 text-yellow-600' />
                <span className='capitalize'>Manufacturer</span>
              </span>
            )}
          </div>
        </>
      ),
    };

    const Profile = renderVendorProfileTabs();

    const PurchaseOrders = renderVendorPurchaseOrderList({ useVendor, id });

    const VendorGodown = renderVendorGodown({ useBatchItemGodowns, id });

    const PurchaseEnquiries = renderVendorPurchaseEnquiryList({
      useVendor,
      id,
    });

    const VendorContracts = renderVendorContracts({
      useVendor,
      useDeliveryTerms,
      usePaymentTerms,
      useUserAuthorization,
      id,
    });

    const History = renderHistoryTab({ id, useVendor });

    const newCategories: DataArray = [
      {
        name: 'profile',
        Fn: () => (
          <Profile
            setVendor={setVendor}
            vendor={vendor}
            loading={loading}
            useVendor={useVendor}
            id={id}
            useFileTransfer={useFileTransfer}
            usePublicTransfer={usePublicTransfer}
            useCurrentCompanyGroup={useCurrentCompanyGroup}
            useItems={useItems}
            useVendorItem={useVendorItem}
            useUserAuthorization={useUserAuthorization}
            usePaymentTerms={usePaymentTerms}
            useItem={useItem}
            useStockGroup={useStockGroup}
            useDeliveryTerms={useDeliveryTerms}
            useCurrentCompany={useCurrentCompany}
            useUsers={useUsers}
            itemService={itemService}
          />
        ),
      },
      { name: 'overview', Fn: PurchaseOrders },
      { name: 'purchase-orders', Fn: PurchaseOrders },
      { name: 'godown', Fn: VendorGodown },
      { name: 'contracts', Fn: VendorContracts },
      { name: 'enquiry-and-offers', Fn: PurchaseEnquiries },
      { name: 'history', Fn: History },
    ];

    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={true} />,
    };

    return (
      <>
        <Card header={header} body={body} />
      </>
    );
  };
}
