/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import {
  PurchaseOrder,
  PurchaseOrderFulfilment,
} from '@erp_core/erp-types/dist/modules/order';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  ActionContextMenu,
  CardBody,
  CardHeader,
  Modal,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { generateName } from '@erp_core/erp-utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { UseCurrentUser } from '../../../../../../hooks/admin/user-admin/use-current-user';
import { UseUsers } from '../../../../../../hooks/admin/user-admin/use-users-admin';
import { UseBatchItemGodown } from '../../../../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UseItem } from '../../../../../../hooks/inventory/item/use-item';
import { UsePurchaseOrderFulfilment } from '../../../../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilment';
import { UsePurchaseOrderFulfilments } from '../../../../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilments';
import { UsePurchaseOrder } from '../../../../../../hooks/inventory/purchase/purchase-order/use-purchase-order';
import { UsePurchaseOrders } from '../../../../../../hooks/inventory/purchase/purchase-order/use-purchase-orders';
import { UseBoms } from '../../../../../../hooks/order/work-order/bom/use-boms';
import { renderCreatePurchaseOrderFulfilmentForm } from './forms/add-fulfilment';
import AllocateForm from './forms/allocate';
import { renderPoInspectionForm } from './forms/po-inspection';
import { AddFulfillment } from './types/add-fulfilment';

export const renderPurchaseOrderFulfilmentDetails = ({
  usePurchaseOrderFulfilment,
  usePurchaseOrder,
  usePurchaseOrders,
  useBoms,
  useBatchItemGodown,
  useUsers,
  useItem,
  usePurchaseOrderFulfilments,
  useCurrentUser,
}: {
  usePurchaseOrderFulfilment: UsePurchaseOrderFulfilment;
  usePurchaseOrderFulfilments: UsePurchaseOrderFulfilments;
  usePurchaseOrder: UsePurchaseOrder;
  usePurchaseOrders: UsePurchaseOrders;
  useBoms: UseBoms;
  useItem: UseItem;
  useBatchItemGodown: UseBatchItemGodown;
  useUsers: UseUsers;
  useCurrentUser: UseCurrentUser;
}) => {
  return function PurchaseOrderFulfilmentDetails({
    purchaseOrder,
    cgs,
  }: {
    purchaseOrder: PurchaseOrder;
    cgs: CompanyGroupSetting;
  }) {
    const { id } = useParams();
    const { syncSet: setFulfillments } = usePurchaseOrderFulfilment();
    const { syncSet: setPurchaseOrder } = usePurchaseOrder();
    const {
      data: fulfillments,
      getAll: getFulfillments,
    } = usePurchaseOrderFulfilments();
    const { getAllSync: getAllFulfilments } = usePurchaseOrderFulfilments();
    const { data: itemData, get: getItem } = useItem();
    const { data: currentUser } = useCurrentUser();
    useEffect(() => {
      getFulfillments({ purchaseOrderId: id });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
      if (fulfillments && fulfillments.length > 0) {
        const itemId =
          fulfillments[0]?.purchaseOrder?.details?.itemDetails?.id || '';
        getItem(itemId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fulfillments]);

    const [modalState, setModalState] = useState({
      visible: false,
      title: 'Add PO fulfilment',
      content: <>Add Transaction</>,
    });

    const [actionsState, setActionsState] = useState({
      visible: false,
      xPosition: 200,
      yPosition: 200,
      menu: ['Start Inspection', 'Allocate'],
    });

    const [activeDataId] = useState<string>('');

    const handleAddFulfilment = async (
      formData: AddFulfillment & { summary?: string; isApproved?: boolean }
    ) => {
      const { data: grnName } = await generateName({
        pattern: cgs.details.purchase?.grn?.grnNamePattern || '',
        getMany: getAllFulfilments,
      });
      const finalData = {
        purchaseOrder: { id: purchaseOrder.id },
        name: grnName || '',
        details: {
          quantity: formData.quantity,
          invoiceNo: formData.invoiceNo,
          arrivedAt: formData.arrivedAt,
          attachments: [],
          remarks: formData.remarks,
          isApproved: formData.isApproved || false,
          grnNumber: grnName,
          invoiceQuantity: formData.invoiceQuantity,
          allowMismatchedQuantity: formData.allowMismatchedQuantity,
          uom: formData.uom,
          reasonForMismatchQuantity: formData.reasonForMismatchQuantity || '',
          lorryReceiptNumber: formData.lorryReceiptNumber || '',
          AWBorBlNumber: formData.AWBorBlNumber || '',
          invoiceDate: formData.invoiceDate,
          eWayBillNumber: formData.eWayBillNumber,
          girirNo: formData.girirNumber,
          totalAmountPayable: formData.totalAmountPayable,
          sealNo: formData.sealNumber,
          transporter: { id: '', name: formData.transporter }, // TODO
          supplier: { id: '', name: formData.supplier },
        },
      };
      const poActivity = {
        action: 'fulfilment-added',
        details: '',
        user: currentUser,
        event: `${currentUser.name} added a fulfilment`,
        time: moment.utc().format(),
      };
      try {
        await setFulfillments(
          (finalData as unknown) as PurchaseOrderFulfilment
        );
        await setPurchaseOrder({
          id,
          details: {
            ...purchaseOrder?.details,
            summary: formData.summary,
            activity: [poActivity, ...(purchaseOrder?.details.activity || [])],
          },
        } as any);
        getFulfillments({ purchaseOrderId: id });
        setModalState((ms) => ({ ...ms, visible: false }));
        toast('Added successfully');
      } catch (error) {
        toast((error as any).message);
      }
    };

    const handleAllocationSave = () => {
      setModalState({ ...modalState, visible: false });
      toast('Allocated Successfully');
    };

    const handleActionItemClick = async (action: string) => {
      if (action === 'Allocate') {
        const activeFulfilment = fulfillments?.find(
          (f) => f.id === activeDataId
        );
        setModalState({
          ...modalState,
          visible: true,
          title: 'Allocate',
          content: (
            <AllocateForm
              fulfilmentData={activeFulfilment as PurchaseOrderFulfilment}
              usePurchaseOrders={usePurchaseOrders}
              usePurchaseOrderFulfilment={usePurchaseOrderFulfilment}
              useBoms={useBoms}
              useBatchItemGodown={useBatchItemGodown}
              usePurchaseOrder={usePurchaseOrder}
              onSave={handleAllocationSave}
              onCancel={(ms) => setModalState({ ...ms, visible: false })}
            />
          ),
        });
      }
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'GRN No', rowSpan: 2 },
        { name: 'Invoice No', rowSpan: 2 },
        { name: 'Arrived at', rowSpan: 2 },
        { name: 'Item', rowSpan: 2 },
        { name: 'Quantity/Invoice Quantity', rowSpan: 2 },
        { name: 'remarks', rowSpan: 2 },
        { name: 'Attachments', rowSpan: 2 },
        { name: 'Created By', rowSpan: 2 },
        { name: 'Status', rowSpan: 2 },
      ],
    ];

    const getStatus = (pof: PurchaseOrderFulfilment) => {
      if (pof.underInspection) return 'Under inspection';
      if (pof.isApproved && pof.underInspection === false) return 'Approved';
      if (pof.underInspection === false && pof.isApproved === false)
        return 'Open/Rejected';
      return 'Unknown';
    };

    const tableBody: TableBody =
      fulfillments?.map((item) => ({
        cells: [
          { value: item.name },
          { value: item.details.invoiceNo },
          { value: item.details.arrivedAt },
          { value: item?.purchaseOrder?.details?.itemDetails?.name },
          {
            value: `${item.details.quantity || '--'}/${
              item.details.invoiceQuantity || '--'
            }`,
          },
          { value: item.details.remarks },
          {
            value: (
              <div>
                {item.details?.attachments?.map((a, i) => (
                  <div key={i}>{a}</div>
                ))}
              </div>
            ),
          },
          { value: item?.createdBy?.name },
          { value: item ? getStatus(item) : '--' },
        ],
        rowData: {
          purchaseOrderFulfillment: item,
        },
      })) || [];
    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Purchase Orders Fulfilments (GRN)',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: `Add PO fulfilment (GRN) for ${purchaseOrder.grade?.name}`,
              content: ({ onClose }) => {
                const addFulfilmentFormData = {
                  total: Number(purchaseOrder.details.quantity),
                  previouslyAdded:
                    fulfillments?.reduce(
                      (a, b) => a + Number(b?.details?.quantity || 0),
                      0
                    ) || 0,
                };
                const Form = renderCreatePurchaseOrderFulfilmentForm();
                return (
                  <Form
                    data={addFulfilmentFormData}
                    purchaseOrder={purchaseOrder}
                    onSave={async (form) => {
                      handleAddFulfilment(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          body={tableBody}
          actions={[
            {
              name: 'Send For Inspection',
              show: ({ purchaseOrderFulfillment }) => {
                const status = getStatus(purchaseOrderFulfillment);
                if (status === 'Open/Rejected') {
                  return true;
                }

                return false;
              },
              behaviour: 'modal',
              modal: {
                title: 'Send for Inspection',
                content: ({ data: { purchaseOrderFulfillment }, onClose }) => {
                  const cont = async ({
                    inspectors,
                  }: {
                    inspectors: IdName[];
                  }) => {
                    const finalData = {
                      id: purchaseOrderFulfillment.id,
                      underInspection: true,
                      details: {
                        inspectors: inspectors.map((i) => i.id),
                        // TODO: Currently taking inspectors from UI should also include default inspectors from item master
                      },
                    };
                    await setFulfillments(
                      (finalData as unknown) as PurchaseOrderFulfilment
                    );
                    onClose();
                    toast(
                      <div
                        className='cursor-pointer'
                        onClick={() =>
                          (window.location.href =
                            '/purchase/deliveries/purchase-item-inspection')
                        }
                      >
                        <div>Item sent for inspection</div>
                        <div>Click to view</div>
                      </div>
                    );
                  };

                  const Form = renderPoInspectionForm({ useUsers });

                  return <Form onSave={cont} itemData={itemData} />;
                },
              },
            },
          ]}
        />
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
        <ActionContextMenu
          actionsState={actionsState}
          setActionsState={setActionsState}
          handleActionItemClick={handleActionItemClick}
        />
        <Modal
          showModal={modalState.visible}
          setShowModal={() =>
            setModalState({ ...modalState, visible: !modalState.visible })
          }
          title={modalState.title}
          onOk={() => {}}
          style={{ width: '800px' }}
        >
          {modalState.content}
        </Modal>
      </div>
    );
  };
};
