import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseCurrentCompany } from '../../../../hooks/admin/company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseCurrentUser } from '../../../../hooks/admin/user-admin/use-current-user';
import { UseChangeAssesment } from '../../../../hooks/quality-assurance/change-assesment/use-change-assesment';
import { UseChangeAssesments } from '../../../../hooks/quality-assurance/change-assesment/use-change-assesments';
import { ChangeAssesmentType } from '../../../../models/types/quality-assurance/change-assesment';
import { impactCategories } from '../../units/impact';

type RenderAcknowledgeChangeControl = {
  useChangeAssesment: UseChangeAssesment;
  useChangeAssesments: UseChangeAssesments;
  useCurrentUser: UseCurrentUser;
  useCurrentCompany: UseCurrentCompany;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
};

export type ImpactTicks = {
  [key: string]: {
    yesSelected: boolean;
    noSelected: boolean;
    unknownSelected: boolean;
    remark: string;
    naSelected: boolean;
  };
};

export const renderAcknowledgeChangeControl = ({
  useChangeAssesment,
  useChangeAssesments,
  useCurrentUser,
  useCurrentCompany,
  useCurrentCompanyGroup,
}: RenderAcknowledgeChangeControl) => {
  return function AcknowledgeChangeControl({
    id,
    onClose,
  }: {
    id: string;
    onClose: () => any;
  }) {
    const { syncSet: setChangeAssesment } = useChangeAssesment();

    const { data: currentUser } = useCurrentUser();

    const { data: company } = useCurrentCompany();

    const { currentCompanyGroup: companyGroup } = useCurrentCompanyGroup();

    const {
      data: changeAssesments,
      getAll: getChangeAssesments,
    } = useChangeAssesments();

    useEffect(() => {
      getChangeAssesments();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (impactCategories.length) {
        const pl: ImpactTicks = {};
        for (let item of impactCategories) {
          pl[item.name] = {
            yesSelected: false,
            noSelected: true,
            unknownSelected: false,
            remark: '',
            naSelected: false,
          };
        }
        setImpactTicks(pl);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [impactCategories]);

    const [impactTicks, setImpactTicks] = useState<ImpactTicks>({});

    const Table = renderTableComponent();

    function handleChange(item: string, propertyName: string, value: string) {
      impactTicks[item][propertyName] = value;
      setImpactTicks({
        ...impactTicks,
      });
    }

    async function submitAck() {
      try {
        const itemData = changeAssesments?.find((x) => x.id === id);
        if (itemData) {
          await setChangeAssesment(({
            id: itemData.id,
            details: {
              ...itemData?.details,
              acknowledge: impactTicks,
            },
            status: 'evaluation',
            companyGroup: {
              id: companyGroup.id,
              name: companyGroup.name,
            },
            company: {
              id: company.id,
              name: company.name,
            },
            createdBy: {
              id: currentUser.id,
              name: currentUser.name,
            },
            lastModifiedBy: {
              id: currentUser.id,
              name: currentUser.name,
            },
          } as unknown) as ChangeAssesmentType);

          toast('Data added sucessfully');
          onClose();
          getChangeAssesments();
        }
      } catch (error) {
        toast('Something went wrong');
      }
    }

    const tableHeader: TableHeader = [
      [
        { name: 'Impact', rowSpan: 2 },
        {
          name: 'Tick wherever required',
          colSpan: 4,
          style: 'text-center',
        },
        { name: 'Remark', rowSpan: 2 },
      ],

      [
        // Tick wherever
        {
          name: 'Yes',
          style: 'border border-l-gray-150',
        },
        { name: 'No' },
        { name: 'Unknown' },
        { name: 'NA' },
      ],
    ];

    const tableData =
      impactCategories?.map((item) => ({
        cells: [
          { value: item?.name },

          {
            value: (
              <input
                type='checkbox'
                checked={impactTicks[item?.name]?.yesSelected}
                onChange={(e) => {
                  e.preventDefault();
                  impactTicks[item?.name]['yesSelected'] = e.target.checked;
                  setImpactTicks({
                    ...impactTicks,
                  });
                }}
                id='checked'
                name='checked'
              />
            ),
          },
          {
            value: (
              <input
                type='checkbox'
                checked={impactTicks[item?.name]?.noSelected}
                onChange={(e) => {
                  e.preventDefault();
                  impactTicks[item?.name]['noSelected'] = e.target.checked;
                  setImpactTicks({
                    ...impactTicks,
                  });
                }}
                id='checked'
                name='checked'
              />
            ),
          },
          {
            value: (
              <input
                type='checkbox'
                checked={impactTicks[item?.name]?.unknownSelected}
                onChange={(e) => {
                  e.preventDefault();
                  impactTicks[item?.name]['unknownSelected'] = e.target.checked;
                  setImpactTicks({
                    ...impactTicks,
                  });
                }}
                id='checked'
                name='checked'
              />
            ),
          },
          {
            value: (
              <input
                type='checkbox'
                checked={impactTicks[item?.name]?.naSelected}
                onChange={(e) => {
                  e.preventDefault();
                  impactTicks[item?.name]['naSelected'] = e.target.checked;
                  setImpactTicks({
                    ...impactTicks,
                  });
                }}
                id='checked'
                name='checked'
              />
            ),
          },
          {
            value: (
              <input
                type='text'
                value={impactTicks[item?.name]?.remark || ''}
                className='border border-black text-left h-50 w-50'
                onChange={(e) => {
                  handleChange(item?.name, 'remark', e.target.value);
                }}
              ></input>
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const header: CardHeader = {
      title:
        'Evaluation of proposed change (to be filled by the department seeking change)',
      actions: [
        {
          type: 'button',
          button: {
            behaviour: 'click',
            name: 'Submit',
            onClick: async () => submitAck(),
          },
        },
      ],
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <Table header={tableHeader} body={tableBody} />
        </>
      ),
    };

    return (
      <>
        <Card header={header} body={body} />
      </>
    );
  };
};
