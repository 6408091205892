// import { BOM } from '@erp_core/erp-icons/icons/web/bom';
import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { useMemo } from 'react';
import { UseCurrentCompany } from '../../../hooks/admin/company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseCurrentLocation } from '../../../hooks/admin/location-admin/use-current-location';
import { UseCurrentUser } from '../../../hooks/admin/user-admin/use-current-user';
import { UseUsers } from '../../../hooks/admin/user-admin/use-users-admin';
import { UseGodowns } from '../../../hooks/inventory/godown-inventory/use-godowns';
import { UseGodownTransfer } from '../../../hooks/inventory/godown-transfer/use-godown-transfer';
import { UseGrades } from '../../../hooks/inventory/grade/use-grades';
import { UseBatchItemGodown } from '../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UseBatchItemGodowns } from '../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { UsePhysicalStock } from '../../../hooks/inventory/item/stock/use-physical-stock';
import { UseVirtualStock } from '../../../hooks/inventory/item/stock/use-virtual-stock';
import { UseMRS } from '../../../hooks/inventory/mrs/use-mrs';
import { UsePurchaseRequisition } from '../../../hooks/inventory/purchase/purchase-requisition/use-purchase-requisition';
import { UseBomProject } from '../../../hooks/order/work-order/bom-project/use-bom-project';
import { UseBomProjects } from '../../../hooks/order/work-order/bom-project/use-bom-projects';
import { UsePaginatedBomProjects } from '../../../hooks/order/work-order/bom-project/use-paginated-bom-projects';
import { UseBom } from '../../../hooks/order/work-order/bom/use-bom';
import { UseBoms } from '../../../hooks/order/work-order/bom/use-boms';
import { UsePaginatedBoms } from '../../../hooks/order/work-order/bom/use-paginated-boms';
import { BomFlowInterface } from '../../../models/interfaces/inventory/bom-flow';
import { GradeInterface } from '../../../models/interfaces/inventory/grade';
import { ItemInterface } from '../../../models/interfaces/inventory/item';
import { PurchaseRequisitionInterface } from '../../../models/interfaces/order/purchase/purchase-requisition';
import { renderBOMFormV2 } from './bom-form-v2';
import { renderBOMProjectRegister } from './bom-project-register';
import { renderCommonBomTable } from './bom-register/fragments/table/table';
import { renderMRSRegister } from './mrs-register';

type RenderRequisitionListProps = {
  hooks: {
    useGrades: UseGrades;
    useGodowns: UseGodowns;
    useUsers: UseUsers;
    useBoms: UseBoms;
    useBom: UseBom;
    usePhysicalStock: UsePhysicalStock;
    useVirtualStock: UseVirtualStock;
    usePurchaseRequisition: UsePurchaseRequisition;
    useBatchItemGodowns: UseBatchItemGodowns;
    useMRS: UseMRS;
    useBatchItemGodown: UseBatchItemGodown;
    useGodownTransfer: UseGodownTransfer;
    useCurrentUser: UseCurrentUser;
    usePaginatedBoms: UsePaginatedBoms;
    useBomProject: UseBomProject;
    useBomProjects: UseBomProjects;
    usePaginatedBomProjects: UsePaginatedBomProjects;
    useCurrentCompanyGroup: UseCurrentCompanyGroup;
    useCurrentCompany: UseCurrentCompany;
    useCurrentLocation: UseCurrentLocation;
  };
  services: {
    purchaseRequisitionService: PurchaseRequisitionInterface;
    itemV2Service: ItemInterface;
    bomFlowService: BomFlowInterface;
    gradeService: GradeInterface;
  };
};

export function renderRequisitionList({
  hooks: {
    useGrades,
    useGodowns,
    useUsers,
    useBoms,
    useBatchItemGodowns,
    useBom,
    usePhysicalStock,
    useVirtualStock,
    usePurchaseRequisition,
    useMRS,
    useBatchItemGodown,
    useGodownTransfer,
    useCurrentUser,
    usePaginatedBoms,
    useBomProjects,
    usePaginatedBomProjects,
    useBomProject,
    useCurrentCompanyGroup,
    useCurrentCompany,
    useCurrentLocation,
  },
  services: { itemV2Service, gradeService, bomFlowService },
}: RenderRequisitionListProps): () => JSX.Element {
  const NewTabs = renderTabsV2Component();

  const NewBomRegister = renderCommonBomTable({
    type: 'approved',
    useBatchItemGodown,
    itemService: itemV2Service,
    useBatchItemGodowns,
    useBom,
    useBomProjects,
    useBoms,
    useGrades,
    usePaginatedBoms,
    usePurchaseRequisition,
    useUsers,
    useCurrentUser,
    useGodowns,
  });

  const NewBomDraftRegister = renderCommonBomTable({
    type: 'draft',
    usePaginatedBoms,
    useGrades,
    useBomProjects,
    useBatchItemGodowns,
    useBoms,
    useBatchItemGodown,
    useBom,
    useUsers,
    useGodowns,
    itemService: itemV2Service,
    usePurchaseRequisition,
    useCurrentUser,
  });

  // const BOMDraft = renderBOMDraft({
  //   hooks: {
  //     useBatchItemGodown,
  //     useBatchItemGodowns,
  //     usePurchaseRequisition,
  //     useGrades,
  //     useBomProjects,
  //     useGodowns,
  //     useBoms,
  //     useBom,
  //     usePhysicalStock,
  //     useVirtualStock,
  //     useCurrentUser,
  //     usePaginatedBoms,
  //     useUsers,
  //   },
  //   service: {
  //     itemService: itemV2Service
  //   },
  // });

  const MRSRegister = renderMRSRegister({
    useMRS,
    useBatchItemGodowns,
    useBatchItemGodown,
    useGodownTransfer,
    useBom,
  });

  const BOMProjectRegister = renderBOMProjectRegister({
    useBomProjects,
    usePaginatedBomProjects,
    useBomProject,
    useCurrentUser,
    useCurrentCompanyGroup,
    useCurrentCompany,
    useCurrentLocation,
    useUsers,
  });

  const categories: DataArray = [
    { name: 'bom-project-register', Fn: BOMProjectRegister },
    { name: 'bom-register', Fn: NewBomRegister },
    { name: 'bom-draft', Fn: NewBomDraftRegister },
    { name: 'mrs-register', Fn: MRSRegister },
  ];

  return function RequisitionList(): JSX.Element {
    const { data: currentUser } = useCurrentUser();
    const Card = renderCardComponent();
    const header: CardHeader = useMemo(() => {
      return {
        title: 'Material Requisitions',
        actions: [
          {
            type: 'button',
            button: {
              name: 'Add BOM',
              behaviour: 'modal',
              modal: {
                title: 'Add Bom',
                content: ({ onClose }) => {
                  const Form = renderBOMFormV2({
                    useGrades,
                    useUsers,
                    useGodowns,
                    useBomProjects,
                    bomFlowService,
                    useBom,
                    currentUser,
                    onClose,
                    gradeService,
                  });
                  return <Form />;
                },
              },
            },
          },
        ],
      };
      // eslint-disable-next-line
    }, []);

    const body: CardBody = useMemo(() => {
      return {
        type: 'jsx-component',
        body: <NewTabs data={categories} manipulateLocation={true} />,
      };
      // eslint-disable-next-line
    }, []);

    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
