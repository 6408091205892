import { HighLight } from '@erp_core/erp-icons/icons/web/highlight';
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import { SalaryRevisionType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import {
  CardActions,
  renderCardComponent,
  renderSearchBox,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { renderAuthWrapper } from '../../../components/auth';
import { UseCompanyGroupSettings } from '../../../hooks/admin/company-group-setting/use-company-group-settings';
import { UseEmployeeCategories } from '../../../hooks/admin/constants/employee-category/use-employee-categories';
import { UseCurrentUserRoles } from '../../../hooks/admin/role-admin/use-current-user-roles';
import { UseCurrentUser } from '../../../hooks/admin/user-admin/use-current-user';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseMisconducts } from '../../../hooks/hrd/disciplinary/misconduct/use-miscounducts';
import { UseEmployeeProfiles } from '../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UsePremiums } from '../../../hooks/hrd/insurance/premiums/use-premiums';
import { UseSalaryAdvances } from '../../../hooks/hrd/salary-advance/use-salary-advances';
import { UseSalaryParams } from '../../../hooks/hrd/salary-parameter/use-salary-params';
import { UseSalaryRevision } from '../../../hooks/hrd/salary-revision/use-salary-revision';
import { UseSalaryRevisions } from '../../../hooks/hrd/salary-revision/use-salary-revisions';
import { UseSalaries } from '../../../hooks/hrd/salary/use-salaries';
import { UseLoans } from '../../../hooks/loans/loan/use-loans';
import { UseRepaymentSchedules } from '../../../hooks/loans/repayment-schedule/use-repayment-schedules';
import { SalaryRevisionInterface } from '../../../models/interfaces/hrd/salary-revision';
import { UserRendererInterface } from '../../common/fragments/user';
import { createBulkRevisions } from './bulk-revisions';
import { createCompareRevision } from './comparison-view';
import { createSalaryRevision } from './create-salary-revision';
import { calculateIsExecutive } from './util/executive-grade';

export type CreateSalaryRevisionsPageProps = {
  useEmployees: UseEmployeeProfiles;
  useSalaries: UseSalaries;
  useUserAuthorization: UseUserAuthorization;
  useSalaryRevisions: UseSalaryRevisions;
  useSalaryRevision: UseSalaryRevision;
  useCurrentUser: UseCurrentUser;
  useCompanyGroupSettings: UseCompanyGroupSettings;
  useSalaryParams: UseSalaryParams;
  useMisconducts: UseMisconducts;
  useSalaryAdvances: UseSalaryAdvances;
  usePremiums: UsePremiums;
  useLoans: UseLoans;
  useRepaymentSchedules: UseRepaymentSchedules;
  useCurrentUserRoles: UseCurrentUserRoles;
  userRendererService: UserRendererInterface;
  salaryRevisionService: SalaryRevisionInterface;
  useEmployeeCategories: UseEmployeeCategories;
};

export function createSalaryRevisionPage({
  useCurrentUser,
  useEmployees,
  useSalaryRevision,
  useSalaryRevisions,
  useSalaryParams,
  useCompanyGroupSettings,
  useMisconducts,
  useSalaryAdvances,
  usePremiums,
  useLoans,
  useRepaymentSchedules,
  useCurrentUserRoles,
  useUserAuthorization,
  userRendererService,
  salaryRevisionService,
  useEmployeeCategories,
}: CreateSalaryRevisionsPageProps): () => JSX.Element {
  let Table = renderTableComponent();
  let Card = renderCardComponent();

  return function SalaryPage(): JSX.Element {
    const { data: currentUserRoles } = useCurrentUserRoles();
    const [cgSetting, setCgSetting] = useState<CompanyGroupSetting | null>();

    const { getAllSync } = useCompanyGroupSettings();

    const getData = async () => {
      const cgs = await getAllSync();
      if (cgs.length > 0) setCgSetting(cgs[0]);
    };

    const isSystemAdmin = currentUserRoles?.find(
      (r) => r.name === 'System-Admin'
    )
      ? true
      : false;
    const {
      getAll: getAllSalaryRevisions,
      data: salaryRevisions,
    } = useSalaryRevisions();
    const {
      getAll: getAllSalaryParams,
      data: salaryParams,
    } = useSalaryParams();
    const { data: currentUser } = useCurrentUser();
    const { data: misconducts, getAll: getMisconducts } = useMisconducts();
    const {
      data: salaryAdvances,
      getAll: getSalaryAdvances,
    } = useSalaryAdvances();
    const { syncSet: saveSalaryRevision } = useSalaryRevision();
    const { data: premiums, getAll: getPremiums } = usePremiums();
    const [selectedParams, setSelectedParams] = useState<SalaryParamType[]>([]);
    const { getAllSync: getLoans } = useLoans();
    const { getAllSync: getRepaymentSchedules } = useRepaymentSchedules();

    const [selectedEmployee, setSelectedEmployee] = useState<
      EmployeeProfileType
    >({} as EmployeeProfileType);

    const SearchBox = renderSearchBox<EmployeeProfileType>({
      useSearchValues: useEmployees,
      onSearchValueSelect: (u) => {
        setSelectedEmployee(u);
      },
    });

    useEffect(() => {
      getAllSalaryParams();
      getMisconducts();
      getSalaryAdvances();
      getPremiums();
      getData();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (salaryParams?.length) {
        const result: SalaryParamType[] = [];

        salaryParams.forEach((s) => {
          const existing = result.find((x) => x.name === s.name);
          if (existing) {
            if (existing.revisionNum < s.revisionNum) {
              const index = result.findIndex((x) => x.id === existing.id);
              result.splice(index, 1);
              result.push(s);
            }
          } else {
            result.push(s);
          }
        });
        setSelectedParams(result);
        // const maxPublished = _.maxBy(salaryParams, (
        //   param: SalaryParamType) => { return param.revisionNum });

        // if (maxPublished && maxPublished?.id !== selectedParam.id) {
        //   setSelectedParams(maxPublished);
        // }
      }
      // eslint-disable-next-line
    }, [salaryParams]);

    useEffect(() => {
      if (selectedEmployee.id) {
        getAllSalaryRevisions({
          employeeId: `equal::${selectedEmployee.id}`,
        });
      }
      // eslint-disable-next-line
    }, [selectedEmployee]);

    const AuthWrapper = renderAuthWrapper({ useUserAuthorization });

    const tableHeader = [
      [
        { name: 'Revision #' },
        { name: 'Details' },
        { name: 'Effective Date' },
        { name: 'Approved By' },
      ],
    ];

    const incrementableSalRev = salaryRevisions
      ?.filter((x) => x.status === 'published')
      ?.sort((a, b) => parseInt(a.revisionNum) - parseInt(b.revisionNum))
      ?.pop();

    const tableBody: TableBody =
      _.sortBy(salaryRevisions || [], 'revisionNum')
        .reverse()
        .map((e) => {
          return {
            rowData: {
              salaryRevision: e,
              active: incrementableSalRev === e,
            },
            cells: [
              {
                value: (
                  <div>
                    {e.revisionNum} {e.status === 'published' ? '✅' : null}
                    {incrementableSalRev === e ? (
                      <span className='px-1 mx-1 border rounded-lg bg-silver-100'>
                        active
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                ),
              },
              {
                value: (
                  <div>
                    {(e as any).details.encrypted ? (
                      <>
                        <LockClosedIcon className='inline text-red-700 w-4' />{' '}
                        You are not authorized!
                      </>
                    ) : (
                      <>
                        {calculateIsExecutive(
                          selectedEmployee.details.grade.id
                        ) ? (
                          <AuthWrapper
                            showUnAuthorizedWarning={true}
                            action='UI:EXEC-SAL-REP:VIEW'
                            children={
                              <>
                                <div>CTC: {e.details.ctcAnnual} per year</div>
                                <div>Skill Level: {e.details.skillLevel}</div>
                                <div>
                                  Retention Applicable:{' '}
                                  {e.details.isRetentionApplicable}
                                </div>
                                {e.details.isRetentionApplicable === 'Yes' ? (
                                  <>
                                    <div>
                                      Retention By: {e.details.retentionBy}
                                    </div>
                                    <div>
                                      Retention Of: {e.details.retentionOf}
                                    </div>
                                  </>
                                ) : null}
                                <div>
                                  Advance Bonus Payout:{' '}
                                  {e.details.advanceBonusPayout}
                                </div>
                                <div>Income Tax: {e.details.incomeTax}</div>
                                <div>Notes: {e.details.notes}</div>
                              </>
                            }
                          />
                        ) : (
                          <>
                            <div>CTC: {e.details.ctcAnnual} per year</div>
                            <div>Skill Level: {e.details.skillLevel}</div>
                            <div>
                              Retention Applicable:{' '}
                              {e.details.isRetentionApplicable}
                            </div>
                            {e.details.isRetentionApplicable === 'Yes' ? (
                              <>
                                <div>Retention By: {e.details.retentionBy}</div>
                                <div>Retention Of: {e.details.retentionOf}</div>
                              </>
                            ) : null}
                            <div>
                              Advance Bonus Payout:{' '}
                              {e.details.advanceBonusPayout}
                            </div>
                            <div>Income Tax: {e.details.incomeTax}</div>
                            <div>Notes: {e.details.notes}</div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                ),
              },
              { value: moment(e.date, 'YYYY-MM-DD').format('LL') },
              {
                value: (
                  <div>
                    {e.approved ? (
                      <>
                        <div className='inline'>
                          <userRendererService.userCard
                            link={true}
                            size='small'
                            id={e.approvedBy?.id}
                            name={e.approvedBy?.name}
                            extraInfo={'✔️ approved'}
                          />
                        </div>
                      </>
                    ) : (
                      <>⏳ Pending</>
                    )}
                  </div>
                ),
              },
            ],
          };
        }) || [];

    const actions: Array<CardActions> = [
      {
        type: 'jsx',
        jsx: (
          <div className='flex w-96 p-1 space-x-2 items-center font-bolder'>
            <span className='font-bold truncate'>Select</span>
            <SearchBox />
          </div>
        ),
      },
    ];

    if (selectedEmployee.id && tableBody.length === 0) {
      actions.push({
        type: 'button',
        button: {
          name: 'Add Revision',
          behaviour: 'modal',
          modal: {
            title: 'Add Salary Revision',
            size: 'large',
            content: ({ onClose }) => {
              const AddSalaryRevision = createSalaryRevision({
                useEmployeeCategories,
                useSalaryParams,
                misconducts: misconducts || [],
                salaryAdvances: salaryAdvances || [],
                premiums: premiums || [],
                selectedParams,
                useCompanyGroupSettings,
                employee: selectedEmployee,
                useSalaryRevision: useSalaryRevision,
                useCurrentUser,
                onClose: onClose,
              });
              return (
                <AddSalaryRevision
                  salaryRevisionService={salaryRevisionService}
                  salaryRevisions={salaryRevisions || []}
                  getLoans={getLoans}
                  getRepaymentSchedules={getRepaymentSchedules}
                />
              );
            },
          },
        },
      });
    }

    if (
      isSystemAdmin ||
      currentUserRoles?.find((cr) => cr.name === 'Executives Salary Officer')
    ) {
      actions.push({
        type: 'button',
        button: {
          name: 'Bulk Revisions',
          behaviour: 'modal',
          modal: {
            title: 'Compute Revisions in Bulk',
            size: 'large',
            content: ({ onClose }) => {
              if (cgSetting) {
                const BulkRevisions = createBulkRevisions({
                  salaryRevisionService,
                  companyGroupConfig: cgSetting,
                  useEmployees,
                  useSalaryRevisions,
                  salaryParams: salaryParams || [],
                  useCurrentUser,
                  useSalaryRevision,
                  onClose,
                  userRendererService,
                });
                return <BulkRevisions />;
              }

              return <div></div>;
            },
          },
        },
      });
    }

    return (
      <div className='pb-52'>
        <Card
          header={{
            title: `Salary Revisions: ${
              selectedEmployee.id ? selectedEmployee.name : 'None Selected'
            }`,
            icon: (
              <HighLight className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
            ),
            subheading: (
              <div className='flex items-center'>
                {selectedEmployee.id ? (
                  <>
                    Next Salary Revision on{' '}
                    {(selectedEmployee.id &&
                      selectedEmployee.details?.nextSalaryRevision) ||
                      'not-set'}
                  </>
                ) : null}
              </div>
            ),
            actions: actions,
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                {tableBody.length ? (
                  <Table
                    header={tableHeader}
                    body={tableBody}
                    actions={[
                      {
                        name: 'Approve Revision',
                        show: ({
                          salaryRevision,
                        }: {
                          salaryRevision: SalaryRevisionType;
                        }) => {
                          if (
                            !salaryRevision.approved &&
                            !salaryRevision.details.encrypted
                          ) {
                            return true;
                          }
                          return false;
                        },
                        behaviour: 'confirm',
                        onConfirm: ({ salaryRevision }) => {
                          return {
                            title: `Are you Sure you want to approve Salary Revision ${salaryRevision.revisionNum} for ${selectedEmployee.name}`,
                            onConfirm: async () => {
                              const dataToSend = {
                                id: salaryRevision.id,
                                approved: true,
                                approvedBy: currentUser,
                              } as SalaryRevisionType;
                              await saveSalaryRevision(dataToSend);
                              getAllSalaryRevisions({
                                employeeId: `equal::${selectedEmployee.id}`,
                              });
                            },
                          };
                        },
                      },
                      {
                        name: 'Edit Revision',
                        show: ({
                          salaryRevision,
                          active,
                        }: {
                          salaryRevision: SalaryRevisionType;
                          active: boolean;
                        }) => {
                          if (
                            !salaryRevision.approved &&
                            !salaryRevision.details.encrypted
                          ) {
                            return true;
                          }

                          if (isSystemAdmin && active) {
                            return true;
                          }
                          return false;
                        },
                        behaviour: 'modal',
                        modal: {
                          title: 'Edit Revision',
                          size: 'large',
                          content: ({ data: { salaryRevision }, onClose }) => {
                            const AddSalaryRevision = createSalaryRevision({
                              useEmployeeCategories,
                              useSalaryParams,
                              misconducts: misconducts || [],
                              salaryAdvances: salaryAdvances || [],
                              premiums: premiums || [],
                              selectedParams,
                              useCompanyGroupSettings,
                              employee: selectedEmployee,
                              useSalaryRevision: useSalaryRevision,
                              useCurrentUser,
                              onClose: onClose,
                            });

                            return (
                              <AddSalaryRevision
                                salaryRevisionService={salaryRevisionService}
                                id={salaryRevision.id}
                                salaryRevisions={salaryRevisions || []}
                                getLoans={getLoans}
                                getRepaymentSchedules={getRepaymentSchedules}
                              />
                            );
                          },
                        },
                      },
                      {
                        name: 'Compare Salary Revisions',
                        show: ({
                          salaryRevision,
                          active,
                        }: {
                          salaryRevision: SalaryRevisionType;
                          active: boolean;
                        }) => {
                          if (
                            calculateIsExecutive(
                              selectedEmployee.details?.grade?.id
                            )
                          ) {
                            if (
                              currentUserRoles?.find(
                                (x) => x.name === 'Executives Salary Officer'
                              )
                            ) {
                              return true;
                            }

                            return false;
                          }

                          if (active) {
                            return true;
                          }
                          return false;
                        },
                        behaviour: 'modal',
                        modal: {
                          title: 'Compare Salary Revisions',
                          size: 'large',
                          content: ({ data: { salaryRevision }, onClose }) => {
                            const CompareRevision = createCompareRevision({
                              activeSalaryRevision: salaryRevision,
                              selectedParams: selectedParams || [],
                              salaryRevisions: salaryRevisions || [],
                              employee: selectedEmployee,
                              onClose: onClose,
                            });

                            return <CompareRevision />;
                          },
                        },
                      },
                      {
                        name: 'Add Revision',
                        show: ({
                          salaryRevision,
                        }: {
                          salaryRevision: SalaryRevisionType;
                        }) => {
                          if (
                            calculateIsExecutive(
                              selectedEmployee.details?.grade?.id
                            )
                          ) {
                            if (
                              currentUserRoles?.find(
                                (x) => x.name === 'Executives Salary Officer'
                              )
                            ) {
                              return true;
                            }

                            return false;
                          }

                          if (
                            salaryRevision === incrementableSalRev &&
                            !salaryRevision.details.encrypted
                          ) {
                            return true;
                          }
                          return false;
                        },
                        behaviour: 'modal',
                        modal: {
                          title: 'Add Revision',
                          size: 'large',
                          content: ({ data: { salaryRevision }, onClose }) => {
                            const parentId = salaryRevision.id;
                            const parentRevision = parseInt(
                              salaryRevision.revisionNum
                            );

                            const AddSalaryRevision = createSalaryRevision({
                              useEmployeeCategories,
                              useSalaryParams,
                              misconducts: misconducts || [],
                              salaryAdvances: salaryAdvances || [],
                              premiums: premiums || [],
                              selectedParams,
                              useCompanyGroupSettings,
                              employee: selectedEmployee,
                              useSalaryRevision: useSalaryRevision,
                              useCurrentUser,
                              onClose: onClose,
                            });

                            return (
                              <AddSalaryRevision
                                salaryRevisionService={salaryRevisionService}
                                parentId={parentId}
                                parentRevision={parentRevision}
                                salaryRevisions={salaryRevisions || []}
                                getLoans={getLoans}
                                getRepaymentSchedules={getRepaymentSchedules}
                              />
                            );
                          },
                        },
                      },
                      {
                        name: 'Publish Revision',
                        show: ({
                          salaryRevision,
                        }: {
                          salaryRevision: SalaryRevisionType;
                        }) => {
                          if (
                            salaryRevision.approved &&
                            !salaryRevision.details.encrypted &&
                            salaryRevision.status !== 'published'
                          ) {
                            return true;
                          }
                          return false;
                        },
                        behaviour: 'confirm',
                        onConfirm: ({ salaryRevision }) => {
                          return {
                            title: `Are you Sure you want to publish Salary Revision{' '}
                                ${salaryRevision.revisionNum} for{' '}
                                ${selectedEmployee.name}`,
                            message:
                              'You wont be allowed to edit this version after publishing!',
                            onConfirm: async () => {
                              const dataToSend = {
                                id: salaryRevision.id,
                                status: 'published',
                              } as SalaryRevisionType;
                              await saveSalaryRevision(dataToSend);
                              getAllSalaryRevisions({
                                employeeId: `equal::${selectedEmployee.id}`,
                              });
                            },
                          };
                        },
                      },
                    ]}
                  />
                ) : (
                  <div className='mx-auto p-5 text-center text-xl'>
                    {selectedEmployee.id ? (
                      <>
                        <div>
                          Seems like {selectedEmployee.name} does not have any
                          Salary Revisions set!
                        </div>
                      </>
                    ) : (
                      <>Select an Employee to see Salary revisions</>
                    )}
                  </div>
                )}
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
