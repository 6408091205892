import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  LoadingButton,
  ModalV2Props,
  renderCardComponent,
  renderModal,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { LeaveInterface } from 'models/interfaces/hrd/leave';
import { useEffect, useState } from 'react';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseLeaves } from '../../../../hooks/hrd/leave/use-leaves';
import { getCount } from '../util/generic-leave-card';
import { renderPersistRefillLeaves } from './persist-refill-leaves';

type RenderRefillLeavesBook = {
  useEmployeeProfiles: UseEmployeeProfiles;
  useLeaves: UseLeaves;
  leaveService: LeaveInterface;
};

export type ProjectedLeaves = {
  [key: string]: {
    name: string;
    privilege: number;
    sick: number;
    casual: number;
    compoff: number;
    selected: boolean;
  };
};

export const renderRefillLeavesBook = ({
  useEmployeeProfiles,
  useLeaves,
  leaveService,
}: RenderRefillLeavesBook) => {
  return function EmployeeLeavesBook() {
    const Table = renderTableComponent();
    const Modal = renderModal();

    const { data: employees, getAll: getAllEmployees } = useEmployeeProfiles();
    const [sortedEmp, setSortedEmp] = useState<Array<EmployeeProfileType>>([]);
    const [ignoredEmployees, setIgnoredEmployees] = useState<
      Array<EmployeeProfileType>
    >([]);
    const { data: leaves, getAll: getLeaves } = useLeaves();
    const [projectedLeaves, setProjectedLeaves] = useState<ProjectedLeaves>({});

    const closePersistModal = () => {
      setModal({
        isVisible: false,
        title: 'Refill Leave Bank',
        onClose: closePersistModal,
        body: <div />,
      });
    };

    const [modal, setModal] = useState<ModalV2Props>({
      isVisible: false,
      title: '',
      onClose: closePersistModal,
      body: <div />,
    });

    function handleChange(empId: string, propertyName: string, value: number) {
      projectedLeaves[empId][propertyName] = value;
      setProjectedLeaves({
        ...projectedLeaves,
      });
      // console.log('projectedLeaves',projectedLeaves)
    }

    useEffect(() => {
      getAllEmployees({ crossGroup: 'true' });
      getLeaves({ crossGroup: 'true' });
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (employees?.length) {
        const filteredEmployees = employees.filter(
          (x) =>
            x.details?.resigned !== 'yes' &&
            (!x.details?.onboardToAttendance ||
              ['yes', 'Yes'].includes(x.details?.onboardToAttendance))
        );

        const ignoredEmployees = employees.filter(
          (x) =>
            x.details?.resigned === 'yes' ||
            ['no', 'No'].includes(x.details?.onboardToAttendance)
        );

        setIgnoredEmployees(ignoredEmployees);
        const sortedEmpData = _.orderBy(filteredEmployees, (e) => e.name, [
          'asc',
        ]);

        // const sortedEmpData = employees
        //   // .map(e => {

        //   //   return {
        //   //     id: e.id,
        //   //     name: e.name,
        //   //     employeeId: e?.details?.employeeId || '',
        //   //     grade: e?.details?.grade?.name || '',
        //   //     personalEmail: e.details?.contactDetails?.emailAddress?.personalEmail || '',
        //   //     officeEmail: e.details?.contactDetails?.emailAddress?.officeEmail || '',
        //   //     resigned: e.details?.resigned || 'no'
        //   //   }
        //   // })
        //   .orderBy('employee.name')((a, b) => a.details?.grade?.name < b.details?.grade?.name ? 1 : -1)
        setSortedEmp(sortedEmpData);
      }
    }, [employees]);

    useEffect(() => {
      if (sortedEmp.length) {
        const pl: ProjectedLeaves = {};
        for (let emp of sortedEmp) {
          pl[emp.id] = {
            name: emp.name,
            privilege: 15,
            sick: 5,
            casual: 5,
            compoff: 0,
            selected: false,
          };
        }
        setProjectedLeaves(pl);
      }
    }, [sortedEmp]);

    const PersistLeaves = renderPersistRefillLeaves({
      projectedLeaves,
      leaveService,
      onClose: closePersistModal,
    });

    async function submitLeaveBank() {
      setModal({
        ...modal,
        title: 'Refill Leave Bank',
        isVisible: true,
        body: (
          <>
            <PersistLeaves />
          </>
        ),
      });
    }

    const leavesFilterEmp = (empId: string) => {
      const unusedLeaves = leaves?.filter(
        (l) => l.status === 'un-consumed' && l?.employee?.id === empId
      );
      return unusedLeaves;
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Grade', rowSpan: 2 },
        { name: 'Employee', rowSpan: 2 },
        {
          name: 'Current Leaves',
          colSpan: 4,
          style: 'text-center',
        },
        {
          name: 'Projected Leaves (2024)',
          colSpan: 4,
          style: 'text-center',
        },
        { name: 'Actions', rowSpan: 2 },
      ],

      [
        // Current Leaves
        {
          name: 'Privilege',
          style: 'border border-l-gray-150',
        },
        { name: 'Casual' },
        { name: 'Sick' },
        { name: 'Comp-off' },

        // Projected Leaves
        { name: 'Privilege' },
        { name: 'Casual' },
        { name: 'Sick' },
        { name: 'Comp-off' },
      ],
    ];

    const tableData =
      sortedEmp?.map((emp) => ({
        cells: [
          { value: emp?.details?.grade?.name || '-' },
          { value: emp?.name, link: `/users/profile/${emp?.id}` },

          // Current Leaves Data
          {
            value: getCount(leavesFilterEmp(emp?.id) || [], 'privilege-leave'),
          },
          { value: getCount(leavesFilterEmp(emp?.id) || [], 'casual-leave') },
          { value: getCount(leavesFilterEmp(emp?.id) || [], 'sick-leave') },
          {
            value: getCount(leavesFilterEmp(emp?.id) || [], 'compensatory-off'),
          },

          // Projected Leaves Data
          {
            value: (
              <input
                type='number'
                min={0}
                value={projectedLeaves[emp?.id]?.privilege || 0}
                className='border border-black text-left h-50 w-12'
                onChange={(e) => {
                  handleChange(
                    emp?.id,
                    'privilege',
                    parseInt(e.target.value || '0')
                  );
                }}
              ></input>
            ),
          },
          {
            value: (
              <input
                type='number'
                value={projectedLeaves[emp?.id]?.casual || 0}
                className='border border-black text-left h-50 w-12'
                onChange={(e) => {
                  handleChange(
                    emp?.id,
                    'casual',
                    parseInt(e.target.value || '0')
                  );
                }}
              ></input>
            ),
          },
          {
            value: (
              <input
                type='number'
                value={projectedLeaves[emp?.id]?.sick || 0}
                className='border border-black text-left h-50 w-12'
                onChange={(e) => {
                  handleChange(
                    emp?.id,
                    'sick',
                    parseInt(e.target.value || '0')
                  );
                }}
              ></input>
            ),
          },
          {
            value: (
              <input
                type='number'
                value={projectedLeaves[emp?.id]?.compoff || 0}
                className='border border-black text-left h-50 w-12'
                onChange={(e) => {
                  handleChange(
                    emp?.id,
                    'compoff',
                    parseInt(e.target.value || '0')
                  );
                }}
              ></input>
            ),
          },

          {
            value: (
              <input
                type='checkbox'
                checked={projectedLeaves[emp?.id]?.selected}
                onChange={(e) => {
                  e.preventDefault();
                  projectedLeaves[emp?.id]['selected'] = e.target.checked;
                  setProjectedLeaves({
                    ...projectedLeaves,
                  });
                }}
                id='checked'
                name='checked'
              />
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Refill Leaves Book',
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <Table header={tableHeader} body={tableBody} />
          <div className='flex justify-end'>
            <LoadingButton
              text='Submit'
              behaviorFn={submitLeaveBank}
              behaviourParams={{}}
              defaultStyle='px-4 py-2 m-1.5 bg-green-600 border rounded-lg'
            />
          </div>

          <div className='my-2'>
            <Table
              header={[
                [
                  { name: 'Grade' },
                  { name: 'Employee Name' },
                  {
                    name: 'Resigned / Not Onboarded To Attendance',
                  },
                ],
              ]}
              body={ignoredEmployees.map((x) => {
                return {
                  cells: [
                    { value: x?.details?.grade?.name || '-' },
                    { value: x?.name, link: `/users/profile/${x?.id}` },
                    {
                      value:
                        x.details?.resigned === 'yes'
                          ? 'Resigned'
                          : 'Not Onboarded to Attendance',
                    },
                  ],
                };
              })}
            />
          </div>
        </>
      ),
    };

    return (
      <>
        <Card header={header} body={body} />
        <Modal {...modal} />
      </>
    );
  };
};
