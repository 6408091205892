/* eslint-disable no-unused-vars */
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormProps,
  LoadingButton,
  ModalV2Props,
  renderCardComponent,
  renderFormV2,
  renderModal,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { UseCompanyGroupSetting } from '../../../../../hooks/admin/company-group-setting/use-company-group-setting';

export type RenderRmQualityMetric = {
  closeModal: () => void;
  useCompanyGroupSetting: UseCompanyGroupSetting;
};

type Props = {
  comGrpSet?: CompanyGroupSetting;
};

export function renderRMQualityMetric({
  closeModal,
  useCompanyGroupSetting,
}: RenderRmQualityMetric): (data: Props) => JSX.Element {
  return function ({ comGrpSet }: Props): JSX.Element {
    const Modal = renderModal();

    const [modal, setModal] = useState<ModalV2Props>({
      isVisible: false,
      title: '',
      onClose: () => {
        setModal({ ...modal, isVisible: false, title: '', body: <div /> });
      },
      body: <div />,
    });

    const { syncSet: setCompanyGroupSetting } = useCompanyGroupSetting();
    if (!comGrpSet) return <Recat className='h-5 inline animate-pulse mx-4' />;
    const rmQualityMetric = comGrpSet?.details?.purchase?.rmQualityMetric || [];
    const saveRmQualityMetric = async (form: FormType) => {
      const finalData = {
        id: comGrpSet.id,
        details: {
          ...comGrpSet.details,
          purchase: {
            ...comGrpSet.details.purchase,
            rmQualityMetric: comGrpSet.details.purchase?.rmQualityMetric,
          },
        },
      };

      finalData.details.purchase.rmQualityMetric = (
        finalData.details?.purchase?.rmQualityMetric || []
      ).find((k) => k.id === form.id)
        ? (finalData.details?.purchase?.rmQualityMetric || []).map((r) => {
            if (r.id === form.id) {
              return {
                id: r.id,
                name: r.name,
              };
            }
            return r;
          })
        : [
            ...(finalData.details?.purchase?.rmQualityMetric || []),
            {
              id: form.id,
              name: form.name,
            },
          ];

      await setCompanyGroupSetting(finalData as CompanyGroupSetting);
      closeModal();
    };

    type FormType = {
      id: string;
      name: string;
    };

    const formProps: FormProps<FormType> = {
      fieldsData: [
        { property: 'id', label: 'Quality Id', type: 'input', required: true },
        {
          property: 'name',
          label: 'Quality Name',
          type: 'input',
          required: true,
        },
      ],
      initialFormState: {
        id: '',
        name: '',
      },
      allowUnchanged: true,
      mapTToU: (d) => {
        return d as FormType;
      },
      style: 'md:w-1/2',
      onSubmit: saveRmQualityMetric,
    };

    const Form = renderFormV2<any>(formProps);

    const Card = renderCardComponent();

    const Table = renderTableComponent();

    return (
      <div>
        <Card
          header={{
            title: 'Raw Material Quality Metric',
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <LoadingButton
                    defaultStyle='bg-white p-2 rounded'
                    behaviorFn={async () => {
                      setModal({
                        ...modal,
                        title: 'Add new Rm Quality Metric',
                        isVisible: true,
                        body: <Form />,
                      });
                    }}
                    text='Add new Quality Metric'
                  />
                ),
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <Table
                header={[[{ name: 'Id' }, { name: 'Name' }]]}
                body={rmQualityMetric.map((r) => {
                  return { cells: [{ value: r.id }, { value: r.name }] };
                })}
              />
            ),
          }}
        />
        <Modal {...modal} />
      </div>
    );
  };
}
