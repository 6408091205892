/* eslint-disable react-hooks/exhaustive-deps */
import { ApprovedBadge } from '@erp_core/erp-icons/icons/web/approved-badge';
import { RejectedBadge } from '@erp_core/erp-icons/icons/web/rejected-badge';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderInlineInput,
  renderTabsComponent,
  UnderConstruction,
} from '@erp_core/erp-ui-components';
import { CloudIcon, CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseCurrentCompanyGroup } from '../../hooks/admin/company-group-admin/use-current-company-group';
import { UseUsers } from '../../hooks/admin/user-admin/use-users-admin';
import { UseUserAuthorization } from '../../hooks/admin/user-authorization/use-user-authorization';
import { UseDeliveryTerms } from '../../hooks/constants/delivery-term/use-delivery-terms';
import { UsePaymentTerms } from '../../hooks/constants/payment-term/use-payment-terms';
import { UseFileTransfer } from '../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../hooks/file-transfer/use-public-transfer';
import { UseGodowns } from '../../hooks/inventory/godown-inventory/use-godowns';
import { UseItems } from '../../hooks/inventory/item/use-items';
import { UseClientItem } from '../../hooks/order/sales/client-item/use-client-item';
import { UseClient } from '../../hooks/order/sales/client/use-client';
import { UseClients } from '../../hooks/order/sales/client/use-clients';
import { UseSalesOrders } from '../../hooks/order/sales/order/use-orders';
import { UsePaginatedSalesOrders } from '../../hooks/order/sales/order/use-paginated-orders';
import { ItemInterface } from '../../models/interfaces/inventory/item';
import { UserRendererInterface } from '../common/fragments/user';
import { renderClientOffer } from './client-offer';
import { renderClientProfileTabs } from './client-profile-tab';
import renderClientSalesOrderDetails from './client-sales-order';
import { renderHistoryTab } from './history-tab';

type RenderClientV2Profile = {
  useGodowns: UseGodowns;
  useClient: UseClient;
  useClients: UseClients;
  useSalesOrders: UseSalesOrders;
  usePaginatedSalesOrders: UsePaginatedSalesOrders;
  useUsers: UseUsers;
  useItems: UseItems;
  useClientItem: UseClientItem;
  useUserAuthorization: UseUserAuthorization;
  usePaymentTerms: UsePaymentTerms;
  useDeliveryTerms: UseDeliveryTerms;
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  itemService: ItemInterface;
  userRendererService: UserRendererInterface;
};

export function renderClientV2Profile({
  useClient,
  useClients,
  useSalesOrders,
  usePaginatedSalesOrders,
  useUsers,
  useItems,
  useClientItem,
  useUserAuthorization,
  usePaymentTerms,
  useDeliveryTerms,
  useFileTransfer,
  usePublicTransfer,
  useCurrentCompanyGroup,
  useGodowns,
  itemService,
  userRendererService,
}: RenderClientV2Profile): () => JSX.Element {
  const Card = renderCardComponent();

  return function ClientV2Profile(): JSX.Element {
    const { id } = useParams();
    const Tabs = renderTabsComponent();

    const {
      data: client,
      get: getClient,
      loading,
      syncSet: setClient,
    } = useClient();

    const { getAll: getGodowns, data: godowns } = useGodowns();

    useEffect(() => {
      if (id) getClient(id);
    }, [id]);

    useEffect(() => {
      if (client?.id && client?.company?.id) {
        getGodowns({
          type: 'virtual',
          companyId: client?.company?.id,
          search: `Client Godown of ${client.name}`,
        });
      }
    }, [client?.id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const InlineInput = renderInlineInput({ useResource: useClient, id });

    const header: CardHeader = {
      title: (
        <div className='flex'>
          <InlineInput name={'name'} value={client.name || id} />
          {godowns?.length ? (
            <CloudIcon
              title='Virtual Godown Exists'
              className='w-5 h-5 inline text-indigo-900 mx-1'
            />
          ) : null}
        </div>
      ) as any,
      icon: <CubeIcon className='text-gray-800 w-24 h-full self-center' />,
      subheading: (
        <>
          <div className='text-gray-700'>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${client.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {client.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${client.lastModifiedBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {client.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(client.lastModifiedAt).fromNow()}
          </div>
          <div className='flex space-x-2 my-2'>
            {/* {client.disabled === false ? ( */}
            {client.details?.partyProfile?.approvalStatus === 'approved' ? (
              <span className='border border-green-600 rounded-md px-2 py-1 text-sm text-green-600'>
                <ApprovedBadge className='inline w-5 text-green-600' />
                <span className='capitalize'>Approved</span>
              </span>
            ) : (
              <span className='border border-red-600 rounded-md px-2 py-1 text-sm text-red-600'>
                <RejectedBadge className='inline w-5 text-red-600' />
                <span className='capitalize'>Unapproved</span>
              </span>
            )}
          </div>
        </>
      ),
    };

    const Profile = renderClientProfileTabs();

    const History = renderHistoryTab({ id, useClient });

    const SalesOrders = renderClientSalesOrderDetails({
      id,
      userRendererService,
      useSalesOrders,
      usePaginatedSalesOrders,
      useUsers,
    });

    const Offers = renderClientOffer();

    const categories: Map<string, () => JSX.Element> = new Map<
      string,
      () => JSX.Element
    >([
      ['Overview', UnderConstruction], // renderItemProfile(itemService, id) ],
      ['Sales Orders', SalesOrders],
      [
        'Profile',
        () => (
          <Profile
            client={client}
            loading={loading}
            setClient={setClient}
            useItems={useItems}
            useClientItem={useClientItem}
            useUserAuthorization={useUserAuthorization}
            useFileTransfer={useFileTransfer}
            usePublicTransfer={usePublicTransfer}
            useCurrentCompanyGroup={useCurrentCompanyGroup}
            usePaymentTerms={usePaymentTerms}
            useDeliveryTerms={useDeliveryTerms}
            itemService={itemService}
          />
        ),
      ],
      ['Offers', Offers],
      ['History', History],
    ]);

    const body: CardBody = {
      type: 'jsx-component',
      body: <Tabs data={categories} keyTab={nanoid()} defaultTabIndex={0} />,
    };

    return <Card header={header} body={body} />;
  };
}
