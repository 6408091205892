import { SalaryParamGroup } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param-group';
import { ModalV2Props } from '@erp_core/erp-ui-components';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { deleteResource } from './delete-resource';

export function UpDownDelete({
  idx,
  list,
  setSalParams,
  salParams,
  modal,
  setModal,
  textSize,
  resourceName,
}: {
  idx: number;
  list: Array<any>;
  setSalParams: React.Dispatch<React.SetStateAction<SalaryParamGroup>>;
  salParams: SalaryParamGroup;
  modal: ModalV2Props;
  setModal: React.Dispatch<React.SetStateAction<ModalV2Props>>;
  textSize: number;
  resourceName: string;
}): JSX.Element {
  return (
    <div className='flex w-18'>
      {idx !== 0 ? (
        <div>
          <ArrowUpIcon
            className={`w-${textSize} h-${textSize} text-gray-500 cursor-pointer`}
            onClick={() => {
              const deleted = list.splice(idx, 1);
              list.splice(idx - 1, 0, deleted[0]);

              setSalParams({ ...salParams });
            }}
          />
        </div>
      ) : null}
      {idx !== list.length - 1 ? (
        <div>
          <ArrowDownIcon
            className={`w-${textSize} h-${textSize} text-gray-500 cursor-pointer`}
            onClick={() => {
              const deleted = list.splice(idx, 1);
              list.splice(idx + 1, 0, deleted[0]);

              setSalParams({ ...salParams });
            }}
          />
        </div>
      ) : null}
      <div>
        <XMarkIcon
          className={`w-${textSize} h-${textSize} text-red-500 cursor-pointer`}
          onClick={() => {
            deleteResource({
              obj: list,
              index: idx,
              resource: resourceName,
              modal,
              setModal,
            });
          }}
        />
      </div>
    </div>
  );
}
