/* eslint-disable no-await-in-loop */
import { UserIdName } from '@erp_core/erp-types/dist/modules/common';
import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import {
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { TrashIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { renderTextEditor } from '../../../../../components/html-editor';
import { UseCurrentCompanyGroup } from '../../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseHazardGhsClass } from '../../../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseHazardGhsClasses } from '../../../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-classes';
import { UseGhsPrecautionaryStatements } from '../../../../../hooks/admin/hazard-regulations/ghs-precautionary-statements/use-ghs-precautionary-statements';
import { UseHazardStatements } from '../../../../../hooks/admin/hazard-regulations/hazard-statements/use-hazard-statements';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { downloadItemMsdS } from '../../utils/item-msds-downloader';
import { renderAddHazardPrecautionInItem } from './forms/add-hazard-precautions';
import { renderAddHazardStatementInItem } from './forms/add-hazard-statements';

const header: TableHeader = [
  [
    { name: 'Symbol' },
    { name: 'Type' },
    { name: 'Class-Category' },
    { name: 'Code' },
    { name: 'Statement' },
    { name: 'Actions' },
  ],
];

type Props = {
  itemData: ItemRes;
  setItem: (s: ItemRes, o?: any) => Promise<ItemRes>;
  useHazardClass: UseHazardGhsClass;
  useHazardClasses: UseHazardGhsClasses;
  useHazardStatements: UseHazardStatements;
  useGhsPrecautionaryStatements: UseGhsPrecautionaryStatements;
  useFileTransfer: UseFileTransfer;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  id: string;
};

export function renderItemGHSRegulations({
  itemData,
  setItem,
  useGhsPrecautionaryStatements,
  useHazardClass,
  useHazardClasses,
  useHazardStatements,
  useFileTransfer,
  useCurrentCompanyGroup,
  id,
}: Props) {
  const TextEditor = renderTextEditor();
  const Card = renderCardComponent();
  const Table = renderTableComponent();

  return function () {
    const {
      data: statementsData,
      getAll: getStatements,
    } = useHazardStatements();

    const {
      data: precautionsData,
      getAll: getPrecautions,
    } = useGhsPrecautionaryStatements();

    const { data: classesData, getAll: getClasses } = useHazardClasses();

    const { get: getTemplate, set: setTemplate } = useFileTransfer();

    const { currentCompanyGroup } = useCurrentCompanyGroup();

    useEffect(() => {
      getClasses();
      getStatements();
      getPrecautions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function onTemplateSave(data: any) {
      const file = new File([data], `${id.toLowerCase()}.html`, {
        type: 'text/plain',
      });
      setTemplate(
        `${currentCompanyGroup.id}/msds-template/${id.toLowerCase()}`,
        file,
        'html'
      );
    }

    const statementsTableBody: TableBody =
      itemData?.details?.hazard?.statements?.map((p) => {
        return {
          cells: [
            {
              value: p.symbol ? (
                <img className='w-5 h-5' src={p.symbol} alt={p.name} />
              ) : (
                ''
              ),
            },
            { value: p.type || '' },
            { value: p.category || '' },
            { value: p.name || '' },
            { value: p.statement || '' },
            {
              value: (
                <TrashIcon
                  className='w-5 h-5 hover:cursor-pointer hover:text-blue-500'
                  onClick={async () => {
                    const hazard = { ...itemData.details.hazard };
                    hazard.statements =
                      itemData.details.hazard?.statements?.filter(
                        (f) => f.id !== p.id
                      ) || [];
                    await setItem({
                      id: itemData.id,
                      details: { ...itemData.details, hazard: { ...hazard } },
                    } as ItemRes);
                  }}
                />
              ),
            },
          ],
        };
      }) || [];

    const precautionsTableBody: TableBody =
      itemData?.details?.hazard?.precautions?.map((p) => {
        return {
          cells: [
            {
              value: p.symbol ? (
                <img className='w-5 h-5' src={p.symbol} alt={p.name} />
              ) : (
                ''
              ),
            },
            { value: p.type || '' },
            { value: p.category || '' },
            { value: p.name || '' },
            { value: p.statement || '' },
            {
              value: (
                <TrashIcon
                  className='w-5 h-5 hover:cursor-pointer hover:text-blue-500'
                  onClick={async () => {
                    const hazard = { ...itemData.details.hazard };
                    hazard.precautions =
                      itemData.details.hazard?.precautions?.filter(
                        (f) => f.id !== p.id
                      ) || [];
                    await setItem({
                      id: itemData.id,
                      details: { ...itemData.details, hazard: { ...hazard } },
                    } as ItemRes);
                  }}
                />
              ),
            },
          ],
        };
      }) || [];

    return (
      <div className='space-y-3'>
        <Card
          disclosure={true}
          header={{
            title: 'Hazard Statements',
            actions: [
              {
                type: 'button',
                button: {
                  behaviour: 'modal',
                  modal: {
                    title: 'Add Statements',
                    content: ({ onClose }) => {
                      const onSave = async (f: {
                        class: UserIdName;
                        statements: UserIdName[];
                      }) => {
                        while (!statementsData || !classesData) {}
                        const selectedIds = f.statements.map((f) => f.id); // Filtering statements
                        const cl = classesData?.find(
                          (i) => i.id === f.class.id
                        ); // Getting class Info
                        const statements =
                          statementsData?.filter((f) => {
                            return selectedIds.includes(f.id);
                          }) || [];
                        const saveData = itemData.details.hazard || {
                          precautions: [],
                          statements: [],
                        };
                        saveData.statements = [
                          ...(saveData?.statements || []),
                          ...statements.map((t) => ({
                            id: t.id,
                            name: t.name || t.code,
                            statement: t.statement,
                            symbol: cl?.details.symbol,
                            type: cl?.type,
                            ghsClassId: cl?.id,
                            category: cl?.categoryName,
                          })),
                        ];

                        saveData.statements = _.uniqBy(
                          saveData.statements,
                          'id'
                        ); // Removing duplicate
                        await setItem({
                          id: itemData.id,
                          details: { ...itemData.details, hazard: saveData },
                        } as ItemRes);
                        onClose();
                      };
                      const Form = renderAddHazardStatementInItem({
                        useHazardClass,
                        onSave,
                        useHazardClasses,
                        useHazardStatements,
                      });
                      return (
                        <Form
                          defaultStatements={itemData.details?.hazard || {}}
                        />
                      );
                    },
                  },
                  name: 'Add Statement',
                  style:
                    'bg-purple-500 text-white hover:bg-purple-600 font-bold',
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: <Table header={header} body={statementsTableBody} />,
          }}
        />

        <Card
          disclosure={true}
          header={{
            title: 'Hazard Precautions',
            actions: [
              {
                type: 'button',
                button: {
                  behaviour: 'modal',
                  modal: {
                    title: 'Add Precautions',
                    content: ({ onClose }) => {
                      const onSave = async (f: {
                        class: UserIdName;
                        statements: UserIdName[];
                      }) => {
                        while (!precautionsData || !classesData) {}
                        const selectedIds = f.statements.map((f) => f.id); // Filtering statements
                        const cl = classesData?.find(
                          (i) => i.id === f.class.id
                        ); // Getting class Info
                        const precautions =
                          precautionsData?.filter((f) => {
                            return selectedIds.includes(f.id);
                          }) || [];
                        const saveData = itemData.details.hazard || {
                          precautions: [],
                          statements: [],
                        };
                        saveData.precautions = [
                          ...(saveData?.precautions || []),
                          ...precautions.map((t) => ({
                            id: t.id,
                            name: t.name || t.code,
                            statement: t.statement,
                            symbol: cl?.details.symbol,
                            type: cl?.type,
                            ghsClassId: cl?.id,
                            category: cl?.categoryName,
                          })),
                        ];

                        saveData.precautions = _.uniqBy(
                          saveData.precautions,
                          'id'
                        ); // Removing duplicate
                        await setItem({
                          id: itemData.id,
                          details: { ...itemData.details, hazard: saveData },
                        } as ItemRes);
                        onClose();
                      };

                      const Form = renderAddHazardPrecautionInItem({
                        useHazardClass,
                        onSave,
                        useHazardClasses,
                        useGhsPrecautionaryStatements,
                      });
                      return (
                        <Form defaultHazard={itemData.details.hazard || {}} />
                      );
                    },
                  },
                  name: 'Add Precaution',
                  style: 'bg-blue-500 text-white hover:bg-blue-600 font-bold',
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: <Table header={header} body={precautionsTableBody} />,
          }}
        />

        <Card
          disclosure={true}
          header={{
            title: 'Download MSDS Sheet',
            actions: [
              {
                type: 'button',
                button: {
                  name: 'Download',
                  behaviour: 'click',
                  onClick: async () =>
                    await downloadItemMsdS({
                      companyGroupId: currentCompanyGroup.id,
                      item: itemData,
                      getSpecTemplate: getTemplate,
                    }),
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                For adding dynamic values from specification use{' '}
                <span className='font-bold'>
                  {'{{ specification.propertyName }}'}
                </span>
                .
                <div className='font-bold'>
                  Eg. {'{{ specification.type }}'}{' '}
                </div>
                <div>
                  Find all valid specifications form
                  <a
                    target='_blank'
                    rel='noreferrer'
                    className='text-blue-600'
                    href={`/inventory/masters/items/${itemData.id}/properties`}
                  >
                    {' '}
                    specifications{' '}
                  </a>{' '}
                  tab.
                </div>
              </div>
            ),
          }}
        />

        <Card
          header={{
            title: 'MSDS Template',
          }}
          body={{
            type: 'jsx-component',
            body: (
              <>
                {currentCompanyGroup.id ? (
                  <OpenMSDSTemplate
                    companyGroupId={currentCompanyGroup.id}
                    path={`${
                      currentCompanyGroup.id
                    }/msds-template/${id.toLowerCase()}.html`}
                    onTemplateSave={onTemplateSave}
                    getTemplate={getTemplate}
                  />
                ) : (
                  <div />
                )}
              </>
            ),
          }}
        />
      </div>
    );
  };

  function OpenMSDSTemplate({
    path,
    onTemplateSave,
    getTemplate,
    companyGroupId,
  }: {
    path: string;
    onTemplateSave: (data: any) => void;
    getTemplate: (path: string) => Promise<string>;
    companyGroupId: string;
  }): JSX.Element {
    const [data, setData] = useState<string>('');
    useEffect(() => {
      getTemplate(path)
        .then((res) => {
          if (res) setData(res);
          else {
            if (companyGroupId) {
              getTemplate(
                `${companyGroupId}/templates/msds-sheet-template.html`
              )
                .then((res2) => {
                  setData(res2);
                })
                .catch((e) => console.log(e));
            }
          }
        })
        .catch((e) => console.log(e));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    return (
      <>
        <TextEditor initialHtml={data} onSave={onTemplateSave} />
      </>
    );
  }
}
