/* eslint-disable react-hooks/exhaustive-deps */
// import { ApprovedBadge } from '@erp_core/erp-icons/icons/web/approved-badge';
// import { RejectedBadge } from '@erp_core/erp-icons/icons/web/rejected-badge';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderInlineInput,
  renderTabsComponent,
} from '@erp_core/erp-ui-components';
import { CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseCurrentCompanyGroup } from '../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseMetrics } from '../../../hooks/admin/constants/metrics/use-metrics';
import { UseEmployeeProfiles } from '../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseItems } from '../../../hooks/inventory/item/use-items';
import { UseClientItem } from '../../../hooks/order/sales/client-item/use-client-item';
import { UseClient } from '../../../hooks/order/sales/client/use-client';
import { UseClients } from '../../../hooks/order/sales/client/use-clients';
import { UseLeadCustomerEnquiry } from '../../../hooks/order/sales/lead-customer-enquiry/use-lead-customer-enquiry';
import { UseLeadCustomer } from '../../../hooks/order/sales/lead-customer/use-lead-customer';
import { UseLeadCustomers } from '../../../hooks/order/sales/lead-customer/use-lead-customers';
import { ItemInterface } from '../../../models/interfaces/inventory/item';
import { renderLeadCustomerEnquiryDetailsProfile } from '../lead-customer-enquiry-details-profile';

type RenderLeadCustomerEnquiryDetails = {
  useLeadCustomerEnquiry: UseLeadCustomerEnquiry;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useItems: UseItems;
  useEmployeeProfiles: UseEmployeeProfiles;
  useLeadCustomers: UseLeadCustomers;
  useLeadCustomer: UseLeadCustomer;
  useMetrics: UseMetrics;
  useClient: UseClient;
  useClients: UseClients;
  useClientItem: UseClientItem;
  itemService: ItemInterface;
};

export function renderLeadCustomerEnquiryDetails({
  useLeadCustomerEnquiry,
  useCurrentCompanyGroup,
  useItems,
  useEmployeeProfiles,
  useLeadCustomers,
  useLeadCustomer,
  useMetrics,
  useClient,
  useClients,
  useClientItem,
  itemService,
}: RenderLeadCustomerEnquiryDetails): () => JSX.Element {
  const Card = renderCardComponent();

  return function LeadCustomerEnquiryProfile(): JSX.Element {
    const { id } = useParams();
    const Tabs = renderTabsComponent();

    const {
      data: leadCustomerEnquiry,
      get: getLeadCustomerEnquiry,
      loading,
      syncSet: setLeadCustomerEnquiry,
    } = useLeadCustomerEnquiry();

    useEffect(() => {
      if (id) getLeadCustomerEnquiry(id);
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const InlineInput = renderInlineInput({
      useResource: useLeadCustomerEnquiry,
      id,
    });

    const header: CardHeader = {
      title: (
        <div>
          <></>
          <InlineInput name={'name'} value={leadCustomerEnquiry?.name || id} />
        </div>
      ) as any,
      icon: <CubeIcon className='text-gray-800 w-24 h-full self-center' />,
      subheading: (
        <>
          <div className='text-gray-700'>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${leadCustomerEnquiry.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {leadCustomerEnquiry?.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${leadCustomerEnquiry?.lastModifiedBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {leadCustomerEnquiry.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(leadCustomerEnquiry?.lastModifiedAt).fromNow()}
          </div>
          {/* <div className='flex space-x-2 my-2'>
            {leadCustomerEnquiry.disabled === false ? (
              <span className='border border-green-600 rounded-md px-2 py-1 text-sm text-green-600'>
                <ApprovedBadge className='inline w-5 text-green-600' />
                <span className='capitalize'>Approved</span>
              </span>
            ) : (
              <span className='border border-red-600 rounded-md px-2 py-1 text-sm text-red-600'>
                <RejectedBadge className='inline w-5 text-red-600' />
                <span className='capitalize'>Unapproved</span>
              </span>
            )}
          </div> */}
        </>
      ),
    };

    const Profile = renderLeadCustomerEnquiryDetailsProfile({
      leadCustomerEnquiry,
      setLeadCustomerEnquiry,
      loading,
      useItems,
      useEmployeeProfiles,
      useLeadCustomers,
      useLeadCustomer,
      useMetrics,
      useClient,
      useClients,
      useClientItem,
      itemService,
    });

    const categories: Map<string, () => JSX.Element> = new Map<
      string,
      () => JSX.Element
    >([['Profile', () => <Profile />]]);

    const body: CardBody = {
      type: 'jsx-component',
      body: <Tabs data={categories} keyTab={nanoid()} defaultTabIndex={0} />,
    };

    return <Card header={header} body={body} />;
  };
}
