/* eslint-disable no-unused-vars */
import {
  CompanyGroupSetting,
  CompanyGroupSettingDetails,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCompanyGroupSetting } from '../../../../../hooks/admin/company-group-setting/use-company-group-setting';

type Props = {
  comGrpSetDetails: CompanyGroupSettingDetails;
  closeModal: () => void;
  useCompanyGroupSetting: UseCompanyGroupSetting;
};

export function renderEditMinimumSalesQuantityForm({
  closeModal,
  useCompanyGroupSetting,
}: Props): (p: {
  comGrpSetDetails: CompanyGroupSettingDetails;
}) => JSX.Element {
  return function ({
    comGrpSetDetails,
  }: {
    comGrpSetDetails: CompanyGroupSettingDetails;
  }): JSX.Element {
    const { syncSet: setCompanyGroupSetting } = useCompanyGroupSetting();
    const save = async (form: FormType) => {
      const finalData: CompanyGroupSetting = {
        details: {
          inventory: {
            ...comGrpSetDetails?.inventory,
            item: {
              ...comGrpSetDetails?.inventory?.item,
              minimumSalesQuantity: form.minimumSalesQuantity,
            },
          },
        } as CompanyGroupSettingDetails,
      } as CompanyGroupSetting;
      await setCompanyGroupSetting(finalData);
      closeModal();
    };

    type FormType = {
      minimumSalesQuantity: number;
    };

    const formProps: FormProps<FormType> = {
      fieldsData: [
        {
          property: 'minimumSalesQuantity',
          label: 'Minimum Sales Quantity %',
          type: 'number',
          required: true,
        },
      ],
      initialFormState: {
        minimumSalesQuantity:
          comGrpSetDetails.inventory?.item?.minimumSalesQuantity || '',
      },
      mapTToU: mapPFDToP,
      style: '',
      onSubmit: save,
    };

    const Form = renderFormV2<any>(formProps);

    return (
      <div>
        <Form />
        <div className='font-bold text-md mt-1'>Guidelines</div>
        <div>
          Minimum sales quantity is x percentage of the items default godowns
          capacity.
        </div>
      </div>
    );

    function mapPFDToP(b: FormDataType<FormType>): FormType {
      return b as FormType;
    }
  };
}
