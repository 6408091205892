import { Revision } from '@erp_core/erp-types/dist/modules/inventory';
import { StockGroup } from '@erp_core/erp-types/dist/types/modules/inventory/stockgroup';
import { useEffect, useState } from 'react';
import { UseCurrentCompanyGroup } from '../../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseCompanyGroupSettings } from '../../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { UseAppearances } from '../../../../../hooks/admin/constants/appearance/use-appearances';
import { UseColors } from '../../../../../hooks/admin/constants/color/use-colors';
import { UseGsts } from '../../../../../hooks/admin/constants/gst/use-gsts';
import { UseMetrics } from '../../../../../hooks/admin/constants/metrics/use-metrics';
import { UseOdours } from '../../../../../hooks/admin/constants/odour/use-odours';
import { UseCurrentUserRoles } from '../../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseRoles } from '../../../../../hooks/admin/role-admin/use-roles-admin';
import { UseCurrentUser } from '../../../../../hooks/admin/user-admin/use-current-user';
import { UseUsers } from '../../../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UseRevisions } from '../../../../../hooks/inventory/revision/use-revisions';
import { UseStockGroup } from '../../../../../hooks/inventory/stockgroup/use-stockgroup';
import { StockGroupInterface } from '../../../../../models/interfaces/inventory/stock-group';
import { renderProperties } from '../../../properties/properties';
// import { renderStockGroupSpecifications } from '../specifications';
// import { renderStockGroupSpecificationsTemplate } from '../specifications/specifications-template';
import { UseEmployeeProfiles } from '../../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { renderStockTab } from '../stock-tab/stockTab';

type StockgroupTabsProps = {
  useCompanyGroupSettings: UseCompanyGroupSettings;
  stockGroup: StockGroup;
  sgService: StockGroupInterface;
  setStockGroup: (s: StockGroup) => Promise<StockGroup>;
  setRevision: (r: Revision) => Promise<Revision>;
};

export function renderStockgroupTabs({
  useFileTransfer,
  useCurrentCompanyGroup,
  useStockGroup,
  useMetrics,
  useGsts,
  useCurrentUserRoles,
  useCurrentUser,
  useRevisions,
  useRoles,
  useUsers,
  useAppearances,
  useColors,
  useOdours,
  useEmployees,
}: {
  useFileTransfer: UseFileTransfer;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useStockGroup: UseStockGroup;
  useMetrics: UseMetrics;
  useColors: UseColors;
  useAppearances: UseAppearances;
  useOdours: UseOdours;
  useGsts: UseGsts;
  useCurrentUserRoles: UseCurrentUserRoles;
  useCurrentUser: UseCurrentUser;
  useRevisions: UseRevisions;
  useRoles: UseRoles;
  useUsers: UseUsers;
  useEmployees: UseEmployeeProfiles;
}): (p: StockgroupTabsProps) => JSX.Element {
  const Properties = renderProperties<StockGroup>({
    useMetrics,
    useGsts,
    useAppearances,
    useColors,
    useOdours,
    useCurrentUserRoles,
    useCurrentUser,
    useRevisions,
    useCurrentCompanyGroup,
    useFileTransfer,
    useRoles,
    useUsers,
    useEmployees,
  });

  return function StockgroupTabs({
    stockGroup,
    sgService,
    setStockGroup,
    useCompanyGroupSettings,
    setRevision,
  }: StockgroupTabsProps): JSX.Element {
    const [currentTab, setCurrentTab] = useState<string>('');
    const [tabs, setTabs] = useState<Array<string>>([]);
    const [body, setBody] = useState<JSX.Element>(<div></div>);

    useEffect(() => {
      let tabs: Array<string> = [];
      if (stockGroup.id) {
        sgService.getProperties(stockGroup.id).then((properties) => {
          properties.forEach((p) => {
            p?.classifiers?.forEach((c) => {
              if (c.name && !tabs.includes(c.name)) {
                tabs.push(c.name);
              }
            });
          });

          tabs = [
            // 'Master',
            ...tabs.sort(),
            'Draft',
            // 'Old Specifications',
            // 'Old Specifications Template',
            // 'Old Stock',
          ];
          setTabs(tabs);

          if (tabs.length) {
            setCurrentTab(tabs[0]);
          }
        });
      }

      // eslint-disable-next-line
    }, [stockGroup]);

    useEffect(() => {
      if (currentTab) {
        let Props = <div></div>;

        switch (currentTab) {
          case 'Master': {
            Props = (
              <Properties
                renderOptions={{
                  renderAuth: true,
                  renderAdd: true,
                  renderEditValue: true,
                }}
                useCompanyGroupSettings={useCompanyGroupSettings}
                entityService={sgService}
                entity={stockGroup}
                setEntity={setStockGroup}
                type='stock-group'
                setRevision={setRevision}
              />
            );
            break;
          }

          // case 'Old Specifications': {
          //   const Specs = renderStockGroupSpecifications({
          //     id: stockGroup.id,
          //     useStockGroup: useStockGroup,
          //     useFileTransfer,
          //     useCurrentCompanyGroup,
          //     stockGroupService: sgService,
          //   });
          //   Props = <Specs />;
          //   break;
          // }

          // case 'Old Specifications Template': {
          //   const SpecsTemplate = renderStockGroupSpecificationsTemplate({
          //     stockGroup,
          //     useFileTransfer,
          //     useCurrentCompanyGroup,
          //     useStockGroup,
          //   });
          //   Props = <SpecsTemplate />;
          //   break;
          // }

          case 'Old Stock': {
            const Stock = renderStockTab({
              services: { stockGroupService: sgService },
              stockGroupId: stockGroup.id,
            });
            Props = <Stock />;
            break;
          }

          default: {
            Props = (
              <Properties
                renderOptions={{
                  renderAuth: true,
                  classifier: currentTab,
                  renderAdd: true,
                  renderEditValue: true,
                }}
                useCompanyGroupSettings={useCompanyGroupSettings}
                entityService={sgService}
                entity={stockGroup}
                setEntity={setStockGroup}
                type='stock-group'
                setRevision={setRevision}
              />
            );
          }
        }

        setBody(Props);
      }
      // eslint-disable-next-line
    }, [currentTab, stockGroup]);

    const selectedTabStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 bg-white shadow text-blue-700 border-b-2 border-b-blue-600';
    const nonSelectedStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 hover:bg-white/[0.12] hover:border-b-2 hover:border-b-gray-400 hover:text-blue-700';

    return (
      <div>
        <div className='flex w-full'>
          {tabs.map((x, idx) => (
            <div
              key={x || idx}
              onClick={() => {
                if (x !== currentTab) {
                  setCurrentTab(x);
                }
              }}
              className={currentTab === x ? selectedTabStyle : nonSelectedStyle}
            >
              {x}
            </div>
          ))}
        </div>
        <div className='w-full'>
          <div className='w-full'>{body}</div>
        </div>
      </div>
    );
  };
}
