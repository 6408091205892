import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { ChangeAssesmentType } from 'models/types/quality-assurance/change-assesment';
import moment from 'moment';

export type ExpertOpinionFormType = {
  expertComment: string;
};

// eslint-disable-next-line
export function renderExpertOpinionForm() {
  return function ExpertOpinionForm({
    onSave,
    data,
  }: {
    onSave: (data: ChangeAssesmentType) => Promise<void>;
    data?: ChangeAssesmentType;
  }): JSX.Element {
    const formProps: FormProps<ExpertOpinionFormType> = {
      fieldsData: [
        {
          property: 'expertComment',
          type: 'text-area',
          label: 'Expert`s Opinion',
        },
      ],
      initialFormState: {
        expertComment: data?.details?.expertOpinion?.expertComment || '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        await onSave(({
          details: {
            ...data?.details,
            expertOpinion: {
              expertComment: f.expertComment,
              createdAt: moment().utc().format(),
            },
          },
        } as unknown) as ChangeAssesmentType);
      },
    };

    const Form = renderFormV2<ExpertOpinionFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<ExpertOpinionFormType>
    ): ExpertOpinionFormType {
      return (b as unknown) as ExpertOpinionFormType;
    }
  };
}
