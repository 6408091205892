/* eslint-disable react-hooks/exhaustive-deps */
import { Vendor } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  downloadPdfFile,
  FormField,
  LoadingButton,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { UseCurrentCompanyGroup } from '../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UsePublicTransfer } from '../../../../hooks/file-transfer/use-public-transfer';
import { UseVendor } from '../../../../hooks/inventory/purchase/vendors/use-vendor';

type RenderBrochure = {
  vendor: Vendor;
  setVendor: (s: Vendor) => Promise<Vendor>;
  usePublicTransfer: UsePublicTransfer;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useVendor: UseVendor;
};

export const renderBrochure = ({
  usePublicTransfer,
  useCurrentCompanyGroup,
  vendor,
  setVendor,
}: RenderBrochure) => {
  return function Brochure() {
    const { currentCompanyGroup: companyGroup } = useCurrentCompanyGroup();
    const { get: getPublicFile } = usePublicTransfer();

    const Card = renderCardComponent();

    const addBrochure = async (data: { brochure: string; name: string }) => {
      const finalData = {
        id: vendor.id,
        details: {
          ...vendor.details,
          brochures: vendor.details?.brochures
            ? [
                ...vendor.details.brochures,
                {
                  name: data.name,
                  addedOn: moment().format('DD-MM-YYYY'),
                  link: data.brochure,
                },
              ]
            : [
                {
                  name: data.name,
                  addedOn: moment().format('DD-MM-YYYY'),
                  link: data.brochure,
                },
              ],
        },
      };
      setVendor((finalData as unknown) as Vendor);
    };

    const downloadBrochure = async (link: string, index: number) => {
      const path = link.split(`${companyGroup.id}/`);
      const res = await getPublicFile(`${companyGroup.id}/${path[1]}`, true);
      downloadPdfFile({
        fileName: `brochure-${vendor.id}-(${index + 1}).pdf`,
        result: res,
      });
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [{ name: 'Name' }, { name: 'Created On' }, { name: 'Download' }],
    ];

    const tableData =
      vendor.details?.brochures?.map((r, idx) => ({
        cells: [
          { value: (r as any).name || 'Brochure ' + (idx + 1) },
          { value: r.addedOn },
          {
            value: (
              <LoadingButton
                defaultStyle='p-2 hover:bg-blue-200 border rounded-lg mr-3 mb-2 '
                behaviorFn={async () => await downloadBrochure(r.link, idx)}
                text={<ArrowDownTrayIcon className='h-6 w-6' />}
              />
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardHeader: CardHeader = {
      title: 'Brochures',
      actions: [
        {
          type: 'button',
          show: () => Boolean(vendor?.id),
          button: {
            behaviour: 'modal',
            name: 'Add',
            modal: {
              title: 'Add Brochure',
              content: ({ onClose }) => {
                const fields: Array<FormField<{
                  brochure: string;
                  name: string;
                }>> = [
                  {
                    property: 'name',
                    type: 'input',
                    label: 'Name',
                    required: true,
                  },
                  {
                    required: true,
                    property: 'brochure',
                    type: 'file',
                    accept: 'application/pdf',
                  },
                ];
                const Form = renderFormV2<any>({
                  style: 'md:w-1/2',
                  fieldsData: fields,
                  initialFormState: {
                    brochure: '',
                    name: '',
                  },
                  onSubmit: async (data) => {
                    await addBrochure(data);
                    onClose();
                  },
                  mapTToU: (b) => b,
                  upload: {
                    useFileTransfer: usePublicTransfer,
                    config: [
                      {
                        name: 'brochure',
                        path: `${companyGroup.id}/${
                          vendor.id
                        }/brochures/${JSON.stringify(new Date())}`,
                        type: 'public',
                      },
                    ],
                  },
                });
                return <Form />;
              },
            },
          },
        },
      ],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div className='flex flex-col-reverse'>
          {/* {vendor.details?.brochures?.map((b, index) => {
            return (
              <div key={index} className='mb-2 '>
                <button
                  type='button'
                  className='p-2 hover:bg-blue-200 border rounded-lg mr-3 mb-2 '
                  onClick={() => downloadBrochure(b.link, index)}
                >
                  {' '}
                  <ArrowDownTrayIcon className='h-6 w-6' />
                </button>
                Brochure {index + 1} added on {b.addedOn}
              </div>
            );
          })} */}

          <Table header={tableHeader} body={tableBody} />
        </div>
      ),
    };

    return (
      <div className='w-full space-y-3'>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
};
