//InsurancePayment
/* eslint-disable no-unused-vars */

import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { InsurancePayment } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import {
  ActionContextMenu,
  CardBody,
  CardHeader,
  Modal,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { renderAddBulkInsuranceForm } from '../insurance-form/insurance-add-bulk-form';
import { renderAddInsuranceForm } from '../insurance-form/insurance-add-form';
import { renderEditInsuranceForm } from '../insurance-form/insurance-edit-form';

export function renderInsurance({
  employee,
  setEmployee,
}: {
  employee: EmployeeProfileType;
  setEmployee: (s: EmployeeProfileType) => void;
}): // eslint-disable-next-line
({
  id,
}: {
  id: string;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
  isHr: boolean;
}) => JSX.Element {
  return function Insurance({
    id,
    actions,
    isHr,
  }: {
    id: string;
    isHr: boolean;
    actions: {
      [key: string]: {
        action: boolean;
        locations?: string[] | undefined;
      };
    };
  }) {
    const [modalState, setModalState] = useState({
      visible: false,
      title: 'Edit Insurance ',
      content: <>Edit Insurance Form</>,
    });

    const [actionsState, setActionsState] = useState({
      visible: false,
      xPosition: 200,
      yPosition: 200,
      menu: ['Edit'],
    });

    // eslint-disable-next-line
    const [activeDataId, setActiveDataId] = useState<string>('');

    // eslint-disable-next-line
    const handleInsuranceAdd = async (data: InsurancePayment) => {
      const finalData = {
        id: employee.id,
        details: {
          employeeInsurance: [
            ...(employee.details.employeeInsurance || []),
            data,
          ],
        },
      };

      setEmployee(finalData as EmployeeProfileType);
      setModalState((ms) => ({ ...ms, visible: false }));
    };

    function differenceInMonths(startDate: Moment, endDate: Moment) {
      const startDt = moment(startDate, 'DD.MM.YYYY');
      const endDt = moment(endDate, 'DD.MM.YYYY');
      const calMonths = endDt.diff(startDt, 'months');
      return calMonths;
    }

    const handleBulkInsuranceAdd = async (form: any) => {
      const from = moment(form.fromMonthYear).format('YYYY-MM') + '-01'; // moment(props.queryReq?.month);
      const to = moment(form.toMonthYear).format('YYYY-MM') + '-01';

      const data: InsurancePayment[] = [];
      const difference = differenceInMonths(moment(from), moment(to));
      for (let i = 0; i <= difference; i++) {
        data.push({
          monthYear: moment(form.fromMonthYear)
            .add(i, 'month')
            .format('YYYY-MM'),
          insuranceCompanyName: form.insuranceCompanyName,
          policyNo: form.policyNo,
          amount: form.amount,
          status: 'pending',
          paidBy: {} as any,
        });
      }
      const finalData = {
        id: employee.id,
        details: {
          employeeInsurance: [
            ...(employee.details.employeeInsurance || []),
            ...data,
          ],
        },
      };
      setEmployee(finalData as EmployeeProfileType);
    };
    const handleActionClick = (e: any, id: string) => {
      setActionsState({
        ...actionsState,
        visible: true,
        xPosition: e.pageX,
        yPosition: e.pageY,
      });
      setActiveDataId(id);
    };

    const InsuranceCard = renderCardComponent();
    const InsuranceCardHeader: CardHeader = {
      title: 'Insurance (Legacy)',
      actions: [
        {
          auth: 'HRD:EMP-PROFILE:EDIT',
          type: 'button',
          button: {
            name: 'Add Bulk',
            behaviour: 'modal',
            modal: {
              title: 'Add Bulk Insurance',
              content: ({ onClose }) => {
                const InsuranceForm = renderAddBulkInsuranceForm();
                return (
                  <InsuranceForm
                    onSave={(form) => {
                      handleBulkInsuranceAdd(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
        {
          auth: 'HRD:EMP-PROFILE:EDIT',
          type: 'button',
          button: {
            name: 'Add',
            style: 'float-right',
            behaviour: 'modal',
            modal: {
              title: 'Add Insurance',
              content: ({ onClose }) => {
                const Form = renderAddInsuranceForm();
                return (
                  <Form
                    onSave={async (form) => {
                      await handleInsuranceAdd(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const InsuranceTable = renderTableComponent();
    const insuranceTableHeader: TableHeader = [
      [
        { name: 'Month (YYYY-MM)' },
        { name: 'Ins Company Name' },
        { name: 'Policy #' },
        { name: 'Amount' },
        { name: 'Status' },
        { name: 'Paid By' },
        { name: 'Action' },
      ],
    ];

    function openEditInsurance(idx: number) {
      if (employee.details.employeeInsurance) {
        const insurance = employee.details.employeeInsurance[idx];
        const EditInsurance = renderEditInsuranceForm();
        setModalState({
          ...modalState,
          visible: true,
          title: 'Edit Insurance',
          content: (
            <EditInsurance
              ins={insurance}
              onSave={(c) => {
                const insurances = employee.details.employeeInsurance;
                insurances?.splice(idx, 1, c);
                const finalData = {
                  id: employee.id,
                  details: {
                    employeeInsurance: [...(insurances || [])],
                  },
                };

                setEmployee(finalData as EmployeeProfileType);
                setModalState((ms) => ({ ...ms, visible: false }));
              }}
            />
          ),
        });
      }
    }

    const InsuranceTableBody: TableBody = employee.details?.employeeInsurance
      ? employee.details.employeeInsurance.map((f, idx) => {
          const cells: Array<TableCell> = [];
          cells.push(
            // { value: f.fields?.id},
            { value: f.monthYear },
            { value: f.insuranceCompanyName },
            { value: f.policyNo },
            { value: f.amount },
            { value: f.status },
            { value: f.paidBy?.name || '-' },
            {
              value: (
                <div>
                  {isHr ? (
                    <PencilSquareIcon
                      className='w-4 mx-1 inline'
                      onClick={() => {
                        openEditInsurance(idx);
                      }}
                    />
                  ) : null}
                </div>
              ),
            }
          );

          return { cells };
        })
      : [];

    const InsuranceCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <InsuranceTable
              header={insuranceTableHeader}
              body={InsuranceTableBody}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <InsuranceCard
          header={InsuranceCardHeader}
          body={InsuranceCardBody}
          auth={{ actions }}
        />
        {actionsState.visible && (
          <ActionContextMenu
            actionsState={actionsState}
            setActionsState={setActionsState}
            handleActionItemClick={handleActionClick}
          />
        )}
        <Modal
          showModal={modalState.visible}
          setShowModal={() =>
            setModalState({ ...modalState, visible: !modalState.visible })
          }
          title={modalState.title}
          onOk={() => {}}
          style={{ width: '800px' }}
        >
          {modalState.content}
        </Modal>
      </>
    );
  };
}
