import { Admin } from '@erp_core/erp-icons/icons/web/admin';
import { RoleType } from '@erp_core/erp-types/dist/modules/admin';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseActions } from '../../../hooks/admin/action-admin/use-actions-admin';
import { UseAuthorizations } from '../../../hooks/admin/authorization/use-authorizations';
import { UseLocations } from '../../../hooks/admin/location-admin/use-locations';
import { UseNotificationActions } from '../../../hooks/admin/notification-admin/use-authorizations';
import { UsePaginatedRoles } from '../../../hooks/admin/role-admin/use-paginated-roles-admin';
import { UseRoleAdmin } from '../../../hooks/admin/role-admin/use-role-admin';
import { UseRoles } from '../../../hooks/admin/role-admin/use-roles-admin';
import { RoleFilterType } from '../../../models/interfaces/admin/role-admin';
import { AuthorizationView } from '../user-admin-v2';
import { renderEditRoleForm } from './edit-role-form';

export type RenderRoleAdminProps = {
  useGetAllRoles: UseRoles;
  useRoleAdmin: UseRoleAdmin;
  useActions: UseActions;
  useAuthorizations: UseAuthorizations;
  useLocations: UseLocations;
  useNotificationActions: UseNotificationActions;
  usePaginatedRoles: UsePaginatedRoles;
};

export function renderRoleAdmin({
  useGetAllRoles,
  useLocations,
  useAuthorizations,
  useRoleAdmin,
  useActions,
  useNotificationActions,
  usePaginatedRoles,
}: RenderRoleAdminProps): () => JSX.Element {
  return function RoleAdmin(): JSX.Element {
    const { getAll: getRoles } = useGetAllRoles();

    useEffect(() => {
      getRoles();
      // eslint-disable-next-line
    }, []);

    const EditRoleForm = renderEditRoleForm({
      useRoleAdmin,
      useLocations,
      useAuthorizations,
      useActions,
      useNotificationActions,
    });

    const { delete: deleteRoleAdmin } = useRoleAdmin();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Role Admin Screen',
      icon: (
        <Admin className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Role',
            behaviour: 'modal',
            modal: {
              title: 'Add Role',
              content: ({ onClose }) => {
                const role: Partial<RoleType> = {
                  id: '',
                  name: '',
                  description: '',
                  isSystemRole: false,
                };
                return (
                  <EditRoleForm
                    r={role}
                    onClose={() => {
                      getRoles();
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };
    const Table = renderTableWithMapperComponent<RoleType, RoleFilterType>();

    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Description' },
        { name: 'Actions' },
        { name: 'Notifications' },
        { name: 'Authorizations' },
        { name: 'Edit' },
        { name: 'Delete' },
      ],
    ];

    const bodyMapper = (r: RoleType) => {
      const cells: Array<TableCell> = [
        { value: r.name },
        { value: r.description },
        {
          value: (
            <>
              {r.actions.map((action, idx) => (
                <div
                  key={idx}
                  className='rounded-md inline border-blue-400 border-2 px-2'
                >
                  {action.name}
                </div>
              ))}
            </>
          ),
        },
        {
          value: (
            <>
              {(r as any).notificationActions?.map((na, idx) => (
                <div
                  key={idx}
                  className='rounded-md inline border-blue-400 border-2 px-2'
                >
                  {na.name}
                </div>
              ))}
            </>
          ),
        },
        {
          value: (
            <AuthorizationView
              authorizations={r.authorizations}
              roleName={r.name}
            />
          ),
        },
      ];

      return {
        rowData: { role: r },
        cells,
      };
    };

    const filter: Filter<RoleFilterType> = {
      sortFields: [
        {
          key: 'Name',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'description',
          value: 'all',
          type: 'text',
        },
      ],
      filterMapper: (filterSelection: RoleFilterType) => {
        const filterData: RoleFilterType = {};

        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }

        if (
          filterSelection.description !== 'all' &&
          filterSelection.description !== ''
        ) {
          filterData.description = filterSelection.description;
        }

        return filterData as RoleFilterType;
      },
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              useResources={useGetAllRoles}
              filter={filter}
              actions={[
                {
                  name: 'Edit',
                  show: (p) => !p.role.isSystemRole,
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit',
                    content: ({ data, onClose }) => {
                      return (
                        <EditRoleForm
                          r={data.role}
                          onClose={() => {
                            getRoles();
                            onClose();
                          }}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Delete',
                  show: (p) => !p.role.isSystemRole,
                  behaviour: 'confirm',
                  onConfirm: ({ role }) => {
                    return {
                      title: `Are you sure you want to delete this Role ${role.name}? This cannot be undone.`,
                      type: 'warning',
                      onConfirm: async () => {
                        deleteRoleAdmin(role.id);
                        getRoles();
                      },
                    };
                  },
                },
              ]}
              pagination={{
                enabled: true,
                usePaginatedResources: usePaginatedRoles,
              }}
            />
            ,
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
