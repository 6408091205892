import { BullHorn } from '@erp_core/erp-icons/icons/web/bullhorn';
import {
  ConfiguratorDetailsType,
  ConfiguratorStatusDetailsType,
  ConfiguratorType,
} from '@erp_core/erp-types/dist/modules/notification';
import {
  CardBody,
  CardHeader,
  LoadingButton,
  ModalV2Props,
  renderCardComponent,
  renderModal,
  renderTableComponent,
  TableBody,
  TableBodyRow,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { Popover } from '@headlessui/react';
import { PencilIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseAuthorizations } from '../../../hooks/admin/authorization/use-authorizations';
import { UseCurrentCompany } from '../../../hooks/admin/company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseRoles } from '../../../hooks/admin/role-admin/use-roles-admin';
import { UseCurrentUser } from '../../../hooks/admin/user-admin/use-current-user';
import { UseUsers } from '../../../hooks/admin/user-admin/use-users-admin';
import { UseNotificationConfigurator } from '../../../hooks/notification/configurator/use-notification-configurator';
import { UseNotificationConfigurators } from '../../../hooks/notification/configurator/use-notification-configurators';
import { renderAddConfiguratorDetailsForm } from './form/add-configurator-details';
import { renderAddNotifcnConfiguratorForm } from './form/add-notifcn-configurator';

type RenderNotificationV2Action = {
  useNotificationConfigurators: UseNotificationConfigurators;
  useNotificationConfigurator: UseNotificationConfigurator;
  useCurrentUser: UseCurrentUser;
  useCurrentCompany: UseCurrentCompany;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useRoles: UseRoles;
  useAuthorizations: UseAuthorizations;
  useUsers: UseUsers;
};

export const renderNotificationV2Action = ({
  useNotificationConfigurators,
  useNotificationConfigurator,
  useCurrentUser,
  useCurrentCompany,
  useCurrentCompanyGroup,
  useRoles,
  useAuthorizations,
  useUsers,
}: RenderNotificationV2Action) => {
  return function NotificationV2Action() {
    const {
      data,
      getAll: getNotifcnConfigurators,
    } = useNotificationConfigurators();
    const { syncSet: setNotifcnConfigurator } = useNotificationConfigurator();
    const { data: currentUser } = useCurrentUser();
    const { currentCompanyGroup: companyGroup } = useCurrentCompanyGroup();
    const { data: company } = useCurrentCompany();

    const [modal, setModal] = useState<ModalV2Props>({
      isVisible: false,
      title: '',
      onClose: () => {
        setModal({ ...modal, isVisible: false, title: '', body: <div /> });
      },
      body: <div />,
    });

    useEffect(() => {
      getNotifcnConfigurators();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {}, [data]);

    const Modal = renderModal();

    const Form = renderAddNotifcnConfiguratorForm();

    const editNotificnConfigurator = ({
      configurator,
      configuratorId,
    }: {
      configurator: ConfiguratorType;
      configuratorId: string;
    }) => {
      const saveEditNotifcnConfigurator = async (
        form: Partial<ConfiguratorType>
      ) => {
        try {
          const finalData = {
            ...form,
            id: configuratorId,
            details: configurator?.details,
          } as ConfiguratorType;
          await setNotifcnConfigurator(finalData as ConfiguratorType);
          setModal((ms) => ({ ...ms, isVisible: false }));
          toast('Data edited sucessfully');
          getNotifcnConfigurators();
        } catch (error) {
          toast('Something went wrong');
        }
      };

      setModal({
        ...modal,
        title: 'Edit Notification Configurator',
        isVisible: true,
        body: <Form onSave={saveEditNotifcnConfigurator} data={configurator} />,
      });
    };

    const ConfiguratorDetailsForm = renderAddConfiguratorDetailsForm({
      useRoles,
      useAuthorizations,
      useUsers,
    });

    const handleAddStatus = ({
      configuratorId,
      configuratorDetails,
    }: {
      configuratorId: string;
      configuratorDetails?: ConfiguratorDetailsType;
    }) => {
      const saveConfiguratorDetails = async (form: any) => {
        try {
          const finalData = {
            id: configuratorId,
            details:
              {
                statusDetails: configuratorDetails?.statusDetails
                  ? [
                      ...configuratorDetails?.statusDetails,
                      {
                        status: form?.status || '',
                        roles: form?.roles || [],
                        actions: form?.actions || [],
                        users: form?.users || [],
                        email: form?.email || '',
                        emailContentType: form?.emailContentType || '',
                        emailHtml: form?.emailHtml || '',
                        emailTemplate: form?.emailTemplate || '',
                        emailProducer: form?.emailProducer || '',
                        notification: form?.notification || '',
                        notificationHtml: form?.notificationHtml || '',
                        notificationProducer: form?.notificationProducer || '',
                      } as ConfiguratorStatusDetailsType,
                    ]
                  : [
                      {
                        status: form?.status || '',
                        roles: form?.roles || [],
                        actions: form?.actions || [],
                        users: form?.users || [],
                        email: form?.email || '',
                        emailContentType: form?.emailContentType || '',
                        emailHtml: form?.emailHtml || '',
                        emailTemplate: form?.emailTemplate || '',
                        emailProducer: form?.emailProducer || '',
                        notification: form?.notification || '',
                        notificationHtml: form?.notificationHtml || '',
                        notificationProducer: form?.notificationProducer || '',
                      } as ConfiguratorStatusDetailsType,
                    ],
              } || {},
          } as ConfiguratorType;
          await setNotifcnConfigurator(finalData as ConfiguratorType);
          setModal((ms) => ({ ...ms, isVisible: false }));
          toast('Configurator Details added sucessfully');
          getNotifcnConfigurators();
        } catch (error) {
          toast('Something went wrong');
        }
      };

      setModal({
        ...modal,
        title: 'Add Notification Configurator Status',
        isVisible: true,
        body: <ConfiguratorDetailsForm onSave={saveConfiguratorDetails} />,
      });
    };

    const handleEditStatusAction = ({
      configuratorId,
      statusData,
      configuratorDetails,
      detIdx,
    }: {
      configuratorId: string;
      statusData: ConfiguratorStatusDetailsType;
      configuratorDetails?: ConfiguratorDetailsType;
      detIdx: number;
    }) => {
      const saveStatusConfigDetails = async (form: any) => {
        try {
          const statusDetails = configuratorDetails?.statusDetails || [];
          statusDetails[detIdx].status = form?.status || '';
          statusDetails[detIdx].roles = form?.roles || '';
          statusDetails[detIdx].actions = form?.actions || '';
          statusDetails[detIdx].users = form?.users || '';
          statusDetails[detIdx].email = form?.email || '';
          statusDetails[detIdx].emailContentType = form?.emailContentType || '';
          statusDetails[detIdx].emailHtml = form?.emailHtml || '';
          statusDetails[detIdx].emailTemplate = form?.emailTemplate || '';
          statusDetails[detIdx].emailProducer = form?.emailProducer || '';
          statusDetails[detIdx].notification = form?.notification || '';
          statusDetails[detIdx].notificationHtml = form?.notificationHtml || '';
          statusDetails[detIdx].notificationProducer =
            form?.notificationProducer || '';

          const finalData = {
            id: configuratorId,
            details: {
              statusDetails,
            },
          } as ConfiguratorType;
          await setNotifcnConfigurator(finalData as ConfiguratorType);
          setModal((ms) => ({ ...ms, isVisible: false }));
          toast('Configurator Details edited sucessfully');
          getNotifcnConfigurators();
        } catch (error) {
          toast('Something went wrong');
        }
      };

      setModal({
        ...modal,
        title: 'Edit Notification Configurator Status',
        isVisible: true,
        body: (
          <ConfiguratorDetailsForm
            onSave={saveStatusConfigDetails}
            statusData={statusData}
          />
        ),
      });
    };

    const saveNotifcnConfigurator = async (form: Partial<ConfiguratorType>) => {
      try {
        const finalData = {
          ...form,
          details: {},
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          company: {
            id: company.id,
            name: company.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        } as ConfiguratorType;
        await setNotifcnConfigurator(finalData as ConfiguratorType);
        setModal((ms) => ({ ...ms, isVisible: false }));
        toast('Data added sucessfully');
        getNotifcnConfigurators();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Notification-V2 Actions',
      icon: (
        <BullHorn className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Notification Configurator',
            behaviour: 'modal',
            modal: {
              title: 'Add Notification Configurator',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveNotifcnConfigurator(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };
    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Resource' },
        { name: 'Module' },
        { name: 'Actions' },
        { name: 'Status' },
        { name: 'Roles' },
        { name: 'Auth Actions' },
        { name: 'User' },
        { name: 'Email' },
        { name: 'Notifications' },
        { name: 'Status Actions' },
      ],
    ];

    const statusDetailsTableBody: TableBody = [];
    if (data?.length) {
      data?.forEach((configurator, idx) => {
        if (!configurator?.details?.statusDetails) {
          const row: TableBodyRow = {
            cells: [
              { value: configurator.resource },
              { value: configurator.module },
              {
                value: (
                  <Popover className='relative'>
                    {({ open }) => (
                      <>
                        <Popover.Button>
                          <PencilIcon className='h-6 w-6' />
                        </Popover.Button>
                        <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                          <div className='flex flex-col'>
                            <LoadingButton
                              defaultStyle='mb-1'
                              behaviorFn={async () =>
                                handleAddStatus({
                                  configuratorId: configurator.id,
                                  configuratorDetails: configurator?.details
                                    ? configurator.details
                                    : ({} as ConfiguratorDetailsType),
                                })
                              }
                              text='Add Status'
                            />
                          </div>
                        </Popover.Panel>
                      </>
                    )}
                  </Popover>
                ),
              },
              { value: '-' },
              { value: '-' },
              { value: '-' },
              { value: '-' },
              { value: '-' },
              { value: '-' },
              { value: '-' },
            ],
          };
          statusDetailsTableBody.push(row);
        } else {
          configurator.details.statusDetails?.forEach((std, index) => {
            if (index === 0) {
              const row: TableBodyRow = {
                cells: [
                  {
                    value: configurator.resource,
                    rowSpan: configurator?.details.statusDetails?.length,
                  },
                  {
                    value: configurator.module,
                    rowSpan: configurator?.details.statusDetails?.length,
                  },
                  {
                    value: (
                      <Popover className='relative'>
                        {({ open }) => (
                          <>
                            <Popover.Button>
                              <PencilIcon className='h-6 w-6' />
                            </Popover.Button>
                            <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                              <div className='flex flex-col'>
                                <LoadingButton
                                  // type='button'
                                  defaultStyle='mb-1'
                                  behaviorFn={async () =>
                                    editNotificnConfigurator({
                                      configuratorId: configurator.id,
                                      configurator: configurator,
                                    })
                                  }
                                  text='Edit'
                                />
                                <LoadingButton
                                  // type='button'
                                  defaultStyle='mb-1'
                                  behaviorFn={async () =>
                                    handleAddStatus({
                                      configuratorId: configurator.id,
                                      configuratorDetails: configurator?.details
                                        ? configurator.details
                                        : ({} as ConfiguratorDetailsType),
                                    })
                                  }
                                  text='Add Status'
                                />
                              </div>
                            </Popover.Panel>
                          </>
                        )}
                      </Popover>
                    ),
                    rowSpan: configurator?.details.statusDetails?.length,
                  },
                  { value: std.status || '' },
                  {
                    value: (
                      <>
                        {std?.roles
                          ? std?.roles.map((role) => <>{role.name}, </>)
                          : '-'}
                      </>
                    ),
                  },
                  {
                    value: (
                      <>
                        {std?.actions
                          ? std?.actions.map((action) => <>{action.name}, </>)
                          : '-'}
                      </>
                    ),
                  },
                  {
                    value: (
                      <>
                        {std?.users
                          ? std?.users.map((user) => <>{user.name}, </>)
                          : '-'}
                      </>
                    ),
                  },
                  { value: std?.emailProducer || '-' },
                  { value: std?.notificationProducer || '-' },
                  {
                    value: (
                      <Popover>
                        {({ open }) => (
                          <>
                            <Popover.Button>
                              <PencilIcon className='h-6 w-6' />
                            </Popover.Button>
                            <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                              <div className='flex flex-col'>
                                <LoadingButton
                                  // type='button'
                                  defaultStyle='mb-1'
                                  behaviorFn={async () =>
                                    handleEditStatusAction({
                                      statusData: std,
                                      configuratorId: configurator.id,
                                      configuratorDetails: configurator.details,
                                      detIdx: index,
                                    })
                                  }
                                  text='Edit Status'
                                />
                              </div>
                            </Popover.Panel>
                          </>
                        )}
                      </Popover>
                    ),
                  },
                ],
              };
              statusDetailsTableBody.push(row);
            } else {
              const row: TableBodyRow = {
                cells: [
                  { value: std.status || '' },
                  {
                    value: (
                      <>
                        {std?.roles
                          ? std?.roles.map((role) => <>{role.name}, </>)
                          : '-'}
                      </>
                    ),
                  },
                  {
                    value: (
                      <>
                        {std?.actions
                          ? std?.actions.map((action) => <>{action.name}, </>)
                          : '-'}
                      </>
                    ),
                  },
                  {
                    value: (
                      <>
                        {std?.users
                          ? std?.users.map((user) => <>{user.name}, </>)
                          : '-'}
                      </>
                    ),
                  },
                  { value: std?.emailProducer || '-' },
                  { value: std?.notificationProducer || '-' },
                  {
                    value: (
                      <Popover className='relative'>
                        <Popover.Button>
                          <PencilIcon className='h-6 w-6' />
                        </Popover.Button>
                        <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                          {({ open }) => (
                            <div className='flex flex-col'>
                              <LoadingButton
                                // type='button'
                                defaultStyle='mb-1'
                                behaviorFn={async () =>
                                  handleEditStatusAction({
                                    statusData: std,
                                    configuratorId: configurator.id,
                                    configuratorDetails: configurator.details,
                                    detIdx: index,
                                  })
                                }
                                text='Edit Status'
                              />
                            </div>
                          )}
                        </Popover.Panel>
                      </Popover>
                    ),
                  },
                ],
              };
              statusDetailsTableBody.push(row);
            }
          });
        }
      });
    }

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div className='w-full'>
          <Table header={tableHeader} body={statusDetailsTableBody} />
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
        <Modal {...modal} />
      </div>
    );
  };
};
