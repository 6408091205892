/* eslint-disable no-unused-vars */
import { ReviewList } from '@erp_core/erp-icons/icons/web/review-list';
import {
  EmployeeFilterType,
  EmployeeProfileType,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UsePaginatedEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-paginated-employee-profiles.';

import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UserRendererInterface } from '../../../common/fragments/user';

type RenderEmployeeProfileV2ListProps = {
  useEmployeeProfiles: UseEmployeeProfiles;
  usePaginatedEmployeeProfiles: UsePaginatedEmployeeProfiles;
  userRendererService: UserRendererInterface;
};

export function renderEmployeeProfileV2List({
  useEmployeeProfiles,
  usePaginatedEmployeeProfiles,
  userRendererService,
}: RenderEmployeeProfileV2ListProps): () => JSX.Element {
  return function EmployeeProfileList(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      EmployeeProfileType,
      EmployeeFilterType
    >();
    const tableHeader: TableHeader = [
      [
        { name: 'Employee Name' },
        { name: 'Department' },
        { name: 'Company' },
        { name: 'Designation' },
        { name: 'EmployeeStatus' },
      ],
    ];

    const bodyMapper = (e: EmployeeProfileType) => {
      const cells: Array<TableCell> = [
        {
          value: (
            <div className='flex'>
              <userRendererService.userCard
                link={true}
                id={e.id}
                name={e.name}
                size='small'
                extraInfo={'#' + (e.details.employeeId || '??')}
              />
            </div>
          ),
          link: `/hrd/resources/employees/${e.id}/profile`,
        },
        { value: e.department?.name || '' },
        { value: e.company?.name || '' },
        { value: e.details.designation },
        {
          value:
            e.details.resigned === 'yes'
              ? 'resigned'
              : e.details.status || 'working',
        },
      ];
      return {
        cells,
      };
    };

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Employee List',
      icon: (
        <ReviewList className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
      ),
    };
    const filter: Filter<EmployeeFilterType> = {
      sortFields: [
        {
          key: 'EmployeeName',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'Search',
          value: 'all',
          type: 'text',
        },
      ],
      filterMapper: (filterSelection: any) => {
        const filterData: any = {};

        if (filterSelection.Search !== 'all' && filterSelection.Search !== '') {
          filterData.search = filterSelection.Search;
        }

        return (filterData as unknown) as EmployeeFilterType;
      },
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          bodyMapper={bodyMapper}
          useResources={useEmployeeProfiles}
          filter={filter}
          pagination={{
            enabled: true,
            usePaginatedResources: usePaginatedEmployeeProfiles,
          }}
        />
      ),
    };

    return <Card header={header} body={body} />;
  };
}
