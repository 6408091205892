/* eslint-disable quotes */
import { VendorItem } from '@erp_core/erp-types/dist/modules/order';
import { VendorItemContract } from '@erp_core/erp-types/dist/types/modules/order/purchase/vendor';
import {
  FormProps,
  LoadingButton,
  ModalV2Props,
  renderFormV2,
  renderModal,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useState } from 'react';
import { deliveryModesData } from '../../../../../../datalayer/repository/in-memory/admin/delivery-mode';
import { UseDeliveryTerms } from '../../../../../../hooks/constants/delivery-term/use-delivery-terms';
import { UsePaymentTerms } from '../../../../../../hooks/constants/payment-term/use-payment-terms';
import {
  PaymentSchedule,
  PEPaymentSchedule,
} from '../../../../requistions/purchase-requisition/forms/enquiry/payment-schedule';

export const renderAddVendorItemContractForm = ({
  vendorItem,
  usePaymentTerms,
  useDeliveryTerms,
  onSubmit,
}: {
  vendorItem?: VendorItem;
  usePaymentTerms: UsePaymentTerms;
  useDeliveryTerms: UseDeliveryTerms;
  onSubmit: (data: VendorItemContract) => Promise<void>;
}) => {
  const Modal = renderModal();

  type ItemContractForm = {
    validity: string;
    rate: number;
    batchSize: number;
    gapBetweenDeliveryOfBatches: number;
    creditPeriod: number;
    termOfPayment: { id: string; name: string };
    termOfDelivery: { id: string; name: string };
    deliveryMode: string;
  };

  const onSave = async (
    form: ItemContractForm,
    paymentSchedule: PaymentSchedule[]
  ) => {
    const final: VendorItemContract = {
      validity: form.validity,
      rate: form.rate,
      batchSize: {
        size: form.batchSize,
        unit: '', // TODO: Units
      },
      paymentSchedule: { amountIn: 'per', schedule: paymentSchedule },
      paymentTerm: form.termOfPayment,
      deliveryTerm: form.termOfDelivery,
      deliverySchedule: {
        gapBetweenBatches: form.gapBetweenDeliveryOfBatches,
      },
      creditPeriod: form.creditPeriod,
      deliveryMode: deliveryModesData.find((f) => f.id === form.deliveryMode),
      attachments: [], // TODO
    };

    await onSubmit(final);
  };

  return () => {
    const [modal, setModal] = useState<ModalV2Props>({
      isVisible: false,
      title: '',
      onClose() {
        setModal({
          isVisible: false,
          title: '',
          onClose: modal.onClose,
          body: <div />,
        });
      },
      body: <div />,
    });

    const [paymentSchedule, setPaymentSchedule] = useState<PaymentSchedule[]>(
      []
    );

    const formFields: FormProps<ItemContractForm> = {
      fieldsData: [
        {
          type: 'date',
          property: 'validity',
          label: 'Validity',
          required: true,
        },
        { type: 'number', property: 'rate', label: 'rate', required: true },

        {
          type: 'number',
          property: 'batchSize',
          label: 'Batch Size',
          required: true,
        },
        {
          type: 'number',
          property: 'gapBetweenDeliveryOfBatches',
          label: 'Gap Between Delivery of two batches (days)',
          required: true,
        },
        {
          type: 'select',
          property: 'deliveryMode',
          options: deliveryModesData.map((d) => ({
            value: d.id,
            text: d.name,
          })),
          required: true,
        },
        {
          type: 'searchable-select',
          property: 'termOfDelivery',
          searchOptions: {
            useSearch: useDeliveryTerms,
            onSearchValueSelect() {},
          },
        },

        {
          type: 'searchable-select',
          property: 'termOfPayment',
          searchOptions: {
            useSearch: usePaymentTerms,
            onSearchValueSelect() {},
          },
        },
        {
          type: 'number',
          property: 'creditPeriod',
          label: 'Credit Period',
          dependentOn(formData: any) {
            // console.log(formData);
            if (formData.termOfPayment?.name === 'Credit-Payment') return true;
            return false;
          },
        },
      ],
      onSubmit: async (form) => {
        await onSave(form, paymentSchedule);
      },
      initialFormState: {
        validity:
          vendorItem?.details.contract?.validity ||
          moment().utc().add('months', 3).format('YYYY-MM-DD'),
        rate: vendorItem?.details?.contract?.rate || '',
        batchSize: vendorItem?.details?.contract?.batchSize?.size || '',
        gapBetweenDeliveryOfBatches:
          vendorItem?.details?.contract?.deliverySchedule?.gapBetweenBatches ||
          '',
        deliveryMode: vendorItem?.details?.contract?.deliveryMode?.id || '',
        creditPeriod: vendorItem?.details?.contract?.creditPeriod || '',
        termOfPayment: vendorItem?.details?.contract?.paymentTerm || {
          id: '',
          name: '',
        },
        termOfDelivery: vendorItem?.details?.contract?.deliveryTerm || {
          id: '',
          name: '',
        },
      },
      mapTToU: (p) => p as ItemContractForm,
      allowUnchanged: true,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2(formFields);

    return (
      <div>
        <div className='flex space-x-3'>
          <div>Schedule</div>
          <LoadingButton
            defaultStyle='bg-transparent text-blue-600 font-semibold hover:text-blue-900 rounded'
            behaviorFn={async () => {
              setModal({
                ...modal,
                isVisible: true,
                title: `${
                  paymentSchedule.length === 0 ? 'Add' : 'Edit'
                } Payment Schedule`,
                body: (
                  <PEPaymentSchedule
                    closeModal={() => {
                      modal.onClose();
                    }}
                    setPaymentSchedule={setPaymentSchedule}
                    paymentSchedule={paymentSchedule}
                  />
                ),
              });
            }}
            text={`Payment${
              paymentSchedule.length > 0 ? `(${paymentSchedule.length})` : ''
            }`}
          />
        </div>

        <Modal {...modal} />
        <Form />
      </div>
    );
  };
};
