/* eslint-disable react-hooks/exhaustive-deps */
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { Client } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseItems } from '../../../hooks/inventory/item/use-items';
import { UseClientItem } from '../../../hooks/order/sales/client-item/use-client-item';
import { ItemInterface } from '../../../models/interfaces/inventory/item';
import { renderAddClientItemForm } from './form';

type RenderClientItemsProps = {
  useItems: UseItems;
  useClientItem: UseClientItem;
  useUserAuthorization: UseUserAuthorization;
  client: Client;
  loading: boolean;
  setClient: (s: Client) => Promise<Client>;
  itemService: ItemInterface;
};
export const renderClientItemList = ({
  client,
  loading,
  setClient,
  useItems,
  useClientItem,
  useUserAuthorization,
  itemService,
}: RenderClientItemsProps) => {
  return function ClientItemList() {
    const { syncSet: setClientItem } = useClientItem();
    const { get: getAllowedActions } = useUserAuthorization();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Items',
      menu: {
        actions: [
          {
            name: 'Add Client Item',
            auth: 'UI:BTN-ADD-VENDOR-ITEM:VIEW',
            behaviour: 'modal',
            show: () => {
              if (client.id) {
                return true;
              }
              return false;
            },
            modal: {
              title: 'Add Client Item',
              content: ({ onClose }) => {
                const ClientItemForm = renderAddClientItemForm({
                  client,
                  setClientItem,
                  useItems,
                  itemService,
                });
                return (
                  <>
                    <ClientItemForm onClose={onClose} />
                  </>
                );
              },
            },
          },
        ],
      },
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        // { name: 'Name',  },
        // { name: 'Status',  },
        { name: 'Item Name' },
        { name: 'Approval status' },
        { name: 'End Use' },
        { name: 'Client Location' },
        { name: 'Contact Person' },
        { name: 'Specifications' },
        // { name: 'Approval Documents',  },
      ],
    ];

    const tableBody: TableBody =
      client?.items?.map((l, idx) => ({
        cells: [
          { value: l.name },
          { value: l.status },
          {
            value: (
              <>
                {l.details?.endUse
                  ? l.details?.endUse.map((o) => `${o.name},`)
                  : '--'}
              </>
            ),
          },
          {
            value: `${l.details?.clientLocation?.name || '--'}`,
          },
          {
            value: `${l.details?.contactPerson?.name || '--'}`,
          },
          { value: '-' },
        ],
      })) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table header={tableHeader} body={tableBody} />
          </div>
        </div>
      ),
    };

    return (
      <>
        <div className='w-full space-y-3'>
          {loading ? (
            <Recat className='h-5 inline animate-pulse mx-4' />
          ) : (
            <Card
              header={cardHeader}
              body={cardBody}
              auth={{ actions: getAllowedActions().actions }}
            />
          )}
        </div>
      </>
    );
  };
};
