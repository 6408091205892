import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import {
  AttendanceType,
  CombinedAttDataType,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  Congratulations,
  DateSelector,
  MultiSelect,
  renderCardComponent,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import { FingerPrintIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseCurrentCompany } from '../../../../hooks/admin/company-admin/use-current-company';
import { UseCompanyGroupSettings } from '../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { UseCurrentLocation } from '../../../../hooks/admin/location-admin/use-current-location';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseCurrentUser } from '../../../../hooks/admin/user-admin/use-current-user';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseAttendance } from '../../../../hooks/hrd/attendance/use-attendance';
import { UseAttendances } from '../../../../hooks/hrd/attendance/use-attendances';
import { UseGatePass } from '../../../../hooks/hrd/employee/profile/gate-pass/use-gate-pass';
import { UseOvertime } from '../../../../hooks/hrd/employee/profile/overtime/use-overtime';
import { UseOvertimes } from '../../../../hooks/hrd/employee/profile/overtime/use-overtimes';
import { UseEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseLeave } from '../../../../hooks/hrd/leave/use-leave';
import { UseLeaves } from '../../../../hooks/hrd/leave/use-leaves';
import { UseOffSiteWorkHour } from '../../../../hooks/hrd/off-site-work-hours/use-off-site-work-hour';
import { UseRotationalShiftDay } from '../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-day';
import { UseShiftSchedules } from '../../../../hooks/planning/shift-schedule/use-shift-schedules';
import { AttendanceInterface } from '../../../../models/interfaces/hrd/attendance';
import { LeaveInterface } from '../../../../models/interfaces/hrd/leave';
import { LogRecordInterface } from '../../../../models/interfaces/planning/log-record';
import { UserRendererInterface } from '../../../common/fragments/user';
import { saveNewOvertime } from '../util/add-overtime';
import { renderBulkShiftChange } from '../util/bulk-shift-change';
import { renderActions } from './components/table/table-actions';
import { renderAttendanceBody } from './components/table/table-body';
import { Header } from './components/table/table-header';

export function createNewAttendancePage({
  attendanceService,
  useCurrentLocation,
  useCurrentUser,
  useAttendances,
  useAttendance,
  useCurrentUserRoles,
  useUserAuthorization,
  useEmployees,
  useLeaves,
  useEmployeeProfile,
  useLeave,
  useGatePass,
  useOvertimes,
  useOvertime,
  useCurrentCompany,
  useCompanyGroupSettings,
  useOffSiteWorkHour,
  useShiftSchedules,
  leaveService,
  logRecordService,
  useRotationalShiftDay,
  userRendererService,
}: {
  attendanceService: AttendanceInterface;
  useCurrentLocation: UseCurrentLocation;
  useCurrentUser: UseCurrentUser;
  useAttendances: UseAttendances;
  useAttendance: UseAttendance;
  useCurrentUserRoles: UseCurrentUserRoles;
  useUserAuthorization: UseUserAuthorization;
  useEmployees: UseEmployeeProfiles;
  useLeaves: UseLeaves;
  useEmployeeProfile: UseEmployeeProfile;
  useLeave: UseLeave;
  useGatePass: UseGatePass;
  useOvertimes: UseOvertimes;
  useOvertime: UseOvertime;
  useOffSiteWorkHour: UseOffSiteWorkHour;
  useCurrentCompany: UseCurrentCompany;
  useCompanyGroupSettings: UseCompanyGroupSettings;
  leaveService: LeaveInterface;
  logRecordService: LogRecordInterface;
  useShiftSchedules: UseShiftSchedules;
  useRotationalShiftDay: UseRotationalShiftDay;
  userRendererService: UserRendererInterface;
}): () => JSX.Element {
  let Table = renderTableComponent();
  let Card = renderCardComponent();
  const tableHeader = Header({ type: 'daybook' });

  return function NewAttendancePage(): JSX.Element {
    const {
      data: shiftSchedules,
      getAll: getShiftSchedules,
    } = useShiftSchedules();

    const today = moment().format('YYYY-MM-DD');

    // TODO: This needs to be removed
    const defaultWorkArea = {
      id: '9B4C5A4F-BA23-41C1-B33A-DB6F46E17886',
      name: 'Production Area',
    };

    const { syncSet: setRotationalShiftDay } = useRotationalShiftDay();

    const [date, setDate] = useState(
      localStorage.getItem('attendance-date') ||
        moment.utc().format('YYYY-MM-DD')
    );
    const [combinedData, setCombinedData] = useState<
      Array<CombinedAttDataType>
    >([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { data: currentLocation } = useCurrentLocation();
    const { data: currentUser } = useCurrentUser();
    const { getAllSync: getAllAttendanceSync } = useAttendances();
    const { syncSet: setAttendance } = useAttendance();
    const { data: currentUserRoles } = useCurrentUserRoles();
    const { get: getAllowedActions } = useUserAuthorization();
    const { getAllSync: getLeavesSync } = useLeaves();
    const { syncSet: setLeave } = useLeave();
    const { getSync: getEmployeeSync } = useEmployeeProfile();
    const { syncSet: setGatePass } = useGatePass();
    const { syncSet: setOffsite } = useOffSiteWorkHour();
    const { syncSet: setOvertime } = useOvertime();
    const [companyGroupSetting, setCompanyGroupSetting] = useState<
      CompanyGroupSetting
    >();
    const { data: currentCompany } = useCurrentCompany();
    const { getAllSync: getCompanyGroupSettings } = useCompanyGroupSettings();
    const [isHr, setIsHr] = useState<boolean>(false);
    const [isSystemAdmin, setIsSystemAdmin] = useState<boolean>(false);

    useEffect(() => {
      if (
        currentUserRoles?.length &&
        currentUserRoles.find((x) => x.name === 'HR Officer')
      ) {
        setIsHr(true);
      }

      setIsSystemAdmin(
        currentUserRoles?.find((x) => x.name === 'System-Admin') ? true : false
      );
    }, [currentUserRoles]);

    const attForSupervision = combinedData?.filter((d) => {
      if (d.finalized) {
        return false;
      }

      if (
        d.employeeProfile.details?.authorizations?.attendanceSupervisor?.id ===
          currentUser?.id ||
        d.shiftDay?.supervisor?.id === currentUser.id
      ) {
        return true;
      }

      return false;
    });

    const attForAuthorization = combinedData?.filter((d) => {
      if (d.finalized) {
        return false;
      }

      if (
        d.employeeProfile.details?.authorizations?.attendanceAuthorizer?.id ===
          currentUser?.id &&
        d.isLateForShift &&
        d.details &&
        !d.details.lateMark
      ) {
        return true;
      }

      return false;
    });

    const attUnderCurrentUser = combinedData.filter((d) => {
      if (
        d.employee.id === currentUser.id ||
        d.employeeProfile.details?.authorizations?.attendanceSupervisor?.id ===
          currentUser?.id ||
        d.employeeProfile.details?.authorizations?.attendanceAuthorizer?.id ===
          currentUser?.id ||
        d.employeeProfile.details?.authorizations?.attendanceSupervisorMonitor
          ?.id === currentUser?.id ||
        d.shiftDay?.supervisor?.id === currentUser.id
      ) {
        return true;
      }

      return false;
    });

    useEffect(() => {
      getCompanyGroupSettings().then((cgs) => {
        if (cgs.length > 0) {
          setCompanyGroupSetting(cgs[0]);
        }
      });
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (currentLocation.id) {
        getShiftSchedules({
          location: currentLocation.name.toLowerCase(),
        });
      }
      // eslint-disable-next-line
    }, [currentLocation]);

    async function fetchData(showLoader?: boolean) {
      if (showLoader) {
        setLoading(true);
      }
      attendanceService
        .getAttendanceByDate({
          date,
          location: currentLocation.name.toLowerCase(),
        })
        .then((res) => {
          setCombinedData(res);
          if (showLoader) {
            setLoading(false);
          }
        });
    }

    useEffect(() => {
      if (date) {
        const val = localStorage.getItem('attendance-date');
        if (val !== date) {
          localStorage.setItem('attendance-date', date);
        }
      }
    }, [date]);

    useEffect(() => {
      if (currentLocation.name && currentUserRoles?.length && currentUser.id) {
        fetchData(false);
      }
      // eslint-disable-next-line
    }, [currentLocation, currentUser, currentUserRoles]);

    useEffect(() => {
      if (currentLocation.name && currentUserRoles?.length && currentUser.id) {
        fetchData(true);
      }
      // eslint-disable-next-line
    }, [date]);

    const supMultiSelect: MultiSelect = {
      actions: [
        {
          name: 'Verify',
          show: () => true,
          behaviour: 'confirm',
          onConfirm: (entries) => {
            const data = attForSupervision.filter((x, idx) =>
              entries.includes(idx)
            );
            return {
              title: `Are you sure you want to approve verification for ${data.map(
                (x) => x.employee.name
              )}`,
              type: 'warning',
              onConfirm: async () => {
                for (const c of data) {
                  if (!c.verified || c.verified.verified !== 'yes') {
                    const verified = {
                      verified: 'yes',
                      verifiedAt: moment().utc().format(),
                      verifiedBy: {
                        id: currentUser.id,
                        name: currentUser.name,
                      },
                    };
                    if (c.id) {
                      await setAttendance({
                        id: c.id,
                        verified,
                      } as AttendanceType);
                    } else {
                      await setAttendance({
                        name: c.name,
                        date: c.date,
                        employee: c.employee,
                        status: c.status,
                        details: c.details,
                        verified: verified,
                        finalizedBy: {
                          id: currentUser.id,
                          name: currentUser.name,
                        },
                      } as AttendanceType);
                    }
                  }
                }
                await fetchData();
              },
            };
          },
        },
      ],
    };

    const authMultiSelect: MultiSelect = {
      actions: [
        {
          name: 'Approve Latemark',
          behaviour: 'confirm',
          show: () => true,
          onConfirm: (entries) => {
            const data = attForAuthorization.filter((x, idx) =>
              entries.includes(idx)
            );
            return {
              title: `Are you sure you want to approve latemark for ${data.map(
                (x) => x.employee.name
              )}`,
              type: 'warning',
              onConfirm: async () => {
                for (const c of data) {
                  let lateMark: any = {
                    status: 'approved',
                    reason: 'bulk approved',
                    approvedBy: currentUser,
                  };
                  setAttendance({
                    id: c.id,
                    details: {
                      lateMark,
                    },
                  } as any);
                }
                await fetchData();
              },
            };
          },
        },
        {
          name: 'Reject Latemark',
          behaviour: 'confirm',
          show: () => true,
          onConfirm: (entries) => {
            const data = attForAuthorization.filter((x, idx) =>
              entries.includes(idx)
            );
            return {
              title: `Are you sure you want to reject latemark for ${data.map(
                (x) => x.employee.name
              )}`,
              type: 'warning',
              onConfirm: async () => {
                for (const c of data) {
                  let lateMark: any = {
                    status: 'rejected',
                    reason: 'bulk rejected',
                    approvedBy: currentUser,
                  };
                  setAttendance({
                    id: c.id,
                    details: {
                      lateMark,
                    },
                  } as any);
                }
                await fetchData();
              },
            };
          },
        },
      ],
    };

    const BulkShiftChange = renderBulkShiftChange();

    const hrMultiSelect: MultiSelect = {
      actions: [
        {
          name: 'Bulk Shift Change',
          behaviour: 'modal',
          show: () => true,
          modal: {
            size: 'large',
            title: 'Bulk Shift Change',
            content: ({ entries, onClose }) => {
              return (
                <BulkShiftChange
                  setRotationalShiftDay={setRotationalShiftDay}
                  workArea={defaultWorkArea}
                  onClose={onClose}
                  combinedData={combinedData}
                  entries={entries}
                  shiftSchedules={shiftSchedules || []}
                />
              );
            },
          },
        },
      ],
    };

    const AttBody = renderAttendanceBody({
      userRendererService,
      currentUser,
      getAllAttendanceSync,
      setAttendance,
      currentUserRoles: currentUserRoles || [],
      useUserAuthorization,
      fetchData: fetchData,
    });

    const tableBody: TableBody = AttBody({
      type: 'daybook',
      list: combinedData.map((c) => ({ attendance: c })),
    });
    const filteredSupervisionTableBody: TableBody = AttBody({
      type: 'daybook',
      list: attForSupervision.map((c) => ({ attendance: c })),
    });
    const filteredAuthorizationTableBody: TableBody = AttBody({
      type: 'daybook',
      list: attForAuthorization.map((c) => ({ attendance: c })),
    });
    const filteredUnderCurrentUserTableBody: TableBody = AttBody({
      type: 'daybook',
      list: attUnderCurrentUser.map((c) => ({ attendance: c })),
    });

    const Actions = renderActions({
      isHr,
      setAttendance,
      leaveService,
      currentUser,
      useEmployees,
      useLeaves,
      getEmployeeSync,
      setGatePass,
      setOffsite,
      useOvertimes,
      saveNewOvertime,
      setOvertime,
      currentCompany,
      companyGroupSetting,
      fetchData,
      isSystemAdmin,
      attendanceService,
      logRecordService,
      getLeavesSync,
      setLeave,
    });
    const actions = Actions();

    function renderBody() {
      if (loading) {
        return (
          <div className='text-center'>
            <Recat className='h-5 inline animate-pulse mx-4' />
          </div>
        );
      }

      if (
        date > today ||
        moment(date).format('ddd MMM DD YYYY') === 'Invalid date'
      ) {
        return (
          <div className='w-full h-48 font-semibold flex justify-center items-center'>
            Invalid date, select date till {today}
          </div>
        );
      }
      return (
        <div>
          {filteredSupervisionTableBody.length ? (
            <>
              <Card
                disclosure={true}
                header={{
                  title: 'Requests for you (Supervisor)',
                }}
                body={{
                  type: 'jsx-component',
                  body: (
                    <Table
                      header={tableHeader}
                      body={filteredSupervisionTableBody}
                      multiSelect={supMultiSelect}
                      actions={actions}
                      auth={{ actions: getAllowedActions().actions }}
                    />
                  ),
                }}
              />
            </>
          ) : (
            <>
              {!isHr && !loading ? (
                <>
                  <Congratulations message='Congratulations! All your Supervision activities are completed. Great Job!' />
                </>
              ) : null}
            </>
          )}
          {filteredAuthorizationTableBody.length ? (
            <div className='my-2'>
              <Card
                disclosure={true}
                header={{
                  title: 'Requests for you (Authorizer)',
                }}
                body={{
                  type: 'jsx-component',
                  body: (
                    <Table
                      header={tableHeader}
                      body={filteredAuthorizationTableBody}
                      multiSelect={authMultiSelect}
                      actions={actions}
                      auth={{ actions: getAllowedActions().actions }}
                    />
                  ),
                }}
              />
            </div>
          ) : (
            <>
              {!isHr && !loading ? (
                <>
                  <Congratulations message='Congratulations! All your Authorizer activities are completed. Great Job!' />
                </>
              ) : null}
            </>
          )}
          {filteredUnderCurrentUserTableBody.length ? (
            <div className='my-2'>
              <Card
                disclosure={true}
                header={{
                  title: 'Attendances under your supervision / authorization',
                }}
                body={{
                  type: 'jsx-component',
                  body: (
                    <Table
                      header={tableHeader}
                      body={filteredUnderCurrentUserTableBody}
                      actions={actions}
                      auth={{ actions: getAllowedActions().actions }}
                    />
                  ),
                }}
              />
            </div>
          ) : null}

          {isHr ? (
            <div className='my-2'>
              <Card
                disclosure={true}
                header={{
                  title: 'All Attendances',
                }}
                body={{
                  type: 'jsx-component',
                  body: (
                    <Table
                      header={tableHeader}
                      multiSelect={hrMultiSelect}
                      body={tableBody}
                      auth={{ actions: getAllowedActions().actions }}
                      actions={actions}
                    />
                  ),
                }}
              />
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <div className='pb-52'>
        <Card
          header={{
            sticky: true,
            title: 'Employee Attendance',
            icon: (
              <FingerPrintIcon className='w-8 h-8 stroke-indigo-900 self-center' />
            ),
            subheading: (
              <div className='flex items-center'>
                <span className='text-blue-700 font-bold'>
                  {moment(date).format('ddd MMM DD YYYY')}
                </span>
              </div>
            ),
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <div className='flex w-fit p-1 space-x-2 items-center font-bolder right-5 top-24 bg-slate-300'>
                    <DateSelector
                      max={today}
                      initialState={date}
                      format='YYYY-MM-DD'
                      onChange={(date) => setDate(date)}
                    />
                  </div>
                ),
              },
            ],
            menu: {
              actions: [
                {
                  name: 'Auto-Finalize Day',
                  show: () => true,
                  auth: 'UI:BTN-ATTEN-FINALIZE:VIEW',
                  behaviour: 'confirm',
                  onConfirm: () => {
                    return {
                      title: 'Are you sure you want to run Auto-finalization?',
                      type: 'warning',
                      onConfirm: async () => {
                        await attendanceService.autofinalize(date);
                        toast.success(
                          `Autofinalization successfully triggered for ${date}`
                        );
                      },
                    };
                  },
                },
              ],
            },
          }}
          body={{
            type: 'jsx-component',
            body: renderBody(),
          }}
          auth={{ actions: getAllowedActions().actions }}
        />
      </div>
    );
  };
}
