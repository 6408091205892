import {
  renderCardComponent,
  renderTabsComponent,
} from '@erp_core/erp-ui-components';
import { nanoid } from 'nanoid';
import { UseCurrentLocation } from '../../../hooks/admin/location-admin/use-current-location';
import { UseGodown } from '../../../hooks/inventory/godown-inventory/use-godown';
import { UseGodownsTree } from '../../../hooks/inventory/godown-inventory/use-godowns-tree';
import { UseBatchItemGodown } from '../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UseBatchItemGodowns } from '../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { UseItem } from '../../../hooks/inventory/item/use-item';
import { UseItems } from '../../../hooks/inventory/item/use-items';
import { UseStockGroups } from '../../../hooks/inventory/stockgroup/use-stockgroups';
import { FileTransferInterface } from '../../../models/interfaces/fileTransfer/fileTransfer';
import { renderGodownItemTree } from './godown-item/godown-item-tree';
import { renderGodownTree } from './godown/godown-tree';
import { renderRangeSlidersUsePage } from './range-sliders';
import { renderStockGroupItemTree } from './stockgroup-item';

type CreateInventoryTree = {
  useGodownsTree: UseGodownsTree;
  useGodown: UseGodown;
  fileTransferService: FileTransferInterface;
  useCurrentLocation: UseCurrentLocation;
  useBatchItems: UseBatchItemGodowns;
  useBatchItem: UseBatchItemGodown;
  useStockGroups: UseStockGroups;
  useItems: UseItems;
  useItem: UseItem;
};

export function createInventoryTree({
  useGodownsTree,
  useCurrentLocation,
  useStockGroups,
  useItems,
  useBatchItem,
  useItem,
  useGodown,
  useBatchItems,
  fileTransferService,
}: CreateInventoryTree): () => JSX.Element {
  const Card = renderCardComponent();
  const Tabs = renderTabsComponent();
  const GodownTree = renderGodownTree({
    useGodownsTree,
    useCurrentLocation,
    useGodown,
    fileTransferService,
  });
  const GodownItemTree = renderGodownItemTree({
    useCurrentLocation,
    useGodownsTree,
    useBatchItem,
    useBatchItems,
    fileTransferService,
  });
  const StockGroupItemTree = renderStockGroupItemTree({
    useCurrentLocation,
    useItem,
    useItems,
    useStockGroups,
  });

  const RangeSlidersUsePage = renderRangeSlidersUsePage();

  const categories: Map<string, () => JSX.Element> = new Map<
    string,
    () => JSX.Element
  >([
    ['Godown', GodownTree],
    ['Godown-Items', GodownItemTree],
    ['Stockgroup-Items', StockGroupItemTree],
    ['Range-Sliders', RangeSlidersUsePage],
  ]);

  return function InventoryTree(): JSX.Element {
    return (
      <div className='h-full'>
        <Card
          header={{
            title: 'Inventory',
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div className='h-full'>
                <Tabs data={categories} keyTab={nanoid()} defaultTabIndex={2} />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
