import {
  CardBody,
  CardHeader,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { renderTextEditor } from '../../../../../components/html-editor';
import { UseCurrentCompanyGroup } from '../../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';

// eslint-disable-next-line
export function renderItemLabelTemplate({
  useFileTransfer,
  useCurrentCompanyGroup,
}: {
  useFileTransfer: UseFileTransfer;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
}): () => JSX.Element {
  const TextEditor = renderTextEditor();

  // eslint-disable-next-line
  return function ItemLabelTemplate() {
    // eslint-disable-next-line
    const { get: getTemplate, set: setTemplate } = useFileTransfer();
    const { currentCompanyGroup } = useCurrentCompanyGroup();
    const [data, setData] = useState<string>('');
    function onTemplateSave(data: any) {
      const file = new File(
        [data],
        `${currentCompanyGroup.id.toLowerCase()}.html`,
        { type: 'text/plain' }
      );
      setTemplate(
        `${
          currentCompanyGroup.id
        }/templates/item-label-template/${currentCompanyGroup.id.toLowerCase()}`,
        file,
        'html'
      );
    }

    function OpenItemLabelTemplate({
      path,
      onTemplateSave,
      getTemplate,
    }: {
      path: string;
      onTemplateSave: (data: any) => void;
      getTemplate: (path: string) => Promise<string>;
    }): JSX.Element {
      useEffect(() => {
        getTemplate(path)
          .then((res) => {
            setData(res);
          })
          .catch((e) => console.log(e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      <TextEditor initialHtml={data} onSave={onTemplateSave} />;
      return (
        <>
          <TextEditor initialHtml={data} onSave={onTemplateSave} />
        </>
      );
    }

    const Card = renderCardComponent();

    const cardHeader: CardHeader = {
      title: '',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <OpenItemLabelTemplate
            path={`${
              currentCompanyGroup.id
            }/templates/item-label-template/${currentCompanyGroup.id.toLowerCase()}.html`}
            onTemplateSave={onTemplateSave}
            getTemplate={getTemplate}
          />
        </>
      ),
    };

    return (
      <>
        <Card body={cardBody} header={cardHeader} />
      </>
    );
  };
}
