import { StatutoryReportBuilderType } from '@erp_core/erp-types/dist/types/modules/hrd/statutory-returns/report-builder';
import { ModalV2Props } from '@erp_core/erp-ui-components';
import { ReportEditValueForm } from './edit-value-form';

export function reportEdit({
  obj,
  propName,
  formType,
  options,
  showTextArea,
  modal,
  setModal,
  statutoryReport,
  setStatutoryReport,
}: {
  obj: any;
  propName: string;
  formType: 'text' | 'select';
  options?: Array<string>;
  showTextArea?: boolean;
  modal: ModalV2Props;
  setModal: (value: any) => void;
  setStatutoryReport: (value: any) => void;
  statutoryReport: StatutoryReportBuilderType;
}) {
  setModal({
    ...modal,
    isVisible: true,
    body: (
      <ReportEditValueForm
        formType={formType}
        options={options}
        showTextArea={showTextArea}
        onClose={(val) => {
          obj[propName] = val;
          setStatutoryReport({ ...statutoryReport });
          setModal({ ...modal, isVisible: false, body: <div />, title: '' });
        }}
        initialValue={obj[propName]}
        propName={propName}
      />
    ),

    title: `Edit ${propName.toUpperCase()}`,
  });
}
