import { BOMType } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseCurrentUser } from '../../../../hooks/admin/user-admin/use-current-user';
import { UseBatchItemAllocate } from '../../../../hooks/inventory/item/batch/use-batch-item-allocate';
import { UseBatchItemGodowns } from '../../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { UsePhysicalStock } from '../../../../hooks/inventory/item/stock/use-physical-stock';
import { UseVirtualStock } from '../../../../hooks/inventory/item/stock/use-virtual-stock';
import { UseVirtualItemAllocate } from '../../../../hooks/inventory/purchase/purchase-order/use-allocate-virtual-item';
import { UsePurchaseOrders } from '../../../../hooks/inventory/purchase/purchase-order/use-purchase-orders';
import { UseBom } from '../../../../hooks/order/work-order/bom/use-bom';
import { BOMInterface } from '../../../../models/interfaces/order/work-order/bom';
import { BomIcon } from '../bom-register/utils/bomIcons';
import { renderBomProfile } from './bom-profile';
import { renderBomHistory } from './history';

export type RenderBOMDetailsProp = {
  hooks: {
    useBom: UseBom;
    usePhysicalStock: UsePhysicalStock;
    useVirtualStock: UseVirtualStock;
    useBatchItemGodowns: UseBatchItemGodowns;
    useBatchGodownItemAllocate: UseBatchItemAllocate;
    usePurchaseOrders: UsePurchaseOrders;
    useVirtualItemAllocate: UseVirtualItemAllocate;
    useCurrentUser: UseCurrentUser;
  };
  services: {
    bomService: BOMInterface;
  };
};

export function renderBOMDetails({
  hooks: {
    useBom,
    usePhysicalStock,
    useVirtualStock,
    useBatchItemGodowns,
    useBatchGodownItemAllocate,
    usePurchaseOrders,
    useVirtualItemAllocate,
    useCurrentUser,
  },
  services: { bomService },
}: RenderBOMDetailsProp): () => JSX.Element {
  return function BOMDetails(): JSX.Element {
    const { id } = useParams();
    const { data, get: getBom } = useBom();
    const NewTabs = renderTabsV2Component();
    const Card = renderCardComponent();

    useEffect(() => {
      if (id) {
        getBom(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    function activityOf(bomType: BOMType) {
      switch (bomType) {
        case 'M-BOM':
          return 'maintaining';
        case 'F-BOM':
          return 'fabricating';
        case 'P-BOM':
          return 'manufacturing';
        case 'A-BOM':
          return 'asset';
        default:
          return '';
      }
    }

    function bomPattern(bomType: BOMType) {
      if (bomType === 'P-BOM') {
        return (
          <>
            &nbsp;&nbsp;|&nbsp;&nbsp; Prod Engineer:
            <a
              className='font-bold'
              href={`/users/profile/${data.engineer?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />
              {data?.engineer?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; Prod Officer:
            <a
              className='font-bold'
              href={`/users/profile/${data.officer?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />
              {data?.officer?.name || '??'}
            </a>
            <div className='border border-purple-600 rounded-md   text-purple-600 w-32'>
              Finished Item:{' '}
              <b>{data?.details?.finishedItem?.quantity || '??'}</b>
            </div>
          </>
        );
      } else
        return (
          <>
            &nbsp;&nbsp;|&nbsp;&nbsp; Design Engineer:{' '}
            <a
              className='font-bold'
              href={`/users/profile/${data.engineer?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />
              {data?.engineer?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; Site Engineer:{' '}
            <a
              className='font-bold'
              href={`/users/profile/${data.engineer?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />
              {data?.engineer?.name || '??'}
            </a>
            <div className='border border-purple-600 rounded-md  w-32 text-sm text-purple-600'>
              Finished Equipment:{' '}
              <b>{data?.details?.finishedItem?.quantity || '??'}</b>
            </div>
          </>
        );
    }

    if (!id) {
      return <div>Loading...</div>;
    }

    const header: CardHeader = {
      title: `${data.name || id} | ${data.rawItem?.name || '??'}`,
      icon: <div className='w-24 self-center'>{BomIcon[data.bomType]}</div>,
      subheading: (
        <>
          <div className='text-gray-700'>
            <span className='border rounded-md bg-indigo-100 text-indigo-900 px-1 py-0.5'>
              {data.details?.status || 'open'}
            </span>
            raised for {activityOf(data.bomType as BOMType)}{' '}
            <a
              className='font-bold'
              href={`/inventory/masters/items/${data.item?.id}/properties`}
              target='_blank'
              rel='noreferrer'
            >
              {data.item?.name || '??'}
            </a>
          </div>
          <div className='text-gray-700'>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${data.engineer?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' /> {data.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${data.engineer?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {data.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(data?.lastModifiedAt).fromNow()}
            {bomPattern(data.bomType as BOMType)}
            {
              <span className='border border-orange-600 rounded-md w-32 text-sm text-orange-600'>
                Required Quantity:{data.details?.rawMaterial?.quantity || '??'}
                {data.details?.rawMaterial?.uom}
              </span>
            }
          </div>
          <div className='flex space-x-2 my-2'></div>
        </>
      ),
    };

    // const categories: Map<string, () => JSX.Element> = new Map<
    //   string,
    //   () => JSX.Element
    // >([
    //   [
    //     'Details',
    // renderBomProfile({
    //   id,
    //   useBom,
    //   usePhysicalStock,
    //   useVirtualStock,
    //   useBatchItemGodowns,
    //   useBatchGodownItemAllocate,
    //   usePurchaseOrders,
    //   useVirtualItemAllocate,
    //   useCurrentUser,
    // }),
    //   ],
    //   ['History', renderBomHistory({ id, useBom })],
    // ]);

    const newCategories: DataArray = [
      {
        name: 'details',
        Fn: renderBomProfile({
          id,
          useBom,
          usePhysicalStock,
          useVirtualStock,
          useBatchItemGodowns,
          useBatchGodownItemAllocate,
          usePurchaseOrders,
          useVirtualItemAllocate,
          useCurrentUser,
        }),
      },
      {
        name: 'history',
        Fn: renderBomHistory({ id, useBom }),
      },
    ];

    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={true} />,
    };

    return <Card header={header} body={body} />;
  };
}
