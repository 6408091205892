import { Company } from '@erp_core/erp-types/dist/modules/admin';
import { RegistrationDocument } from '@erp_core/erp-types/dist/types/modules/admin/company';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFileViewerUploader,
  renderFormV2,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';

type CompanyRegistrationProps = {
  company: Company;
  setCompany: (s: Company) => Promise<Company>;
  useFileTransfer: UseFileTransfer;
};

export function createCompanyDocumentRegistration(): ({
  company,
  setCompany,
  useFileTransfer,
}: CompanyRegistrationProps) => JSX.Element {
  const Table = renderTableComponent();
  const Card = renderCardComponent();

  return function CompanyDocumentRegistration({
    company,
    setCompany,
    useFileTransfer,
  }: CompanyRegistrationProps): JSX.Element {
    function showuploadDoc(r: Partial<RegistrationDocument>, idx: number) {
      if (r.documentUrl) {
        return (
          <div>
            {!r.documentUrl ? 'Pending Proof' : 'Proof'}{' '}
            <FileViewerUploader
              mode='upload'
              url={r.documentUrl || ''}
              useFileTransfer={useFileTransfer}
              path={`${company.id}/company-registration/${idx}/proofs.pdf`.replaceAll(
                ' ',
                '-'
              )}
              type='private'
              onUpload={async (path) => {
                let registrationdoc = r.documentUrl || {};
                const documents = company.details.documents || [];
                if (registrationdoc) {
                  registrationdoc = path.url;
                  // eslint-disable-next-line
                  documents[idx].type = documents[idx].type;
                  // eslint-disable-next-line
                  documents[idx].number = documents[idx].number;
                  // eslint-disable-next-line
                  documents[idx].validityUpto = documents[idx].validityUpto;
                  documents[idx].documentUrl = path.url;

                  await setCompany({
                    id: company.id,
                    details: { documents },
                  } as Company);
                }
              }}
            />
          </div>
        );
      }
      return <div></div>;
    }

    const FileViewerUploader = renderFileViewerUploader();

    const cardHeader: CardHeader = {
      title: 'Registration Document Details',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add Registration',
              content: ({ onClose }) => {
                const Form = renderFormV2<Partial<RegistrationDocument>>({
                  formName: 'Add Registration',
                  fieldsData: [
                    {
                      label: 'Document Type',
                      property: 'type',
                      type: 'select',
                      options: [
                        { value: 'select', text: 'select' },
                        { value: 'pan-card', text: 'pan-card' },
                        { value: 'gst', text: 'gst' },
                        { value: 'ie-code', text: 'ie-code' },
                        { value: 'import-licence', text: 'import-licence' },
                      ],
                      required: true,
                    },
                    {
                      label: 'Number',
                      property: 'number',
                      type: 'input',
                      required: true,
                    },
                    {
                      label: 'Departments',
                      property: 'department',
                      type: 'tags',
                      required: false,
                    },
                    {
                      label: 'Validity Upto',
                      property: 'validityUpto',
                      type: 'date',
                      required: false,
                    },
                  ],
                  style: 'w-1/2',
                  initialFormState: {
                    type: '',
                    number: '',
                    department: [],
                    validityUpto: '',
                  },
                  onSubmit: async (res) => {
                    const documents = company.details.documents || [];
                    documents.push(res as RegistrationDocument);
                    await setCompany({
                      id: company.id,
                      details: { documents },
                    } as Company);
                    onClose();
                  },
                  mapTToU: (s) => {
                    return s as any;
                  },
                });

                return <Form />;
              },
            },
          },
        },
      ],
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Document Type' },
        { name: 'Number' },
        { name: 'Validity Upto' },
        { name: 'Department' },
        { name: 'Document' },
      ],
    ];

    const tableBody: TableBody = company.details?.documents
      ? company.details.documents.map((r, idx) => ({
          rowData: { document: r, idx: idx },
          cells: [
            { value: r.type || '' },
            { value: r.number?.toString() || '' },
            { value: r.validityUpto || '' },
            { value: r?.department.toString() || '' },
            { value: showuploadDoc(r, idx) || '-' },
          ],
        }))
      : [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          body={tableBody}
          actions={[
            {
              name: 'Edit',
              show: () => true,
              behaviour: 'modal',
              modal: {
                title: 'Edit',
                content: ({ data, onClose }) => {
                  const doc = data.document;
                  const idx = data.idx;

                  const Form = renderFormV2<RegistrationDocument>({
                    formName: 'Edit Registration',
                    fieldsData: [
                      {
                        label: 'Document Type',
                        property: 'type',
                        type: 'select',
                        options: [
                          { value: 'select', text: 'select' },
                          { value: 'pan-card', text: 'pan-card' },
                          { value: 'gst', text: 'gst' },
                          { value: 'ie-code', text: 'ie-code' },
                          { value: 'import-licence', text: 'import-licence' },
                        ],
                        required: true,
                      },
                      {
                        label: 'Number',
                        property: 'number',
                        type: 'input',
                        required: true,
                      },
                      {
                        label: 'Department',
                        property: 'department',
                        type: 'tags',
                        required: true,
                      },
                      {
                        label: 'Validity Upto',
                        property: 'validityUpto',
                        type: 'date',
                        required: true,
                      },
                    ],
                    style: 'w-1/2',
                    initialFormState: { ...doc } as any,
                    onSubmit: async (res) => {
                      const documents = company.details.documents || [];
                      console.log('documents', documents);
                      documents[idx].type = res.type || documents[idx].type;
                      documents[idx].number =
                        res.number || documents[idx].number;
                      documents[idx].department =
                        res.department || documents[idx].department;
                      documents[idx].validityUpto =
                        res.validityUpto || documents[idx].validityUpto;
                      documents[idx].documentUrl =
                        documents[idx].documentUrl ||
                        documents[idx].documentUrl;
                      console.log('documents', documents[idx]);

                      await setCompany({
                        id: company.id,
                        details: { documents },
                      } as Company);
                      onClose();
                    },
                    mapTToU: (s) => {
                      return s as any;
                    },
                  });

                  return <Form />;
                },
              },
            },
            {
              name: 'Attach Proof',
              show: () => true,
              behaviour: 'modal',
              modal: {
                title: 'Attach Proof',
                content: ({ data, onClose }) => {
                  return (
                    <FileViewerUploader
                      mode='upload'
                      url={data.document.documentUrl || ''}
                      useFileTransfer={useFileTransfer}
                      path={`${company.id}/company-registration/${data.idx}/proofs.pdf`.replaceAll(
                        ' ',
                        '-'
                      )}
                      type='private'
                      onUpload={async (path) => {
                        let registrationdoc = data.document.documentUrl || {};
                        const documents = company.details.documents || [];
                        if (registrationdoc) {
                          registrationdoc = path.url;
                          // eslint-disable-next-line
                          documents[data.idx].type = documents[data.idx].type;
                          documents[data.idx].number =
                            // eslint-disable-next-line
                            documents[data.idx].number;
                          documents[data.idx].validityUpto =
                            // eslint-disable-next-line
                            documents[data.idx].validityUpto;
                          documents[data.idx].documentUrl = path.url;

                          await setCompany({
                            id: company.id,
                            details: { documents },
                          } as Company);
                        }
                        onClose();
                      }}
                    />
                  );
                },
              },
            },
          ]}
        />
      ),
    };

    return (
      <>
        <Card header={cardHeader} body={cardBody} />
      </>
    );
  };
}
