import { CopyToClipboard as ClipboardIcon } from '@erp_core/erp-icons/icons/web/copy-to-clipboard';
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import { LoadingButton } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { SalaryInterface } from '../../../../models/interfaces/hrd/salary';
import { findParamType, formatParam } from '../utils/generate-salary';

export function RecalculateScreen({
  salaryId,
  salaryParam,
  salaryRevisionId,
  employee,
  companyGroupSetting,
  month,
  salaryService,
  onClose,
}: {
  salaryId: string;
  salaryParam: SalaryParamType;
  salaryRevisionId: string;
  employee: EmployeeProfileType;
  companyGroupSetting: CompanyGroupSetting;
  month: string;
  salaryService: SalaryInterface;
  onClose: () => void;
}): JSX.Element {
  const [currTab, setCurrTab] = useState<string>('earning');
  const [result, setResult] = useState<{
    oldSalary: {
      other: any;
      earning: any;
      deduction: any;
    };
    newSalary: {
      other: any;
      earning: any;
      deduction: any;
    };
    remarks: string[];
    summary: {
      action: 'disburse' | 'recover';
      employee: string;
      company: string;
      bankAccount: string;
      amount: number;
      month: string;
      category: string;
      ifsc: string;
      requestText: string;
    };
  } | null>(null);

  useEffect(() => {
    salaryService
      .recalculateSalary({
        salaryId,
        salaryParamId: salaryParam.id,
        salaryRevisionId,
        employeeId: employee.id,
        companyGroupSetting,
        month,
      })
      .then((res) => {
        console.log(res);
        if (res.result) {
          setResult(res.result);
        }
      });

    // eslint-disable-next-line
  }, []);
  return (
    <div>
      Recalculate Salary for {employee.name}
      {result ? (
        <div className='w-full'>
          <div className='flex w-full'>
            <div
              onClick={() => setCurrTab('earning')}
              className={`text-center border border-gray-200 p-1 basis-1/3 ${
                currTab === 'earning'
                  ? 'bg-blue-300 text-white'
                  : 'bg-white text-black'
              } cursor-pointer`}
            >
              Earning
            </div>
            <div
              onClick={() => setCurrTab('deduction')}
              className={`text-center border border-gray-200 p-1 basis-1/3 ${
                currTab === 'deduction'
                  ? 'bg-blue-300 text-white'
                  : 'bg-white text-black'
              } cursor-pointer`}
            >
              Deduction
            </div>
            <div
              onClick={() => setCurrTab('other')}
              className={`text-center border border-gray-200 p-1 basis-1/3 ${
                currTab === 'other'
                  ? 'bg-blue-300 text-white'
                  : 'bg-white text-black'
              } cursor-pointer`}
            >
              Hidden
            </div>
          </div>

          <div className='flex justify-center'>
            <div className='bg-slate-50 w-1/2'>
              <table>
                <thead>
                  <tr>
                    <th>Particulars</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {_.keys(result?.newSalary[currTab]).map((key) => {
                    const value = formatParam({
                      value: result?.newSalary[currTab][key],
                      metric: findParamType(
                        key,
                        salaryParam.details.inner,
                        'metric'
                      ),
                      scale: findParamType(
                        key,
                        salaryParam.details.inner,
                        'scale'
                      ),
                    });
                    return (
                      <tr key={key}>
                        <td>{key}</td>
                        <td className='text-right'>
                          {['string', 'number', 'boolean'].includes(
                            typeof value
                          )
                            ? value
                            : 'invalid-data'}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className='bg-slate-50 w-1/2'>
              <table>
                <thead>
                  <tr>
                    <th>Old Salary Particulars</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {_.keys(result.oldSalary[currTab]).map((key) => {
                    const value = formatParam({
                      value: result.oldSalary[currTab][key],
                      metric: findParamType(
                        key,
                        salaryParam.details.inner,
                        'metric'
                      ),
                      scale: findParamType(
                        key,
                        salaryParam.details.inner,
                        'scale'
                      ),
                    });
                    return (
                      <tr key={key}>
                        <td>{key}</td>
                        <td className='text-right'>
                          {['string', 'number', 'boolean'].includes(
                            typeof value
                          )
                            ? value
                            : 'invalid-data'}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className='text-center mx-auto'>
            <div className='flex'>
              <div className='flex-auto text-center animate-pulse text-red-500 text-lg'>
                {result.summary.action === 'disburse' ? (
                  <div>
                    <div>
                      Please disburse the following amount as correction in{' '}
                      {result.summary.month} salary:
                    </div>
                    <div>
                      <ul>
                        <li>Employee Name: {result.summary.employee}</li>
                        <li>Company: {result.summary.company}</li>
                        <li>Category: {result.summary.category}</li>
                        <li>Payment Amount: Rs. {result.summary.amount}</li>
                        <li>
                          Bank Account Number: {result.summary.bankAccount}
                        </li>
                        <li>IFSC: {result.summary.ifsc}</li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      Please deduct the following amount as excess amount was
                      paid in {result.summary.month} salary:
                    </div>
                    <div>
                      <ul>
                        <li>Employee Name: {employee.name}</li>
                        <li>Deduction Amount: Rs. {result.summary.amount}</li>
                        <li>
                          Advance to be deducted in: {result.summary.month}{' '}
                          Salary
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div className='flex-none'>
                <CopyToClipboard text={result.summary.requestText}>
                  <LoadingButton
                    behaviorFn={async () => {
                      toast.success('Successfully copied!');
                    }}
                    text={
                      <ClipboardIcon className='inline w-5 stroke-blue-400 cursor-pointer' />
                    }
                  />
                </CopyToClipboard>
              </div>
            </div>
            <LoadingButton
              behaviorFn={async () => {
                // Logic to auto-download the difference report
                await salaryService.recalculateSalary({
                  salaryId,
                  salaryParamId: salaryParam.id,
                  salaryRevisionId,
                  employeeId: employee.id,
                  companyGroupSetting,
                  month,
                  persist: true,
                });
                toast.success('Salary recalculated successfully');
                onClose();
              }}
              text='Save Recalculated Salary'
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
