import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { PrEditFormData } from '../types/pr-edit-form';

type PREditFormProps = {
  prData: PrEditFormData;
  onSave: (props: any) => Promise<void>;
};

type PREditFormType = {
  item: string;
  quantity: number;
  remarks: string;
  targetDate: string;
};

export function renderPREditForm({
  prData,
  onSave,
}: PREditFormProps): () => JSX.Element {
  return function PurchaseRequisitionEditForm(): JSX.Element {
    const onSubmit = async (form) => {
      await onSave(form);
    };

    const formProps: FormProps<Partial<PREditFormType>> = {
      fieldsData: [
        { property: 'item', label: ' Item:', type: 'input', readonly: true },
        { property: 'quantity', label: 'Quantity', type: 'number' },
        { property: 'targetDate', label: 'Target Date', type: 'date' },
        { property: 'remarks', label: 'Remarks', type: 'input' },
      ],
      allowUnchanged: true,
      initialFormState: {
        item: prData.item,
        quantity: prData.quantity,
        targetDate: prData.targetDate,
        remarks: '',
      },
      mapTToU: mapPFDToP,
      onSubmit: onSubmit,
    };

    const Form = renderFormV2<Partial<PREditFormType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<PREditFormType>>
    ): Partial<PREditFormType> {
      return {
        quantity: b.quantity as number,
        targetDate: b.targetDate as string,
        remarks: b.remarks as string,
      };
    }
  };
}
