import { Stockgroup } from '@erp_core/erp-icons/icons/web/stock-group';
import {
  StockGroup,
  StockGroupTempFilterType,
} from '@erp_core/erp-types/dist/types/modules/inventory/stockgroup';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import {
  CubeIcon,
  InformationCircleIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UsePaginatedStockGroups } from '../../../../hooks/inventory/stockgroup/use-paginated-stockgroups';
import { UseStockGroup } from '../../../../hooks/inventory/stockgroup/use-stockgroup';
import { UseStockGroups } from '../../../../hooks/inventory/stockgroup/use-stockgroups';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderAddStockGroupForm } from '../form/add-single-stockgroup-form';

export type RenderStockGroupListProps = {
  useStockGroups: UseStockGroups;
  useStockGroup: UseStockGroup;
  useUserAuthorization: UseUserAuthorization;
  usePaginatedStockGroups: UsePaginatedStockGroups;
  useUsers: UseUsers;
  userRendererService: UserRendererInterface;
};

export function renderStockGroupList({
  useStockGroups,
  useStockGroup,
  useUserAuthorization,
  usePaginatedStockGroups,
  useUsers,
  userRendererService,
}: RenderStockGroupListProps): () => JSX.Element {
  return function ItemList(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      StockGroup,
      StockGroupTempFilterType
    >();
    const { data: stockgroups, getAll: getAllStockGroup } = useStockGroups();
    const { get: getAllowedActions } = useUserAuthorization();

    useEffect(() => {
      getAllStockGroup();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { syncSet: setStockGroup } = useStockGroup();

    const saveStockGroup = async (form) => {
      if (!form.name || !form.description) {
        toast('Name and Description is required');
        return;
      }
      const save: Partial<StockGroup> = {};
      form.parentStockGroup?.id
        ? (save['parentStockGroupId'] = form.parentStockGroup.id)
        : (save['parentStockGroupId'] = 'root');
      save['name'] = form.name;
      save['description'] = form.description;
      await setStockGroup(save as StockGroup);
    };
    const tableHeader: TableHeader = [
      [
        {
          name: (
            <>
              <Stockgroup className='inline w-5 h-5 fill-white text-black' />
              StockGroup Name
            </>
          ),
        },
        {
          name: 'Version',
        },
        {
          name: (
            <>
              <InformationCircleIcon className='inline w-5 h-5' />
              Description
            </>
          ),
        },
        {
          name: (
            <>
              <CubeIcon className='inline w-5 h-5' />
              Parent Stock Group
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Updated By
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
      ],
    ];

    const bodyMapper = (i: StockGroup) => {
      const cells: Array<TableCell> = [
        {
          value: (
            <>
              {i.name || ''}{' '}
              {/* {i.details?.template ? (
                <DocumentIcon className='text-red-500 w-5 inline mx-1' />
              ) : null} */}
            </>
          ),
          link: `/inventory/masters/stock-groups/${i.id}/profile`,
        },
        { value: i.version },
        { value: i.description },
        {
          value:
            i.parentStockGroupId === 'root'
              ? 'ROOT'
              : (stockgroups || []).find((s) => s.id === i.parentStockGroupId)
                  ?.name || '',
        },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={i.lastModifiedBy?.id}
              name={i.lastModifiedBy?.name}
            />
          ),
        },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={i.createdBy?.id}
              name={i.createdBy?.name}
            />
          ),
        },
      ];

      return {
        cells,
      };
    };

    const Card = renderCardComponent();

    const header: CardHeader = {
      title: 'StockGroup List',
      actions: [
        {
          auth: 'UI:BTN-ADD-STOCKGROUP:VIEW',
          type: 'button',
          button: {
            name: 'Add Stock Group',
            behaviour: 'modal',
            modal: {
              title: 'Add Stock Group',
              content: ({ onClose }) => {
                const AddStockGroupForm = renderAddStockGroupForm({
                  useStockGroups: useStockGroups,
                  onSave: async (form) => {
                    await saveStockGroup(form);
                    onClose();
                  },
                });
                return <AddStockGroupForm />;
              },
            },
          },
        },
      ],
    };

    const filter: Filter<StockGroupTempFilterType> = {
      sortFields: [
        {
          key: 'StockGrpName',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        // {
        //   key: 'Status',
        //   value: 'all',
        //   type: 'drop-down',
        //   options: [
        //     { text: 'all', value: 'all' },
        //     { text: 'approved', value: 'approved' },
        //     { text: 'unapproved', value: 'unapproved' },
        //   ]
        // },
        {
          key: 'createdBy',
          type: 'search-select',
          value: '',
        },
        {
          key: 'lastModifiedBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: StockGroupTempFilterType) => {
        const filterData: StockGroupTempFilterType = {};

        // if (filterSelection.Status !== 'all') {
        //   filterData.status = filterSelection.Status;
        // }
        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }

        if (filterSelection.createdBy) {
          filterData.createdBy = filterSelection.createdBy;
        }

        if (filterSelection.lastModifiedBy) {
          filterData.lastModifiedBy = filterSelection.lastModifiedBy;
        }

        return filterData as StockGroupTempFilterType;
      },
      filterResources: {
        createdBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
        lastModifiedBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          bodyMapper={bodyMapper}
          auth={{ actions: getAllowedActions().actions }}
          useResources={() => {
            return {
              data: [],
              getAll: () => {},
              getAllSync: async (filter) => [],
              loading: false,
            };
          }}
          filter={filter}
          pagination={{
            enabled: true,
            usePaginatedResources: usePaginatedStockGroups,
          }}
        />
      ),
    };

    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
