/* eslint-disable react-hooks/exhaustive-deps */
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { LoadingButton, renderModal } from '@erp_core/erp-ui-components';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { UseCurrentCompanyGroup } from '../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseCompanyGroupSetting } from '../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { UseCompanyGroupSettings } from '../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { renderEditBomNamePatternForm } from './forms/edit-bom-name-pattern';

type CreateWorkOrderConfigPage = {
  useCompanyGroupSetting: UseCompanyGroupSetting;
  useCompanyGroupSettings: UseCompanyGroupSettings;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
};

export const createWorkOrderConfigPage = ({
  useCurrentCompanyGroup,
  useCompanyGroupSetting,
  useCompanyGroupSettings,
}: CreateWorkOrderConfigPage) => {
  return function () {
    const { data: allData, getAll } = useCompanyGroupSettings();

    const { currentCompanyGroup: companyGroup } = useCurrentCompanyGroup();

    const [data, setData] = useState<CompanyGroupSetting | null>();

    useEffect(() => {
      setData(
        (allData || []).length > 0 ? (allData ? allData[0] : null) : null
      );
    }, [allData]);

    useEffect(() => {
      companyGroup.id && getAll();
    }, [companyGroup]);

    const [modalState, setModalState] = useState({
      title: 'Edit BOM name pattern',
      isVisible: false,
      onClose: () => closeModal(),
      body: <div className='hidden' />,
    });

    const closeModal = () => {
      setModalState((ms) => ({ ...ms, isVisible: false }));
    };

    const handleEditBomnNamePatters = () => {
      setModalState((ms) => ({
        ...ms,
        isVisible: true,
        body: <EditBomNamePatternForm data={data as CompanyGroupSetting} />,
      }));
      return null;
    };

    const Modal = renderModal();
    const EditBomNamePatternForm = renderEditBomNamePatternForm({
      closeModal,
      useCompanyGroupSetting,
    });

    return (
      <div>
        <div>
          <div className='my-2'>
            <span className='font-bold text-md mr-3'>Bom name pattern: </span>
            {data?.details?.workOrder?.bom?.bomNamePattern || ''}
            <LoadingButton
              defaultStyle='ml-4'
              behaviorFn={async () => {
                handleEditBomnNamePatters();
              }}
              text={<PencilSquareIcon className='h-4 w-4' />}
            />
          </div>
        </div>
        <Modal
          title={modalState.title}
          isVisible={modalState.isVisible}
          body={modalState.body}
          onClose={modalState.onClose}
        />
      </div>
    );
  };
};
