/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import {
  CardBody,
  CardHeader,
  Modal,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { Popover } from '@headlessui/react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseCurrentUser } from '../../../hooks/admin/user-admin/use-current-user';
import { UseBatchItemAllocate } from '../../../hooks/inventory/item/batch/use-batch-item-allocate';
import { UseBatchItemGodowns } from '../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { UsePhysicalStock } from '../../../hooks/inventory/item/stock/use-physical-stock';
import { UseVirtualMFStock } from '../../../hooks/inventory/item/stock/use-virtual-manufacturing-stock';
import { UseSalesEnquiries } from '../../../hooks/order/sales/enquiry/use-enquiries';
import { UseManufacturingVouchers } from '../../../hooks/order/work-order/mf-voucher/use-manufacturing-vouchers';
import { UseVirtuallyReserveMfVoucher } from '../../../hooks/order/work-order/mf-voucher/use-virtually-reserve-manufacturing-voucher';
import { renderVirtualStockMf } from '../../common/fragments/virtual-stock-mf';
import { renderPhysicalStock } from '../../inventory/requisitions/bom-register/fragments/physical-stock';
import { renderEnquiryItemAction } from './components/enquiry-action';

function renderSalesItemEnquiryDetails({
  useEnquiries,
  usePhysicalStock,
  useBatchItemGodowns,
  useBatchGodownItemAllocate,
  useVirtualMfStock,
  useManufacturingVouchers,
  useVirtuallyReserveMfVoucher,
  useCurrentUser,
}: {
  useEnquiries: UseSalesEnquiries;
  usePhysicalStock: UsePhysicalStock;
  useBatchItemGodowns: UseBatchItemGodowns;
  useBatchGodownItemAllocate: UseBatchItemAllocate;
  useVirtualMfStock: UseVirtualMFStock;
  useManufacturingVouchers: UseManufacturingVouchers;
  useVirtuallyReserveMfVoucher: UseVirtuallyReserveMfVoucher;
  useCurrentUser: UseCurrentUser;
}) {
  return function SalesItemEnquiryDetails() {
    const PhysicalStock = renderPhysicalStock({ usePhysicalStock });

    const VirtualStock = renderVirtualStockMf({
      useVirtualStock: useVirtualMfStock,
    });

    // const { set: setSalesEnquiry } = useSalesEnquiry();
    const { getAll, data: enquiriesData } = useEnquiries();

    const itemEnqData = _.flatten(enquiriesData?.map((ed) => ed.enquiryItems));

    const [modalState, setModalState] = useState({
      visible: false,
      title: 'Response',
      content: <>Response</>,
    });

    useEffect(() => {
      // getData()
      getAll();
      // eslint-disable-next-line
    }, []);

    const handleSaveAllocation = () => {
      setModalState((ms) => ({ ...ms, visible: false }));
      toast('Allocated Sucessfully');
    };

    const showModal = ({
      title,
      content,
    }: {
      title: string;
      content: JSX.Element;
    }) => {
      setModalState({
        visible: true,
        title,
        content,
      });
    };
    const ItemAction = renderEnquiryItemAction({
      usePhysicalStock,
      showModal,
      handleSaveAllocation,
      setModalState,
      useBatchItemGodowns,
      useBatchGodownItemAllocate,
      useVirtualMfStock,
      useManufacturingVouchers,
      useVirtuallyReserveMfVoucher,
      useCurrentUser,
    });

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Item', rowSpan: 2 },
        { name: 'Respond By', rowSpan: 2 },
        { name: 'Quantity', rowSpan: 2 },
        { name: 'Grade', rowSpan: 2 },
        // { name: 'Needed By',  rowSpan: 2 },
        { name: 'remarks', rowSpan: 2 },
        { name: 'Attachments', rowSpan: 2 },
        { name: 'Physical stock', colSpan: 2 },
        { name: 'Virtual stock', colSpan: 2 },
        { name: 'Status', rowSpan: 2 },
        { name: 'Actions', rowSpan: 2 },
      ],
      [
        // Physical Stock
        {
          name: 'Reserved / Unallocated',

          style: 'w-14',
        },
        { name: 'Total' },

        // Virtual Stock
        {
          name: 'Reserved / Unallocated',

          style: 'w-14',
        },
        { name: 'Total' },
      ],
    ];

    const tableData =
      itemEnqData?.map((item) => ({
        cells: [
          {
            value: item.item.name,
            link: `/sales/enquiry/items/details/${item.item.id}`,
          },
          { value: item.enquiry.respondBy },
          { value: item.quantity },
          { value: item.details.specifications?.grade || 'NA' },
          //   { value: item.neededBy },
          { value: item.enquiry.details.remark },
          {
            value: (
              <div>
                {item.enquiry.details.attachments?.map((a, i) => (
                  <a key={i}>{a.name}, </a>
                ))}
              </div>
            ),
          },
          { value: <PhysicalStock id={item.item.id} uom='kg' view='combo' /> },
          { value: <PhysicalStock id={item.item.id} uom='kg' view='total' /> },
          {
            value: (
              <VirtualStock id={item.item.id} uom='kg' view='combo-reserved' />
            ),
          },
          { value: <VirtualStock id={item.item.id} uom='kg' view='total' /> },
          {
            value:
              moment() > moment(item.enquiry.respondBy) ? (
                <span className='bg-red-300'>expired</span>
              ) : (
                item.enquiry.status
              ),
          },
          {
            value: (
              <Popover className='relative'>
                {({ open }) => (
                  <>
                    <Popover.Button>
                      <PencilSquareIcon className='w-6 h-6 text-blue-600 cursor-pointer' />
                    </Popover.Button>
                    <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                      <ItemAction itemEnquiry={item} />
                    </Popover.Panel>
                  </>
                )}
              </Popover>
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Sales Item Enquiries',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: <Table header={tableHeader} body={tableBody} />,
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
        <Modal
          showModal={modalState.visible}
          setShowModal={() =>
            setModalState({ ...modalState, visible: !modalState.visible })
          }
          title={modalState.title}
          onOk={() => {}}
          style={{ width: '800px' }}
        >
          {modalState.content}
        </Modal>
      </div>
    );
  };
}

export default renderSalesItemEnquiryDetails;
