import { XlsxDownloadMapper } from '@erp_core/erp-ui-components';

export const TdsMapper: XlsxDownloadMapper = [
  {
    columnName: 'Employee Id',
    dataBinding: { property: 'empid' },
  },
  {
    columnName: 'Category',
    dataBinding: { property: 'category' },
  },
  {
    columnName: 'Name',
    dataBinding: { property: 'name' },
  },
  {
    columnName: 'Gross Earnings',
    dataBinding: { property: 'gross_pay' },
  },
  {
    columnName: 'Income Tax Deductions',
    dataBinding: { property: 'incometaxdeduction' },
  },
  {
    columnName: 'Bonus Disbursed',
    dataBinding: { property: 'bonus' },
  },
];
