import { renderModal } from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { UseCurrentCompanyGroup } from '../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { CGConfigRenderer } from '../config-renderer';
import { renderItemLabelTemplate } from './item-label';
import { renderMSDSTemplate } from './msds';
import { renderSpecificationsTemplate } from './specifications';

type CreateInventoryTemplatesPage = {
  useFileTransfer: UseFileTransfer;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
};

export const createInventoryTemplatesPage = ({
  useFileTransfer,
  useCurrentCompanyGroup,
}: CreateInventoryTemplatesPage) => {
  return function () {
    const [data] = useState<string>('');

    const [modalState, setModalState] = useState({
      title: 'MSDS Template',
      isVisible: false,
      onClose: () => closeModal(),
      body: (p?: any) => <div className='hidden' />,
    });

    const closeModal = () => {
      setModalState((ms) => ({ ...ms, isVisible: false }));
    };

    const handleMSDSTemplate = () => {
      const MSDSTemplate = renderMSDSTemplate({
        useFileTransfer,
        useCurrentCompanyGroup,
      });
      setModalState((ms) => ({
        ...ms,
        isVisible: true,
        title: 'MSDS Template',
        body: MSDSTemplate,
      }));

      return null;
    };

    const handleItemLabelTemplate = () => {
      const ItemLabelTemplate = renderItemLabelTemplate({
        useFileTransfer,
        useCurrentCompanyGroup,
      });
      setModalState((ms) => ({
        ...ms,
        isVisible: true,
        title: 'Item Label Template',
        body: ItemLabelTemplate,
      }));

      return null;
    };

    const handleSpecificationsTemplate = () => {
      const SpecificationsTemplate = renderSpecificationsTemplate({
        useFileTransfer,
        useCurrentCompanyGroup,
      });
      setModalState((ms) => ({
        ...ms,
        isVisible: true,
        title: 'Specifications Template',
        body: SpecificationsTemplate,
      }));

      return null;
    };

    const Modal = renderModal();

    type Configs = {
      settingName: string;
      editAction: () => void;
    };

    const configs: Configs[] = [
      {
        settingName: 'MSDS Template',
        editAction: handleMSDSTemplate,
      },
      {
        settingName: 'Specifications Template',
        editAction: handleSpecificationsTemplate,
      },
      {
        settingName: 'Item Label Template',
        editAction: handleItemLabelTemplate,
      },
    ];

    return (
      <div className='w-full'>
        <CGConfigRenderer configs={configs} loading={false} />
        <Modal
          title={modalState.title}
          isVisible={modalState.isVisible}
          body={<div />}
          onClose={modalState.onClose}
        >
          <modalState.body comGrpSetDetails={data} />
        </Modal>
      </div>
    );
  };
};
