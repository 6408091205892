import {
  CardBody,
  CardHeader,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { renderTextEditor } from '../../../../../components/html-editor';
import { UseCurrentCompanyGroup } from '../../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';

const TextEditor = renderTextEditor();
// eslint-disable-next-line
export function renderSpecificationsTemplate({
  useFileTransfer,
  useCurrentCompanyGroup,
}: {
  useFileTransfer: UseFileTransfer;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
}): () => JSX.Element {
  // eslint-disable-next-line
  return function SpecificationsTemplate() {
    // eslint-disable-next-line
    const { get: getTemplate, set: setTemplate } = useFileTransfer();
    const { currentCompanyGroup } = useCurrentCompanyGroup();
    function onTemplateSave(data: any) {
      const file = new File([data], 'item-specification-template.html', {
        type: 'text/plain',
      });
      setTemplate(
        `${currentCompanyGroup.id}/templates/item-specification-template`,
        file,
        'html'
      );
    }

    const Card = renderCardComponent();

    const cardHeader: CardHeader = {
      title: '',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <OpenSpecificationsTemplate
            path={`${currentCompanyGroup.id}/templates/item-specification-template.html`}
            onTemplateSave={onTemplateSave}
            getTemplate={getTemplate}
          />
        </>
      ),
    };

    if (!currentCompanyGroup.id) return <div />;
    return (
      <>
        <Card body={cardBody} header={cardHeader} />
      </>
    );
  };
}

function OpenSpecificationsTemplate({
  path,
  onTemplateSave,
  getTemplate,
}: {
  path: string;
  onTemplateSave: (data: any) => void;
  getTemplate: (path: string) => Promise<string>;
}): JSX.Element {
  const [data, setData] = useState<string>('');

  useEffect(() => {
    getTemplate(path)
      .then((res) => {
        setData(res);
      })
      .catch((e) => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <TextEditor initialHtml={data} onSave={onTemplateSave} />
    </>
  );
}
