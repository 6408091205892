import { XlsxDownloadMapper } from '@erp_core/erp-ui-components';

export const AdvanceRecoveryMapper: XlsxDownloadMapper = [
  {
    columnName: 'Employee Id',
    dataBinding: { property: 'empid' },
  },
  {
    columnName: 'Category',
    dataBinding: { property: 'category' },
  },
  {
    columnName: 'Name',
    dataBinding: { property: 'name' },
  },
  {
    columnName: 'Advance_1',
    dataBinding: { property: 'advances' },
  },
  {
    columnName: 'Advance_2',
    dataBinding: { property: 'otheradvances' },
  },
  {
    columnName: 'Total',
    dataBinding: { property: 'totaladvances' },
  },
];
