import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  RotationalShiftDayType,
  SwitchShiftScheduleType,
} from '@erp_core/erp-types/dist/modules/planning';
import {
  LoadingButton,
  ModalV2Props,
  MonthSelector,
  renderCardComponent,
  renderModal,
  renderSearchBox,
  renderTableComponent,
  TableBody,
  useConfirm,
} from '@erp_core/erp-ui-components';
import {
  ArrowPathIcon,
  ArrowsUpDownIcon,
  ClockIcon,
  CogIcon,
  MagnifyingGlassCircleIcon,
  PencilIcon,
  TrashIcon,
  UserIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { renderAuthWrapper } from '../../../../components/auth';
import { UseCurrentCompany } from '../../../../hooks/admin/company-admin/use-current-company';
import { UseCurrentLocation } from '../../../../hooks/admin/location-admin/use-current-location';
import { UseCurrentUser } from '../../../../hooks/admin/user-admin/use-current-user';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseOvertime } from '../../../../hooks/hrd/employee/profile/overtime/use-overtime';
import { UseOvertimes } from '../../../../hooks/hrd/employee/profile/overtime/use-overtimes';
import { UseEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseRotationalShiftDay } from '../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-day';
import { UseRotationalShiftDays } from '../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-days';
import { UseSwitchShift } from '../../../../hooks/hrd/switch-shift-schedule/use-switch-shift';
import { UseGodowns } from '../../../../hooks/inventory/godown-inventory/use-godowns';
import { UseShiftSchedules } from '../../../../hooks/planning/shift-schedule/use-shift-schedules';
import { UserRendererInterface } from '../../../common/fragments/user';
import { adjustEmployeeOvertime } from '../../attendance/leaves-book/forms/approve-leave-form';
import { AddShiftForm, AddShiftFormType } from './forms/add-shift-form';
import { renderChangeSupervisor } from './forms/change-supervisor';
import { SelectSwitchAdjustEmployeeForm } from './forms/select-switch-adjust-employee-form';
import { SelectSwitchEmployeeForm } from './forms/select-switch-employee-form';
import { SelectSwitchWeeklyOffEmployeeForm } from './forms/select-switch-weekly-off-form';

export type CreateEmployeeShiftsPageProps = {
  useEmployee: UseEmployeeProfile;
  useEmployees: UseEmployeeProfiles;
  useRotationalShiftDays: UseRotationalShiftDays;
  useRotationalShiftDay: UseRotationalShiftDay;
  useShiftSchedules: UseShiftSchedules;
  useOvertime: UseOvertime;
  useOvertimes: UseOvertimes;
  useGodowns: UseGodowns;
  useCurrentUser: UseCurrentUser;
  useSwitchShift: UseSwitchShift;
  useUserAuthorization: UseUserAuthorization;
  userRendererService: UserRendererInterface;
  useCurrentCompany: UseCurrentCompany;
  useCurrentLocation: UseCurrentLocation;
};

export function createEmployeeShiftsPage({
  useEmployee,
  useGodowns,
  useEmployees,
  useRotationalShiftDays,
  useOvertime,
  useShiftSchedules,
  useOvertimes,
  useRotationalShiftDay,
  useCurrentUser,
  useSwitchShift,
  useUserAuthorization,
  useCurrentCompany,
  useCurrentLocation,
  userRendererService,
}: CreateEmployeeShiftsPageProps): () => JSX.Element {
  let Table = renderTableComponent();
  let Card = renderCardComponent();
  const Modal = renderModal();
  const AuthWrapper = renderAuthWrapper({ useUserAuthorization });

  return function EmployeeShiftsPage(): JSX.Element {
    const { getSync: getEmployeeSync } = useEmployee();
    const {
      data: schedules,
      getAll: getAllShiftSchedules,
    } = useShiftSchedules();
    const { syncSet: setSwitchShift } = useSwitchShift();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { syncSet: setOvertime } = useOvertime();
    const {
      data: shiftDays,
      getAll: getAllShiftDays,
      getAllSync: getSyncShiftDays,
    } = useRotationalShiftDays();
    const {
      syncSet: setRotationalShiftDay,
      delete: deleteShift,
    } = useRotationalShiftDay();
    const { data: overtimes, getAll: getAllOvertimes } = useOvertimes();
    const [selectedEmployee, setSelectedEmployee] = useState<
      EmployeeProfileType
    >({} as EmployeeProfileType);
    const { data: currentLocation } = useCurrentLocation();
    const { data: currentCompany } = useCurrentCompany();
    const { data: currentUser } = useCurrentUser();
    const [month, setMonth] = useState(
      localStorage.getItem('shift-month') || moment.utc().format('YYYY-MM')
    );
    const { getAll: getAllWorkAreas, data: workAreas } = useGodowns();
    useEffect(() => {
      getAllWorkAreas();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      localStorage.setItem('shift-month', month);
      if (selectedEmployee.id) {
        getData();
      }
      // eslint-disable-next-line
    }, [month]);

    const { Confirm, openConfirm } = useConfirm();

    const [modal, setModal] = useState<ModalV2Props>({
      isVisible: false,
      title: '',
      onClose() {
        setModal({
          isVisible: false,
          title: '',
          onClose: modal.onClose,
          body: <div />,
        });
      },
      body: <div />,
    });

    const SearchBox = renderSearchBox<EmployeeProfileType>({
      useSearchValues: useEmployees,
      extraFilter: {
        crossGroup: 'true',
      },
      onSearchValueSelect: (u) => {
        setSelectedEmployee(u);
      },
    });

    useEffect(() => {
      getAllShiftSchedules();
      // eslint-disable-next-line
    }, []);

    const getData = () => {
      if (selectedEmployee.id) {
        getAllShiftSchedules();

        getAllShiftDays({
          employeeId: `equal::${selectedEmployee.id}`,
          from: `more-than::${month}-00`,
          to: `less-than::${month}-32`,
        });

        getAllOvertimes({
          employeeId: `equal::${selectedEmployee.id}`,
        });
      }
    };

    useEffect(() => {
      getData();
      // eslint-disable-next-line
    }, [selectedEmployee]);

    useEffect(() => {
      // console.log(overtimes);
    }, [overtimes]);

    const manualEdit = async (newData) => {
      const originalShift = shiftDays?.find((x) => x.date === newData.date);
      if (originalShift) {
        await setRotationalShiftDay({
          id: originalShift.id,
          details: {
            newShiftId: newData.shift,
          },
        } as any);

        await setSwitchShift({
          name: `${originalShift.employee.name}-${originalShift.employee.name}-${originalShift.date}`,
          currentEmployee: originalShift.employee,
          replaceEmployee: originalShift.employee,
          currentShiftDay: originalShift,
          replaceShiftDay: originalShift,
          status: 'pending',
          details: { reason: newData.reason, type: 'individual' },
          approvedBy: currentUser,
        } as SwitchShiftScheduleType);
        toast(
          'Change Edit request submitted successfully. Check Switch Shift!'
        );
        modal.onClose();
      }
    };

    const switchShift = async (
      emp1Shift: RotationalShiftDayType,
      emp2Shift: RotationalShiftDayType,
      reason: string,
      type: 'company-mandated' | 'individual'
    ) => {
      // openConfirm({
      //   title: 'Switch shift',
      //   message: `Shift of Employee ${emp1Shift.employee.name} will be switched with shift of employee ${emp2Shift.employee.name}. Switched by ${currentUser.name}`,
      //   onConfirm: async () => {
      //     // change employee 1 shift
      //     await setRotationalShiftDay({
      //       id: emp1Shift.id,
      //       employee: emp2Shift.employee,
      //       details: {
      //         shiftSwitches: [
      //           {
      //             reason,
      //             type,
      //             timeStamp: moment().utc().format(),
      //             switchedWithEmployee: emp1Shift.employee,
      //             switchedBy: { id: currentUser.id, name: currentUser.name }
      //           },
      //           ...(emp1Shift.details?.shiftSwitches || [])
      //         ]
      //       }
      //     } as RotationalShiftDayType)

      //     // change employee 2 shift
      //     await setRotationalShiftDay({
      //       id: emp2Shift.id,
      //       employee: emp1Shift.employee,
      //       details: {
      //         shiftSwitches: [
      //           {
      //             reason,
      //             type,
      //             timeStamp: moment().utc().format(),
      //             switchedWithEmployee: emp2Shift.employee,
      //             switchedBy: { id: currentUser.id, name: currentUser.name }
      //           },
      //           ...(emp2Shift.details?.shiftSwitches || [])
      //         ]
      //       }
      //     } as RotationalShiftDayType)
      //     modal.onClose();
      //     getData();
      //   }
      // });

      console.log(emp1Shift, emp2Shift, reason, type);
      await setSwitchShift({
        name: `${emp1Shift.employee.name}-${emp2Shift.employee.name}-${emp1Shift.date}`,
        currentEmployee: emp1Shift.employee,
        replaceEmployee: emp2Shift.employee,
        currentShiftDay: emp1Shift,
        replaceShiftDay: emp2Shift,
        status: 'pending',
        details: { reason, type },
        approvedBy: currentUser,
      } as SwitchShiftScheduleType);
      toast('Switch Shift request submitted successfully!');
      modal.onClose();
      getData();
    };

    //Replace Weekly Off logic
    const replaceWeeklyOff = async (
      emp1Shift: RotationalShiftDayType,
      emp2Shift: RotationalShiftDayType,
      emp1ReplaceShift: RotationalShiftDayType,
      emp2ReplaceShift: RotationalShiftDayType,
      reason: string,
      type: string
    ) => {
      openConfirm({
        title: 'Switch shift',
        message: ((
          <div>
            <div>Following will happen</div>
            <div className='text-center'>
              <div className='font-bold'>Date: {emp1Shift.date}</div>
              <div className='flex'>
                <div className='w-1/2 text-center border-r'>
                  <div>{emp1Shift.employee.name}</div>
                  <div>will be on weekly off</div>
                </div>
                <div className='w-1/2 text-center'>
                  <div>{emp2Shift.employee.name}</div>
                  <div>will be working</div>
                </div>
              </div>

              <div className='font-bold mt-3'>
                Replacement date: {emp1ReplaceShift.date}
              </div>
              <div className='flex'>
                <div className='w-1/2 text-center border-r'>
                  <div>{emp1ReplaceShift.employee.name}</div>
                  <div>will be working</div>
                </div>
                <div className='w-1/2 text-center'>
                  <div>{emp2ReplaceShift.employee.name}</div>
                  <div>will be on weekly off</div>
                </div>
              </div>
            </div>
          </div>
        ) as unknown) as string,
        onConfirm: async () => {
          // change employee 1 shift
          // await setRotationalShiftDay({
          //   id: emp1Shift.id,
          //   employee: emp2Shift.employee,
          //   details: {
          //     shiftSwitches: [
          //       {
          //         reason,
          //         type,
          //         timeStamp: moment().utc().format(),
          //         switchedWithEmployee: emp1Shift.employee,
          //         switchedBy: { id: currentUser.id, name: currentUser.name },
          //       },
          //       ...(emp1Shift.details?.shiftSwitches || []),
          //     ],
          //   },
          // } as RotationalShiftDayType);

          // // change employee 2 shift
          // await setRotationalShiftDay({
          //   id: emp2Shift.id,
          //   employee: emp1Shift.employee,
          //   details: {
          //     shiftSwitches: [
          //       {
          //         reason,
          //         type,
          //         timeStamp: moment().utc().format(),
          //         switchedWithEmployee: emp2Shift.employee,
          //         switchedBy: { id: currentUser.id, name: currentUser.name },
          //       },
          //       ...(emp2Shift.details?.shiftSwitches || []),
          //     ],
          //   },
          // } as RotationalShiftDayType);

          // // change employee 1 replacement shift
          // await setRotationalShiftDay({
          //   id: emp1ReplaceShift.id,
          //   employee: emp2ReplaceShift.employee,
          //   details: {
          //     shiftSwitches: [
          //       {
          //         reason,
          //         type,
          //         timeStamp: moment().utc().format(),
          //         switchedWithEmployee: emp1ReplaceShift.employee,
          //         switchedBy: { id: currentUser.id, name: currentUser.name },
          //       },
          //       ...(emp1ReplaceShift.details?.shiftSwitches || []),
          //     ],
          //   },
          // } as RotationalShiftDayType);

          // // change employee 2 replacement shift
          // await setRotationalShiftDay({
          //   id: emp2ReplaceShift.id,
          //   employee: emp1ReplaceShift.employee,
          //   details: {
          //     shiftSwitches: [
          //       {
          //         reason,
          //         type,
          //         timeStamp: moment().utc().format(),
          //         switchedWithEmployee: emp2ReplaceShift.employee,
          //         switchedBy: { id: currentUser.id, name: currentUser.name },
          //       },
          //       ...(emp2ReplaceShift.details?.shiftSwitches || []),
          //     ],
          //   },
          // } as RotationalShiftDayType);

          // console.log(emp1Shift, emp2Shift, emp1ReplaceShift, emp2ReplaceShift, reason, type)
          const empsShiftData = {
            name: `${emp1Shift.employee.name}-${emp2Shift.employee.name}-${emp1Shift.date}`,
            currentEmployee: emp1Shift.employee,
            replaceEmployee: emp2Shift.employee,
            currentShiftDay: emp1Shift,
            replaceShiftDay: emp2Shift,
            status: 'pending',
            details: { reason, type },
            approvedBy: currentUser,
          };

          // console.log(empsShiftData)
          await setSwitchShift(empsShiftData as SwitchShiftScheduleType);

          const empsReplaceShiftData = {
            name: `${emp2ReplaceShift.employee.name}-${emp1ReplaceShift.employee.name}-${emp2ReplaceShift.date}`,
            currentEmployee: emp2ReplaceShift.employee,
            replaceEmployee: emp1ReplaceShift.employee,
            currentShiftDay: emp2ReplaceShift,
            replaceShiftDay: emp1ReplaceShift,
            status: 'pending',
            details: { reason, type },
            approvedBy: currentUser,
          };
          // console.log(empsReplaceShiftData)
          await setSwitchShift(empsReplaceShiftData as SwitchShiftScheduleType);

          toast('Weekly Switch Shift request submitted successfully!');
          modal.onClose();
          getData();
        },
      });
    };

    // const adjustEmployeeOvertime = async (empShift: RotationalShiftDayType, re1: RotationalShiftDayType, re2?: RotationalShiftDayType, reason?: string, type?: string) => {

    //   openConfirm({
    //     title: 'Create Overtime',
    //     message: <div>
    //       <p>
    //         {re2?.id ? '4' : '8'} hours of overtime will be created for {re1.employee.name} on date {re1.date}
    //       </p>
    //       <p>
    //         { re2?.id ? `4 hours overtime will be created for ${re2.employee.name} on date ${re2.date}` : '' }
    //       </p>
    //       <p>
    //         {empShift.employee.name} will be marked on leave for date {empShift.date}
    //       </p>
    //     </div> as any,
    //     onConfirm: async () => {
    //       // create overtime for re1 8hours if rm2 is undefined and 4hours if rm2 is defined
    //       const re1Details = await getEmployeeSync(re1.employee.id);
    //       const emp1Overtime: Partial<OvertimeType> = {
    //         employee: re1.employee,
    //         company: re1Details.company,
    //         date: re1.date,
    //         hours: re2?.id ? '4' : '8',
    //         reason: reason || '',
    //         status: 'issued',
    //         issuedBy: { id: currentUser.id, name: currentUser.name },
    //       }
    //       await setOvertime(emp1Overtime as OvertimeType)

    //       // create overtime for re2 if re2 is defined
    //       if (re2 && re2.id) {
    //         const re2Details = await getEmployeeSync(re2.employee.id);
    //         const emp2Overtime: Partial<OvertimeType> = {
    //           employee: re2.employee,
    //           company: re2Details.company,
    //           date: re2.date,
    //           hours: '4',
    //           reason: reason || '',
    //           status: 'issued',
    //           issuedBy: { id: currentUser.id, name: currentUser.name },
    //         }
    //         await setOvertime(emp2Overtime as OvertimeType)
    //       }

    //       // update empShift attendance = 'leave'
    //       await setRotationalShiftDay({
    //         id: empShift.id,
    //         attendance: 'leave'
    //       } as RotationalShiftDayType);

    //       modal.onClose();
    //       getData();
    //     }
    //   })
    // }

    const onAddShift = async (formData: AddShiftFormType) => {
      if (!formData.supervisor.id) {
        // Fetch the rotational shift supervisor for that day
        const shiftDays = await getSyncShiftDays({
          date: formData.date,
        });

        const supervisor = shiftDays.find(
          (x) =>
            x.details.role === 'supervisor' &&
            x.details?.shiftId?.id === formData.shift.id
        );
        if (supervisor) {
          formData.supervisor = supervisor.employee;
        }
      }

      if (formData.supervisor.id) {
        const final: Partial<RotationalShiftDayType> = {
          employee: { id: selectedEmployee.id, name: selectedEmployee.name },
          date: formData.date,
          supervisor: formData.supervisor,
          attendance: formData.attendance || 'working',
          details: {
            shiftId: formData.shift,
            scheduleId: '',
            role: 'employee',
            workArea: formData.workArea,
          },
        };
        await setRotationalShiftDay(
          (final as unknown) as RotationalShiftDayType
        );
      } else {
        toast.error('Could not find a valid supervisor');
      }
      getData();
      modal.onClose();
    };

    const tableHeader = [
      [
        { name: 'Date' },
        { name: 'Attendance' },
        { name: 'Shift' },
        { name: 'WorkArea' },
        { name: 'Responsibility' },
        { name: 'Supervisor' },
        { name: 'Actions' },
      ],
    ];

    const tableBody: TableBody =
      _.sortBy(shiftDays || [], 'date').map((e) => {
        const sch = schedules?.find((x) => x.id === e.details.shiftId?.id);

        return {
          cells: [
            { value: <div>{e.date} </div> },
            { value: <div>{e.attendance}</div> },
            {
              value:
                e.attendance === 'working' ? (
                  <div>
                    <div>{sch?.name}</div>
                    <div className='text-xs text-gray-800 font-thin'>
                      ({sch?.startTime || '?'} to {sch?.endTime || '?'})
                    </div>
                    {overtimes
                      ?.filter((x) => x.date === e.date)
                      .map((x) => (
                        <div key={x.id} className='bg-slate-50 my-0.5'>
                          <ClockIcon className='w-4 h-4 inline mx-1' />{' '}
                          {x.hours} hours overtime
                        </div>
                      ))}
                  </div>
                ) : (
                  <></>
                ),
            },
            {
              value:
                e.attendance === 'working' ? (
                  <div>{e.details.workArea?.name}</div>
                ) : (
                  <></>
                ),
            },
            {
              value: (
                <div>
                  {e.attendance === 'working' ? (
                    <div>
                      <span className='capitalize'>
                        {e.details.role === 'employee' ? (
                          <UserIcon className='w-5 inline text-blue-500' />
                        ) : null}
                        {e.details.role === 'supervisor' ? (
                          <MagnifyingGlassCircleIcon className='w-5 inline text-blue-500' />
                        ) : null}
                        {e.details.role === 'employee'
                          ? e.details.roleName
                          : e.details.role}{' '}
                        Role
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ),
            },
            {
              value: (
                <div>
                  <userRendererService.userCard
                    link={true}
                    size='small'
                    id={e.supervisor.id}
                    name={e.supervisor.name}
                  />
                </div>
              ),
            },
            {
              value: (
                <div>
                  {e.attendance !== 'weekly-off' ? (
                    <div className='flex space-x-2'>
                      <AuthWrapper
                        action='UI:BTN-SWITCH-SHIFT:VIEW'
                        children={
                          <ArrowPathIcon
                            title='Switch shift'
                            className='w-5 h-5 text-blue-500 cursor-pointer hover:text-blue-700'
                            onClick={() => {
                              setModal({
                                ...modal,
                                isVisible: true,
                                title: 'Switching employees shifts',
                                body: (
                                  <div>
                                    <SelectSwitchEmployeeForm
                                      date={e.date}
                                      emp1Shift={e}
                                      useEmployees={useEmployees}
                                      useRotationalShiftDays={
                                        useRotationalShiftDays
                                      }
                                      schedules={schedules}
                                      switchShift={switchShift}
                                    />
                                  </div>
                                ),
                              });
                            }}
                          />
                        }
                      />

                      <AuthWrapper
                        action='UI:BTN-ADJUST-SHIFT:VIEW'
                        children={
                          <CogIcon
                            title='Assign Overtime'
                            className='w-5 h-5 text-pink-500 cursor-pointer hover:text-pink-700'
                            onClick={() => {
                              setModal({
                                ...modal,
                                isVisible: true,
                                title: `Adjusting overtime for ${selectedEmployee.name}'s unavailability`,
                                body: (
                                  <div>
                                    <SelectSwitchAdjustEmployeeForm
                                      targetedEmployee={selectedEmployee}
                                      markCurrentEmployeeOnLeave={false}
                                      editCurrentEmployeeOnLeave={true}
                                      date={e.date}
                                      emp1Shift={e}
                                      useEmployees={useEmployees}
                                      useRotationalShiftDays={
                                        useRotationalShiftDays
                                      }
                                      schedules={schedules}
                                      adjustEmployeeOvertime={(props) => {
                                        adjustEmployeeOvertime(props, {
                                          currentUser,
                                          setOvertime,
                                          getEmployeeSync,
                                          setRotationalShiftDay,
                                          openConfirm,
                                        });
                                      }}
                                    />
                                  </div>
                                ),
                              });
                            }}
                          />
                        }
                      />

                      <AuthWrapper
                        action='UI:BTN-SWAP-WEEKOFF:VIEW'
                        children={
                          <ArrowsUpDownIcon
                            title='switch weekly off'
                            className='w-5 h-5 text-green-500 cursor-pointer hover:text-green-700'
                            onClick={() => {
                              setModal({
                                ...modal,
                                isVisible: true,
                                title: `Switching ${selectedEmployee.name}'s weekly off (${e.date})`,
                                body: (
                                  <div>
                                    <SelectSwitchWeeklyOffEmployeeForm
                                      date={e.date}
                                      emp1Shift={e}
                                      useEmployees={useEmployees}
                                      useRotationalShiftDays={
                                        useRotationalShiftDays
                                      }
                                      schedules={schedules}
                                      //Replace weekly off new logic is passed
                                      replaceWeeklyOffs={replaceWeeklyOff}
                                    />
                                  </div>
                                ),
                              });
                            }}
                          />
                        }
                      />

                      <AuthWrapper
                        // action='UI:BTN-SWAP-WEEKOFF:VIEW'
                        action='UI:BTN-EDIT-SINGLE-SHIFT:VIEW'
                        children={
                          <PencilIcon
                            title='edit single shift'
                            className='w-5 h-5 text-blue-500 cursor-pointer hover:text-blue-700'
                            onClick={() => {
                              setModal({
                                ...modal,
                                title: 'Edit Shift',
                                isVisible: true,
                                body: (
                                  <AddShiftForm
                                    currentCompany={currentCompany}
                                    currentLocation={currentLocation}
                                    isEditMode={true}
                                    initialData={{
                                      date: e.date,
                                      shift: e.details.shiftId,
                                      attendance: e.attendance,
                                      workArea: e.details?.workArea,
                                      supervisor: e.supervisor?.name,
                                      reason: '',
                                    }}
                                    onSubmit={manualEdit}
                                    workAreas={workAreas || []}
                                    useWorkAreas={useGodowns}
                                    useShifts={useShiftSchedules}
                                    avoidDates={
                                      shiftDays?.map((s) => s.date) || []
                                    }
                                    supervisor={
                                      selectedEmployee.details.authorizations
                                        ?.attendanceSupervisor || {
                                        id: '',
                                        name: '',
                                      }
                                    }
                                  />
                                ),
                              });
                            }}
                          />
                        }
                      />

                      {e.details?.role === 'employee' ? (
                        <AuthWrapper
                          action='UI:BTN-CHG-SUP:VIEW'
                          children={
                            <UserPlusIcon
                              title='Change Supervisor to Employee Role'
                              className='w-5 h-5 text-purple-500 cursor-pointer hover:text-purple-700'
                              onClick={() => {
                                openConfirm({
                                  title:
                                    'Are your sure you want to change employee role to supervisor?',
                                  onConfirm: async () => {
                                    await setRotationalShiftDay({
                                      id: e.id,
                                      details: {
                                        role: 'supervisor',
                                      },
                                    } as any);
                                    toast.success(
                                      `${e.employee.name} was updated to Supervisor role...`
                                    );
                                  },
                                });
                              }}
                            />
                          }
                        />
                      ) : null}

                      <AuthWrapper
                        action='UI:BTN-CHG-SUP:VIEW'
                        children={
                          <MagnifyingGlassCircleIcon
                            title='Chnage Supervisor'
                            className='w-5 h-5 text-pink-500 cursor-pointer hover:text-pink-700'
                            onClick={() => {
                              const ChangeSupervisor = renderChangeSupervisor();

                              setModal({
                                ...modal,
                                isVisible: true,
                                title: 'Change Supervisor',
                                body: (
                                  <div>
                                    <ChangeSupervisor
                                      form={{
                                        date: e.date,
                                        name: e.employee.name,
                                        supervisor: e.supervisor,
                                      }}
                                      saveShift={async (form) => {
                                        if (form.supervisor?.id) {
                                          await setRotationalShiftDay({
                                            id: e.id,
                                            supervisor: form.supervisor,
                                          } as RotationalShiftDayType);
                                        }
                                        modal.onClose();
                                      }}
                                      useEmployees={useEmployees}
                                    />
                                  </div>
                                ),
                              });
                            }}
                          />
                        }
                      />

                      <AuthWrapper
                        action='UI:BTN-DEL-SHFT:VIEW'
                        children={
                          <TrashIcon
                            title='Delete Shift'
                            className='w-5 h-5 text-red-500 cursor-pointer hover:red-pink-700'
                            onClick={() => {
                              openConfirm({
                                type: 'warning',
                                onConfirm: async () => {
                                  deleteShift(e.id);
                                },
                                message:
                                  'Are you sure you want to delete the Shift',
                              });
                            }}
                          />
                        }
                      />
                    </div>
                  ) : (
                    <div className='flex space-x-2'>
                      <AuthWrapper
                        action='UI:BTN-DEL-SHFT:VIEW'
                        children={
                          <TrashIcon
                            title='Delete Shift'
                            className='w-5 h-5 text-red-500 cursor-pointer hover:red-pink-700'
                            onClick={() => {
                              openConfirm({
                                type: 'warning',
                                onConfirm: async () => {
                                  deleteShift(e.id);
                                },
                                message:
                                  'Are you sure you want to delete the Shift',
                              });
                            }}
                          />
                        }
                      />
                    </div>
                  )}
                </div>
              ),
            },
          ],
        };
      }) || [];

    const AddShiftButton = () => {
      // if shift type rotational than we can not add manual shift
      // if (selectedEmployee.details.jobProfile.shiftType === 'Rotational') return null;
      return (
        <AuthWrapper
          action='UI:BTN-EDIT-SINGLE-SHIFT:VIEW'
          children={
            <LoadingButton
              behaviorFn={async () => {
                setModal({
                  ...modal,
                  title: 'Add Shift',
                  isVisible: true,
                  body: (
                    <AddShiftForm
                      currentCompany={currentCompany}
                      currentLocation={currentLocation}
                      onSubmit={onAddShift}
                      workAreas={workAreas || []}
                      useWorkAreas={useGodowns}
                      useShifts={useShiftSchedules}
                      avoidDates={shiftDays?.map((s) => s.date) || []}
                      supervisor={
                        selectedEmployee.details.authorizations
                          ?.attendanceSupervisor || { id: '', name: '' }
                      }
                    />
                  ),
                });
              }}
              defaultStyle='text-blue-500 font-bold font-serif p-2 hover:text-blue-800'
              text='Add Shift'
            />
          }
        />
      );
    };

    return (
      <div className='pb-52'>
        <Card
          header={{
            title: `Employee Shift: ${
              selectedEmployee.id ? selectedEmployee.name : 'None Selected'
            }`,
            subheading: (
              <div className='flex items-center'>
                {selectedEmployee?.details?.jobProfile?.shiftType
                  ? `Shift ${selectedEmployee?.details?.jobProfile?.shiftType}`
                  : ''}
              </div>
            ),
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
                    <span className='font-bold truncate'>Select Month</span>
                    <MonthSelector
                      format='YYYY-MM'
                      initialState={month}
                      onChange={(m) => setMonth(m)}
                    />
                  </div>
                ),
              },
              {
                type: 'jsx',
                jsx: (
                  <div className='flex p-1 w-80 space-x-2 items-center justify-between font-bolder'>
                    <div className='font-bold flex-1'>Select</div>
                    <div>
                      <SearchBox />
                    </div>
                  </div>
                ),
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                {tableBody.length ? (
                  <div>
                    <AddShiftButton />
                    <Table header={tableHeader} body={tableBody} />
                  </div>
                ) : (
                  <div className='mx-auto p-5 text-center text-xl'>
                    {selectedEmployee.id ? (
                      <>
                        <div>
                          <div>
                            Seems like {selectedEmployee.name} does not have any
                            Schedule set!
                          </div>
                          <AddShiftButton />
                        </div>
                      </>
                    ) : (
                      <>Select an Employee to see Schedule</>
                    )}
                  </div>
                )}
                <Modal {...modal} />
                <Confirm />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
