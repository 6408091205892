/* eslint-disable no-unused-vars */

import { SalesEnquiryItem } from '@erp_core/erp-types/dist/modules/order';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UseCurrentUser } from '../../../../hooks/admin/user-admin/use-current-user';
import { UseBatchItemAllocate } from '../../../../hooks/inventory/item/batch/use-batch-item-allocate';
import { UseBatchItemGodowns } from '../../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { UsePhysicalStock } from '../../../../hooks/inventory/item/stock/use-physical-stock';
import { UseVirtualMFStock } from '../../../../hooks/inventory/item/stock/use-virtual-manufacturing-stock';
import { UseManufacturingVouchers } from '../../../../hooks/order/work-order/mf-voucher/use-manufacturing-vouchers';
import { UseVirtuallyReserveMfVoucher } from '../../../../hooks/order/work-order/mf-voucher/use-virtually-reserve-manufacturing-voucher';
import { AllocateFormRequestType, AllocationForm } from '../forms/allocate';
import { ReserveVirtuallyMv } from '../forms/virtually-reserve-form';

export function renderEnquiryItemAction({
  usePhysicalStock,
  showModal,
  handleSaveAllocation,
  setModalState,
  useBatchItemGodowns,
  useBatchGodownItemAllocate,
  useVirtualMfStock,
  useManufacturingVouchers,
  useVirtuallyReserveMfVoucher,
  useCurrentUser,
}: {
  usePhysicalStock: UsePhysicalStock;
  useBatchItemGodowns: UseBatchItemGodowns;
  showModal: (data: { title: string; content: JSX.Element }) => void;
  handleSaveAllocation: () => void;
  setModalState: (data: any) => void;
  useBatchGodownItemAllocate: UseBatchItemAllocate;
  useVirtualMfStock: UseVirtualMFStock;
  useManufacturingVouchers: UseManufacturingVouchers;
  useVirtuallyReserveMfVoucher: UseVirtuallyReserveMfVoucher;
  useCurrentUser: UseCurrentUser;
}): ({ itemEnquiry }: { itemEnquiry: SalesEnquiryItem }) => JSX.Element {
  return function ItemAction({
    itemEnquiry,
  }: {
    itemEnquiry: SalesEnquiryItem;
  }): JSX.Element {
    const { data: physicalStock, get: getPhysicalStock } = usePhysicalStock();
    const {
      data: virtualMfStockData,
      get: getVirtualMfStock,
    } = usePhysicalStock();

    const [actionList, setActionList] = useState<
      Array<{ title: string; modalTitle?: string; content?: JSX.Element }>
    >([]);

    useEffect(() => {
      getPhysicalStock(itemEnquiry.item.id);
      getVirtualMfStock(itemEnquiry.item.id);
      // eslint-disable-next-line
    }, []);

    // const qtyBalanced = getBalancedQuantity({referenceId: bom.id, total: bom.details.rawMaterial?.quantity || 0, usePurchaseOrders, useBatchItemGodowns}) ;
    const qtyBalanced = 100; // will change this on virtual allocation
    useEffect(() => {
      const psUnalloted = physicalStock.unAllocated
        ? physicalStock.unAllocated
        : 0; // this shoudl be via api

      const { unAllocated } = virtualMfStockData;

      const vrMfUnallocated = unAllocated || 0;

      const actions: Array<{
        title: string;
        modalTitle?: string;
        content?: JSX.Element;
      }> = [];

      // No actions if item enquiry expired
      if (moment() > moment(itemEnquiry.enquiry.respondBy)) {
        actions.push({ title: 'Close' });
        setActionList(actions);
        return;
      }

      if (qtyBalanced <= 0) {
        actions.push({ title: 'Mark done' });
      }

      if (psUnalloted > 0) {
        const req: AllocateFormRequestType = {
          id: itemEnquiry.id,
          rawItem: {
            id: itemEnquiry.item.id,
            name: itemEnquiry.item.name,
            quantity: itemEnquiry.quantity,
            uom: 'kg',
          },
          schedule: itemEnquiry.details.schedule,
        };
        actions.push({
          title: 'Allocate',
          modalTitle: `Allocate ${itemEnquiry.item.name}`,
          content: (
            <AllocationForm
              useCurrentUser={useCurrentUser}
              useBatchItemGodowns={useBatchItemGodowns}
              enquiryItemData={req}
              useBatchGodownItemAllocate={useBatchGodownItemAllocate}
              onSave={handleSaveAllocation}
              onCancel={() =>
                setModalState((ms) => ({ ...ms, visible: false }))
              }
            />
          ),
        });

        if (vrMfUnallocated > 0) {
          actions.push({
            title: 'Virtually Allocate',
            modalTitle: `Virtually Reserve ${itemEnquiry.item.name} for item enquiry ${itemEnquiry.enquiry.name}`,
            content: (
              <ReserveVirtuallyMv
                enquiryItemData={itemEnquiry}
                onSave={(e) => {
                  // console.log(e);
                }}
                onCancel={() => {}}
                useManufacturingVouchers={useManufacturingVouchers}
                useVirtuallyReserveMfVoucher={useVirtuallyReserveMfVoucher} // Todo create VirtualMvReserve hook
              />
            ),
          });
        }
      }

      setActionList(actions);
      // eslint-disable-next-line
    }, [physicalStock, qtyBalanced, virtualMfStockData]);

    return (
      <div>
        {actionList.map((a, index) => (
          <div
            key={index}
            className={
              index % 2 === 0
                ? 'cursor-pointer p-2'
                : 'bg-gray-200 cursor-pointer p-2'
            }
            onClick={() => {
              if (a.content) {
                showModal({
                  title: a.modalTitle || a.title,
                  content: a.content,
                });
              }
            }}
          >
            {a.title}
          </div>
        ))}
      </div>
    );
  };
}
