import { PolicyType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFileViewerUploader,
  renderTableComponent,
  TableActionsType,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseCurrentCompany } from '../../../hooks/admin/company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseCurrentUserRoles } from '../../../hooks/admin/role-admin/use-current-user-roles';
import { UseCurrentUser } from '../../../hooks/admin/user-admin/use-current-user';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UseEmployeeProfiles } from '../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UsePolicies } from '../../../hooks/hrd/insurance/policy/use-policies';
import { UsePolicy } from '../../../hooks/hrd/insurance/policy/use-policy';
import { UsePremium } from '../../../hooks/hrd/insurance/premiums/use-premium';
import { UsePremiums } from '../../../hooks/hrd/insurance/premiums/use-premiums';
import { UserRendererInterface } from '../../common/fragments/user';
import { PremiumsForm } from './components/premiums';
import { renderAddPolicyForm } from './form/policy-addform';

export type RenderPoliciesProps = {
  usePolicy: UsePolicy;
  usePolicies: UsePolicies;
  useEmployees: UseEmployeeProfiles;
  usePremium: UsePremium;
  usePremiums: UsePremiums;
  useCurrentUser: UseCurrentUser;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useCurrentUserRoles: UseCurrentUserRoles;
  useFileTransfer: UseFileTransfer;
  useCurrentCompany: UseCurrentCompany;
  useUserAuthorization: UseUserAuthorization;
  userRendererService: UserRendererInterface;
};

export function renderPolicies({
  usePolicy,
  usePolicies,
  useEmployees,
  usePremium,
  usePremiums,
  useCurrentUser,
  useCurrentCompanyGroup,
  useCurrentUserRoles,
  useFileTransfer,
  useCurrentCompany,
  useUserAuthorization,
  userRendererService,
}: RenderPoliciesProps): () => JSX.Element {
  return function Policies(): JSX.Element {
    const { data: currentUserRoles } = useCurrentUserRoles();

    const { data: company } = useCurrentCompany();
    const { currentCompanyGroup: companyGroup } = useCurrentCompanyGroup();

    const FileViewerUploader = renderFileViewerUploader();

    const { syncSet: setPolicy } = usePolicy();

    const { data: policies, getAll: getPolicies } = usePolicies();

    const { get: getAllowedActions } = useUserAuthorization();

    const { data: premiums, getAll: getPremiums } = usePremiums();

    useEffect(() => {}, [policies]);

    useEffect(() => {
      getPolicies();
      getPremiums();
      // eslint-disable-next-line
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Insurance',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Insurance',
            behaviour: 'modal',
            modal: {
              title: 'Add Insurance',
              content: ({ onClose }) => {
                const Form = renderAddPolicyForm({
                  useUsers: useEmployees,
                  usePolicies,
                });

                return (
                  <Form
                    onSave={async (form) => {
                      saveInsurance(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const saveInsurance = async (form: Partial<PolicyType>) => {
      try {
        const finalData = {
          ...form,
        } as PolicyType;
        await setPolicy(finalData);
        toast('Data added sucessfully');
        getPolicies();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Employee' },
        { name: 'Ins Company Name' },
        { name: 'Policy Number' },
        { name: 'Frequency' },
        { name: 'Amount' },
        { name: 'Last Premium Month' },
        { name: 'Document' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
        { name: 'Actions' },
      ],
    ];

    function addActions(): TableActionsType[] {
      return [
        {
          name: 'Attach Proof',
          show: ({ policy }: { policy: PolicyType }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            let eligibleToUndertake = false;
            if (currentUserRoles?.find((x) => x.name === 'HR Officer')) {
              eligibleToUndertake = true;
            }
            if (eligibleToUndertake) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Attach proof',
            content: ({
              data: { policy },
              onClose,
            }: {
              data: {
                policy: PolicyType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <FileViewerUploader
                    mode='upload'
                    url={policy.details?.document || ''}
                    useFileTransfer={useFileTransfer}
                    path={`${companyGroup.id}/${company.id}/insurance/${policy.id}/proofs.pdf`.replaceAll(
                      ' ',
                      '-'
                    )}
                    type='private'
                    onUpload={async (path) => {
                      let insurancedoc = policy.details?.document || {};
                      if (insurancedoc) {
                        insurancedoc = path.url;
                        await setPolicy({
                          id: policy.id,
                          details: insurancedoc,
                        } as PolicyType);
                      }
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Premiums',
          show: ({ policy }: { policy: PolicyType }) => {
            let eligibleToUndertake = false;
            if (currentUserRoles?.find((x) => x.name === 'HR Officer')) {
              eligibleToUndertake = true;
            }
            if (eligibleToUndertake) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Premiums',
            content: ({
              data: { policy },
              onClose,
            }: {
              data: {
                policy: PolicyType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <PremiumsForm
                    userRendererService={userRendererService}
                    usePremium={usePremium}
                    usePremiums={usePremiums}
                    useCurrentUser={useCurrentUser}
                    useCurrentCompanyGroup={useCurrentCompanyGroup}
                    policyId={policy.id}
                  />
                </div>
              );
            },
          },
        },
      ];
    }

    //   setModal({
    //     ...modal,
    //     modalStyle: {
    //       minWidth: 'min-w-[70%]',
    //       minHeight: 'min-h-[70%]',
    //     },
    //     title: 'Premiums',
    //     isVisible: true,
    //     body: (
    //       <PremiumsForm
    //         usePremium={usePremium}
    //         usePremiums={usePremiums}
    //         useCurrentUser={useCurrentUser}
    //         useCurrentCompanyGroup={useCurrentCompanyGroup}
    //         policyId={r.id}
    //       />
    //     ),
    //   });
    // };

    function showuploadDoc(r: PolicyType) {
      if (r.details) {
        return (
          <div>
            {!r.details.document ? 'Pending Proof' : 'Proof'}{' '}
            <FileViewerUploader
              mode='upload'
              url={r.details.document || ''}
              useFileTransfer={useFileTransfer}
              path={`${companyGroup.id}/${company.id}/insurance/${r.id}/proofs.pdf`.replaceAll(
                ' ',
                '-'
              )}
              type='private'
              onUpload={async (path) => {
                let insurancedoc = r.details?.document || {};
                if (insurancedoc) {
                  insurancedoc = path.url;
                  await setPolicy({
                    id: r.id,
                    details: insurancedoc,
                  } as PolicyType);
                }
              }}
            />
          </div>
        );
      }
      return <div></div>;
    }

    function showLastPremiumMonth(id: string) {
      try {
        const lastPremiumMonth = premiums?.filter((x) => x.policy.id === id);

        const premiumRcds = _.orderBy(
          lastPremiumMonth,
          (e) => [moment(e.monthYear).format('YYYYMM')],
          ['desc', 'desc']
        );

        return (
          <div>
            <>{premiumRcds[0].monthYear ? premiumRcds[0].monthYear : '-'}</>
          </div>
        );
      } catch (e) {
        return <>-</>;
      }
    }

    const tableData =
      policies?.map((r, idx) => ({
        rowData: {
          policy: r,
        },
        cells: [
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.employee?.id}
                name={r.employee.name}
              />
            ),
          },
          { value: r.insuranceCompany },
          { value: r.policyNumber },
          { value: r.frequency },
          { value: r.amount },
          { value: showLastPremiumMonth(r.id) },
          { value: showuploadDoc(r) },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.createdBy?.id}
                name={r.createdBy?.name}
                extraInfo={moment.utc(r.createdAt).fromNow()}
              />
            ),
          },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.lastModifiedBy?.id}
                name={r.lastModifiedBy?.name}
                extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
              />
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={addActions()}
              auth={{ actions: getAllowedActions().actions }}
            />
          </div>
        </div>
      ),
    };
    return (
      <>
        <Card header={cardHeader} body={cardBody} />
      </>
    );
  };
}
