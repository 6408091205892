import { BarGraph, PieGraph, PolarGraph } from '@erp_core/erp-reports';
import { DashboardType } from '@erp_core/erp-types/dist/modules/analytics';
import {
  MonthSelector,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Bar, Pie, PolarArea } from 'react-chartjs-2';
import { CountUp } from 'use-count-up';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseCurrentUser } from '../../../../hooks/admin/user-admin/use-current-user';
import { UseDashboards } from '../../../../hooks/analytics/dashboard/use-dashboards';
import { DashboardInterface } from '../../../../models/interfaces/analytics/dashboard';
import {
  BarGraphData,
  ListData,
  NumericData,
  PolarGraphData,
} from '../../../../models/types/analytics/hrd-dashboard';
import { renderEditUserSettings } from '../forms/edit-user-dashboard-settings';

type ReportDataType = {
  key: string;
  type:
    | 'unknown'
    | 'invalid'
    | 'numeric'
    | 'bar-graph'
    | 'polar-graph'
    | 'pie-graph'
    | 'list';
  data:
    | 'loading'
    | 'not-configured'
    | 'invalid-data'
    | NumericData
    | BarGraphData
    | PolarGraphData
    | ListData;
};

export function createDashboardRenderer({
  useDashboards,
  useCurrentUserRoles,
  useCurrentUser,
  dashboardService,
}: {
  useDashboards: UseDashboards;
  useCurrentUserRoles: UseCurrentUserRoles;
  useCurrentUser: UseCurrentUser;
  dashboardService: DashboardInterface;
}) {
  const Card = renderCardComponent();
  return function DashboardRenderer({
    module,
  }: {
    module: string;
  }): JSX.Element {
    const [month, setMonth] = useState(
      localStorage.getItem('attendance-month') || moment.utc().format('YYYY-MM')
    );
    const { data: reports, getAll: getReports } = useDashboards();
    const { data: userRoles } = useCurrentUserRoles();
    const { data: currentUser } = useCurrentUser();
    const [currentUserSettings, setCurrentUserSettings] = useState<any>({});
    const [filteredReports, setFilteredReports] = useState<DashboardType[]>([]);
    const [reportData, setReportData] = useState<{
      [key: string]: ReportDataType;
    }>({});
    const [finalReports, setFinalReports] = useState<DashboardType[]>([]);
    const [subModules, setSubModules] = useState<Array<string>>([]);
    const [selectedSubModule, setSelectedSubModule] = useState<string>('All');

    useEffect(() => {
      getReports({ module: module.toLowerCase(), status: 'published' });
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const validUserRoles: Array<string> = userRoles?.map((x) => x.id) || [];
      if (reports?.length) {
        // Logic to first check how many reports are published
        const filtered = reports.filter((x) => {
          const roles = (x?.roles || [])?.map((y) => y.id);
          if (_.intersection(roles, validUserRoles).length) {
            return true;
          }

          return false;
        });

        setFilteredReports(filtered);

        setSubModules(['All', ..._.uniq(reports.map((x) => x.subModule))]);
      }
    }, [reports, currentUserSettings, userRoles]);

    function updateCurrentUserSettings() {
      if (currentUser?.id) {
        const settings = JSON.parse(
          localStorage.getItem('user-dashboard-settings') || '{}'
        );
        setCurrentUserSettings(settings[currentUser.id] || {});
      }
    }

    useEffect(() => {
      updateCurrentUserSettings();
      // eslint-disable-next-line
    }, [currentUser]);

    function fetchReportData(finalReports: Array<DashboardType>) {
      for (const rep of finalReports) {
        if (rep.details?.url) {
          let finalUrl = rep.details.url
            .replace(':report-key', _.kebabCase(rep.name))
            .replace(':month', month);

          dashboardService
            .getReportData(finalUrl, {
              key: _.kebabCase(rep.name),
              date: moment().format('YYYY-MM-DD'),
              month: month,
            })
            .then((res) => {
              if (res.status !== 200) {
                setReportData((x) => {
                  const newData: { [key: string]: ReportDataType } = JSON.parse(
                    JSON.stringify(x)
                  );
                  newData[_.kebabCase(rep.name)].data = 'invalid-data';
                  newData[_.kebabCase(rep.name)].type = 'invalid';
                  return newData;
                });
                return;
              }

              setReportData((x) => {
                const newData: { [key: string]: ReportDataType } = JSON.parse(
                  JSON.stringify(x)
                );
                newData[_.kebabCase(rep.name)].data = res.data.data;
                newData[_.kebabCase(rep.name)].type = res.data.type;
                return newData;
              });
            });
        }
      }
    }

    useEffect(() => {
      const final: Array<DashboardType> = [];
      filteredReports.forEach((x) => {
        if (
          currentUserSettings[module] &&
          currentUserSettings[module][x.name] &&
          currentUserSettings[module][x.name].enabled === 'yes'
        ) {
          const y: DashboardType = JSON.parse(JSON.stringify(x));
          if (!y.details.displaySettings) {
            y.details.displaySettings = {
              rowSpan: 0,
              colSpan: 0,
              sequence: 0,
            };
          }
          y.details.displaySettings.colSpan =
            (currentUserSettings[module] &&
              currentUserSettings[module][x.name] &&
              currentUserSettings[module][x.name].colSpan) ||
            y.details.displaySettings.colSpan;
          y.details.displaySettings.rowSpan =
            (currentUserSettings[module] &&
              currentUserSettings[module][x.name] &&
              currentUserSettings[module][x.name].rowSpan) ||
            y.details.displaySettings.rowSpan;
          y.details.displaySettings.sequence =
            (currentUserSettings[module] &&
              currentUserSettings[module][x.name] &&
              currentUserSettings[module][x.name].sequence) ||
            y.details.displaySettings.sequence;

          final.push(y);
        }
      });

      const repData: { [key: string]: ReportDataType } = {};
      final.forEach((x) => {
        repData[_.kebabCase(x.name)] = {
          key: _.kebabCase(x.name),
          data: 'not-configured',
          type: 'unknown',
        };
      });
      setReportData(repData);
      const sorted = final.sort(
        (a, b) =>
          a.details?.displaySettings.sequence -
          b.details.displaySettings.sequence
      );
      setFinalReports(sorted);

      fetchReportData(sorted);
      // eslint-disable-next-line
    }, [filteredReports, currentUserSettings, month]);

    if (!reports || reports.length === 0) {
      return (
        <div className='text-center my-24 text-6xl capitalize text-l9-highlight animate-pulse'>
          {module} Module
        </div>
      );
    }

    return (
      <Card
        header={{
          title: <div className='capitalize'> {module} Dashboard</div>,
          subheading: (
            <div className='my-2 cursor-pointer'>
              <span className='mx-2 italic font-semibold'>Sub-modules: </span>
              {subModules.map((x, idx) => (
                <span
                  key={idx}
                  onClick={() => {
                    setSelectedSubModule(x);
                  }}
                  className={`mx-1 border border-gray-200 p-1 rounded-md capitalize ${
                    selectedSubModule === x ? 'bg-indigo-100' : 'bg-gray-50'
                  }`}
                >
                  {x}
                </span>
              ))}
            </div>
          ),
          actions: [
            {
              type: 'jsx',
              jsx: (
                <div>
                  <MonthSelector
                    format='YYYY-MM'
                    initialState={month}
                    onChange={(m) => {
                      setMonth(m);
                      localStorage.setItem('attendance-month', m);
                    }}
                  />
                </div>
              ),
            },
            {
              type: 'button',
              button: {
                name: 'Settings',
                style: 'border border-green-500 text-green-500 w-28',
                suffix: (
                  <AdjustmentsVerticalIcon className='w-5 text-green-800 inline' />
                ),
                behaviour: 'modal',
                modal: {
                  size: 'large',
                  title: 'Settings',
                  content: ({ onClose }) => {
                    const EditUserSettings = renderEditUserSettings();
                    return (
                      <EditUserSettings
                        userSettings={currentUserSettings}
                        module={module}
                        filteredReports={filteredReports}
                        update={(data) => {
                          // console.log('final Data', data)
                          const settings = JSON.parse(
                            localStorage.getItem('user-dashboard-settings') ||
                              '{}'
                          );
                          settings[currentUser.id] = data;

                          // console.log('data to save', settings);
                          localStorage.setItem(
                            'user-dashboard-settings',
                            JSON.stringify(settings)
                          );
                          updateCurrentUserSettings();
                          onClose();
                        }}
                      />
                    );
                  },
                },
              },
            },
          ],
        }}
        body={{
          type: 'jsx-component',
          body: (
            <div>
              <div className='grid grid-cols-1 md:grid-cols-6 gap-4 w-full'>
                {finalReports.map((x) =>
                  selectedSubModule === 'All' ||
                  x.subModule === selectedSubModule ? (
                    <div
                      key={x.id}
                      className={`min-h-48 border border-gray-200 row-span-${x.details.displaySettings.rowSpan} col-span-${x.details.displaySettings.colSpan}`}
                    >
                      {reportData[_.kebabCase(x.name)].type === 'unknown' &&
                      reportData[_.kebabCase(x.name)].data === 'loading' ? (
                        <div className='h-full flex justify-center items-center flex-col'>
                          <div className='text-lg'>
                            {_.startCase(x.name)}
                            Loading...
                          </div>
                        </div>
                      ) : null}

                      {['unknown', 'invalid'].includes(
                        reportData[_.kebabCase(x.name)].type
                      ) &&
                      reportData[_.kebabCase(x.name)].data ===
                        'not-configured' ? (
                        <div className='h-full flex justify-center items-center flex-col'>
                          <div className='text-lg'>
                            {_.startCase(x.name)}
                            Invalid Configuration
                          </div>
                        </div>
                      ) : null}

                      {reportData[_.kebabCase(x.name)].type !== x.type ? (
                        <div className='h-full flex justify-center items-center flex-col'>
                          <div className='text-lg'>
                            {_.startCase(x.name)}
                            Invalid Report Type
                          </div>
                          <div>
                            Expected {x.type} got{' '}
                            {reportData[_.kebabCase(x.name)].type}{' '}
                          </div>
                        </div>
                      ) : null}

                      {reportData[_.kebabCase(x.name)].type === 'bar-graph' ? (
                        <BarGraph
                          title={_.startCase(
                            reportData[_.kebabCase(x.name)].type
                          )}
                          components={{ BarGraph: Bar }}
                          labels={
                            (reportData[_.kebabCase(x.name)]
                              .data as BarGraphData).data.labels
                          }
                          datasets={
                            (reportData[_.kebabCase(x.name)]
                              .data as BarGraphData).data.datasets
                          }
                        />
                      ) : null}
                      {reportData[_.kebabCase(x.name)].type ===
                      'polar-graph' ? (
                        <PolarGraph
                          title={_.startCase(
                            reportData[_.kebabCase(x.name)].key
                          )}
                          components={{ PolarGraph: PolarArea }}
                          labels={
                            (reportData[_.kebabCase(x.name)]
                              .data as PolarGraphData).data.labels
                          }
                          datasets={
                            (reportData[_.kebabCase(x.name)]
                              .data as PolarGraphData).data.datasets
                          }
                        />
                      ) : null}
                      {reportData[_.kebabCase(x.name)].type === 'pie-graph' ? (
                        <PieGraph
                          title={_.startCase(
                            reportData[_.kebabCase(x.name)].key
                          )}
                          components={{ PieGraph: Pie }}
                          labels={
                            (reportData[_.kebabCase(x.name)]
                              .data as PolarGraphData).data.labels
                          }
                          datasets={
                            (reportData[_.kebabCase(x.name)]
                              .data as PolarGraphData).data.datasets
                          }
                        />
                      ) : null}
                      {reportData[_.kebabCase(x.name)].type === 'numeric' ? (
                        <div className='text-center h-full flex justify-center items-center flex-col'>
                          <div className='text-6xl animate-pulse'>
                            {['string', 'number'].includes(
                              typeof (reportData[_.kebabCase(x.name)]
                                .data as NumericData).value
                            ) ? (
                              <CountUp
                                isCounting
                                end={parseFloat(
                                  (reportData[_.kebabCase(x.name)]
                                    .data as NumericData).value
                                )}
                                duration={3.2}
                              />
                            ) : (
                              'Invalid Data'
                            )}
                          </div>
                          <div className='text-lg'>
                            {_.startCase(
                              (reportData[_.kebabCase(x.name)]
                                .data as NumericData).label
                            )}
                          </div>
                        </div>
                      ) : null}
                      {reportData[_.kebabCase(x.name)].type === 'list' &&
                      reportData[_.kebabCase(x.name)].data &&
                      (reportData[_.kebabCase(x.name)].data as any)?.value ? (
                        <div className='h-full flex justify-center items-center flex-col'>
                          <div className='text-lg'>
                            {_.startCase(
                              (reportData[_.kebabCase(x.name)].data as ListData)
                                .label
                            )}
                            {(reportData[_.kebabCase(x.name)]
                              .data as ListData)?.value?.map((l, idx) => (
                              <li key={idx}>{l}</li>
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null
                )}
              </div>
            </div>
          ),
        }}
      />
    );
  };
}
