import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  LoadingButton,
  Modal,
  renderCardComponent,
  renderPureHistory,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { renderAuthWrapper } from '../../../../components/auth';
import { UseCurrentUser } from '../../../../hooks/admin/user-admin/use-current-user';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { renderEmployeeStatusForm } from './forms/update-status';

export const renderEmployeeTimeLine = ({
  data,
  useCurrentUser,
  useUserAuthorization,
}: {
  data: EmployeeProfileType;
  useCurrentUser: UseCurrentUser;
  useUserAuthorization: UseUserAuthorization;
}) => {
  return function EmployeeTimeLine({
    setEmployee,
  }: {
    setEmployee: (s: EmployeeProfileType) => void;
  }) {
    const { data: currentUser } = useCurrentUser();
    const AuthWrapper = renderAuthWrapper({ useUserAuthorization });

    const [modalState, setModalState] = useState({
      visible: false,
      title: '',
      content: <></>,
    });

    const getActivity = (savedStatus?: string, currentStatus?: string) => {
      if (savedStatus !== 'on-strike' && currentStatus === 'on-strike')
        return 'went-on-strike';
      if (savedStatus === 'on-strike' && currentStatus !== 'on-strike')
        return 'came-back-from-strike';
      if (currentStatus === 'resigned') return 'resigned';
      return 'joined';
    };

    const handleStatusSave = (form) => {
      const finalData = {
        id: data.id,
        details: {
          ...data?.details,
          status: form.status,
          workTimeLines: [
            {
              activity: getActivity(data?.details?.status, form.status),
              date: form.date,
              updatedBy: {
                id: currentUser.id,
                name: currentUser.name,
              },
              updatedOn: moment.utc(),
            },
            ...(data?.details?.workTimeLines || []),
          ],
        },
      };
      setEmployee(finalData as any);
      // console.log('finalData', form);
      toast('Updated sucessfully');
      setModalState((ms) => ({ ...ms, visible: false }));
    };

    const handleUpdateTimeline = () => {
      const Form = renderEmployeeStatusForm({});
      setModalState({
        ...modalState,
        visible: true,
        title: 'Update status',
        content: (
          <Form
            data={{
              status: data?.details?.status || '',
              date: moment().format('YYYY-MM-DD'),
            }}
            onSave={handleStatusSave}
          />
        ),
      });
    };
    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Career Highlights',
      subheading: <>{data?.details?.status || 'status'}</>,
      actions: [
        {
          type: 'jsx',
          jsx: (
            <AuthWrapper
              action='UI:EDIT-EMP-CAREER-HL:VIEW'
              children={
                <LoadingButton
                  defaultStyle='p-2 border rounded-lg'
                  behaviorFn={async () => handleUpdateTimeline()}
                  text='Update Status'
                />
              }
            />
          ),
        },
      ],
    };

    const History = renderPureHistory();
    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <History
          logs={
            data?.details?.workTimeLines?.map((t) => ({
              event: `${t.activity} on ${t.date}`,
              time: t.date,
              action: t.activity,
              details: '',
            })) || []
          }
        />
      ),
    };
    return (
      <div>
        <Card header={header} body={body} />
        <Modal
          showModal={modalState.visible}
          setShowModal={() =>
            setModalState({ ...modalState, visible: !modalState.visible })
          }
          title={modalState.title}
          onOk={() => {}}
          style={{ width: '800px' }}
        >
          {modalState.content}
        </Modal>
      </div>
    );
  };
};
