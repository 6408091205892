import { renderFormV2 } from '@erp_core/erp-ui-components';

type Props = { onSave: any };

export function renderAddClientForm(): (props: Props) => JSX.Element {
  return function AddClientForm({ onSave }: Props): JSX.Element {
    const Form = renderFormV2<any>({
      style: 'md:w-1/2',
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          required: true,
          label: 'Client Name',
        },
      ],
      initialFormState: {
        name: '',
      },
      onSubmit: onSave,
      mapTToU: (b) => b,
    });

    return <Form />;
  };
}
