import { Company, LocationType } from '@erp_core/erp-types/dist/modules/admin';
import { Godown } from '@erp_core/erp-types/dist/modules/inventory';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseGodowns } from '../../../../../hooks/inventory/godown-inventory/use-godowns';
import { UseShiftSchedules } from '../../../../../hooks/planning/shift-schedule/use-shift-schedules';

export type AddShiftFormType = {
  date: string;
  shift: IdName;
  attendance: 'weekly-off' | 'working';
  workArea: IdName;
  supervisor: IdName;
  reason: string;
};
type Props = {
  initialData?: any;
  isEditMode?: boolean;
  avoidDates: string[];
  supervisor: IdName;
  workAreas: Godown[];
  useShifts: UseShiftSchedules;
  useWorkAreas: UseGodowns;
  onSubmit: (d: AddShiftFormType) => void;
  currentCompany: Company;
  currentLocation: LocationType;
};

export const AddShiftForm = ({
  initialData,
  isEditMode,
  avoidDates,
  onSubmit,
  supervisor,
  useShifts,
  useWorkAreas,
  workAreas,
  currentCompany,
  currentLocation,
}: Props) => {
  const Form = renderFormV2<AddShiftFormType>({
    fieldsData: [
      {
        property: 'date',
        label: 'date',
        required: true,
        type: 'date',
        readonly: isEditMode || false,
        validate: async (formData) => {
          if (!isEditMode && avoidDates.includes(formData.date as string)) {
            return { date: 'Shift already exists for this date' };
          }
          return {} as any;
        },
      },
      {
        property: 'shift',
        label: 'Select shift',
        required: true,
        type: 'searchable-select',
        searchOptions: {
          useSearch: useShifts,
          onSearchValueSelect: () => {},
        },
      },
      {
        property: 'workArea',
        label: `Select Work Area from ${currentCompany?.name}-${currentLocation?.name}`,
        subHeading: (
          <span className='text-indigo-600 animate-pulse'>
            Ensure Correct Company and Location are selected
          </span>
        ),
        required: true,
        readonly: isEditMode || false,
        type: 'searchable-select',
        validate: (formData: any) => {
          const godown = workAreas?.find((d) => d.id === formData.workArea.id);
          if (!godown) return { workArea: 'Invalid' };
          if (godown.details.workArea || godown.details.supervisorWorkArea) {
            return {} as any;
          } else {
            return { workArea: 'Not a work area' };
          }
        },
        searchOptions: {
          useSearch: useWorkAreas,
          filter: { crossCompany: true },
          onSearchValueSelect: () => {},
        },
      },
      {
        property: 'attendance',
        label: 'Attendance',
        type: 'select',
        readonly: isEditMode || false,
        options: [
          { text: 'Working', value: 'working' },
          { text: 'Weekly off', value: 'weekly-off' },
        ],
      },
      {
        property: 'supervisor',
        label: 'Supervisor [remains empty for rotational employees]',
        required: false,
        readonly: true,
        type: 'input',
      },
      {
        property: 'reason',
        label: 'Reason',
        required: true,
        type: 'input',
      },
    ],
    onSubmit: async (data) => {
      onSubmit(data);
    },
    mapTToU(p) {
      p.supervisor = supervisor;
      return p as any;
    },
    button: { style: 'w-fit' },
    allowUnchanged: true,
    initialFormState: !isEditMode
      ? {
          date: '',
          shift: {} as any,
          attendance: 'working',
          workArea: {} as any,
          supervisor: supervisor.name as any,
        }
      : initialData,
    style: 'md:w-1/2',
  });

  return <Form />;
};
