import {
  Client,
  SalesEnquiry,
  SalesEnquiryDetails,
  SalesEnquiryItem,
  SalesOrder,
} from '@erp_core/erp-types/dist/modules/order';
import { SalesEnquiryFilter } from '@erp_core/erp-types/dist/types/modules/order/sales/enquiry';
import {
  ActionButton,
  ActionContextMenu,
  CardBody,
  CardHeader,
  Filter,
  Modal,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseItems } from '../../../hooks/inventory/item/use-items';
import { UseClients } from '../../../hooks/order/sales/client/use-clients';
import { UseSalesEnquiries } from '../../../hooks/order/sales/enquiry/use-enquiries';
import { UseSalesEnquiry } from '../../../hooks/order/sales/enquiry/use-enquiry';
import { UsePaginatedEnquiries } from '../../../hooks/order/sales/enquiry/use-paginated-enquiries';
import { UseSalesOrder } from '../../../hooks/order/sales/order/use-order';
import { ManufacturingVoucherInterface } from '../../../models/interfaces/order/work-order/manufacturing-voucher.ts';
import { renderAddSalesEnquiryForm } from './forms/add-sales-enquiry';
import { renderCreateOrderFrom } from './forms/create-order';
import { renderExtendEnquiryFrom } from './forms/extend-enquiry-form';
import { renderSendSalesEnquiryQuotesForm } from './forms/sent-quotes-form';

import { UseUsers } from '../../../hooks/admin/user-admin/use-users-admin';
import { UserRendererInterface } from '../../common/fragments/user';

type RenderSalesEnquiryProps = {
  hooks: {
    useEnquiry: UseSalesEnquiry;
    useEnquiries: UseSalesEnquiries;
    useItems: UseItems;
    useClients: UseClients;
    useOrder: UseSalesOrder;
    usePaginatedEnquiries: UsePaginatedEnquiries;
    useUsers: UseUsers;
  };
  services: {
    manufacturingVoucherService: ManufacturingVoucherInterface;
    userRendererService: UserRendererInterface;
  };
};

function renderSalesEnquiryDetails({
  hooks: {
    useEnquiries,
    useEnquiry,
    useItems,
    useClients,
    useOrder,
    usePaginatedEnquiries,
    useUsers,
  },
  services: { manufacturingVoucherService, userRendererService },
}: RenderSalesEnquiryProps) {
  const Card = renderCardComponent();
  const Table = renderTableWithMapperComponent<
    SalesEnquiry,
    SalesEnquiryFilter
  >();

  return function SalesEnquiryDetails() {
    const { set: setSalesEnquiry } = useEnquiry();
    const { getAll, data: enquiriesData } = useEnquiries();

    const { set: SetSalesOrder } = useOrder();

    const [urgentLength, setUrgentLength] = useState(-1);
    const [missedLength, setMissedLength] = useState(-1);

    useEffect(() => {
      getAll();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const sortedArray = _.orderBy(
        enquiriesData,
        (e) => moment(e.respondBy).format('YYYYMMDD'),
        ['desc']
      );
      const dateToday = moment(new Date());
      const range = 5; // in days

      const urgent = sortedArray.filter(
        (a) =>
          moment(a.respondBy).diff(dateToday, 'days') <= range &&
          moment(a.respondBy).diff(dateToday, 'days') >= 0
      );
      const missed = sortedArray.filter(
        (a) =>
          moment(a.respondBy).diff(dateToday, 'days') >= -range &&
          moment(a.respondBy).diff(dateToday, 'days') < 0
      );
      setMissedLength(missed.length);
      setUrgentLength(urgent.length);

      const arr = [
        ...missed,
        ...urgent,
        ...sortedArray.filter(
          (a) =>
            moment(a.respondBy).diff(dateToday, 'days') > range ||
            moment(a.respondBy).diff(dateToday, 'days') < -range
        ),
      ];

      setData(arr);
    }, [enquiriesData]);

    const [modalState, setModalState] = useState({
      visible: false,
      title: 'Add enquiry details',
      content: <>Add enquiry details</>,
    });

    const [actionsState, setActionsState] = useState({
      visible: false,
      xPosition: 200,
      yPosition: 200,
      menu: ['Send Quote', 'Close enquiry'],
    });

    const [activeDataId, setActiveDataId] = useState<string>('');
    const [data, setData] = useState<SalesEnquiry[]>();

    useEffect(() => {
      getAll();
      // eslint-disable-next-line
    }, []);

    type SalesEnquiryForm = {
      client: string;
      items: Array<{
        id: string;
        quantity: number;
        grade: string;
        stateOfMatter: string;
        color: string;
        packaging: string;
        schedule: {
          batchQuantity: string;
          batchNeededBy: string;
        }[];
      }>;
      name: string;
      remark: string;
      respondBy: Date;
      expireBy: Date;
      attachments: string;
    };
    const handleAddSalesEnquiry = (formData: SalesEnquiryForm) => {
      try {
        // console.log('Final Form Data', formData);
        const finalData: Partial<SalesEnquiry> = {
          name: formData.name,
          details: {
            extendDateReason: '',
            attachments: [],
            items: formData.items.map((i) => ({ id: i.id, name: '' })),
            remark: formData.remark,
          },
          enquiryItems: formData.items.map((i) => ({
            item: {
              id: i.id,
              name: '',
            },
            quantity: i.quantity,
            details: {
              specifications: {
                grade: i.grade || '',
                color: i.color || '',
                stateOfMatter: i.stateOfMatter,
                packaging: i.packaging,
              },
              schedule: i.schedule,
            },
          })) as SalesEnquiryItem[],
          respondBy: formData.respondBy.toString(),
          expireBy: formData.expireBy.toString(),
          client: { id: formData.client } as Client,
        };
        setModalState((ms) => ({ ...ms, visible: false }));
        try {
          setSalesEnquiry(finalData as SalesEnquiry);
          toast('Added successfully');
        } catch (error) {
          toast((error as any).message);
        }
      } catch (err) {
        // console.log('Error');
      }
    };

    const getActionMenu = (id: string) => {
      const activeEnquiry = data?.find((f) => f.id === id);

      const expired =
        moment().format('YYYY-MM-DD') >
        moment(activeEnquiry?.expireBy).format('YYYY-MM-DD');
      if (expired || activeEnquiry?.status === 'order-created')
        return ['Close'];

      return ['Send Quote', 'Extend', 'Create Order', 'Close enquiry'];
    };

    const handleActionClick = (e: any, id: string) => {
      setActionsState({
        ...actionsState,
        visible: true,
        xPosition: e.pageX,
        yPosition: e.pageY,
        menu: getActionMenu(id),
      });
      setActiveDataId(id);
    };

    const handleActionItemClick = async (action: string) => {
      const enquiry = data ? data.find((d) => d.id === activeDataId) : null;

      if (!enquiry) return;

      if (action === 'Send Quote') {
        const SendQuotesForm = renderSendSalesEnquiryQuotesForm();
        if (!enquiry) return;
        setActionsState({
          ...actionsState,
          visible: false,
        });
        setModalState({
          visible: true,
          title: 'Send Quote',
          content: (
            <SendQuotesForm
              manufacturingVoucherService={manufacturingVoucherService}
              enquiryData={enquiry}
              onSave={(form) => {
                const finalData = {
                  id: enquiry.id,
                  status: 'quote-sent',
                  quotes: {
                    items: enquiry.enquiryItems
                      .map((i) => ({
                        id: i.id,
                        name: i.item.name,
                        rate: form[i.id],
                      }))
                      .filter((i) => i.rate), // Filtering out those items whom rate not provided by user
                    client: enquiry.client,
                    credit: form.credit,
                    availableBy: form.availableBy,
                  },
                };
                setSalesEnquiry((finalData as unknown) as SalesEnquiry);

                setModalState({
                  visible: false,
                  title: '',
                  content: <div className='hidden' />,
                });
              }}
            />
          ),
        });
      }

      if (action === 'Extend') {
        const ExtendEnquiryForm = renderExtendEnquiryFrom();
        if (!enquiry) return;
        setActionsState({
          ...actionsState,
          visible: false,
        });
        setModalState({
          visible: true,
          title: `Extend Enquiry ${enquiry.name}`,
          content: (
            <div>
              <ExtendEnquiryForm
                onSave={(form) => {
                  console.log(form);
                  const final: Partial<SalesEnquiry> = {
                    id: enquiry.id,
                    expireBy: form.extendedDate,
                    userReason: form.reason,
                    details: {
                      extendDateReason: form.reason,
                    } as SalesEnquiryDetails,
                  };
                  setSalesEnquiry(final as SalesEnquiry);
                  setModalState({
                    visible: false,
                    title: '',
                    content: <div className='hidden' />,
                  });
                  toast('Enquiry date extended');
                }}
              />
            </div>
          ),
        });
      }

      if (action === 'Create Order') {
        const Form = renderCreateOrderFrom();
        setModalState({
          ...modalState,
          visible: true,
          title: 'Create Order',
          content: (
            <Form
              onSave={(form) => {
                const finalData = {
                  name: form.name,
                  enquiry,
                  client: enquiry.client,
                  orderItems: enquiry.enquiryItems.map((ei) => ({
                    name: '',
                    item: ei.item,
                    deliverBy: form.deliverBy,
                    details: ei.details,
                    quantity: ei.quantity,
                  })),
                  details: { remarks: form.remark },
                };
                // console.log('finalData', finalData);
                SetSalesOrder(finalData as SalesOrder);
                setModalState({
                  visible: false,
                  title: '',
                  content: <div className='hidden' />,
                });
                toast('Order created');
              }}
            />
          ),
        });
      }

      if (action === 'Close enquiry') {
        // const activeFulfillment  = data?.find((f)=>f.id===activeDataId)
        setModalState({
          ...modalState,
          visible: true,
          title: 'Close enquiry',
          content: <div>Close enquiry</div>,
        });
      }
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Name', rowSpan: 2 },
        { name: 'Respond By', rowSpan: 2 },
        { name: 'Expire By', rowSpan: 2 },
        { name: 'Client', rowSpan: 2 },
        { name: 'Items', rowSpan: 2 },
        { name: 'Remarks', rowSpan: 2 },
        { name: 'Attachments', rowSpan: 2 },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        { name: 'Status', rowSpan: 2 },
        { name: 'Actions', rowSpan: 2 },
      ],
    ];

    var index: any;

    const bodyMapper = (item: SalesEnquiry) => {
      const cells: Array<TableCell> = [
        { value: item.name, link: `/sales/enquiries/enquiry/${item.id}` },
        {
          value: (
            <div
              className={
                index <= missedLength - 1
                  ? 'bg-red-200'
                  : index <= missedLength + urgentLength - 1
                  ? 'bg-amber-100'
                  : ''
              }
            >
              {item.respondBy}
            </div>
          ),
        },
        { value: moment(item.expireBy).format('YYYY-MM-DD') },
        { value: item.client.name },
        // eslint-disable-next-line
        {
          value: (
            <div>
              {item.enquiryItems.map((a, i) => (
                <span key={i}> {a.item.name}, </span>
              ))}
            </div>
          ),
        },
        { value: item.details.remark },
        // eslint-disable-next-line
        {
          value: (
            <div>
              {item.details.attachments.map((a, i) => (
                <span key={i}>{a.name}, </span>
              ))}{' '}
            </div>
          ),
        },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={item.createdBy?.id}
              name={item.createdBy?.name}
              extraInfo={moment.utc(item.createdAt).fromNow()}
            />
          ),
        },
        { value: <Status date={item.expireBy} status={item.status} /> },
        {
          value: (
            <ActionButton handleActionClick={handleActionClick} id={item.id} />
          ),
        },
      ];
      return {
        cells,
      };
    };

    const filter: Filter<SalesEnquiryFilter> = {
      sortFields: [
        {
          key: 'Name',
          value: 'name',
          defaultOrder: 'asc',
        },
        // {
        //     key: 'Client',
        //     value: 'client', // TODO: sort filter not working
        //     defaultOrder: 'asc',
        // },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'createdBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: SalesEnquiryFilter) => {
        const filterData: SalesEnquiryFilter = {};

        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }
        if (filterSelection.createdBy) {
          filterData.createdBy = filterSelection.createdBy;
        }

        return filterData as SalesEnquiryFilter;
      },
      filterResources: {
        createdBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };

    const cardHeader: CardHeader = {
      title: 'Sales Enquiries',
      actions: [
        {
          type: 'button',
          button: {
            behaviour: 'modal',
            name: 'add',
            modal: {
              title: 'Add Sales Enquiry',
              content: ({ onClose }) => {
                const Form = renderAddSalesEnquiryForm({
                  useItems,
                  useClients,
                });
                return (
                  <Form
                    data={{}}
                    onSave={async (form) => {
                      await handleAddSalesEnquiry(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <Table
            header={tableHeader}
            bodyMapper={bodyMapper}
            useResources={useEnquiries}
            filter={filter}
            pagination={{
              enabled: true,
              usePaginatedResources: usePaginatedEnquiries,
            }}
          />
        </>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
        <ActionContextMenu
          actionsState={actionsState}
          setActionsState={setActionsState}
          handleActionItemClick={handleActionItemClick}
        />
        <Modal
          showModal={modalState.visible}
          setShowModal={() =>
            setModalState({ ...modalState, visible: !modalState.visible })
          }
          title={modalState.title}
          onOk={() => {}}
          style={{ width: '800px' }}
        >
          {modalState.content}
        </Modal>
      </div>
    );
  };
}

function Status({ date, status }: { date: string; status: string }) {
  const now = moment().format('YYYY-MM-DD');
  if (now === moment(date).format('YYYY-MM-DD'))
    return (
      <span>
        {status} <span className='bg-yellow-300'>Expiring today</span>
      </span>
    );
  if (now > moment(date).format('YYYY-MM-DD'))
    return <span className='bg-red-300'>Expired</span>;
  return <span>{status}</span>;
}

export default renderSalesEnquiryDetails;
