import { TaskType } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export type TaskFormType = {
  name: string;
  cronExpression: string;
  resource: string;
  url: string;
};

export function renderAddTaskForm() {
  return function AddTaskForm({
    onSave,
  }: {
    onSave: (data: TaskType) => Promise<void>;
  }): JSX.Element {
    const formProps: FormProps<Partial<TaskFormType>> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          label: 'Name',
          required: true,
        },
        {
          property: 'resource',
          type: 'input',
          label: 'Resource',
          required: true,
        },
        {
          property: 'url',
          type: 'input',
          label: 'Url',
          required: true,
        },
        {
          property: 'cronExpression',
          type: 'input',
          label: 'Cron Expression',
          required: true,
        },
      ],
      initialFormState: {
        name: '',
        resource: '',
        url: '',
        cronExpression: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          name: l.name,
          cronExpression: l.cronExpression,
          jobDetails: {
            resource: l.resource,
            url: l.url,
          },
        } as TaskType);
      },
    };

    const Form = renderFormV2<Partial<TaskFormType>>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<Partial<TaskFormType>>): TaskFormType {
      return (b as unknown) as TaskFormType;
    }
  };
}
