/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { RoleType } from '@erp_core/erp-types/dist/modules/admin';
import { useEffect, useState } from 'react';
import { UseCurrentUser } from '../user-admin/use-current-user';
import { UseUserAdmin } from '../user-admin/use-user-admin';

export type UseCurrentUserRoles = () => {
  data: RoleType[] | undefined;
};

export interface UseCurrentUserRolesProps {
  useCurrentUser: UseCurrentUser;
  useUser: UseUserAdmin;
}

export function createUseCurrentUserRoles({
  useCurrentUser,
  useUser,
}: UseCurrentUserRolesProps): UseCurrentUserRoles {
  return () => {
    const [currentUserRoles, setCurrentUserRoles] = useState<RoleType[]>();

    const { data: currentUser } = useCurrentUser();
    const { getSync: getUserDetails } = useUser();

    const getUserRoles = async () => {
      if (currentUser.id) {
        const userDetails = await getUserDetails(currentUser.id);
        setCurrentUserRoles(userDetails.roles);
      }
    };

    useEffect(() => {
      if (!currentUser) return;
      getUserRoles();
    }, [currentUser]);

    return {
      data: currentUserRoles,
    };
  };
}
