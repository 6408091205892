import {
  Godown,
  ItemBatchGodown,
} from '@erp_core/erp-types/dist/modules/inventory';
import { GodownDetails } from '@erp_core/erp-types/dist/types/modules/inventory/godown';
import {
  LoadingButton,
  ModalV2Props,
  OpenConfirm,
  renderCardComponent,
  renderFormV2,
  renderModal,
  renderTableComponent,
  renderTableWithAddEdit,
  TableBody,
} from '@erp_core/erp-ui-components';
import { CubeIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { UseEmployeeProfiles } from '../../../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseBatchItemGodowns } from '../../../../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { UseShiftSchedules } from '../../../../../../hooks/planning/shift-schedule/use-shift-schedules';
import { WASch } from '../../../../../inventory/godowns/godown-profile/shift-config/components/shift-generator';
import { AddEmployeeScheduleForm } from '../../../../../inventory/godowns/godown-profile/shift-config/forms/add-sch-employee-form';
import { AddRelieverScheduleForm } from '../../../../../inventory/godowns/godown-profile/shift-config/forms/add-sch-reliever-form';

type Props = {
  godown: Godown;
  setGodown: (data: Godown, options?: any) => Promise<Godown>;
  openConfirm: OpenConfirm;
};

export const renderGodownShiftConfigV2 = ({
  useBatchItemGodowns,
  useShiftSchedules,
  useEmployees,
}: {
  useBatchItemGodowns: UseBatchItemGodowns;
  useShiftSchedules: UseShiftSchedules;
  useEmployees: UseEmployeeProfiles;
}) => {
  const Table = renderTableComponent();
  const Modal = renderModal();

  return function ({ godown, setGodown, openConfirm }: Props) {
    const [scheduleData, setScheduleData] = useState<{
      employees: {
        roleName: string;
        count: number;
        supervisor: 'profile' | 'rotational';
      }[];
      relievers: {
        roleName: string;
        count: number;
        supervisor: 'profile' | 'rotational';
      }[];
      schedule: WASch;
    }>({
      employees: godown.details.scheduleConfig?.employees || [],
      relievers: godown.details.scheduleConfig?.relievers || [],
      schedule: godown.details.scheduleConfig?.schedule || [],
    });

    const [modal, setModal] = useState<ModalV2Props>({
      isVisible: false,
      title: '',
      onClose: () => {
        setModal((current) => {
          return { ...current, isVisible: false, title: '', body: <div /> };
        });
      },
      body: <div />,
    });

    const { getAll: getAssets, data: assets } = useBatchItemGodowns();

    useEffect(() => {
      getAssets({ godownId: godown.id, category: 'FA' });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const godownDetails: GodownDetails = godown.details;

    const addSchEmployees = async (form) => {
      scheduleData.employees = [...scheduleData.employees, form];
      // const dataToSave = JSON.stringify(scheduleData);
      // const file = new File([dataToSave], `${godown.id}.json`, { type: 'application/json' });
      // setSchedule(`${currentCompanyGroup.id}/workarea/${godown.id}`, file, 'json');

      const schConfig = godownDetails.scheduleConfig || { type: 'custom' };
      (schConfig as any).employees = scheduleData.employees;

      const finalData: Partial<Godown> = {
        id: godown.id,
        details: {
          scheduleConfig: schConfig,
        } as GodownDetails,
      };

      await setGodown((finalData as unknown) as Godown);
      setScheduleData({ ...scheduleData });
      closeModal();
    };

    const addSchRelievers = async (form) => {
      scheduleData.relievers = [...scheduleData.relievers, form];

      const schConfig = godownDetails.scheduleConfig || { type: 'custom' };
      (schConfig as any).relievers = scheduleData.relievers;

      const finalData: Partial<Godown> = {
        id: godown.id,
        details: {
          scheduleConfig: schConfig,
        } as GodownDetails,
      };
      await setGodown((finalData as unknown) as Godown);
      setScheduleData({ ...scheduleData });

      closeModal();
    };

    const editSchEmployees = async (form, index) => {
      scheduleData.employees[index] = form;

      const schConfig = godownDetails.scheduleConfig || { type: 'system' };
      (schConfig as any).employees = scheduleData.employees;

      const finalData: Partial<Godown> = {
        id: godown.id,
        details: {
          scheduleConfig: schConfig,
        } as GodownDetails,
      };
      await setGodown((finalData as unknown) as Godown);
      setScheduleData({ ...scheduleData });

      closeModal();
    };

    function closeModal() {
      setModal({
        isVisible: false,
        title: '',
        onClose: () => {
          setModal((current) => {
            return { ...current, isVisible: false, title: '', body: <div /> };
          });
        },
        body: <div />,
      });
    }

    const editSchRelievers = async (form, index) => {
      scheduleData.relievers[index] = form;

      const schConfig = godownDetails.scheduleConfig || { type: 'system' };
      (schConfig as any).relievers = scheduleData.relievers;

      const finalData: Partial<Godown> = {
        id: godown.id,
        details: {
          scheduleConfig: schConfig,
        } as GodownDetails,
      };
      await setGodown((finalData as unknown) as Godown);
      setScheduleData({ ...scheduleData });
      closeModal();
    };

    const addSchEmployee = () => {
      setModal({
        ...modal,
        isVisible: true,
        title: 'Add Schedule Employee',
        body: <AddEmployeeScheduleForm save={addSchEmployees} />,
      });
    };

    const addSchReliever = () => {
      setModal({
        ...modal,
        isVisible: true,
        title: 'Add Schedule Reliever',
        body: <AddRelieverScheduleForm save={addSchRelievers} />,
      });
    };

    const editSchEmployee = (
      data: {
        schEmployee: {
          roleName: string;
          count: number;
          supervisor: 'profile' | 'rotational';
        };
      },
      index: number
    ) => {
      setModal({
        ...modal,
        isVisible: true,
        title: 'Edit Schedule Employee',
        body: (
          <AddEmployeeScheduleForm
            save={async (form) => {
              await editSchEmployees(form, index);
            }}
            data={data.schEmployee}
          />
        ),
      });
    };

    const editSchReliever = (
      data: {
        schReliever: {
          roleName: string;
          count: number;
          supervisor: 'profile' | 'rotational';
        };
      },
      index: number
    ) => {
      setModal({
        ...modal,
        isVisible: true,
        title: 'Edit Schedule Reliever',
        body: (
          <AddRelieverScheduleForm
            save={async (form) => {
              await editSchRelievers(form, index);
            }}
            data={data.schReliever}
          />
        ),
      });
    };

    const schEmployeesTableBody: TableBody =
      scheduleData.employees?.map((s, index) => {
        if (!s) return { cells: [] };
        return {
          cells: [
            { value: s?.roleName || '' },
            { value: s.supervisor || '' },
            { value: s?.count || 0 },
            {
              value: (
                <div className='flex space-x-1'>
                  <PencilIcon
                    onClick={() => {
                      editSchEmployee({ schEmployee: s }, index);
                    }}
                    className='w-5 h-5 hover:cursor-pointer text-blue-500 inline-block'
                  />
                  {/* <TrashIcon onClick={() => deleteSiblingWorkArea(index) } className='w-5 h-5 text-red-500 inline-block' /> */}
                </div>
              ),
            },
          ],
        };
      }) || [];

    const schRelieversTableBody: TableBody =
      scheduleData.relievers?.map((s, index) => {
        if (!s) return { cells: [] };
        return {
          cells: [
            { value: s?.roleName || '' },
            { value: s.supervisor || '' },
            { value: s?.count || 0 },
            {
              value: (
                <div className='flex space-x-1'>
                  <PencilIcon
                    onClick={() => {
                      editSchReliever({ schReliever: s }, index);
                    }}
                    className='w-5 h-5 hover:cursor-pointer text-blue-500 inline-block'
                  />
                  {/* <TrashIcon onClick={() => deleteSiblingWorkArea(index) } className='w-5 h-5 text-red-500 inline-block' /> */}
                </div>
              ),
            },
          ],
        };
      }) || [];

    const ShiftComponent = renderTableWithAddEdit<Godown>({
      resource: 'shifts',
      properties: [
        {
          name: 'name',
          type: 'searchable-select',
          data: { useSearch: useShiftSchedules },
          required: true,
          formToObjectMapper: (f, o) => {
            o.id = f.name.id;
            o.name = f.name.name;
          },
          objectToFormMapper: (o, f) => {
            f.name = { id: o.id, name: o.name };
          },
        },
      ],
      actions: [{ name: 'edit' }, { name: 'delete' }],
      resourceData: godown,
      setResource: setGodown,
    });

    const SupervisorAddEditComponent = renderTableWithAddEdit<Godown>({
      resource: 'supervisors',
      properties: [
        {
          name: 'name',
          type: 'searchable-select',
          data: {
            useSearch: useEmployees,
            filter: {
              crossGroup: 'true',
            },
          },
          required: true,
          formToObjectMapper: (f, o) => {
            o.id = f.name.id;
            o.name = f.name.name;
          },
          objectToFormMapper: (o, f) => {
            f.name = { id: o.id, name: o.name };
          },
        },
      ],
      actions: [{ name: 'edit' }, { name: 'delete' }],
      resourceData: godown,
      setResource: setGodown,
    });

    const SupervisorCard = renderCardComponent();
    const SupervisorTable = renderTableComponent();

    return (
      <div className='flex flex-col space-y-4'>
        <div>
          <div className='font-bold'>
            Assets installed in godown {godown.name}
          </div>
          <div className='flex space-x-2 space-y-2'>
            {assets?.map((fa, idx) => {
              return (
                <div className='p-2 w-1/2 rounded shadow' key={idx}>
                  <div className='font-bold'>
                    {fa.item.name} - <span className='text-xs'>{fa.name}</span>
                  </div>
                  <div className='flex space-x-2'>
                    <div>
                      {fa.item?.details?.profile?.logo ? (
                        <img
                          className='w-20 h-20'
                          alt='FA'
                          src={fa.item?.details?.profile?.logo}
                        />
                      ) : (
                        <CubeIcon className='w-14 h-14' />
                      )}
                    </div>
                    <div>
                      <SkillsRequired fa={fa} />
                      <RolesRequired fa={fa} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {godownDetails.workArea || godownDetails.supervisorWorkArea ? (
          <div>
            <ShiftComponent />
          </div>
        ) : null}

        {godownDetails.supervisorWorkArea ? (
          <div>
            <SupervisorAddEditComponent />
            <SupervisorCard
              header={{
                title: 'Supervisors',
                actions: [
                  {
                    type: 'button',
                    button: {
                      name: 'Add',
                      behaviour: 'modal',
                      modal: {
                        title: 'Add Supervisor',
                        content: ({ onClose }) => {
                          const Form = renderFormV2({
                            fieldsData: [
                              {
                                property: 'employee',
                                type: 'searchable-select',
                                label: 'Employee',
                                searchOptions: {
                                  filter: { crossGroup: 'true' },
                                  useSearch: useEmployees,
                                  onSearchValueSelect: () => {},
                                },
                              },
                            ],
                            onSubmit: async (form) => {
                              if (
                                (form.employee as any)?.id &&
                                !godown.details.supervisors?.find(
                                  (x) => x.id === (form.employee as any).id
                                )
                              ) {
                                if (!godown.details.supervisors) {
                                  godown.details.supervisors = [];
                                }
                                godown.details.supervisors.push(
                                  form.employee as any
                                );
                                setGodown({
                                  id: godown.id,
                                  details: {
                                    supervisors: godown.details.supervisors,
                                  },
                                } as any);
                                onClose();
                              }
                            },
                            initialFormState: {
                              employee: { id: '', name: '' },
                            },
                            mapTToU: (p) => p,
                          });
                          return <Form />;
                        },
                      },
                    },
                  },
                ],
              }}
              body={{
                type: 'jsx-component',
                body: (
                  <div>
                    <SupervisorTable
                      header={[[{ name: 'Name' }]]}
                      body={
                        godown.details.supervisors?.map((s, idx) => {
                          return {
                            cells: [{ value: s.name }],
                            rowData: { supervisor: s, idx },
                          };
                        }) || []
                      }
                      actions={[
                        {
                          name: 'Edit',
                          show: () => true,
                          behaviour: 'modal',
                          modal: {
                            title: 'Edit',
                            content: ({ data, onClose }) => {
                              const Form = renderFormV2({
                                fieldsData: [
                                  {
                                    property: 'employee',
                                    type: 'searchable-select',
                                    label: 'Employee',
                                    searchOptions: {
                                      filter: { crossGroup: 'true' },
                                      useSearch: useEmployees,
                                      onSearchValueSelect: () => {},
                                    },
                                  },
                                ],
                                onSubmit: async (form) => {
                                  if (
                                    form.employee &&
                                    godown.details.supervisors &&
                                    (form.employee as any).id !==
                                      godown.details.supervisors[data.idx].id
                                  ) {
                                    godown.details.supervisors[
                                      data.idx
                                    ] = form.employee as any;

                                    setGodown({
                                      id: godown.id,
                                      details: {
                                        supervisors: godown.details.supervisors,
                                      },
                                    } as any);
                                    onClose();
                                  }
                                },
                                initialFormState: { employee: data.supervisor },
                                mapTToU: (p) => p,
                              });
                              return <Form />;
                            },
                          },
                        },
                        {
                          name: 'Delete',
                          show: () => true,
                          behaviour: 'confirm',
                          onConfirm: ({ supervisor, idx }) => {
                            return {
                              title: ` Are you sure you want to remove ${supervisor.name}?`,
                              onConfirm: async () => {
                                godown.details.supervisors?.splice(idx, 1);
                                setGodown({
                                  id: godown.id,
                                  details: {
                                    supervisors: godown.details.supervisors,
                                  },
                                } as any);
                              },
                            };
                          },
                        },
                      ]}
                    />
                  </div>
                ),
              }}
            />
          </div>
        ) : null}

        {godownDetails.scheduleConfig?.type !== 'system' ? (
          <div>
            {godownDetails.workArea ? (
              <div className='flex'>
                <div className='w-1/2 p-2'>
                  <div className='flex justify-between pb-2'>
                    <div className='font-bold'>Employees required</div>
                    <LoadingButton
                      defaultStyle='bg-blue-500 p-2 rounded shadow text-white font-semibold hover:bg-blue-600'
                      behaviorFn={async () => addSchEmployee()}
                      text='Add Employee'
                    />
                  </div>
                  <div>
                    <Table
                      header={[
                        [
                          { name: 'Role Name' },
                          { name: 'Supervisor Type' },
                          { name: 'Count' },
                          { name: 'Action' },
                        ],
                      ]}
                      body={schEmployeesTableBody}
                    />
                  </div>
                </div>

                <div className='w-1/2 p-2'>
                  <div className='flex justify-between pb-2'>
                    <div className='font-bold'>Relievers required</div>
                    <LoadingButton
                      defaultStyle='bg-blue-500 p-2 rounded shadow text-white font-semibold hover:bg-blue-600'
                      behaviorFn={async () => addSchReliever()}
                      text='Add Reliever'
                    />
                  </div>
                  <div>
                    <Table
                      header={[
                        [
                          { name: 'Role Name' },
                          { name: 'Supervisor Type' },
                          { name: 'Count' },
                          { name: 'Action' },
                        ],
                      ]}
                      body={schRelieversTableBody}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        <Modal {...modal} />
      </div>
    );
  };
};

export const SkillsRequired = ({ fa }: { fa: ItemBatchGodown }) => {
  return (
    <div>
      <div className='font-semibold'>Skills required</div>
      <div>
        {fa.item.details?.shiftConfig?.skills?.map((s) => {
          return (
            <div>
              {s.name} -{' '}
              {s.min === s.max
                ? `${s.min} Required`
                : `${s.min} - ${s.max} required`}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const RolesRequired = ({ fa }: { fa: ItemBatchGodown }) => {
  return (
    <div>
      <div className='font-semibold'>Roles required</div>
      <div>
        {fa.item.details?.shiftConfig?.roles?.map((s) => {
          return (
            <div>
              {s.name} <i>({s.level})</i>{' '}
              {s.min === s.max
                ? `${s.min} Required`
                : `${s.min} - ${s.max} required`}
            </div>
          );
        })}
      </div>
    </div>
  );
};
