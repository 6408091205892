import { XlsxDownloadMapper } from '@erp_core/erp-ui-components';

export const PtSummaryMapper: XlsxDownloadMapper = [
  {
    columnName: 'Category',
    dataBinding: { property: 'category' },
  },
  {
    columnName: 'PT_AMOUNT',
    dataBinding: { property: 'pt_amount' },
  },
  {
    columnName: 'NO_OF_EMPLOYEES',
    dataBinding: { property: 'countofemployee' },
  },
  {
    columnName: 'TOTAL_PT',
    dataBinding: { property: 'total_pt_amount' },
  },
];
