import { CardHeader } from '@erp_core/erp-ui-components';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseLeaves } from '../../../../hooks/hrd/leave/use-leaves';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderGenericLeaveCard } from '../../attendance/util/generic-leave-card';

type renderEmployeeLeaveProps = {
  id: string;
  useLeaves: UseLeaves;
  useFileTransfer: UseFileTransfer;
  userRendererService: UserRendererInterface;
};

export function renderEmployeeLeave({
  id,
  useLeaves,
  useFileTransfer,
  userRendererService,
}: renderEmployeeLeaveProps): () => JSX.Element {
  return function EmployeeLeave(): JSX.Element {
    const cardHeader: CardHeader = {
      title: '',
    };

    const GenericLeaveCard = renderGenericLeaveCard({
      id,
      cardHeader,
      useLeaves,
      useFileTransfer,
      userRendererService,
    });

    return (
      <>
        <GenericLeaveCard />
      </>
    );
  };
}
