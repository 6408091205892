/* eslint-disable no-unused-vars */
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import React from 'react';

type Fileds = {
  ManufacturedQty: string;
  ManufacturedBy: string;
};

// eslint-disable-next-line
export function renderManufacturingSalesItemForm(): (
  props: any
) => JSX.Element {
  return function ManufacturingSalesItemForm({
    data,
    onSave,
  }: {
    data: {};
    onSave: any;
  }): JSX.Element {
    const formProps: FormProps<Fileds> = {
      fieldsData: [
        {
          property: 'ManufacturedQty',
          type: 'input',
          label: 'ManufacturedQty',
        },
        {
          property: 'ManufacturedBy',
          type: 'date',
          label: 'Manufactured By',
        },
      ],
      initialFormState: {
        ManufacturedBy: '',
        ManufacturedQty: '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };
    const NewForm = renderFormV2<Fileds>(formProps);
    return <NewForm />;

    function mapPFDToP(b: FormDataType<Fileds>): Fileds {
      return {
        ManufacturedQty: b.ManufacturedQty as string,
        ManufacturedBy: b.ManufacturedBy as string,
      };
    }
  };
}
