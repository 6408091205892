import {
  BomFlow,
  BomFlowFilter,
} from '@erp_core/erp-types/dist/modules/inventory';
import {
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UseBomFlow } from '../../../../hooks/order/work-order/bom-flow/use-bom-flow';
import { UseBomFlows } from '../../../../hooks/order/work-order/bom-flow/use-boms';
import { UsePaginatedBomFlows } from '../../../../hooks/order/work-order/bom-flow/use-paginated-bom-flows';
import { renderAddBomFlow } from './forms/add-bom-flow';

type RenderBomFlowProps = {
  usePaginatedBomFlows: UsePaginatedBomFlows;
  useBomFlows: UseBomFlows;
  useBomFlow: UseBomFlow;
};

export function renderBomFlow({
  usePaginatedBomFlows,
  useBomFlows,
  useBomFlow,
}: RenderBomFlowProps): () => JSX.Element {
  const Card = renderCardComponent();
  return function BomFlow(): JSX.Element {
    const { syncSet: setBomFlow } = useBomFlow();

    const Table = renderTableWithMapperComponent<BomFlow, BomFlowFilter>();

    const tableHeader: TableHeader = [[{ name: 'Name' }, { name: 'Details' }]];

    const filter: Filter<BomFlowFilter> = {
      sortFields: [
        {
          key: 'Name',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'Search',
          value: 'all',
          type: 'text',
        },
      ],
      filterMapper: (filterSelection: any) => {
        const filterData: any = {};

        if (filterSelection.Search !== 'all' && filterSelection.Search !== '') {
          filterData.search = filterSelection.Search;
        }

        return (filterData as unknown) as BomFlowFilter;
      },
    };

    const bodyMapper = (e: BomFlow) => {
      const cells: Array<TableCell> = [
        {
          value: <div>{e.name}</div>,
          link: `/inventory/registers/bom-flow/${e.id}`,
        },
        { value: e.description },
      ];
      return {
        cells,
      };
    };

    return (
      <div>
        <Card
          header={{
            title: 'Bom Flows',
            actions: [
              {
                type: 'button',
                button: {
                  name: 'Add',
                  behaviour: 'modal',
                  modal: {
                    title: 'Add Bom Flow',
                    content: ({ onClose }) => {
                      const Form = renderAddBomFlow();
                      return (
                        <Form
                          save={async (data) => {
                            await setBomFlow(data);
                            onClose();
                          }}
                        />
                      );
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <Table
                  header={tableHeader}
                  bodyMapper={bodyMapper}
                  useResources={useBomFlows}
                  filter={filter}
                  pagination={{
                    enabled: true,
                    usePaginatedResources: usePaginatedBomFlows,
                  }}
                />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
