/* eslint-disable no-unused-vars */

import {
  EducationDetailsType,
  StatusOfEducationType,
} from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
// import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
// import { UseCurrentCompanyGroup } from '../../../../../hooks/admin/company-group-admin/use-current-company-group';
// import { UseCurrentCompany } from '../../../../../hooks/admin/company-admin/use-current-company';

type EditEducationFormProps = {
  data: EducationDetailsType;
  onSave: (data: Partial<EducationDetailsType>) => Promise<void>;
};

type RenderEditEducationForm = {
  // employeeId: string;
  // useFileTransfer: UseFileTransfer;
  // useCurrentCompanyGroup: UseCurrentCompanyGroup;
  // useCurrentCompany: UseCurrentCompany;
};

// eslint-disable-next-line
export function renderEditEducationForm({}: // employeeId,
// useFileTransfer,
// useCurrentCompanyGroup,
// useCurrentCompany,
RenderEditEducationForm): (data: EditEducationFormProps) => JSX.Element {
  return function EditEducationDetailsForm({
    data,
    onSave,
  }: EditEducationFormProps): JSX.Element {
    // const { data: company } = useCurrentCompany();
    // const { currentCompanyGroup: companyGroup } = useCurrentCompanyGroup();
    const formProps: FormProps<Partial<EducationDetailsType>> = {
      fieldsData: [
        {
          property: 'statusOfEducation',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Below-V', value: 'Below-V' },
            { text: 'V-IX', value: 'V-IX' },
            { text: 'X', value: 'X' },
            { text: 'XII', value: 'XII' },
            { text: 'ITI', value: 'ITI' },
            { text: 'Engineering-Diploma', value: 'Engineering-Diploma' },
            { text: 'Vocational-Diploma', value: 'Vocational-Diploma' },
            { text: 'Graduation', value: 'Graduation' },
            { text: 'Post-Graduation', value: 'Post-Graduation' },
            { text: 'Other', value: 'Other' },
          ],
          label: 'Status Of Education',
        },
        {
          property: 'degreeTitle',
          type: 'input',
          label: 'Degree Title',
        },
        {
          property: 'college',
          type: 'input',
          label: 'College Name',
        },
        {
          property: 'yearOfPassing',
          type: 'input',
          label: 'Year Of Passing',
        },
        { property: 'marks', type: 'input' },
        // {
        //   property: 'document',
        //   type: 'file',
        //   accept: 'application/pdf',
        //   required: true,
        //   label: 'Documents'
        // }
      ],
      initialFormState: {
        statusOfEducation: data?.statusOfEducation,
        degreeTitle: data?.degreeTitle,
        college: data?.college,
        yearOfPassing: data?.yearOfPassing,
        marks: data?.marks,
        // document: data?.document
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      // upload: {
      //   useFileTransfer: useFileTransfer,
      //   config: [
      //     {
      //       name: 'document',
      //       path: `${companyGroup.id}/${company.id}/employees/education-details/${employeeId}/${data.statusOfEducation}-${data.degreeTitle}`,
      //       type: 'private'
      //     }
      //   ]
      // },
      allowUnchanged: true,
    };

    const Form = renderFormV2<Partial<EducationDetailsType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<EducationDetailsType>>
    ): EducationDetailsType {
      return {
        statusOfEducation: b.statusOfEducation as StatusOfEducationType,
        degreeTitle: b.degreeTitle as string,
        college: b.college as string,
        yearOfPassing: b.yearOfPassing as string,
        marks: b.marks as number,
        // document: b.document as string
      } as any;
    }
  };
}
