import { useEffect } from 'react';
import { UseBatchItemGodowns } from '../../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { Batch } from '../../../../modules/common/fragments/batch';

export function renderVendorGodown({
  id,
  useBatchItemGodowns,
}: {
  id: string;
  useBatchItemGodowns: UseBatchItemGodowns;
}) {
  return function VendorGodown(): JSX.Element {
    const { data: batches, getAll: getBatches } = useBatchItemGodowns();
    useEffect(() => {
      getBatches({
        getAll: true,
        // search?: string;
        // item_id?: string;
        godownId: id,
        // category?: string;
        // lastModifiedBy?: string;
        // reference_id?: string;
        // state?: string;
        // grade_id?: string;
        // active?: boolean;
        crossLocations: true,
        // expiry_by?: string;
        // action?: string;
        // reference_type?: string;
      });
      // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
      console.log(batches);
    }, [batches]);
    return (
      <div className='flex flex-wrap'>
        {batches?.map((x) => (
          <div key={x.id}>
            <Batch
              id={x.id}
              quantity={x.quantity}
              name={x.name}
              description={<>{(x as any)?.grade?.name}</>}
              state={(x as any).state}
            />
          </div>
        ))}
      </div>
    );
  };
}
