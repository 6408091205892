import { Company } from '@erp_core/erp-types/dist/modules/admin';
import { BankAccountDetail } from '@erp_core/erp-types/dist/types/modules/admin/company';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';

type CompanyRegistrationProps = {
  company: Company;
  setCompany: (s: Company) => Promise<Company>;
};

export function createCompanyRegistration(): ({
  company,
  setCompany,
}: CompanyRegistrationProps) => JSX.Element {
  const Table = renderTableComponent();
  const Card = renderCardComponent();

  return function CompanyRegistration({
    company,
    setCompany,
  }: CompanyRegistrationProps): JSX.Element {
    const cardHeader: CardHeader = {
      title: 'Bank Details',
      actions: [
        {
          type: 'button',
          button: {
            behaviour: 'modal',
            modal: {
              content: ({ onClose }) => {
                const Form = renderFormV2<Partial<BankAccountDetail>>({
                  formName: 'Add Bank',
                  fieldsData: [
                    {
                      label: 'Account',
                      property: 'account',
                      type: 'input',
                      required: true,
                    },
                    {
                      label: 'Name',
                      property: 'name',
                      type: 'input',
                      required: true,
                    },
                    {
                      label: 'Branch',
                      property: 'branch',
                      type: 'input',
                      required: true,
                    },
                    {
                      label: 'Currency',
                      property: 'currency',
                      type: 'input',
                      required: true,
                    },
                    {
                      label: 'Address',
                      property: 'address',
                      type: 'input',
                      required: true,
                    },
                    { property: 'ifsc', type: 'input' },
                    { property: 'accountId', type: 'input' },
                    { property: 'logo', type: 'input' },
                    { property: 'swift', type: 'input' },
                    { property: 'adCode', type: 'input' },
                  ],
                  style: 'w-1/2',
                  initialFormState: {
                    account: '',
                    name: '',
                    branch: '',
                    ifsc: '',
                    // code: '',
                    logo: '',
                    swift: '',
                    currency: '',
                    adCode: '',
                    accountId: '',
                    address: '',
                  },
                  onSubmit: async (res) => {
                    const bankAccounts = company.details.bankAccounts || [];
                    console.log(bankAccounts);
                    bankAccounts.push(res as BankAccountDetail);
                    console.log(bankAccounts);
                    await setCompany({
                      id: company.id,
                      details: { bankAccounts },
                    } as Company);
                    onClose();
                  },
                  mapTToU: (s) => {
                    return s as any;
                  },
                });

                return <Form />;
              },
              title: 'Add Bank',
            },
            name: 'Add',
          },
        },
      ],
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Account' },
        { name: 'Name' },
        { name: 'Branch' },
        { name: 'Currency' },
        { name: 'IFSC / Swift' },
        { name: 'Logo' },
      ],
    ];

    const tableBody: TableBody = company.details?.bankAccounts
      ? company.details.bankAccounts.map((r, idx) => ({
          rowData: { bank: r, idx: idx },
          cells: [
            { value: r.account },
            { value: r.name },
            { value: r.branch },
            { value: r.currency },
            { value: (r.ifsc || '-') + '/' + (r.swift || '-') },
            { value: <img src={r.logo} alt={r.name} /> },
          ],
        }))
      : [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          body={tableBody}
          actions={[
            {
              name: 'Edit',
              show: (p) => true,
              behaviour: 'modal',
              modal: {
                title: 'Edit',
                content: ({ data, onClose }) => {
                  // const bank = company.details.bankAccounts[idx];
                  const bank = data.bank;
                  const idx = data.idx;

                  const Form = renderFormV2<BankAccountDetail>({
                    formName: 'Edit Bank',
                    fieldsData: [
                      {
                        label: 'Account',
                        property: 'account',
                        type: 'input',
                        required: true,
                      },
                      {
                        label: 'Name',
                        property: 'name',
                        type: 'input',
                        required: true,
                      },
                      {
                        label: 'Branch',
                        property: 'branch',
                        type: 'input',
                        required: true,
                      },
                      {
                        label: 'Address',
                        property: 'address',
                        type: 'input',
                        required: true,
                      },
                      {
                        label: 'Currency',
                        property: 'currency',
                        type: 'input',
                        required: true,
                      },
                      { property: 'ifsc', type: 'input' },
                      { property: 'accountId', type: 'input' },
                      { property: 'logo', type: 'input' },
                      { property: 'swift', type: 'input' },
                      { property: 'adCode', type: 'input' },
                    ],
                    style: 'w-1/2',
                    initialFormState: { ...bank } as any,
                    onSubmit: async (res) => {
                      const bankAccounts = company.details.bankAccounts || [];
                      bankAccounts[idx].account =
                        res.account || bankAccounts[idx].account;
                      bankAccounts[idx].name =
                        res.name || bankAccounts[idx].name;
                      bankAccounts[idx].branch =
                        res.branch || bankAccounts[idx].branch;
                      bankAccounts[idx].address =
                        res.address || bankAccounts[idx].address;
                      bankAccounts[idx].accountId =
                        res.accountId || bankAccounts[idx].accountId;
                      bankAccounts[idx].ifsc =
                        res.ifsc || bankAccounts[idx].ifsc;
                      bankAccounts[idx].logo =
                        res.logo || bankAccounts[idx].logo;
                      bankAccounts[idx].swift =
                        res.swift || bankAccounts[idx].swift;
                      bankAccounts[idx].currency =
                        res.currency || bankAccounts[idx].currency;
                      bankAccounts[idx].adCode =
                        res.adCode || bankAccounts[idx].adCode;

                      await setCompany({
                        id: company.id,
                        details: { bankAccounts },
                      } as Company);
                      onClose();
                    },
                    mapTToU: (s) => {
                      return s as any;
                    },
                  });

                  return <Form />;
                },
              },
            },
          ]}
        />
      ),
    };

    return (
      <>
        <Card header={cardHeader} body={cardBody} />
      </>
    );
  };
}
