export function renderProfitLossStatement(): () => JSX.Element {
  return function ProfitLossStatement(): JSX.Element {
    return (
      <>
        <div>
          <table className='px-6 py-4 whitespace-nowrap border-collapse w-full border border-black'>
            <tbody>
              <tr>
                <td className='px-6 py-4 whitespace-nowrap w-full text-center'>
                  <b>Profit And Loss Statement</b>
                </td>
              </tr>
            </tbody>
          </table>
          <table className='px-6 py-4 whitespace-nowrap border-collapse w-full border border-black'>
            <tbody>
              <tr>
                <td className='px-6 py-4 whitespace-nowrap w-full text-center'>
                  <b>FY 2023-2024</b>
                </td>
              </tr>
            </tbody>
          </table>
          <table className='px-6 py-4 whitespace-nowrap border-collapse w-full border border-black'>
            <tbody>
              <tr>
                <td className='px-6 py-4 whitespace-nowrap text-right'>
                  Company Name &nbsp; 2023-2024
                  <br />
                  as at &nbsp; 2023-01-14
                </td>
                <td className='px-6 py-4 whitespace-nowrap w-2/4 border-l border-black text-right'>
                  Company Name &nbsp; 2023-2024
                  <br />
                  as at &nbsp; 2023-01-14
                </td>
              </tr>
              <tr>
                <td className='px-6 py-4 whitespace-nowrap w-2/4'>
                  <b>Particulars</b>
                </td>
                <td className='px-6 py-4 whitespace-nowrap w-2/4 border-l border-black'>
                  <b>Particulars</b>
                </td>
              </tr>
              <tr>
                <td className='px-6 py-4 whitespace-nowrap'>
                  <b>Data 1</b>
                </td>
                <td className='px-6 py-4 whitespace-nowrap w-2/4 border-l border-black'>
                  <b>Data 1</b>
                </td>
              </tr>
              <tr>
                <td className='px-6 py-4 whitespace-nowrap'>Data 2</td>
                <td className='px-6 py-4 whitespace-nowrap w-2/4 border-l border-black'>
                  Data 2
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className='px-6 py-4 whitespace-nowrap w-2/4 border-l border-black'>
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td className='px-6 py-4 whitespace-nowrap'>&nbsp;</td>
                <td className='px-6 py-4 whitespace-nowrap w-2/4 border-l border-black'>
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td className='px-6 py-4 whitespace-nowrap'>&nbsp;</td>
                <td className='px-6 py-4 whitespace-nowrap w-2/4 border-l border-black'>
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td className='px-6 py-4 whitespace-nowrap'>&nbsp;</td>
                <td className='px-6 py-4 whitespace-nowrap w-2/4 border-l border-black'>
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td className='px-6 py-4 whitespace-nowrap'>&nbsp;</td>
                <td className='px-6 py-4 whitespace-nowrap w-2/4 border-l border-black'>
                  &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
          <table className='px-6 py-4 whitespace-nowrap border-collapse w-full border border-black'>
            <tbody>
              <tr>
                <td className='px-6 py-4 whitespace-nowrap w-1/4  border-black'>
                  <b>Total</b>
                </td>
                <td className='px-6 py-4 whitespace-nowrap w-1/4  border-black text-right'>
                  <b>1235</b>
                </td>
                <td className='px-6 py-4 whitespace-nowrap w-1/4 border-l border-black'>
                  <b>Total</b>
                </td>
                <td className='px-6 py-4 whitespace-nowrap w-1/4  border-black text-right'>
                  <b>1235</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };
}
