import { PageNotFound, UnAuthorizedPage } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { renderAuthWrapper } from '../../../components/auth';
import { UseCurrentUserRoles } from '../../../hooks/admin/role-admin/use-current-user-roles';
import { UseCurrentUser } from '../../../hooks/admin/user-admin/use-current-user';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseDashboards } from '../../../hooks/analytics/dashboard/use-dashboards';
import { DashboardInterface } from '../../../models/interfaces/analytics/dashboard';
import { createDashboardRenderer } from '../../../modules/admin/dashboard/dashboard-renderer';
import { ModuleType } from '../modules';

export function LayoutBody({
  path,
  modules,
  useUserAuthorization,
  useDashboards,
  useCurrentUser,
  useCurrentUserRoles,
  dashboardService,
}: {
  path: string;
  modules: ModuleType[];
  useUserAuthorization: UseUserAuthorization;
  useDashboards: UseDashboards;
  useCurrentUser: UseCurrentUser;
  useCurrentUserRoles: UseCurrentUserRoles;
  dashboardService: DashboardInterface;
}): JSX.Element {
  const AuthWrapper = renderAuthWrapper({ useUserAuthorization });
  const DashboardRenderer = createDashboardRenderer({
    useDashboards,
    useCurrentUser,
    useCurrentUserRoles,
    dashboardService,
  });

  return (
    <>
      <Breadcrumbs path={path} />
      <div className='md:container md:mx-auto'>
        <Routes>
          <Route path='/'>
            {modules.map((module) => (
              <Route
                path={module.path}
                key={`${module.name}-module`}
                element={
                  module.content ? (
                    <Page name={module.name} page={<module.content />} />
                  ) : (
                    <Page
                      name={module.name}
                      page={
                        <DashboardRenderer
                          module={_.kebabCase(module.name.toLowerCase())}
                        />
                      }
                    />
                  )
                }
              ></Route>
            ))}
            {modules.map((module) => (
              <Route path={module.path} key={module.name}>
                {module.sidebarLinks.map((link, idx) => {
                  if (link.type === 'link' && link.auth) {
                    if (link.auth) {
                      return (
                        <Route
                          path={link.path}
                          key={idx}
                          element={
                            <AuthWrapper
                              action={link.auth}
                              children={
                                <Page
                                  menu={`${module.name}`}
                                  name={link.name}
                                  page={<link.content />}
                                />
                              }
                            />
                          }
                        />
                      );
                    }

                    return (
                      <Route
                        path={link.path}
                        key={idx}
                        element={
                          <Page
                            menu={`${module.name}`}
                            name={link.name}
                            page={<link.content />}
                          />
                        }
                      />
                    );
                  }

                  if (link.type === 'menu' && link.menu?.length) {
                    return (
                      <Route path={link.path} key={idx}>
                        {link.menu.map((subLink, subIdx) => {
                          if (subLink.auth) {
                            return (
                              <Route
                                path={`${subLink.path}/*`}
                                key={subIdx}
                                element={
                                  <AuthWrapper
                                    customAuthorization={<PageNotAuthorized />}
                                    action={subLink.auth}
                                    children={
                                      <Page
                                        menu={`${module.name}/${link.name}`}
                                        name={subLink.name}
                                        page={<subLink.content />}
                                      />
                                    }
                                  />
                                }
                              />
                            );
                          }

                          return (
                            <Route
                              path={subLink.path}
                              key={subIdx}
                              element={
                                <Page
                                  menu={`${module.name}/${link.name}`}
                                  name={subLink.name}
                                  page={<subLink.content />}
                                />
                              }
                            />
                          );
                        })}
                      </Route>
                    );
                  }

                  return (
                    <Fragment key={idx}>Unhandled Render Condition</Fragment>
                  );
                })}
              </Route>
            ))}
          </Route>
          <Route
            path='*'
            element={<Page name='Page Not Found' page={<PageNotFound />} />}
          />
        </Routes>
      </div>
    </>
  );
}

function Breadcrumbs({ path }: { path: string }) {
  const [crumbs, setCrumbs] = useState<{ name: string; link: string }[]>([]);
  useEffect(() => {
    const split = _.compact(path.split('/'));
    const newCrumbs: { name: string; link: string }[] = [];
    let link = '';
    for (const x of split) {
      link += `/${x}`;
      newCrumbs.push({
        name: x,
        link: link,
      });
    }
    setCrumbs(newCrumbs);
  }, [path]);

  return (
    <div className='bg-indigo-100 py-2 pl-8 md:pl-16 border-b-1 border-gray-500 text-indigo-800'>
      {crumbs.map((c, cIdx) => (
        <span key={cIdx}>
          <a className='capitalize hover:underline' href={c.link}>
            {c.name}
          </a>
          {cIdx !== crumbs.length - 1 ? (
            <span className='mx-2'>&gt;</span>
          ) : null}
        </span>
      ))}
    </div>
  );
}

function Page({
  menu,
  name,
  page,
}: {
  menu?: string;
  name: string;
  page: JSX.Element;
}): JSX.Element {
  useEffect(() => {
    if (name) {
      document.title = `${menu ? `${menu}/` : ''}${name}`;
    }
  }, [name, menu]);

  return page;
}

function PageNotAuthorized() {
  return (
    <div className='w-2/5 m-auto text-green-600 h-full flex justify-center items-center'>
      <UnAuthorizedPage />
    </div>
  );
}
