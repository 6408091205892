/* eslint-disable no-unused-vars */

import { LocationType } from '@erp_core/erp-types/dist/modules/admin';
import { createContext } from 'react';

export const LocationContext = createContext<{
  location: LocationType;
  setLocation: (l: LocationType) => void;
}>({
  location: {} as LocationType,
  setLocation: (l: LocationType) => {},
});
