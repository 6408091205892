import { ExcelDownload } from '@erp_core/erp-icons/icons/web/excel-download';
import {
  renderBulkDownload,
  XlsxDownloadMapper,
} from '@erp_core/erp-ui-components';
import { AttendanceInterface } from '../../../../../models/interfaces/hrd/attendance';

const finalizationMapper: XlsxDownloadMapper = [
  {
    columnName: 'employee_name',
    dataBinding: {
      resource: 'employee',
      property: 'employee.name',
    },
  },
  {
    columnName: 'employee_id',
    dataBinding: {
      resource: 'employee',
      property: 'employee.id',
    },
  },
  {
    columnName: 'month',
    dataBinding: { property: 'month' },
  },
  {
    columnName: 'finalized',
    dataBinding: { property: 'finalized' },
  },
  {
    columnName: 'absent-days',
    dataBinding: { resource: 'summary', property: 'summary.finalized' },
  },
  {
    columnName: 'ot-sal-hours',
    dataBinding: {
      resource: 'summary',
      property: 'summary.overtimeSalaryHours',
    },
  },
  {
    columnName: 'payable-days',
    dataBinding: { resource: 'summary', property: 'summary.payableDays' },
  },
  {
    columnName: 'payable-hours',
    dataBinding: { resource: 'summary', property: 'summary.payableHours' },
  },
  {
    columnName: 'present-days',
    dataBinding: { resource: 'summary', property: 'summary.presentDays' },
  },
];

export function FinalizationDetailsReport({
  attendanceService,
  month,
}: {
  attendanceService: AttendanceInterface;
  month: string;
}): JSX.Element {
  const FinalizationReport = renderBulkDownload({
    mapperFun: async () => {
      const items = await attendanceService.getSummary(month);

      const result: {
        mapper: XlsxDownloadMapper;
        data: any[];
        fileName: string;
      }[] = [];

      if (items.length !== 0) {
        const dynamicMapper: XlsxDownloadMapper = [...finalizationMapper];

        const fileName = 'report'; // File name based in stock group id

        result.push({ mapper: dynamicMapper, data: items, fileName });
      }
      return result;
    },
    downloadIcon: ExcelDownload,
  });

  return (
    <div className='mx-auto'>
      <div className='flex justify-center'>
        <span className='flex border border-green-500 rounded p-1'>
          <FinalizationReport /> Download Finalization Report
        </span>
      </div>
    </div>
  );
}
