import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import {
  CombinedAttDataType,
  EmployeeProfileType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { GatePassType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import { LoadingButton, ModalV2Props } from '@erp_core/erp-ui-components';
import moment from 'moment';
import toast from 'react-hot-toast';
import { UseEmployeeProfiles } from '../../../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseLeaves } from '../../../../../../hooks/hrd/leave/use-leaves';
import { GatePassFilterType } from '../../../../../../models/interfaces/hrd/employee/gate-pass';
import { renderGatePassForm } from '../../../forms/gate-pass';

export function renderAddGatepassButton({
  attendance,
  useEmployees,
  useLeaves,
  setModal,
  modal,
  getEmployeeSync,
  currentUser,
  setGatePass,
  getGatePasses,
  date,
}: {
  attendance: CombinedAttDataType;
  useEmployees: UseEmployeeProfiles;
  useLeaves: UseLeaves;
  modal: ModalV2Props;
  setModal: (value: React.SetStateAction<ModalV2Props>) => void;
  getEmployeeSync: (id: string) => Promise<EmployeeProfileType>;
  currentUser: CurrentUserType;
  setGatePass: (s: GatePassType) => Promise<GatePassType>;
  getGatePasses: (filter?: GatePassFilterType | undefined) => void;
  date: string;
}) {
  const GatePassForm = renderAddGatePassForm({
    getEmployeeSync,
    currentUser,
    setGatePass,
    useEmployees,
    useLeaves,
  });
  return function AddGatepassButton(): JSX.Element {
    return (
      <LoadingButton
        // type='button'
        defaultStyle='mb-1 font-semibold hover:text-black text-gray-800 text-shadow text-left'
        behaviorFn={async () => {
          setModal({
            ...modal,
            isVisible: true,
            title: 'Add Gatepass',
            body: (
              <GatePassForm
                data={{ attendance }}
                onClose={() => {
                  setModal((ms) => ({ ...ms, isVisible: false }));
                  getGatePasses({ date, crossGroup: 'true' });
                }}
              />
            ),
          });
        }}
        text='Add Gatepass'
      />
    );
  };
}

export function renderAddGatePassForm({
  getEmployeeSync,
  currentUser,
  setGatePass,
  useEmployees,
  useLeaves,
}: {
  getEmployeeSync: (id: string) => Promise<EmployeeProfileType>;
  currentUser: CurrentUserType;
  setGatePass: (s: GatePassType) => Promise<GatePassType>;
  useEmployees: UseEmployeeProfiles;
  useLeaves: UseLeaves;
}): ({ data, onClose }: { data: any; onClose: () => void }) => JSX.Element {
  const GatePassForm = renderGatePassForm({ useEmployees });

  return function AddGatePassForm({
    data,
    onClose,
  }: {
    data: {
      attendance: CombinedAttDataType;
    };
    onClose: () => void;
  }): JSX.Element {
    const exitTime =
      moment
        .utc(
          data.attendance?.punchResult?.checkOutList?.[0]?.punches?.[0]
            .timeStamp
        )
        .tz('Asia/Kolkata')
        .format('HH:mm') || '';

    const entryTime =
      moment
        .utc(
          data.attendance?.punchResult?.checkInList?.[1]?.punches?.[0].timeStamp
        )
        .tz('Asia/Kolkata')
        .format('HH:mm') || '';

    const saveGatePass = async (form: any) => {
      const empDetails = await getEmployeeSync(form.employee.id);
      try {
        const finalData = {
          ...form,
          company: empDetails.company,
          issuedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        };
        await setGatePass(finalData);
        onClose();
        toast('Gate pass created sucessfully');
      } catch (error) {
        toast('Something went wrong');
      }
    };

    return (
      <GatePassForm
        data={{
          name: '',
          type: '',
          exitTime,
          entryTime,
          employee: data.attendance.employee as any,
          date: data.attendance.date,
          reason: '',
        }}
        onSave={saveGatePass}
      />
    );
  };
}
