import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTabsComponent,
} from '@erp_core/erp-ui-components';
import { nanoid } from 'nanoid';
import { UseCompanies } from '../../../hooks/admin/company-admin/use-companies-admin';
import { UseCurrentCompany } from '../../../hooks/admin/company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseEmployeeCategories } from '../../../hooks/admin/constants/employee-category/use-employee-categories';
import { UseLocations } from '../../../hooks/admin/location-admin/use-locations';
import { UseOnboardEmployee } from '../../../hooks/admin/onboard-employee/use-onboard-employee';
import { UseOnboardEmployees } from '../../../hooks/admin/onboard-employee/use-onboard-employees';
import { UseCurrentUser } from '../../../hooks/admin/user-admin/use-current-user';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseDepartments } from '../../../hooks/hrd/department/use-departments';
import { UseEmployeeProfile } from '../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseEmployeeProfiles } from '../../../hooks/hrd/employee/profile/use-employee-profiles';
import { renderEmployeeOnboardsRequest } from './employee-onboard-tab/employee-onboard-request';
import { renderEmployeeOnboards } from './employee-onboard-tab/employee-onboard-tab';

type RenderEmployeeOnboardProps = {
  hooks: {
    useEmployeeProfiles: UseEmployeeProfiles;
    useEmployeeProfile: UseEmployeeProfile;
    useCompanies: UseCompanies;
    useDepartments: UseDepartments;
    useLocations: UseLocations;
    useOnboardEmployee: UseOnboardEmployee;
    useCurrentCompanyGroup: UseCurrentCompanyGroup;
    useCurrentUser: UseCurrentUser;
    useCurrentCompany: UseCurrentCompany;
    useOnboardEmployees: UseOnboardEmployees;
    useUserAuthorization: UseUserAuthorization;
    useEmployeeCategories: UseEmployeeCategories;
  };
};

export function renderEmployeeOnboard({
  hooks: {
    useEmployeeProfiles,
    useEmployeeProfile,
    useCompanies,
    useDepartments,
    useLocations,
    useOnboardEmployee,
    useOnboardEmployees,
    useCurrentCompanyGroup,
    useCurrentUser,
    useUserAuthorization,
    useCurrentCompany,
    useEmployeeCategories,
  },
}: RenderEmployeeOnboardProps): () => JSX.Element {
  return function EmployeeExit(): JSX.Element {
    const Tabs = renderTabsComponent();

    const EmployeeOnboardDate = renderEmployeeOnboards({
      useEmployeeProfiles,
      useCompanies,
      useDepartments,
      useLocations,
      useOnboardEmployee,
      useCurrentCompanyGroup,
      useCurrentUser,
      useCurrentCompany,
      useEmployeeCategories,
    });

    const EmployeeOnboardingRequests = renderEmployeeOnboardsRequest({
      useOnboardEmployees,
      useOnboardEmployee,
      useUserAuthorization,
      useCurrentCompany,
      useDepartments,
      useLocations,
      useEmployeeCategories,
      useEmployeeProfiles,
      useCompanies,
    });

    const categories: Map<string, () => JSX.Element> = new Map<
      string,
      () => JSX.Element
    >([
      ['Employee Onboard', EmployeeOnboardDate],
      ['Onboarding Requests', EmployeeOnboardingRequests],
    ]);

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Employee Onboard',
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: <Tabs data={categories} keyTab={nanoid()} defaultTabIndex={0} />,
    };

    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
