import { Client, ClientItem } from '@erp_core/erp-types/dist/modules/order';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { FormField, renderFormV2 } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseItems } from '../../../../hooks/inventory/item/use-items';
import { ItemInterface } from '../../../../models/interfaces/inventory/item';
import { CategoryIcons } from '../../../inventory/items/item-categories';

type Props = { onClose: () => void };

export function renderAddClientItemForm({
  client,
  setClientItem,
  useItems,
  itemService,
}: {
  client: Client;
  setClientItem: (any) => Promise<ClientItem>;
  useItems: UseItems;
  itemService: ItemInterface;
}): (props: Props) => JSX.Element {
  return function AddClientItemForm({ onClose }: Props): JSX.Element {
    const initialOptions = [{ value: '', text: 'Select' }];
    const [item, setItem] = useState<IdName | undefined>(undefined);
    const [properties, setProperties] = useState<Array<ItemPropertyValue>>([]);

    useEffect(() => {
      if (item?.id) {
        itemService.getProperties(item.id).then((c) => {
          setProperties(c);
        });
      }
    }, [item]);

    const endUseFiltered = properties.filter((r) => r.name === 'end-use');

    const endUseProductData = _.map(endUseFiltered, (o, i) =>
      _.map(o.value?.data, (m, idx) => ({
        id: idx + 1,
        name: m?.['end-use-product'],
      }))
    );

    type AddItemForm2Type = {
      item?: { id: string; name: string };
      manufacturer: string;
      clientLocation: string;
      contactPerson: string;
      endUse: { id: string; name: string }[];
    };

    type AddItemForm1Type = {
      item: { id: string; name: string };
    };

    const addItem = async (form: AddItemForm2Type) => {
      const existingItems = client.items
        ? client.items.map((v) => ({
            id: v.id,
            client: { id: v.client.id },
            item: { id: v.item.id },
            details: v.details,
          }))
        : [];

      if (
        existingItems.find(
          (i) =>
            i.item.id === form?.item?.id &&
            i?.details?.contactPerson?.id === form.contactPerson &&
            i?.details?.clientLocation?.id === form.clientLocation
        )
      ) {
        toast(
          `Item ${form?.item?.name} with same contact person and client already exists`
        );
        return;
      }

      const finalDataCI = ({
        name: form.item?.name,
        item: { id: form.item?.id },
        client: { id: client.id },
        status: 'unapproved',
        details: {
          endUse: form?.endUse || [],
          grade: '',
          packing: '',
          manufacturer: {
            id: '',
            name: form.manufacturer,
          },
          clientLocation: {
            id: form.clientLocation,
            name:
              client.details?.locations?.find((s) => {
                return (
                  s.id === form.clientLocation || s.name === form.clientLocation
                );
              })?.name || '',
          },
          contactPerson: {
            id: form.contactPerson,
            name:
              client.details?.contact?.find((s) => {
                return (
                  s.id === form.contactPerson || s.name === form.contactPerson
                );
              })?.name || '',
          },
        },
      } as unknown) as ClientItem;
      await setClientItem(finalDataCI as ClientItem);
      toast('Item added for client');
    };

    const fields: Array<FormField<AddItemForm2Type>> = [
      {
        type: 'searchable-multi-select',
        label: 'End Use',
        property: 'endUse',
        searchOptions: {
          useSearch: () => {
            return {
              data: endUseProductData[0] || [],
              getAll: (filter: { search?: string | undefined }) => {},
            };
          },
          onSearchValueSelect: () => {},
        },
      },
      {
        property: 'manufacturer',
        type: 'input',
        required: true,
        label: 'Manufacturer',
        // readonly: client.details?.partyProfile?.clientType === 'manufacturer',
      },
      {
        property: 'clientLocation',
        type: 'select',
        options: [
          ...initialOptions,
          ...((client?.details?.locations
            ? client?.details?.locations?.map((m) => ({
                value: m.id || m.name,
                text: m.name,
              }))
            : '') || []),
        ],
        required: true,
        label: 'Client Location',
      },
      {
        property: 'contactPerson',
        type: 'select',
        options: [
          ...initialOptions,
          ...(client?.details?.contact?.map((m) => ({
            value: m.id || m.name,
            text: m.name,
          })) || []),
        ],
        required: true,
        label: 'Contact Person',
      },
    ];

    const Form1 = renderFormV2<AddItemForm1Type>({
      style: 'md:w-1/2',
      fieldsData: [
        {
          property: 'item',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useItems,
            onSearchValueSelect: () => {},
            searchOptionsBody: {
              customBody(data) {
                return (
                  <div>
                    {data.categories?.map((x) => (
                      <CategoryIcons category={x.category} key={x.id} />
                    ))}
                    {data?.name}
                  </div>
                );
              },
            },
            // TODO: Filter should accept more than one values in this case
            //       we have to filter on 'RM','FA'
            filter: { category: 'FG' },
          },
          label: 'Item',
        },
      ],
      initialFormState: {
        item: { id: '', name: '' },
      },
      onSubmit: async (formData) => {
        if (formData.item.id) {
          setItem({
            id: formData.item.id,
            name: formData.item.name,
          });
        }
      },
      allowUnchanged: true,
      mapTToU: (b) => b as AddItemForm1Type,
    });

    const Form2 = renderFormV2<AddItemForm2Type>({
      style: 'md:w-1/2',
      fieldsData: fields,
      initialFormState: {
        item: { id: item?.id || '', name: item?.name || '' },
        manufacturer:
          client.details?.partyProfile?.clientType === 'manufacturer'
            ? 'self'
            : '',
        clientLocation: '',
        contactPerson: '',
        endUse: [],
      },
      onSubmit: async (data) => {
        await addItem(data);
        onClose();
      },
      allowUnchanged: true,
      mapTToU: (b) => b as AddItemForm2Type,
    });

    if (!item) {
      return <Form1 />;
    }

    return <Form2 />;
  };
}
