import { Godown } from '@erp_core/erp-types/dist/modules/inventory';
import {
  LoadingButton,
  ModalV2Props,
  renderFormV2,
  renderModal,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

export function SelectFinalEmployees({
  godown,
  setGodown,
}: {
  godown: Godown;
  setGodown: (s: Godown) => Promise<Godown>;
}): JSX.Element {
  const selectedEmployees: Array<{ name: string; id: string }> =
    (godown.details as any).selectedEmployees || [];
  const selectedRelievers: Array<{ name: string; id: string }> =
    (godown.details as any).selectedRelievers || [];
  type FormType = {
    employees: { id: string; name: string }[];
    relievers: { id: string; name: string }[];
  };
  const Modal = renderModal();
  function closeModal() {
    setModal({
      isVisible: false,
      onClose: closeModal,
      title: 'Edit Selected Employees',
      body: <div></div>,
    });
  }

  function openModal() {
    const Form = renderFormV2<FormType>({
      fieldsData: [
        {
          property: 'employees',
          type: 'searchable-multi-select',
          label: 'Employees',
          searchOptions: {
            useSearch: () => {
              return {
                data: godown.details.employees || [],
                getAll: (filter: { search?: string | undefined }) => {},
              };
            },
            onSearchValueSelect(u) {},
          },
        },
        {
          property: 'relievers',
          type: 'searchable-multi-select',
          label: 'Relievers',
          searchOptions: {
            useSearch: () => {
              return {
                data: godown.details.employees || [],
                getAll: (filter: { search?: string | undefined }) => {},
              };
            },
            onSearchValueSelect(u) {},
          },
        },
      ],
      initialFormState: {
        employees: selectedEmployees,
        relievers: selectedRelievers,
      },
      onSubmit: async (data) => {
        setGodown({
          id: godown.id,
          details: {
            selectedEmployees: data.employees,
            selectedRelievers: data.relievers,
          },
        } as any);
      },
      mapTToU(p) {
        return (p as unknown) as FormType;
      },
    });
    setModal({
      isVisible: true,
      onClose: closeModal,
      title: 'Edit Selected Employees',
      body: <Form />,
    });
  }

  const [modal, setModal] = useState<ModalV2Props>({
    isVisible: false,
    onClose: closeModal,
    title: 'Edit Selected Employees',
    body: <div></div>,
  });

  return (
    <div className='p-2 border border-gray-200 rounded-lg'>
      <div>
        <div>
          <div className='text-lg text-center'>Shortlisted Employees</div>
          <div className='flex my-1'>
            <div className='flex-auto'></div>
            {godown.details.employees?.map((e) => (
              <div key={e.id} className='border border-gray-200 p-1 rounded-md'>
                <UserIcon className='w-4 inline' /> {e.name}
              </div>
            ))}
            <div className='flex-auto'></div>
          </div>
        </div>
      </div>
      <div className='text-lg text-center'>Selected Employees</div>
      <div className='flex'>
        <div className='flex-auto'>
          <div className='flex'>
            <div className='font-semibold mx-1'>Employees:</div>
            {selectedEmployees.map((x) => (
              <div className='mx-1 py-0.5 px-2 border rounded-full' key={x.id}>
                {x.name}
              </div>
            ))}
            {selectedRelievers.length ? (
              <>
                <div className='font-semibold mx-1'>Relievers:</div>
                {selectedRelievers.map((x) => (
                  <div
                    className='mx-1 py-0.5 px-2 border rounded-full'
                    key={x.id}
                  >
                    {x.name}
                  </div>
                ))}
              </>
            ) : null}
          </div>
        </div>
        <div>
          <LoadingButton
            behaviorFn={async () => openModal()}
            defaultStyle='bg-green-600 text-white px-2 py-1 border rounded-md'
            text='Edit'
          />
        </div>
      </div>
      <Modal {...modal} />
    </div>
  );
}
