import {
  LeadCustomerEnquiryFilterType,
  LeadCustomerEnquiryItems,
  LeadCustomerEnquiryType,
  LeadCustomerType,
} from '@erp_core/erp-types/dist/modules/order';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { SalesEnquiryFilter } from '@erp_core/erp-types/dist/types/modules/order/sales/enquiry';
import {
  CardBody,
  CardHeader,
  Filter,
  Modal,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';

import { UserIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { UseMetrics } from '../../../../hooks/admin/constants/metrics/use-metrics';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseItems } from '../../../../hooks/inventory/item/use-items';
import { UseClient } from '../../../../hooks/order/sales/client/use-client';
import { UseClients } from '../../../../hooks/order/sales/client/use-clients';
import { UseLeadCustomerEnquiries } from '../../../../hooks/order/sales/lead-customer-enquiry/use-lead-customer-enquiries';
import { UseLeadCustomerEnquiry } from '../../../../hooks/order/sales/lead-customer-enquiry/use-lead-customer-enquiry';
import { UsePaginatedLeadCustomerEnquiries } from '../../../../hooks/order/sales/lead-customer-enquiry/use-paginated-lead-customer-enquiries';
import { UseLeadCustomer } from '../../../../hooks/order/sales/lead-customer/use-lead-customer';
import { UseLeadCustomers } from '../../../../hooks/order/sales/lead-customer/use-lead-customers';
import { ManufacturingVoucherInterface } from '../../../../models/interfaces/order/work-order/manufacturing-voucher.ts';
import { UserRendererInterface } from '../../../common/fragments/user';
import {
  renderAddSalesEnquiryV2Form,
  SalesEnquiryFields,
} from '../forms/add-sales-enquiry-v2';
import {
  renderAddSalesLeadForm,
  SalesLeadFields,
} from '../forms/add-sales-lead';

type RenderSalesEnquiryV2Props = {
  hooks: {
    useItems: UseItems;
    useUsers: UseUsers;
    useClient: UseClient;
    useClients: UseClients;
    useEmployeeProfiles: UseEmployeeProfiles;
    useMetrics: UseMetrics;
    useLeadCustomer: UseLeadCustomer;
    useLeadCustomers: UseLeadCustomers;
    useLeadCustomerEnquiry: UseLeadCustomerEnquiry;
    useLeadCustomerEnquiries: UseLeadCustomerEnquiries;
    usePaginatedLeadCustomerEnquiries: UsePaginatedLeadCustomerEnquiries;
  };
  services: {
    manufacturingVoucherService: ManufacturingVoucherInterface;
    userRendererService: UserRendererInterface;
  };
};

export function renderSalesEnquiryLeadDetailsV2({
  hooks: {
    useItems,
    useUsers,
    useClient,
    useClients,
    useEmployeeProfiles,
    useMetrics,
    useLeadCustomer,
    useLeadCustomers,
    useLeadCustomerEnquiry,
    useLeadCustomerEnquiries,
    usePaginatedLeadCustomerEnquiries,
  },
  services: { manufacturingVoucherService, userRendererService },
}: RenderSalesEnquiryV2Props) {
  const Card = renderCardComponent();
  const Table = renderTableWithMapperComponent<
    LeadCustomerEnquiryType,
    LeadCustomerEnquiryFilterType
  >();

  return function SalesEnquiryDetails() {
    // const { set: setSalesEnquiry } = useEnquiry();

    const { set: setLeadCustomerEnquiry } = useLeadCustomerEnquiry();

    const [modalState, setModalState] = useState({
      visible: false,
      title: 'Add enquiry details',
      content: <>Add enquiry details</>,
    });

    const tableHeader: TableHeader = [
      [
        { name: 'Product', rowSpan: 2 },
        { name: 'Customer', rowSpan: 2 },
        { name: 'Contact', rowSpan: 2 },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Assigned To
            </>
          ),

          rowSpan: 2,
        },
        { name: 'Type', rowSpan: 2 },
        { name: 'Follow up Date', rowSpan: 2 },
        { name: 'Days Lapsed', rowSpan: 2 },
        { name: 'Stage', rowSpan: 2 },
        // { name: 'Status',  rowSpan: 2 },
      ],
    ];

    const bodyMapper = (enq: LeadCustomerEnquiryType) => {
      const latestFollowedUpDateEntry = _.maxBy(
        enq.details.followUps,
        'followedUpDate'
      );

      const cells: Array<TableCell> = [
        {
          value: (
            <>
              {enq?.enquiryItems.length > 0
                ? enq?.enquiryItems.map((ei, idx) => (
                    <span key={idx}> {ei?.item?.name},</span>
                  ))
                : '--'}
            </>
          ),
          link: `/sales/enquiries/lead-customer-enquiry/${enq?.id}/details`,
        },
        {
          value: enq?.leadCustomer?.name || '',
        },
        { value: enq?.details?.customerContact || '' },
        {
          value: (
            <userRendererService.userCard
              id={enq.assignedTo?.id}
              link={true}
              name={enq.assignedTo?.name}
              size='small'
            />
          ),
        },
        { value: enq?.details?.type || '' },
        { value: latestFollowedUpDateEntry?.followedUpDate || '-' },
        { value: `${moment().diff(enq?.details?.date, 'days')}` },
        {
          //TODO: Phase Enquiry-Draft entry should also be included in enquiry followup table
          value: !enq?.details?.followUps
            ? enq?.details?.stage || ''
            : latestFollowedUpDateEntry?.followupStage !== 'Qualified to Buy'
            ? latestFollowedUpDateEntry?.followupStage || ''
            : `${enq?.details?.stage || ''}- ${
                latestFollowedUpDateEntry?.followupStage || ''
              }`,
        },
        // { value: enq.details?.status || '-' }
      ];
      return {
        cells,
      };
    };

    const filter: Filter<SalesEnquiryFilter> = {
      sortFields: [
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'createdBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: SalesEnquiryFilter) => {
        const filterData: SalesEnquiryFilter = {};

        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }
        if (filterSelection.createdBy) {
          filterData.createdBy = filterSelection.createdBy;
        }

        return filterData as SalesEnquiryFilter;
      },
      filterResources: {
        createdBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };

    const cardHeader: CardHeader = {
      title: 'Leads & Sales Enquiries',
      menu: {
        actions: [
          {
            name: 'Add Lead',
            show: () => true,
            behaviour: 'modal',
            modal: {
              title: 'Add Sales Lead',
              content: ({ onClose }) => {
                const Form = renderAddSalesLeadForm({
                  useItems,
                  useEmployeeProfiles,
                  useLeadCustomers,
                  useLeadCustomer,
                });

                const handleAddLeadSales = async (
                  formData: SalesLeadFields
                ) => {
                  try {
                    // console.log(formData);
                    const finalData: Partial<LeadCustomerEnquiryType> = {
                      leadCustomer: {
                        id: formData?.customer?.id,
                        name: formData?.customer?.name,
                      } as LeadCustomerType,
                      assignedTo: formData?.assignedTo as IdName,
                      generatedBy: formData?.leadGeneratedBy as IdName,
                      // item: formData?.product || { id: '', name: '' },
                      enquiryItems: [
                        {
                          item: {
                            id: formData.product?.id,
                            name: formData.product?.name,
                          },
                          details: {},
                        },
                      ] as LeadCustomerEnquiryItems[],
                      details: {
                        date: formData?.date || '',
                        type: formData?.type || '',
                        stage: formData?.stage || '',
                        customerContact: (formData?.contact as string) || '',
                        description: formData?.description || '',
                        targetPrice: formData?.targetPrice || '',
                        exportDataPrice: formData?.exportDataPrice || '',
                        priceAvail: formData?.prevailPrice || '',
                        currentSupplier: formData?.currentSupplier || '',
                      },
                    };

                    await setLeadCustomerEnquiry(
                      finalData as LeadCustomerEnquiryType
                    );
                    toast('Lead Added successfully');
                    // setModalState((ms) => ({ ...ms, visible: false }));
                    await onClose();
                  } catch (err) {
                    // console.log('Error');
                    toast((err as any).message);
                  }
                };

                return (
                  <>
                    <Form onSave={handleAddLeadSales} />
                  </>
                );
              },
            },
          },
          {
            name: 'Add Enquiry',
            show: () => true,
            behaviour: 'modal',
            modal: {
              title: 'Add Sales Enquiry',
              content: ({ onClose }) => {
                const Form = renderAddSalesEnquiryV2Form({
                  useItems,
                  useEmployeeProfiles,
                  useLeadCustomers,
                  useLeadCustomer,
                  useMetrics,
                  useClient,
                  useClients,
                });

                const handleAddSalesEnquiry = async (
                  formData: SalesEnquiryFields
                ) => {
                  try {
                    // console.log('Add Enquiry Form Data', formData);
                    //  const productsArr = _.values(formData?.products);

                    const finalData: Partial<LeadCustomerEnquiryType> = {
                      leadCustomer: {
                        id: formData?.customer?.id,
                        name: formData?.customer?.name,
                      } as LeadCustomerType,
                      assignedTo: formData?.assignedTo as IdName,
                      generatedBy: formData?.assignedTo as IdName,
                      // enquiryItems: productsArr.map((p) => ({
                      //   item: {
                      //     id: p?.product?.id,
                      //     name: p?.product?.name,
                      //   },
                      //   details: {
                      //     endUse: p?.endUse,
                      //     totalQuantity: p?.totalQuantity,
                      //     totalQuantityUom: p?.totalQuantityUom?.name,
                      //     info: _.values(p?.productInfo).map((pi) => ({
                      //       quantity: pi?.quantity,
                      //       uom: pi?.uom?.name,
                      //       deliveryDate: pi?.deliveryDate,
                      //     }))
                      //   },
                      // })) as LeadCustomerEnquiryItems[],
                      enquiryItems: [] as LeadCustomerEnquiryItems[],
                      details: {
                        date: formData?.date || '',
                        type: formData?.type || '',
                        stage: formData?.stage || '',
                        customerContact: (formData?.contact as string) || '',
                        description: formData?.description || '',
                        enquiryMode: formData?.enquiryMode || '',
                        enquiryReferenceNum: formData?.enquiryRefNo || '',
                        deliveryLocation: formData?.deliveryLocation || '',
                      },
                    };

                    await setLeadCustomerEnquiry(
                      finalData as LeadCustomerEnquiryType
                    );
                    toast('Enquiry Added successfully');
                    // setModalState((ms) => ({ ...ms, visible: false }));
                    await onClose();
                  } catch (err) {
                    // console.log('Error');
                    toast((err as any).message);
                  }
                };

                return (
                  <>
                    <Form onSave={handleAddSalesEnquiry} />
                  </>
                );
              },
            },
          },
        ],
      },
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <Table
            header={tableHeader}
            bodyMapper={bodyMapper}
            useResources={useLeadCustomerEnquiries}
            filter={filter}
            pagination={{
              enabled: true,
              usePaginatedResources: usePaginatedLeadCustomerEnquiries,
            }}
          />
        </>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
        <Modal
          showModal={modalState.visible}
          setShowModal={() =>
            setModalState({ ...modalState, visible: !modalState.visible })
          }
          title={modalState.title}
          onOk={() => {}}
          style={{ width: '800px' }}
        >
          {modalState.content}
        </Modal>
      </div>
    );
  };
}
