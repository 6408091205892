import { XlsxDownloadMapper } from '@erp_core/erp-ui-components';

export const LoanRecoveryMapper: XlsxDownloadMapper = [
  {
    columnName: 'Employee Id',
    dataBinding: { property: 'empid' },
  },
  {
    columnName: 'category',
    dataBinding: { property: 'category' },
  },
  {
    columnName: 'Name',
    dataBinding: { property: 'name' },
  },
  {
    columnName: 'Loan EMI',
    dataBinding: { property: 'loanemi' },
  },
  {
    columnName: 'Loan Interest EMI',
    dataBinding: { property: 'loaninterest' },
  },
  {
    columnName: 'Total Recovered',
    dataBinding: { property: 'totalrecovered' },
  },
];
