import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { renderTableComponent, TableCell } from '@erp_core/erp-ui-components';
// import { useState } from 'react';
import { PencilIcon } from '@heroicons/react/24/outline';
import { renderSalaryRuleSimpleForm } from './form';
import { renderBasicDaForm } from './form/basic-da-settings';

export function renderSalaryRules() {
  // const Modal = renderModal();

  return function SalaryRules({
    comGrpSet,
    saveSalaryRules,
  }: {
    comGrpSet: CompanyGroupSetting;
    saveSalaryRules: (data: any) => Promise<void>;
  }): JSX.Element {
    const salaryRules = comGrpSet.details.hrd?.salaryRules || {};
    // const [modalState, setModalState] = useState<ModalV2Props>({
    //   title: '',
    //   isVisible: false,
    //   onClose: () => setModalState((ms) => ({ ...ms, isVisible: false })),
    //   body: <div className='hidden' />,
    // });

    // const handleSalaryRulesSettingS = () => {
    //   const Form = renderSalaryRuleSimpleForm();
    //   setModalState((ms) => ({
    //     ...ms,
    //     isVisible: true,
    //     title: 'Update Salary Rules',
    //     body: <Form data={salaryRules} onSave={saveSalaryRules} />,
    //   }));
    // };

    // const handleBasicDaSetting = () => {
    //   const Form = renderBasicDaForm();
    //   setModalState((ms) => ({
    //     ...ms,
    //     isVisible: true,
    //     title: 'Update Basic DA',
    //     body: <Form data={salaryRules} onSave={saveSalaryRules} />,
    //   }));
    // };

    const Table = renderTableComponent();

    return (
      <div>
        <Table
          header={[[{ name: 'Name' }, { name: 'Applicable To' }]]}
          body={[
            {
              cells: [
                {
                  value: 'ESIC Settings',
                },
                {
                  value: (
                    <>
                      <div className='my-1'>
                        <span className='px-1 py-0.5 border border-gray-200 rounded-md'>
                          <span className='font-semibold'>
                            Upper Threshold :{' '}
                          </span>
                          {salaryRules.upperESICThreshold || '-'}
                        </span>
                        <span className='px-1 py-0.5 border border-gray-200 rounded-md'>
                          <span className='font-semibold'>
                            Lower Threshold :{' '}
                          </span>
                          {salaryRules.lowerESICThreshold || '-'}
                        </span>
                        <span className='px-1 py-0.5 border border-gray-200 rounded-md'>
                          <span className='font-semibold'>
                            CutOff Switch Dates :{' '}
                          </span>
                          {salaryRules.cutOffESICDates || '-'}
                        </span>
                      </div>
                    </>
                  ),
                  button: {
                    show: () => true,
                    behaviour: 'modal',
                    style: 'w-4 h-4 text-gray-500 cursor-pointer',
                    icon: PencilIcon,
                    modal: {
                      onClose: () => {},
                      title: 'Update Salary Rules',
                      content: () => {
                        const Form = renderSalaryRuleSimpleForm();
                        return (
                          <Form data={salaryRules} onSave={saveSalaryRules} />
                        );
                      },
                    },
                  },
                } as TableCell,
              ],
            },
            {
              cells: [
                {
                  value: 'Basic DA Settings',
                },
                {
                  value: (
                    <>
                      <div className='my-1'>
                        <span className='px-1 py-0.5 border border-gray-200 rounded-md'>
                          <span className='font-semibold'>
                            BasicDA Effective Date :
                          </span>{' '}
                          {salaryRules.basicDaEffectiveDate || '-'}
                        </span>
                        <span className='px-1 py-0.5 border border-gray-200 rounded-md'>
                          <span className='font-semibold'>
                            Basic Skilled :{' '}
                          </span>
                          {salaryRules.basicSkilled || '-'}
                        </span>
                        <span className='px-1 py-0.5 border border-gray-200 rounded-md'>
                          <span className='font-semibold'>
                            Basic Semi-Skilled :{' '}
                          </span>
                          {salaryRules.basicSemiSkilled || '-'}
                        </span>
                        <span className='px-1 py-0.5 border border-gray-200 rounded-md'>
                          <span className='font-semibold'>
                            Basicc Unskilled :{' '}
                          </span>
                          {salaryRules.basicUnSkilled || '-'}
                        </span>
                        <span className='px-1 py-0.5 border border-gray-200 rounded-md'>
                          <span className='font-semibold'>DA Skilled : </span>
                          {salaryRules.daSkilled || '-'}
                        </span>
                        <span className='px-1 py-0.5 border border-gray-200 rounded-md'>
                          <span className='font-semibold'>
                            DA Semi-Skilled :{' '}
                          </span>
                          {salaryRules.daSemiSkilled || '-'}
                        </span>
                        <span className='px-1 py-0.5 border border-gray-200 rounded-md'>
                          <span className='font-semibold'>DA Unskilled : </span>
                          {salaryRules.daUnSkilled || '-'}
                        </span>
                      </div>
                    </>
                  ),
                  button: {
                    show: () => true,
                    behaviour: 'modal',
                    icon: PencilIcon,
                    style: 'w-4 h-4 text-gray-500 cursor-pointer',
                    modal: {
                      onClose: () => {},
                      title: 'Update Basic DA',
                      content: () => {
                        const Form = renderBasicDaForm();
                        return (
                          <Form data={salaryRules} onSave={saveSalaryRules} />
                        );
                      },
                    },
                  },
                },
              ],
            },
          ]}
        />
        {/* <div className='w-full flex flex-wrap'>
          <div onClick={handleSalaryRulesSettingS} className='md:basis-1/2'>
            <div className='md:basis-1/2 hover:shadow-md m-3 p-3 hover:cursor-pointer border'>
              <div>ESIC Settings</div>
              <div className='my-1'>
                <span className='px-1 py-0.5 border border-gray-200 rounded-md'>
                  <span className='font-semibold'>Upper Threshold : </span>{salaryRules.upperESICThreshold || '-'}
                </span>
                <span className='px-1 py-0.5 border border-gray-200 rounded-md'>
                <span className='font-semibold'>Lower Threshold : </span>{salaryRules.lowerESICThreshold || '-'}
                </span>
                <span className='px-1 py-0.5 border border-gray-200 rounded-md'>
                <span className='font-semibold'>CutOff Switch Dates : </span>{salaryRules.cutOffESICDates || '-'}
                </span>
              </div>
            </div>
          </div>
          <div onClick={handleBasicDaSetting} className='md:basis-1/2'>
            <div className='md:basis-1/2 hover:shadow-md m-3 p-3 hover:cursor-pointer border'>
              <div>Basic DA Settings</div>
              <div className='my-1'>
                <span className='px-1 py-0.5 border border-gray-200 rounded-md font-bold'>
                  BasicDA Effective Date :{' '}
                  {salaryRules.basicDaEffectiveDate || '-'}
                </span>
                <span className='px-1 py-0.5 border border-gray-200 rounded-md font-bold'>
                  Basic Skilled : {salaryRules.basicSkilled || '-'}
                </span>
                <span className='px-1 py-0.5 border border-gray-200 rounded-md font-bold'>
                  Basic Semi-Skilled : {salaryRules.basicSemiSkilled || '-'}
                </span>
                <span className='px-1 py-0.5 border border-gray-200 rounded-md font-bold'>
                  Basicc Unskilled : {salaryRules.basicUnSkilled || '-'}
                </span>
                <span className='px-1 py-0.5 border border-gray-200 rounded-md font-bold'>
                  DA Skilled : {salaryRules.daSkilled || '-'}
                </span>
                <span className='px-1 py-0.5 border border-gray-200 rounded-md font-bold'>
                  DA Semi-Skilled : {salaryRules.daSemiSkilled || '-'}
                </span>
                <span className='px-1 py-0.5 border border-gray-200 rounded-md font-bold'>
                  DA Unskilled : {salaryRules.daUnSkilled || '-'}
                </span>
              </div>
            </div>
          </div>
        </div> */}
        {/* <Modal {...modalState} /> */}
      </div>
    );
  };
}
