import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

type EmployeeStatusType = {
  status: string;
  date?: string;
};

type RenderEmployeeStatusForm = {};

export const renderEmployeeStatusForm = (e: RenderEmployeeStatusForm) => {
  return function GateEmployeeStatusForm({
    onSave,
    data,
    isEdit,
  }: {
    onSave: any;
    data?: EmployeeStatusType;
    isEdit?: boolean;
  }) {
    const formProps: FormProps<EmployeeStatusType> = {
      fieldsData: [
        {
          property: 'status',
          type: 'select',
          required: true,
          label: 'Status',
          options: [
            { text: 'Select', value: '' },
            { text: 'working', value: 'working' },
            { text: 'probation', value: 'probation' },
            { text: 'on-strike', value: 'on-strike' },
          ],
        },
        {
          property: 'date',
          type: 'date',
          required: true,
          label: 'Date',
          readonly: isEdit,
        },
      ],
      initialFormState: {
        status: data?.status || '',
        date: data?.date || '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EmployeeStatusType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<EmployeeStatusType>
    ): EmployeeStatusType {
      return {
        status: b.status || data?.status,
        date: b.date || data?.date,
      } as any;
    }
  };
};
