import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import { Node } from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseCompanyGroupSettings } from '../../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { UseItem } from '../../../../../hooks/inventory/item/use-item';
import { UseItems } from '../../../../../hooks/inventory/item/use-items';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item';
import { renderDependencyGraph } from './dependency-graph';
import { renderRawMaterialsLists } from './raw-materials';

type RenderItemBomTab = {
  itemData: ItemRes;
  itemService: ItemInterface;
  useItems: UseItems;
  useCompanyGroupSettings: UseCompanyGroupSettings;
  useItem: UseItem;
  id: string;
};

export function renderItemBomTab({
  itemData,
  useItems,
  useItem,
  itemService,
  id,
  useCompanyGroupSettings,
}: RenderItemBomTab) {
  const DependencyGraph = renderDependencyGraph();

  return function () {
    const RawMaterialsList = renderRawMaterialsLists({
      id,
      useItem,
      useItems,
      useCompanyGroupSettings,
    });

    const [nodeGraphData, setNodeGraphData] = useState<Node>();

    useEffect(() => {
      itemService.getBomChart(id).then((r) => {
        setNodeGraphData(r);
      });
      // eslint-disable-next-line
    }, []);

    if (!itemData) return <div />;

    return (
      <div className='space-y-3'>
        {['FG', 'INT', 'RM/FG', 'FA'].includes(itemData.category?.id || '') &&
        nodeGraphData ? (
          <RawMaterialsList nodeGraphData={nodeGraphData} />
        ) : null}
        {['INT', 'FG', 'RM/FG', 'FA'].includes(itemData.category?.id || '') &&
          nodeGraphData && <DependencyGraph nodeGraphData={nodeGraphData} />}

        {/* {
          machinePartsNodeGraphData && (
            <Card
              header={{
                title: 'Machine parts node graph',
                actions: []
              }}
              error={error}
              body={{
                type: 'jsx-component',
                body: <NodeGraph data={machinePartsNodeGraphData}/>
              }}
            />
          )
        } */}
      </div>
    );
  };
}
