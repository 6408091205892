/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import {
  ActionButton,
  ActionContextMenu,
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UsePurchaseOrderFulfilments } from '../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilments';
import { PurchaseOrderFulfilmentFilterType } from '../../../models/interfaces/order/purchase/purchase-order-fulfilment';

function PurchaseItemTransfer({
  usePurchaseOrderFulfilments,
}: {
  usePurchaseOrderFulfilments: UsePurchaseOrderFulfilments;
}) {
  const { data, getAll: getInspectionItems } = usePurchaseOrderFulfilments();

  const [actionsState, setActionsState] = useState({
    visible: false,
    xPosition: 200,
    yPosition: 200,
    menu: ['Mark Transfered'],
  });
  // eslint-disable-next-line
  const [activeDataId, setActiveDataId] = useState<string>('');

  const getData = async () => {
    try {
      getInspectionItems(({
        underInspection: false,
        isApproved: true,
      } as unknown) as PurchaseOrderFulfilmentFilterType);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const handleActionClick = (e: any, id: string) => {
    setActionsState({
      ...actionsState,
      visible: true,
      xPosition: e.pageX,
      yPosition: e.pageY,
    });
    setActiveDataId(id);
  };

  const handleActionItemClick = async (action: string) => {
    if (action === 'Mark Transfered') {
      setActionsState({
        ...actionsState,
        visible: false,
      });
      toast('item transfered');
    }
  };

  const Table = renderTableComponent();
  const tableHeader: TableHeader = [
    [
      { name: 'Item', rowSpan: 2 },
      { name: 'Quantity', rowSpan: 2 },
      { name: 'Actions', rowSpan: 2 },
    ],
  ];

  const tableData =
    data?.map((item) => ({
      cells: [
        { value: item?.purchaseOrder?.details?.itemDetails?.name },
        { value: item.details.quantity },
        {
          value: (
            <ActionButton handleActionClick={handleActionClick} id={item.id} />
          ),
        },
      ],
    })) || [];

  const tableBody: TableBody = tableData;

  const Card = renderCardComponent();
  const cardHeader: CardHeader = {
    title: 'Purchase Item Transfer',
  };

  const cardBody: CardBody = {
    type: 'jsx-component',
    body: <Table header={tableHeader} body={tableBody} />,
  };

  return (
    <div>
      <Card header={cardHeader} body={cardBody} />
      <ActionContextMenu
        actionsState={actionsState}
        setActionsState={setActionsState}
        handleActionItemClick={handleActionItemClick}
      />
    </div>
  );
}

export default PurchaseItemTransfer;
