import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseCurrentUser } from '../../../../hooks/admin/user-admin/use-current-user';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';

// eslint-disable-next-line
export type EmployeeExitFormType = {
  name: { id: string; name: string };
  reason: string;
  exitDate: string;
};
type RenderAddEmployeeExitForm = {
  useEmployeeProfiles: UseEmployeeProfiles;
  useCurrentUser: UseCurrentUser;
};

export function renderAdddExitDateAndReasonForm({
  useEmployeeProfiles,
  useCurrentUser,
}: RenderAddEmployeeExitForm): (data: any) => JSX.Element {
  // eslint-disable-next-line
  const { data: currentUser } = useCurrentUser();
  return function AddExitDateAndReasonForm({
    data,
    onSave,
    isEdit,
  }: {
    data?: EmployeeProfileType;
    onSave: (data: EmployeeProfileType) => Promise<void>;
    isEdit?: boolean;
  }): JSX.Element {
    const { data: employees, getAll: getAllEmployees } = useEmployeeProfiles();

    useEffect(() => {
      getAllEmployees();
      // eslint-disable-next-line
    }, []);

    const validateForm = async (form) => {
      if (isEdit) return '' as any;

      if (!form.name) return '';

      const exitEmployeeRcds = employees?.find(
        (x) =>
          x.id === form.name.id &&
          x.details.workTimeLines?.find((y) => y.activity === 'resigned')?.date
      );

      if (exitEmployeeRcds) {
        return {
          name: 'Employee already resigned',
        } as any;
      }
      return;
    };
    const formProps: FormProps<EmployeeExitFormType> = {
      fieldsData: [
        {
          property: 'name',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useEmployeeProfiles,
            filter: { crossGroup: true },
            onSearchValueSelect: (u) => {},
          },
          required: true,
          label: 'Employee Name',
          readonly: isEdit,
          validate: validateForm,
        },
        {
          property: 'reason',
          type: 'select',
          options: [
            { text: 'Resignation', value: 'Resignation' },
            { text: 'Retirement', value: 'Retirement' },
            { text: 'Retrenchment', value: 'Retrenchment' },
            { text: 'Death', value: 'Death' },
            {
              text: 'Termination-Indiscipline',
              value: 'Termination-Indiscipline',
            },
            { text: 'Termination-Violence', value: 'Termination-Violence' },
          ],
          label: 'Reason',
        },
        {
          property: 'exitDate',
          type: 'date',
          label: 'Exit Date',
        },
      ],
      initialFormState: {
        name: '',
        reason: '',
        exitDate: '',
      },
      onSubmit: async (f) => {
        const data = employees?.find((x) => x.id === f.name.id);

        await onSave(({
          id: f.name.id,
          details: {
            resigned: 'yes',
            workTimeLines: [
              ...(data?.details.workTimeLines || []),
              {
                activity: 'resigned',
                date: f.exitDate,
                updatedBy: {
                  id: currentUser.id,
                  name: currentUser.name,
                },
              },
            ],
          },
        } as unknown) as EmployeeProfileType);
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EmployeeExitFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<EmployeeExitFormType>
    ): EmployeeExitFormType {
      return (b as unknown) as EmployeeExitFormType;
    }
  };
}
