/* eslint-disable no-unused-vars */
import { PurchaseOrder } from '@erp_core/erp-types/dist/modules/order';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import moment from 'moment';
import React from 'react';
import { AddFulfillment } from '../types/add-fulfilment';

// eslint-disable-next-line
export function renderCreatePurchaseOrderFulfilmentForm() {
  return function CreatePurchaseOrderFulfilmentForm({
    data,
    onSave,
    purchaseOrder,
  }: {
    data: {
      total: number;
      previouslyAdded: number;
    };
    purchaseOrder: PurchaseOrder;
    onSave: (data: AddFulfillment & { summary?: string }) => Promise<void>;
  }): JSX.Element {
    console.log(purchaseOrder);
    const getSummary = (total, previous, added) => {
      if (total === previous + added) {
        return 'Order complected';
      }
      if (total < previous + added) {
        return `Order exceeded by ${previous + added - total}`;
      }
      return `${total - previous - added} remaining out of ${total} `;
    };

    const handleSave = async (form: any) => {
      form.summary = getSummary(
        data.total,
        data.previouslyAdded,
        Number(form.quantity)
      );
      await onSave(form); // TODO Enable after proper testing
    };

    const formProps: FormProps<Partial<AddFulfillment>> = {
      fieldsData: [
        {
          property: 'invoiceNo',
          type: 'input',
          label: 'Invoice Number',
          required: true,
        },
        {
          property: 'invoiceDate',
          type: 'date',
          label: 'Invoice Date',
          required: true,
        },
        {
          property: 'invoiceQuantity',
          type: 'number',
          label: 'Quantity in Invoice',
          required: true,
          validate: (formData) => {
            if (
              formData.invoiceQuantity !== formData.quantity &&
              formData.allowMismatchedQuantity === 'no'
            ) {
              return {
                invoiceQuantity:
                  'Invoice quantity is not same as actual quantity',
                quantity: 'Actual quantity is not same as invoice quantity',
              };
            }
            return {} as any;
          },
        },
        {
          property: 'quantity',
          type: 'number',
          label: 'Actual Quantity Received',
          required: true,
        },
        {
          property: 'allowMismatchedQuantity',
          label: 'Allow Mismatching quantity',
          type: 'select',
          options: [
            { value: 'yes', text: 'Yes' },
            { value: 'no', text: 'No' },
          ],
        },
        {
          property: 'reasonForMismatchQuantity',
          label: 'Reason for mismatch quantity',
          type: 'input',
          dependentOn(formData) {
            if (formData.allowMismatchedQuantity === 'yes') return true;
            return false;
          },
        },
        {
          property: 'eWayBillNumber',
          type: 'input',
          label: 'E Way bill Number',
          required: true,
        },
        {
          property: 'uom',
          type: 'input',
          label: 'Unit of Measurement',
          required: true,
        },
        {
          property: 'transporter',
          type: 'input',
          label: 'Transporter',
          required: true,
        },

        {
          property: 'lorryReceiptNumber',
          type: 'input',
          label: 'Lorry Receipt Number',
          validate(formData) {
            if (!formData.lorryReceiptNumber && !formData.AWBorBlNumber) {
              return {
                lorryReceiptNumber:
                  'At least LR number or AWB/BL number is mandatory',
                AWBorBlNumber:
                  'At least LR number or AWB/BL number is mandatory',
              };
            }
            return {} as any;
          },
        },
        {
          property: 'AWBorBlNumber',
          type: 'input',
          label: 'Air Way Bill/ Bill of lading Number',
        },

        {
          property: 'girirNumber',
          type: 'input',
          label: 'GIRIR No',
        },

        {
          property: 'sealNumber',
          type: 'input',
          label: 'Seal No',
        },

        {
          property: 'totalAmountPayable',
          type: 'number',
          label: 'Total Amount Payable',
        },
        {
          property: 'arrivedAt',
          type: 'datetime-local',
          label: 'Arrived at',
          required: true,
        },
        { property: 'remarks', type: 'input' },
      ],
      initialFormState: {
        quantity: 0,
        invoiceNo: '',
        sealNumber: '',
        totalAmountPayable: 0,
        girirNumber: '',
        invoiceDate: moment().format('YYYY-MM-DD'),
        // actualQuantity: 0,
        // eBillNumber: '',
        uom: '',
        lorryReceiptNumber: '',
        AWBorBlNumber: '',
        allowMismatchedQuantity: 'no',
        transporter: '',
        invoiceQuantity: 0,
        remarks: '',
        arrivedAt: moment().format('YYYY-MM-DDTHH:MM'),
      },
      allowUnchanged: true,
      onSubmit: handleSave,
      mapTToU: (t) => {
        return t as AddFulfillment;
      },
      style: 'md:w-1/2',
      button: { style: 'w-fit' },
    };

    const Form = renderFormV2<Partial<AddFulfillment>>(formProps);

    return (
      <div className='max-h-[520px] scrollbar-thin overflow-y-auto'>
        <div className='flex p-2 justify-center items-center space-x-3'>
          <div className='border-blue-400 border p-2 rounded-lg bg-blue-50'>
            Total PO Quantity:{' '}
            <span className='font-medium'>{data.total} </span>
          </div>
          <div className='border-green-400 border p-2 rounded-lg bg-green-50'>
            Total Received:{' '}
            <span className='font-medium'>{data.previouslyAdded}</span>
          </div>
          <div className='border-gray-400 border p-2 rounded-lg bg-gray-50'>
            Remaining:{' '}
            <span className='font-medium'>
              {data.total - data.previouslyAdded}{' '}
            </span>
          </div>
        </div>

        <div className='flex p-2 justify-center items-center space-x-3'>
          <div className='border-purple-400 border p-2 rounded-lg bg-blue-50'>
            PO:{' '}
            <span className='font-medium'>{purchaseOrder?.name || '--'} </span>
          </div>
          <div className='border-purple-400 border p-2 rounded-lg bg-blue-50'>
            Vendor:{' '}
            <span className='font-medium'>
              {purchaseOrder?.vendor?.name || '--'}{' '}
            </span>
          </div>
          <div className='border-purple-400 border p-2 rounded-lg bg-blue-50'>
            Supplier:{' '}
            <span className='font-medium'>
              {purchaseOrder?.details?.supplier?.name || '--'}{' '}
            </span>
          </div>
        </div>

        <Form />
      </div>
    );
  };
}
