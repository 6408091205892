import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  Godown,
  GodownDetails,
} from '@erp_core/erp-types/dist/types/modules/inventory/godown';
import {
  LoadingButton,
  ModalV2Props,
  renderCardComponent,
  renderFormV2,
  renderModal,
  renderTableComponent,
  TableBody,
  useConfirm,
} from '@erp_core/erp-ui-components';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { UseCurrentLocation } from '../../../../hooks/admin/location-admin/use-current-location';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseGodown } from '../../../../hooks/inventory/godown-inventory/use-godown';
import { UseGodowns } from '../../../../hooks/inventory/godown-inventory/use-godowns';
import { ShiftIcon } from '../../../../utils/common';

// TODO: Only Shift type rotational will be filtering out and displaying in un allocated employees..

type RenderSchedulePageProps = {
  useGodowns: UseGodowns;
  useGodown: UseGodown;
  useEmployees: UseEmployeeProfiles;
  useCurrentLocation: UseCurrentLocation;
};

type WPS = {
  employees: IdName[];
  id: string;
  name: string;
  details: GodownDetails;
};

export const renderWorkAreaPage = ({
  useGodowns,
  useGodown,
  useEmployees,
  useCurrentLocation,
}: RenderSchedulePageProps) => {
  const Modal = renderModal();

  const Table = renderTableComponent();

  return function () {
    const { Confirm, openConfirm } = useConfirm();

    const { data: godowns, getAll: getGodowns } = useGodowns();
    const { syncSet: setGodown } = useGodown();

    const [unUsedEmployees, setUnUsedEmployees] = useState<
      EmployeeProfileType[]
    >([]);
    const { data: employees, getAll: getAllEmployees } = useEmployees();

    const { data: currentLocation } = useCurrentLocation();

    const [modal, setModal] = useState<ModalV2Props>({
      isVisible: false,
      body: <div />,
      onClose: () =>
        setModal({ ...modal, isVisible: false, body: <div />, title: '' }),
      title: '',
    });

    useEffect(() => {
      getGodowns();
      getAllEmployees({ crossGroup: 'true' });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      // TODO: remove this hardcoding in future
      const list = (employees || []).filter(
        (x) =>
          x.details.workLocation === currentLocation?.name?.toLowerCase() &&
          x.details.resigned !== 'yes'
      );
      setUnUsedEmployees(list);
    }, [employees, currentLocation]);

    const workPlaces = useMemo(
      () => godowns?.filter((g) => g.details.workArea) || [],
      [godowns]
    );

    const [workPlacesSch, setWorkPlaceSch] = useState<WPS[]>(
      workPlaces
        .map((wps) => {
          return {
            id: wps.id,
            name: wps.name,
            details: wps.details,
            employees: [],
          };
        })
        .sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
    );

    useEffect(() => {
      setWorkPlaceSch(
        workPlaces
          .map((wps) => {
            return {
              id: wps.id,
              name: wps.name,
              details: wps.details,
              employees: wps.details.employees || [],
            };
          })
          .sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          })
      );
    }, [workPlaces]);

    useEffect(() => {
      workPlacesSch.forEach((wps) => {
        wps.employees.forEach((emp) => {
          const copy = [...(unUsedEmployees || [])];
          if (copy.find((uue) => uue.id === emp.id)) {
            const index = copy.findIndex((uue) => uue.id === emp.id);
            copy.splice(index, 1);
            setUnUsedEmployees(() => [...copy]);
          }
        });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workPlacesSch, unUsedEmployees]);

    const saveData = async () => {
      for (let i = 0; i < workPlacesSch.length; i++) {
        const wa = workPlacesSch[i];
        const finalData = {
          id: wa.id,
          details: {
            employees: wa.employees || [],
          },
        };
        await setGodown((finalData as unknown) as Godown);
      }
      toast('Saved data successfully');
    };

    const removeEmployee = (employeeId: string, workPlaceIds: string[]) => {
      openConfirm({
        type: 'info',
        title: 'Confirm',
        message: 'Are you sure to remove employee form work area?',
        onConfirm: async () => {
          for (let i = 0; i < workPlaceIds.length; i++) {
            const index = workPlacesSch.findIndex(
              (w) => w.id === workPlaceIds[i]
            );
            const newData = [...workPlacesSch];

            const _employees = newData[index].employees || [];
            const empIndex = _employees.findIndex((e) => e.id === employeeId);

            _employees.splice(empIndex, 1);

            newData[index] = {
              ...newData[index],
              employees: _employees,
            };
            setWorkPlaceSch([...newData]);
          }

          if (!unUsedEmployees.find((uue) => uue.id === employeeId)) {
            const emp = employees?.find((e) => e.id === employeeId);
            if (emp) {
              setUnUsedEmployees((c) => {
                return [...c, emp];
              });
            }
          }
        },
      });
    };

    const addEmployeeInWorkPlace = (
      gwps: WPS[],
      employeeList: EmployeeProfileType[]
    ) => {
      const addEmployee = async (data: { employee: IdName }) => {
        try {
          const workPlaceIds = gwps.map((gwp) => gwp.id);

          // Looping workPlace ids in which we want to persist
          for (let i = 0; i < workPlaceIds.length; i++) {
            const index = workPlacesSch.findIndex(
              (w) => w.id === workPlaceIds[i]
            );

            const copyData = [...workPlacesSch];

            // Checking if employee already added
            if (
              copyData[index].employees.find((e) => e.id === data.employee.id)
            ) {
              toast('Employee already added');
              modal.onClose();
              return;
            }

            const _employees = copyData[index].employees || [];
            _employees.push(data.employee);

            copyData[index] = {
              ...(copyData[index] || []),
              employees: _employees,
            };
            setWorkPlaceSch([...copyData] as WPS[]);
          }
          modal.onClose();
        } catch (err) {
          toast('Something went wrong');
        }
      };

      const employeeOptions = employeeList
        ?.filter(
          (shType) => shType?.details?.jobProfile?.shiftType === 'Rotational'
        )
        .map((e) => ({ text: e.name, value: e.id }));

      employeeOptions.unshift({ value: '', text: 'select' });

      const Form = renderFormV2<any>({
        fieldsData: [
          {
            property: 'employee',
            label: 'Select Employee',
            type: 'select',
            required: true,
            options: employeeOptions,
          },
        ],
        onSubmit: async (data) => {
          await addEmployee(data);
        },
        initialFormState: { employee: '' },
        mapTToU: (p) => {
          const emp = employeeList.find((e) => e.id === p.employee);
          return {
            employee: {
              id: emp?.id || '',
              name: emp?.name || '',
            },
          };
        },
      });

      setModal({
        ...modal,
        title: 'Add employee',
        body: <Form />,
        isVisible: true,
      });
    };

    const unUsedEmployeesTableBody: TableBody = unUsedEmployees
      ?.filter(
        (shType) => shType?.details?.jobProfile?.shiftType === 'Rotational'
      )
      ?.map((uue) => {
        return {
          cells: [
            {
              value: (
                <div>
                  {uue.name}
                  {ShiftIcon(uue.details?.jobProfile?.shiftType)}
                </div>
              ),
            },
            { value: uue.company?.name },
            {
              value: (
                <div>
                  {uue.details?.jobProfile?.skills?.map((s) => {
                    return <div key={s.name}>{s.name}</div>;
                  })}
                </div>
              ),
            },
            {
              value: (
                <div>
                  {uue.details?.jobProfile?.roles?.map((r) => {
                    return <div key={r.name}>{`${r.name}-${r.level}`}</div>;
                  })}
                </div>
              ),
            },
          ],
        };
      });

    const Card = renderCardComponent();

    // Grouped Work Places Data to Show Representation
    const groupedWorkPlaces: Array<Array<WPS>> = [];
    for (let i = 0; i < workPlacesSch.length; i++) {
      // check if current wp is having any sibling which is already present in the groupedWP
      if (workPlacesSch[i].details.siblingWorkAreas?.length) {
        const siblingEntry = groupedWorkPlaces.find((r) => {
          if (
            r.find(
              (x) =>
                x.id ===
                (workPlacesSch[i].details as any).siblingWorkAreas[0].id
            )
          ) {
            return true;
          }

          return false;
        });

        if (siblingEntry) {
          siblingEntry.push(workPlacesSch[i]);
        } else {
          groupedWorkPlaces.push([workPlacesSch[i]]);
        }
      } else {
        groupedWorkPlaces.push([workPlacesSch[i]]);
      }
    }

    return (
      <div>
        <Confirm />
        <Modal {...modal} />
        <Card
          header={{
            title: 'Work Areas',
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <LoadingButton
                    defaultStyle='bg-blue-500 p-2 rounded shadow text-white font-semibold hover:bg-blue-600'
                    behaviorFn={async () => await saveData()}
                    text='Save'
                  />
                ),
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div className='flex flex-col flex-wrap'>
                {groupedWorkPlaces.map((gwps, gwpIndex) => {
                  const wp = gwps[0];
                  const rolesRequired: Array<{
                    name: string;
                    min: number;
                    max: number;
                  }> = [];
                  wp.details?.requiredRoles?.forEach((x) => {
                    if (x.name && x.min) {
                      rolesRequired.push({
                        name: x.name,
                        min: x.min,
                        max: x.max || x.min,
                      });
                    }
                  });

                  wp.details?.scheduleConfig?.employees?.forEach((x) => {
                    if (
                      x.roleName &&
                      x.count &&
                      !rolesRequired.find((y) => y.name === x.roleName)
                    ) {
                      rolesRequired.push({
                        name: x.roleName,
                        min: x.count,
                        max: x.count,
                      });
                    }
                  });
                  const minRoles =
                    rolesRequired.reduce((c, p) => {
                      return c + Number(p?.min || 0);
                    }, 0) || 0;

                  // After grouping minRole
                  // const minRoles = gwps.map(wp => wp.details.requiredRoles)?.reduce((c, p) => { return c + Number(p?.map(roleReq => roleReq?.min || 0)) }, 0) || 0;
                  // const minSkills = wp.details.requiredSkills?.reduce((c, p) => { return c + Number(p?.min || 0) }, 0) || 0;
                  const minimumEmployeesNeeded = minRoles;

                  const shiftFilteredEmployees = employees?.filter(
                    (shType) =>
                      shType?.details?.jobProfile?.shiftType === 'Rotational'
                  );
                  const matchingRolesEmployees = shiftFilteredEmployees?.filter(
                    (emp) => {
                      const roles = rolesRequired?.find((role) =>
                        emp.details?.jobProfile?.roles?.find(
                          (r) => r.name === role.name
                        )
                      );
                      return roles ? true : false;
                    }
                  );

                  return (
                    <div className='p-2'>
                      <div
                        className={`${
                          wp.employees.length < minimumEmployeesNeeded
                            ? 'border-red-400 hover:border-red-500'
                            : 'border-gray-300 hover:border-gray-800'
                        } border-2 p-2 rounded`}
                      >
                        <div>
                          {gwps.map((gwp) => (
                            <span
                              key={gwp.id}
                              className='mx-1 bg-slate-200 p-1'
                            >
                              <a
                                href={`/inventory/masters/godowns/${gwp.id}`}
                                rel='noreferrer'
                                target='_blank'
                                className='font-bold'
                              >
                                {gwp.name}
                              </a>
                            </span>
                          ))}
                        </div>
                        <div className='flex space-x-2'>
                          <div className='font-semibold my-1 py-1'>
                            Roles Required
                          </div>
                          {rolesRequired?.map((r) => {
                            return (
                              <div
                                key={r.name}
                                className='text-xs m-1 p-1 rounded w-fit border border-blue-300 bg-blue-50'
                              >
                                {r.name} :
                                {/* <i className='text-xs text-gray-500'>
                                  {r.level}
                                </i>{' '} */}
                                {r.min === r.max
                                  ? r.min
                                  : `${r.min} to ${r.max}`}{' '}
                                employees
                              </div>
                            );
                          })}
                        </div>

                        {/* <div className='flex space-x-2'>
                              <div className='font-semibold py-1 my-1'>Skills Requirement</div>
                              {
                                wp.details?.requiredSkills?.map(r => {
                                return <div key={r.name} className='text-xs m-1 p-1 rounded w-fit border border-green-300 bg-green-50'>{r.name} {r.min} - {r.max}</div>;
                              })
                            }
                            </div> */}

                        <div className='flex'>
                          <div className='w-1/2 p-2 border'>
                            <div className=''>
                              <span className='font-bold'>Added Employees</span>
                              <LoadingButton
                                defaultStyle='bg-blue-500 p-2 rounded shadow text-white font-semibold hover:bg-blue-600 p-1 text-xs ml-2'
                                behaviorFn={async () => {
                                  addEmployeeInWorkPlace(
                                    gwps || [],
                                    matchingRolesEmployees || []
                                  );
                                }}
                                text='Add Employee'
                              />
                            </div>
                            <RenderAddedEmployees
                              gwps={gwps}
                              removeEmployee={removeEmployee}
                            />
                          </div>

                          <div className='w-1/2 p-2 border'>
                            <div className='font-bold'>
                              Employees with matching roles
                            </div>
                            <RenderMatchingEmployees
                              employeeList={matchingRolesEmployees}
                            />
                          </div>
                        </div>
                      </div>
                      {wp.employees.length < minimumEmployeesNeeded ? (
                        <div className='text-red-500 text-xs'>
                          Minimum {minimumEmployeesNeeded} employees are
                          required but only {wp.employees.length} are assigned.
                          Please add more employees to this work area.
                        </div>
                      ) : null}
                    </div>
                  );
                })}

                <div className='m-2 p-2'>
                  <Card
                    header={{
                      title: 'Un used Employees',
                    }}
                    body={{
                      type: 'jsx-component',
                      body: (
                        <Table
                          header={[
                            [
                              { name: 'Emp name' },
                              { name: 'Company' },
                              { name: 'Skills' },
                              { name: 'Roles' },
                            ],
                          ]}
                          body={unUsedEmployeesTableBody}
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            ),
          }}
        />
      </div>
    );
  };
};

const RenderAddedEmployees = ({
  gwps,
  removeEmployee,
}: {
  gwps: WPS[];
  removeEmployee: (empId: string, workPlaceIds: string[]) => void;
}) => {
  const wp = gwps[0];
  const workPlaceIds = gwps.map((gwp) => gwp.id);

  if (!wp?.employees || wp.employees.length === 0) {
    return <div>No employees added yet</div>;
  }
  return (
    <div className='flex flex-wrap'>
      {wp.employees?.map((emp) => {
        return (
          <div key={emp.id} className='p-1'>
            <div className='p-1 border border-violet-300 bg-violet-50'>
              {emp.name}
              <span
                onClick={() => removeEmployee(emp.id, workPlaceIds)}
                className='font-bold cursor-pointer mx-1'
              >
                X
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const RenderMatchingEmployees = ({
  employeeList,
}: {
  employeeList?: EmployeeProfileType[];
}) => {
  if (!employeeList || employeeList.length === 0) {
    return <div>No employee with matching role found</div>;
  }
  return (
    <div className='flex flex-wrap'>
      {employeeList?.map((mre) => {
        return (
          <div key={mre.id} className='p-1'>
            <div className='p-1 border border-violet-300 bg-violet-50'>
              {mre.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};
