import { useEffect, useState } from 'react';
import { renderTextEditor } from '../../../../../components/html-editor';
import { UseCurrentCompanyGroup } from '../../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';

export function renderSalarySlipTemplate({
  useFileTransfer,
  useCurrentCompanyGroup,
}: {
  useFileTransfer: UseFileTransfer;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
}): ({ id, onClose }: { id: string; onClose: () => void }) => JSX.Element {
  return function SalarySlipTemplate({
    id,
    onClose,
  }: {
    id: string;
    onClose: () => void;
  }): JSX.Element {
    const TextEditor = renderTextEditor();
    const { currentCompanyGroup } = useCurrentCompanyGroup();
    const { get: getTemplate, set: setTemplate } = useFileTransfer();
    const [data, setData] = useState<string>(
      '<html><body>Salary Slip Template</body></html>'
    );

    useEffect(() => {
      if (currentCompanyGroup.id) {
        try {
          getTemplate(
            `${
              currentCompanyGroup.id
            }/salary-slip-templates/${id.toLowerCase()}.html`
          ).then((result) => {
            if (result) {
              setData(result);
            }
          });
        } catch (e) {
          console.log('file not found', id);
        }
      }
      // eslint-disable-next-line
    }, [currentCompanyGroup]);

    function onTemplateSave(data: any) {
      const file = new File([data], `${id.toLowerCase()}.html`, {
        type: 'text/plain',
      });
      setTemplate(
        `${currentCompanyGroup.id}/salary-slip-templates/${id.toLowerCase()}`,
        file,
        'html'
      );
      onClose();
    }

    return (
      <div className='h-[calc(100vh_-_15rem)]'>
        <TextEditor initialHtml={data} onSave={onTemplateSave} />
      </div>
    );
  };
}
