/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import { SalesEnquiryItem } from '@erp_core/erp-types/dist/modules/order';
import { LoadingButton } from '@erp_core/erp-ui-components';
import { CubeIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UseManufacturingVouchers } from '../../../../hooks/order/work-order/mf-voucher/use-manufacturing-vouchers';
import { UseVirtuallyReserveMfVoucher } from '../../../../hooks/order/work-order/mf-voucher/use-virtually-reserve-manufacturing-voucher';

export type ReserveMvVirtualFormRequestType = {
  id: string;
  name: string;
  item: {
    id: string;
    name: string;
    quantity: number;
  };
};

export function ReserveVirtuallyMv({
  enquiryItemData,
  onSave,
  onCancel,
  useManufacturingVouchers,
  useVirtuallyReserveMfVoucher,
}: {
  enquiryItemData: SalesEnquiryItem;
  onSave: any;
  onCancel: any;
  useManufacturingVouchers: UseManufacturingVouchers;
  useVirtuallyReserveMfVoucher: UseVirtuallyReserveMfVoucher;
}) {
  const { data: mfVouchers, getAll } = useManufacturingVouchers();
  const { data: expired, getAll: getAllExpired } = useManufacturingVouchers();

  const {
    data: reservedVouchers,
    getAll: getAllReservedVouchers,
  } = useManufacturingVouchers();
  const [userReservedValues, setUserReservedValues] = useState<
    Array<{
      mvItemId: string;
      quantity: number;
    }>
  >([]);
  const [userReserveErrors, setUserReserveErrors] = useState<
    Array<{
      error: boolean;
      reason: string;
    }>
  >([]);

  const { set: setVirtuallyReserve } = useVirtuallyReserveMfVoucher();

  useEffect(() => {
    getAll({
      itemId: enquiryItemData.item.id,
      referenceId: 'null',
      referenceType: 'null',
    });

    getAllExpired({
      itemId: enquiryItemData.item.id,
      expireBy: moment().utc().format('YYYY-MM-DD'),
    });

    getAllReservedVouchers({
      itemId: enquiryItemData.item.id,
      referenceId: enquiryItemData.id,
      referenceType: 'enquiry',
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mfVouchers && reservedVouchers && expired) {
      const vouchers = [...mfVouchers, ...expired];
      const values: Array<{
        quantity: number;
        mvItemId: string;
      }> = _.times(vouchers.length)
        .map((i) =>
          vouchers[i].manufacturingVoucherItems.map((j) => ({
            mvItemId: j.id,
            quantity: 0,
          }))
        )
        .flat();
      setUserReservedValues(values);

      const errors: Array<{
        error: boolean;
        reason: string;
      }> = _.times(vouchers.length).map((i) => ({
        error: false,
        reason: '',
      }));
      setUserReserveErrors(errors);
    }
  }, [mfVouchers, reservedVouchers, expired]);

  useEffect(() => {
    const vouchers = [...(mfVouchers || []), ...(expired || [])]
      .map((t) => {
        return t.manufacturingVoucherItems;
      })
      .flat();
    if (userReservedValues.length && vouchers.length) {
      const updatedErrors: Array<{
        error: boolean;
        reason: string;
      }> = _.times(vouchers.length).map((i) => ({
        error: false,
        reason: '',
      }));
      // eslint-disable-next-line
      let updated: boolean = false;
      userReservedValues.forEach((val, idx) => {
        if (
          vouchers &&
          vouchers[idx] &&
          val.quantity > vouchers[idx].quantity
        ) {
          // Todo: Have to think how to remove 0th index
          updated = true;
          updatedErrors[idx].error = true;
          updatedErrors[idx].reason = `can't exceed ${vouchers[idx].quantity}`;
        }
      });
      setUserReserveErrors(updatedErrors);
    }
    // eslint-disable-next-line
  }, [userReservedValues]);

  const handleSaveClick = async () => {
    userReservedValues
      .filter((u) => u.quantity !== 0)
      .forEach(async (v) => {
        setVirtuallyReserve({
          ...v,
          manufacturingVoucherItemId: v.mvItemId,
          referenceId: enquiryItemData.id,
          referenceType: 'enquiry',
          expireBy: enquiryItemData.enquiry.respondBy,
        });
      });
    onSave();
  };

  const vouchers = [...(mfVouchers || []), ...(expired || [])];
  const availableQuantity = vouchers?.reduce(
    (prev, curr) =>
      prev + curr.manufacturingVoucherItems.reduce((p, c) => p + c.quantity, 0),
    0
  );
  const physicallyReserved =
    reservedVouchers?.reduce(
      (prev, curr) =>
        prev +
        curr.manufacturingVoucherItems.reduce((p, c) => p + c.quantity, 0),
      0
    ) || 0;
  const totalAllocatedQuantity =
    userReservedValues?.reduce((prev, curr) => prev + curr.quantity, 0) +
    physicallyReserved;
  const balanceRequiredQuantity = userReservedValues?.reduce(
    (prev, curr) => prev - curr.quantity,
    enquiryItemData.quantity - physicallyReserved
  );

  return (
    <div className='p-5 flex flex-col space-y-4'>
      <div>
        <div className='text-gray-800 text-md font-bold mb-2'> Schedule</div>
        {enquiryItemData.details.schedule?.map((s, i) => (
          <div key={i}>
            {' '}
            Qty:{' '}
            <span className='text-gray-800 font-bold'>{s.batchQuantity}</span> |
            Required On{' '}
            <span className='text-gray-800 font-bold'>{s.batchNeededBy}</span>
          </div>
        ))}
      </div>
      <div>
        {enquiryItemData.quantity === physicallyReserved && (
          <div className='flex space-x-2 justify-center'>
            <InformationCircleIcon className='w-6 h-6 text-green-400' />
            Total required Items are already Reserved.
          </div>
        )}
      </div>
      <div className='flex flex-row'>
        <div className='border p-1 m-1'>
          <label className='text-gray-800 text-md font-bold mb-2'>
            Available:&nbsp;
          </label>
          <span className='text-gray-500'>{availableQuantity}</span>
        </div>
        <div
          className={`p-1 m-1 ${
            balanceRequiredQuantity < 0 ? 'border-2 border-red-600' : 'border'
          }`}
        >
          <label className='text-gray-800 text-md font-bold mb-2'>
            Total Allocated:&nbsp;
          </label>
          <span className='text-gray-500'>{totalAllocatedQuantity}</span>
        </div>
        <div
          className={`p-1 m-1 ${
            balanceRequiredQuantity < 0 ? 'border-2 border-red-600' : 'border'
          }`}
        >
          <label className='text-gray-800 text-md font-bold mb-2'>
            Balance Required:&nbsp;
          </label>
          <span className='text-gray-500'>{balanceRequiredQuantity}</span>
        </div>
      </div>

      <form>
        <div className='flex flex-row flex-wrap'>
          {// Here combining both reserved but expired-enquiry and unallocated mf voucher
          [...(mfVouchers || []), ...(expired || [])]
            ?.map((i, index) => {
              return i.manufacturingVoucherItems;
            })
            .flat()
            .map((mi, j) => {
              return (
                <div key={`${j} ${j}`} className='border p-1 w-1/4'>
                  <div
                    className={`p-1 mx-auto text-center m-1 bg-blue-100 ${
                      userReserveErrors[j]?.error
                        ? 'border-2 border-red-600'
                        : ''
                    }`}
                  >
                    <CubeIcon className='inline-block w-8 h-8 text-blue-500' />
                    <sup className='bg-green-200 text-green-800 rounded sups text-lg'>
                      {mi.quantity}
                    </sup>
                    <input
                      className='mb-3 ml-1 shadow appearance-none border rounded w-14  text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      id='alloted'
                      onChange={(e) => {
                        const { value } = e.target;
                        setUserReservedValues((val) => {
                          const res = [...val];
                          res[j].quantity = parseInt(value || '0', 10);
                          return res;
                        });
                      }}
                      type='number'
                      value={userReservedValues[j]?.quantity || 0}
                    />
                    {userReserveErrors[j]?.error ? (
                      <label className='block text-red-700 text-sm font-bold mb-2'>
                        {userReserveErrors[j]?.reason}
                      </label>
                    ) : null}
                  </div>

                  <div className='mx-auto text-center'>
                    <label className='block text-blue-600 text-md font-bold'>
                      {mi.manufacturingVoucher.name ||
                        mi.manufacturingVoucher.expectedBy}
                    </label>
                  </div>
                </div>
              );
            })}
        </div>

        {balanceRequiredQuantity < 0 ? (
          <div className='mx-auto w-1/2 text-center'>
            <label className='block text-red-700 text-sm font-bold mb-2'>
              Total virtual reservation cannot exceed {enquiryItemData.quantity}
            </label>
          </div>
        ) : null}
        <div className='flex items-center mt-12 justify-end'>
          <LoadingButton
            defaultStyle='bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            behaviorFn={onCancel}
            text='Cancel'
          />
          <button
            className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            type='button'
            disabled={!!userReserveErrors.find((a) => a.error)}
            onClick={handleSaveClick}
          >
            Virtually Reserve
          </button>
        </div>
      </form>
    </div>
  );
}
