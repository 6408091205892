import { PurchaseEnquiry } from '@erp_core/erp-types/dist/modules/order';
import { renderPureHistory } from '@erp_core/erp-ui-components';
import { OverviewFlow } from '../../../../../../components/flow-chart';
import { getEnquiryFlowGraph } from './utils/get-enquiry-flow-graph';
// import { getPrFlowGraph } from './utils/get-pr-flow-graph';

export function renderEnquiryProfile({
  enquiry,
}: {
  enquiry: PurchaseEnquiry;
}): () => JSX.Element {
  return function PRProfile() {
    const History = renderPureHistory();

    return (
      <div>
        <div className='w-full h-48 border border-gray-200 mx-auto'>
          <OverviewFlow data={enquiry} getFlowGraph={getEnquiryFlowGraph} />
        </div>
        <div className='w-full p-4'>
          {enquiry.details?.activity ? (
            <History logs={enquiry.details.activity} />
          ) : null}
        </div>
      </div>
    );
  };
}
