import {
  LoadingButton,
  renderCardComponent,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { UseCurrentCompanyGroup } from '../../../hooks/admin/company-group-admin/use-current-company-group';
import { DashboardInterface } from '../../../models/interfaces/analytics/dashboard';

const list: Array<{
  resource: string;
  module: string;
  dependentModules: Array<string>;
  sync: string;
}> = [
  {
    resource: 'Company Group',
    module: 'Admin',
    dependentModules: [
      'accounts',
      'analytics',
      'communication',
      'disciplinary',
      'hrd',
      'insurance',
      'inventory',
      'loan',
      'notification',
      'order:purchase',
      'order:sales',
      'order:work-order',
      'payroll',
      'planning',
      'quality-assurance',
      'questionnaire',
      'reporting',
      'salary-advance',
      'schedular',
    ],
    sync: 'https://admin-api.recat.in/api/v1/company-groups/sync',
  },
  {
    resource: 'Company',
    module: 'Admin',
    dependentModules: [
      'accounts',
      'analytics',
      'disciplinary',
      'hrd',
      'inventory',
      'notification',
      'order:purchase',
      'order:sales',
      'order:work-order',
      'payroll',
      'planning',
      'reporting',
      'salary-advance',
    ],
    sync: 'https://admin-api.recat.in/api/v1/companies/sync',
  },
  {
    resource: 'Location',
    module: 'Admin',
    dependentModules: [
      'accounts',
      'analytics',
      'communication',
      'disciplinary',
      'hrd',
      'inventory',
      'order:purchase',
      'order:work-order',
      'planning',
      'salary-advance',
    ],
    sync: 'https://admin-api.recat.in/api/v1/locations/sync',
  },
  {
    resource: 'Users',
    module: 'Admin',
    dependentModules: [
      'accounts',
      'analytics',
      'communication',
      'disciplinary',
      'hrd',
      'inventory',
      'order:purchase',
      'order:work-order',
      'planning',
      'salary-advance',
    ],
    sync: 'https://admin-api.recat.in/api/v1/users/sync',
  },
  {
    resource: 'Item',
    module: 'Inventory',
    dependentModules: [
      'accounts',
      'analytics',
      'communication',
      'disciplinary',
      'hrd',
      'inventory',
      'order:purchase',
      'order:work-order',
      'planning',
      'salary-advance',
    ],
    sync: 'https://inventory-api.recat.in/api/v1/items/sync',
  },
  {
    resource: 'Grade',
    module: 'Inventory',
    dependentModules: [
      'accounts',
      'analytics',
      'communication',
      'disciplinary',
      'hrd',
      'inventory',
      'order:purchase',
      'order:work-order',
      'planning',
      'salary-advance',
    ],
    sync: 'https://inventory-api.recat.in/api/v1/grades/sync',
  },
  {
    resource: 'Vendor',
    module: 'Purchase',
    dependentModules: [
      'accounts',
      'analytics',
      'communication',
      'disciplinary',
      'hrd',
      'inventory',
      'order:purchase',
      'order:work-order',
      'planning',
      'salary-advance',
    ],
    sync: 'https://order-api.recat.in/api/v1/purchase/vendors/sync',
  },
  {
    resource: 'Client',
    module: 'Sales',
    dependentModules: [
      'accounts',
      'analytics',
      'communication',
      'disciplinary',
      'hrd',
      'inventory',
      'order:purchase',
      'order:work-order',
      'planning',
      'salary-advance',
    ],
    sync: 'https://order-api.recat.in/api/v1/sales/client/sync',
  },
  {
    resource: 'Overtime',
    module: 'HRD',
    dependentModules: ['analytics'],
    sync: 'https://hrd-api.recat.in/api/v1/overtime/sync',
  },
  {
    resource: 'Leaves',
    module: 'HRD',
    dependentModules: ['analytics'],
    sync: 'https://hrd-api.recat.in/api/v1/leaves/month-leaves/sync',
  },
  {
    resource: 'Attendance',
    module: 'HRD',
    dependentModules: ['analytics'],
    sync: 'https://hrd-api.recat.in/api/v1/attendance/date/sync', // update when period column is added
  },
  {
    resource: 'Gatepass',
    module: 'HRD',
    dependentModules: ['analytics'],
    sync: 'https://hrd-api.recat.in/api/v1/gate-passes/sync', // update when period column is added
  },
  {
    resource: 'Offsite',
    module: 'HRD',
    dependentModules: ['analytics'],
    sync: 'https://hrd-api.recat.in/api/v1/off-site-work-hours/sync', // update when period column is added
  },
  {
    resource: 'Salary',
    module: 'Payroll',
    dependentModules: ['analytics', 'accounts'],
    sync: 'https://payroll-api.recat.in/api/v1/salaries/sync', // update when period column is added
  },
];

export function renderDatabaseSettings({
  dashboardService,
  useCurrentCompanyGroup,
}: {
  dashboardService: DashboardInterface;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
}) {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  return function DatabaseSettings() {
    const { currentCompanyGroup } = useCurrentCompanyGroup();
    return (
      <Card
        header={{ title: 'Database Settings - Sync' }}
        body={{
          type: 'jsx-component',
          body: (
            <>
              <Table
                header={[
                  [
                    { name: 'Resource' },
                    { name: 'Dependent Modules' },
                    { name: 'Sync' },
                  ],
                ]}
                body={list.map((l) => {
                  return {
                    cells: [
                      {
                        value: (
                          <>
                            <div className='font-semibold'>{l.resource}</div>
                            <div>{l.module}</div>
                          </>
                        ),
                      },
                      {
                        value: (
                          <>
                            {l.dependentModules?.map((x) => (
                              <span
                                key={x}
                                className='mx-1 border border-gray-200 rounded-md'
                              >
                                {x}
                              </span>
                            ))}
                          </>
                        ),
                      },
                      {
                        value: (
                          <LoadingButton
                            text={'Sync'}
                            behaviorFn={async () => {
                              console.log(l.sync);
                              await dashboardService.syncDatabase(l.sync, {
                                companyGroupId: currentCompanyGroup.id,
                              });
                            }}
                            behaviourParams={{}}
                          />
                        ),
                      },
                    ],
                  };
                })}
              />
            </>
          ),
        }}
      />
    );
  };
}
