import {
  BomProjectFilterType,
  BomProjectType,
} from '@erp_core/erp-types/dist/modules/inventory';
import {
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseCurrentCompany } from '../../../../hooks/admin/company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseCurrentLocation } from '../../../../hooks/admin/location-admin/use-current-location';
import { UseCurrentUser } from '../../../../hooks/admin/user-admin/use-current-user';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseBomProject } from '../../../../hooks/order/work-order/bom-project/use-bom-project';
import { UseBomProjects } from '../../../../hooks/order/work-order/bom-project/use-bom-projects';
import { UsePaginatedBomProjects } from '../../../../hooks/order/work-order/bom-project/use-paginated-bom-projects';
import { renderAddEditBomProject } from './form/add-bom-project';

export function renderBOMProjectRegister({
  useBomProjects,
  usePaginatedBomProjects,
  useBomProject,
  useCurrentUser,
  useCurrentCompanyGroup,
  useCurrentCompany,
  useCurrentLocation,
  useUsers,
}: {
  useBomProjects: UseBomProjects;
  usePaginatedBomProjects: UsePaginatedBomProjects;
  useBomProject: UseBomProject;
  useCurrentUser: UseCurrentUser;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useCurrentCompany: UseCurrentCompany;
  useCurrentLocation: UseCurrentLocation;
  useUsers: UseUsers;
}): () => JSX.Element {
  const Card = renderCardComponent();
  return function BOMProjectRegister(): JSX.Element {
    const { syncSet: setBomProject } = useBomProject();
    const { data: bomProjects, getAll: getBomProjects } = useBomProjects();

    useEffect(() => {}, [bomProjects]);

    useEffect(() => {
      getBomProjects();
      // eslint-disable-next-line
    }, []);

    const Form = renderAddEditBomProject({
      useUsers,
      useBomProjects,
      useCurrentCompany,
      useCurrentCompanyGroup,
      useCurrentLocation,
      useCurrentUser,
    });
    const Table = renderTableWithMapperComponent<
      BomProjectType,
      BomProjectFilterType
    >();

    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Parent Project' },
        { name: 'Type' },
        { name: 'Description' },
        { name: 'Target Date' },
        { name: 'Budget(%)' },
        { name: 'Design Engineer' },
        { name: 'Site Engineer' },
        { name: 'Agency' },
        { name: 'Status' },
        { name: 'Completion(%)' },
      ],
    ];

    const bodyMapper = (item: BomProjectType) => {
      const cells: Array<TableCell> = [
        {
          value: item.name,
          link: `/inventory/registers/bom-projects/${item.id}`,
        },
        {
          value: item.parentBomProject?.name || '-',
          link: item.parentBomProject?.id
            ? `/inventory/registers/bom-projects/${item.parentBomProject.id}`
            : undefined,
        },
        { value: item.type || '-' },
        { value: item.description },
        { value: item.details.targateDate || '-' },
        { value: item.details.budget || '-' },
        { value: item.details.designEng?.name || '-' },
        { value: item.details.siteEng?.name || '-' },
        { value: item.details.agency || '-' },
        { value: item.status || '-' },
        { value: item.details.completion || '-' },
      ];

      return {
        rowData: item,
        cells,
      };
    };

    const filter: Filter<BomProjectFilterType> = {
      sortFields: [
        {
          key: 'Name',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'Search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'ParentBomProject',
          value: 'parentBomProject',
          type: 'search-select',
        },
        {
          key: 'Type',
          value: 'all',
          type: 'drop-down',
          options: [
            { text: 'all', value: 'all' },
            { value: 'equal::E-BOM', text: 'Electrical' },
            { value: 'equal::C-BOM', text: 'Civil' },
            { value: 'equal::F-BOM', text: 'Fabrication' },
            { value: 'equal::S-BOM', text: 'Structure' },
            { value: 'equal::M-BOM', text: 'Manufacturing' },
            { value: 'equal::FU-BOM', text: 'Furniture' },
            { value: 'equal::A-BOM', text: 'Automation' },
            { value: 'equal::OA-BOM', text: 'Office Automation' },
          ],
        },
        {
          key: 'Status',
          value: 'all',
          type: 'drop-down',
          options: [
            { text: 'all', value: 'all' },
            { text: 'Opened', value: 'equal::Opened' },
            { text: 'Closed', value: 'equal::Closed' },
          ],
        },
        {
          key: 'CreatedBy',
          type: 'search-select',
          value: '',
        },
        {
          key: 'LastModifiedBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: any) => {
        const filterData: any = {};

        if (filterSelection.Status !== 'all') {
          filterData.status = filterSelection.Status;
        }

        if (filterSelection.Type !== 'all') {
          filterData.type = filterSelection.Type;
        }

        if (filterSelection.Search !== 'all' && filterSelection.Search !== '') {
          filterData.search = filterSelection.Search;
        }

        if (filterSelection.ParentBomProject) {
          filterData.parentBomProjectId = filterSelection.ParentBomProject;
        }

        if (filterSelection.CreatedBy) {
          filterData.createdBy = filterSelection.CreatedBy;
        }

        if (filterSelection.LastModifiedBy) {
          filterData.lastModifiedBy = filterSelection.LastModifiedBy;
        }

        return (filterData as unknown) as BomProjectFilterType;
      },
      filterResources: {
        ParentBomProject: {
          searchOptions: {
            useSearch: useBomProjects,
            onSearchValueSelect: (u) => {
              console.log(u);
            },
          },
        },
        CreatedBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {
              console.log(u);
            },
          },
        },
        LastModifiedBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {
              console.log(u);
            },
          },
        },
      },
    };

    return (
      <div>
        <Card
          header={{
            title: 'Bom Project Register',
            actions: [
              {
                type: 'button',
                button: {
                  name: 'Add',
                  behaviour: 'modal',
                  modal: {
                    title: 'Add Bom Project',
                    content: ({ onClose }) => {
                      return (
                        <Form
                          setBomProject={setBomProject}
                          onClose={() => {
                            onClose();
                            getBomProjects();
                          }}
                        />
                      );
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <Table
                  header={tableHeader}
                  bodyMapper={bodyMapper}
                  filter={filter}
                  actions={[
                    {
                      name: 'Edit',
                      show: () => true,
                      behaviour: 'modal',
                      modal: {
                        title: 'Edit',
                        content: ({ data, onClose }) => {
                          return (
                            <Form
                              bomProject={data}
                              setBomProject={setBomProject}
                              onClose={() => {
                                onClose();
                                getBomProjects();
                              }}
                            />
                          );
                        },
                      },
                    },
                  ]}
                  useResources={useBomProjects}
                  pagination={{
                    enabled: true,
                    usePaginatedResources: usePaginatedBomProjects,
                  }}
                />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
