import { Tachometer } from '@erp_core/erp-icons/icons/web/tachometer';
import {
  Carousel,
  MonthSelector,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseHrdDashboardReport } from '../../../../hooks/analytics/hrd-dashboard-report/use-hrd-dashboard-report';
import { UseEmployeeRegistrations } from '../../../../hooks/hrd/employee-registration-dtls/use-registrations';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { AttendanceInterface } from '../../../../models/interfaces/hrd/attendance';
import { HrdReportKey } from '../../../../models/types/analytics/hrd-dashboard';
import { EmployeeDetailsReport } from './reports/employee-details';
import { FinalizationDetailsReport } from './reports/finalization-report';
import { PendingActionsReport } from './reports/pending-actions';

const reports: Array<HrdReportKey> = [];

export function renderAttendanceDashboard({
  attendanceService,
  useEmployees,
  useEmployeeRegistrations,
  useHrdDashboardReport,
}: {
  attendanceService: AttendanceInterface;
  useEmployees: UseEmployeeProfiles;
  useEmployeeRegistrations: UseEmployeeRegistrations;
  useHrdDashboardReport: UseHrdDashboardReport;
}): () => JSX.Element {
  const Card = renderCardComponent();

  return function AttendanceDashboard(): JSX.Element {
    const [month, setMonth] = useState(
      localStorage.getItem('attendance-month') || moment.utc().format('YYYY-MM')
    );

    const CardBody = (
      <div>
        <div className='grid grid-cols-6 gap-4'>
          <div className='col-span-6 my-5'>
            <div className='w-[70%] m-auto'>
              <Carousel
                images={[
                  {
                    url: 'pi.unsplash.com/search/photos?page=1&query=cat',
                    text: 'some beach text',
                  },
                  {
                    url: 'https://source.unsplash.com/1600x900/?cat',
                    text: 'some cat text',
                  },
                  {
                    url: 'https://source.unsplash.com/1600x900/?dog',
                    text: 'some dog text',
                  },
                  {
                    url: 'https://source.unsplash.com/1600x900/?lego',
                    text: 'some lego text',
                  },
                  {
                    url:
                      'https://source.unsplash.com/1600x900/?textures&patterns',
                    text: 'some pattern text',
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className='grid grid-cols-6 gap-4'>
          <div className='col-span-1 row-span-6'></div>
          <div className='col-span-2 row-span-5'></div>
          <div className='col-span-3 row-span-4'></div>
          <div className='col-span-4 row-span-3'></div>
          <div className='col-span-5 row-span-2'></div>
          <div className='col-span-6 row-span-1'></div>
        </div>
      </div>
    );

    return (
      <div>
        <Card
          header={{
            title: 'Attendance Dashboard',
            icon: (
              <Tachometer className='inline w-8 h-8 stroke-none fill-indigo-900 self-center' />
            ),
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <div>
                    <MonthSelector
                      format='YYYY-MM'
                      initialState={month}
                      onChange={(m) => setMonth(m)}
                    />
                  </div>
                ),
              },
            ],
            menu: {
              actions: [
                {
                  show: () => true,
                  name: 'Employee Details',
                  behaviour: 'modal',
                  modal: {
                    title: 'Employee Details',
                    content: ({ onClose }) => {
                      return (
                        <EmployeeDetailsReport
                          useEmplyees={useEmployees}
                          useEmployeeRegistrations={useEmployeeRegistrations}
                        />
                      );
                    },
                  },
                },
                {
                  show: () => true,
                  name: 'Pending Actions',
                  behaviour: 'modal',
                  modal: {
                    title: 'Pending Actions',
                    content: ({ onClose }) => {
                      return (
                        <PendingActionsReport
                          month={month}
                          attendanceService={attendanceService}
                        />
                      );
                    },
                  },
                },
                {
                  show: () => true,
                  name: 'Finalization Report',
                  behaviour: 'modal',
                  modal: {
                    title: 'Finalization Report',
                    content: ({ onClose }) => {
                      return (
                        <FinalizationDetailsReport
                          month={month}
                          attendanceService={attendanceService}
                        />
                      );
                    },
                  },
                },
                {
                  show: () => true,
                  name: 'Daily Report',
                  behaviour: 'confirm',
                  onConfirm: () => ({
                    title:
                      'Are you sure you want to send email of yesterdays daily report?',
                    type: 'warning',
                    onConfirm: async () => {
                      const res = await attendanceService.emailDailyReport(
                        moment().subtract(1, 'day').format('YYYY-MM-DD')
                      );
                      console.log(res);
                      if (res.success) {
                        toast('Email sent successfully');
                      }
                    },
                  }),
                },
                {
                  show: () => true,
                  name: 'Settings',
                  behaviour: 'modal',
                  modal: {
                    title: 'Dashboard Settings',
                    content: ({ onClose }) => {
                      return (
                        <div>
                          <ReportSettings />
                        </div>
                      );
                    },
                  },
                },
              ],
            },
          }}
          body={{
            type: 'jsx-component',
            body: CardBody,
          }}
        />
      </div>
    );
  };
}

function ReportSettings(): JSX.Element {
  const [selectedReports, setSelectedReports] = useState<{
    [key: string]: {
      enabled: 'yes' | 'no';
      sequence: number;
      colSpan: number;
      rowSpan: number;
    };
  }>(JSON.parse(localStorage.getItem('att-dashboard') || '{}'));

  useEffect(() => {
    localStorage.setItem('att-dashboard', JSON.stringify(selectedReports));
  }, [selectedReports]);

  const reportsList = reports
    .map((x) => {
      const rep = selectedReports[x];

      return {
        name: x,
        enabled: rep && rep.enabled === 'yes' ? 'yes' : 'no',
        sequence: rep ? rep.sequence : 0,
        colSpan: rep?.colSpan ? rep.colSpan : 1,
        rowSpan: rep?.rowSpan ? rep.rowSpan : 1,
      };
    })
    .sort((a, b) => a.sequence - b.sequence);

  return (
    <div>
      <div className='my-1, border border-gray-200 rounded-sm flex'>
        <div className='font-bold flex-auto'>Report Name</div>
        <div className='font-bold italic w-64'>Status</div>
        <div className='font-bold flex-none'>Sequence</div>
        <div className='font-bold flex-none'>Row-Span</div>
        <div className='font-bold flex-none'>Col-Span</div>
      </div>
      {reportsList.map((x) => (
        <div
          className='my-1, border border-gray-200 rounded-sm flex'
          key={x.name}
        >
          <div className='font-semibold flex-auto'>{x.name}</div>
          <div className='italic w-64'>
            <select
              onChange={(evt) => {
                setSelectedReports((s) => {
                  return {
                    ...s,
                    ...{
                      [x.name]: {
                        sequence: x.sequence,
                        enabled: evt.target.value as any,
                        rowSpan: x.rowSpan,
                        colSpan: x.colSpan,
                      },
                    },
                  };
                });
              }}
              value={x.enabled}
            >
              <option value='yes'>Enabled</option>
              <option value='no'>Disabled</option>
            </select>
          </div>
          <div className='font-bold flex-none'>
            <input
              type='number'
              className='w-16'
              defaultValue={x.sequence}
              onChange={(evt) => {
                setSelectedReports((s) => {
                  return {
                    ...s,
                    ...{
                      [x.name]: {
                        sequence: parseInt(evt.target.value),
                        enabled: x.enabled as any,
                        colSpan: x.colSpan,
                        rowSpan: x.rowSpan,
                      },
                    },
                  };
                });
              }}
            />
          </div>
          <div className='font-bold flex-none'>
            <input
              type='number'
              className='w-16'
              defaultValue={x.rowSpan}
              onChange={(evt) => {
                setSelectedReports((s) => {
                  return {
                    ...s,
                    ...{
                      [x.name]: {
                        sequence: x.sequence,
                        enabled: x.enabled as any,
                        colSpan: x.colSpan,
                        rowSpan: parseInt(evt.target.value),
                      },
                    },
                  };
                });
              }}
            />
          </div>
          <div className='font-bold flex-none'>
            <input
              type='number'
              className='w-16'
              defaultValue={x.colSpan}
              onChange={(evt) => {
                setSelectedReports((s) => {
                  return {
                    ...s,
                    ...{
                      [x.name]: {
                        sequence: x.sequence,
                        enabled: x.enabled as any,
                        colSpan: parseInt(evt.target.value),
                        rowSpan: x.rowSpan,
                      },
                    },
                  };
                });
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
