import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { UseCurrentCompany } from '../../../../../hooks/admin/company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UseMisconduct } from '../../../../../hooks/hrd/disciplinary/misconduct/use-misconduct';
import { UseMisconducts } from '../../../../../hooks/hrd/disciplinary/misconduct/use-miscounducts';
import { UseEmployeeProfiles } from '../../../../../hooks/hrd/employee/profile/use-employee-profiles';
// import { UseCurrentUser } from '../../../../../hooks/admin/user-admin/use-current-user';
import { UserRendererInterface } from '../../../../common/fragments/user';
import { renderEmployeeMisconductForm } from './misconducts';
// import { createPenaltiesCard } from './penalties';

// import { renderCounsellingForm } from '../disciplinary-form/counselling-form';
// import { renderMemo } from './memo';

type CreateEmployeeDisciplinaryCardType = {
  // id: string;
  // useCurrentUser: UseCurrentUser;
  useMisconducts: UseMisconducts;
  useCurrentCompany: UseCurrentCompany;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useFileTransfer: UseFileTransfer;
  useMisconduct: UseMisconduct;
  useEmployeeProfiles: UseEmployeeProfiles;
  employee: EmployeeProfileType;
  userRendererService: UserRendererInterface;
  // setEmployee: (s: EmployeeProfileType) => void;
};

export function renderEmployeeDisciplinary({
  employee,
  // setEmployee,
  // useCurrentUser,
  // id,
  useMisconducts,
  useCurrentCompany,
  useCurrentCompanyGroup,
  useFileTransfer,
  useMisconduct,
  useEmployeeProfiles,
  userRendererService,
}: CreateEmployeeDisciplinaryCardType): () => JSX.Element {
  // const Memo = renderMemo(useEmployeeProfile);
  // const Counselling = renderCounsellingForm();

  // const PenaltiesCard = createPenaltiesCard();
  const Misconduct = renderEmployeeMisconductForm();

  return function EmployeeDisciplinary(): JSX.Element {
    return (
      <>
        {/* <div>{data.id ? <Memo id={data.id} /> : null}</div> */}
        <br />
        {/* <div>{data.id ? <Counselling id={data.id} /> : null}</div> */}
        {/* <PenaltiesCard
          useCurrentUser={useCurrentUser}
          employee={employee}
          setEmployee={setEmployee}
        /> */}
        <br />
        <Misconduct
          userRendererService={userRendererService}
          useMisconducts={useMisconducts}
          useCurrentCompany={useCurrentCompany}
          useCurrentCompanyGroup={useCurrentCompanyGroup}
          useFileTransfer={useFileTransfer}
          useMisconduct={useMisconduct}
          useEmployeeProfiles={useEmployeeProfiles}
          employee={employee}
        />
      </>
    );
  };
}
