import {
  PurchaseEnquiry,
  PurchaseRequisition,
  VendorLocation,
} from '@erp_core/erp-types/dist/modules/order';
import { Terms } from '@erp_core/erp-types/dist/types/modules/order/purchase/purchase-enquiry';
import { VendorContact } from '@erp_core/erp-types/dist/types/modules/order/purchase/vendor';
import {
  DateSelector,
  LoadingButton,
  renderCardComponent,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import {
  CheckBadgeIcon,
  CheckCircleIcon,
  PencilIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UsePurchaseRequisitions } from '../../../../../hooks/inventory/purchase/purchase-requisition/use-purchase-requisitions';
import { UseVendors } from '../../../../../hooks/inventory/purchase/vendors/use-vendors';
import { EditVendorInEnquiry } from './enquiry/edit-vendor';

export function renderVendorSelectForEnquiry({
  purchaseRequisition,
  onClose,
  useVendors,
  usePurchaseRequisitions,
  setPurchaseEnquiry,
  setPurchaseRequisition,
  paymentTermsOptions,
  deliveryTermsOptions,
  modeOfTransportOptions,
  deliveryModeOptions,
}: {
  purchaseRequisition: PurchaseRequisition;
  onClose: () => void;
  useVendors: UseVendors;
  usePurchaseRequisitions: UsePurchaseRequisitions;
  setPurchaseEnquiry: (s: PurchaseEnquiry) => Promise<PurchaseEnquiry>;
  setPurchaseRequisition: (
    s: PurchaseRequisition
  ) => Promise<PurchaseRequisition>;
  paymentTermsOptions: Array<{ id: string; name: string }>;
  deliveryTermsOptions: Array<{ id: string; name: string }>;
  modeOfTransportOptions: Array<{ id: string; name: string }>;
  deliveryModeOptions: Array<{ id: string; name: string }>;
}): () => JSX.Element {
  const Table = renderTableComponent();

  return function VendorSelectForEnquiry(): JSX.Element {
    const [draftPE, setDraftPE] = useState<{
      name: string;
      validity: number;
      quantity: number;
      date: string;
    }>({
      name: `PE-${moment().format('YYYY-MM-HH:mm')}`,
      validity: 15,
      quantity: 0,
      date: '',
      // deliveryTerms: { id: '', name: '' },
      // preCarraigeBy: '',
      // modeOfTransport: { id: '', name: '' },
      // placeOfReceiptByCarrier: '',
      // deliveryMode: { id: '', name: '' },
      // paymentTerms: { id: '', name: '' },
      // creditPeriod: 0,
    });

    const [shortListedVendors, setShortListedVendors] = useState<
      Array<{
        id: string;
        name: string;
        location: VendorLocation;
        contact: VendorContact;
        verified: boolean;
        selected: boolean;
        terms: Terms;
      }>
    >([]);
    const { data: vendors, getAll: getVendors } = useVendors();
    const {
      data: purchaseRequisitions,
      getAll: getPRs,
    } = usePurchaseRequisitions();
    const [deliverySchedule, setDeliverySchedule] = useState<Array<any>>([]);
    // eslint-disable-next-line
    const [paymentSchedule, setPaymentSchedule] = useState<Array<any>>([]);
    const [schSummary, setSchSummary] = useState<{
      delivery: { total: number; scheduled: number };
      payment: { total: number; scheduled: number };
    }>({
      delivery: { total: 0, scheduled: 0 },
      payment: { total: 100, scheduled: 0 },
    });

    useEffect(() => {
      setSchSummary({
        delivery: {
          total: draftPE.quantity || 0,
          scheduled: deliverySchedule.reduce((prev, sc) => {
            return prev + sc.quantity;
          }, 0),
        },
        payment: {
          total: 100,
          scheduled: paymentSchedule.reduce((prev, sc) => {
            return prev + sc.payment;
          }, 0),
        },
      });
    }, [deliverySchedule, paymentSchedule, vendors, draftPE]);

    useEffect(() => {
      // we update the target date with the earliest pr.
      if (purchaseRequisitions?.length) {
        let date = draftPE.date;
        (purchaseRequisitions || []).forEach((pr) => {
          if (!date || moment(pr.details.targetDate).isBefore(moment(date))) {
            date = pr.details.targetDate;
          }
        });

        if (!date || draftPE.date !== date) {
          // We alse set the initial total quantity
          setDraftPE((d) => ({
            ...d,
            ...{
              date: date,
              quantity:
                purchaseRequisitions?.reduce((prev, pr) => {
                  return prev + pr.details.quantity;
                }, 0) || 0,
            },
          }));
        }
      }
      // eslint-disable-next-line
    }, [purchaseRequisitions]);

    useEffect(() => {
      if (vendors?.length) {
        const slv: Array<{
          id: string;
          name: string;
          location: VendorLocation;
          contact: VendorContact;
          verified: boolean;
          selected: boolean;
          terms: Terms;
        }> = [];
        vendors.forEach((v) => {
          slv.push({
            id: v.id,
            name: v.name,
            location:
              v.details?.locations?.find(
                (c) =>
                  c.id ===
                  v.items?.find(
                    (x) => x.item?.id === purchaseRequisition?.grade?.item?.id
                  )?.details?.supplierLocation?.id
              ) ||
              (v.details?.locations && v.details?.locations[0]) ||
              ({} as any),
            contact:
              v.details?.contact?.find(
                (c) =>
                  c.id ===
                  v.items?.find(
                    (x) => x.item?.id === purchaseRequisition?.grade?.item?.id
                  )?.details?.contactPerson?.id
              ) ||
              (v.details?.contact && v.details?.contact[0]) ||
              ({} as any),
            selected: v.items?.find(
              (x) => x.item.id === purchaseRequisition.grade?.item?.id
            )?.approved
              ? true
              : false,
            verified: v.items?.find(
              (x) => x.item.id === purchaseRequisition.grade?.item?.id
            )?.approved
              ? true
              : false,
            terms: {
              delivery: {
                deliveryTerms: v.details?.termsOfBusiness?.deliveryTerms || {
                  id: '',
                  name: '',
                },
                // preCarraigeBy: v.details?.termsOfBusiness?.preCarraigeBy || '',
                modeOfTransport: v.details?.termsOfBusiness
                  ?.modeOfTransport || { id: '', name: '' },
                // placeOfReceiptByCarrier:
                //   v.details?.termsOfBusiness?.placeOfReceiptByCarrier || '',
                deliveryMode: { id: 'A', name: '' },
              },
              payment: {
                paymentTerms: v.details?.termsOfBusiness?.paymentTerms || {
                  id: '',
                  name: '',
                },
                // creditPeriod: v.details?.termsOfBusiness?.creditPeriod || 0,
              },
            } as any,
          });
        });

        setShortListedVendors(slv);
      }
    }, [vendors]);

    useEffect(() => {
      if (vendors?.length) {
        const slv = shortListedVendors;
        // deliveryMode: { id: '', name: '' },
        slv.forEach((v) => {
          // if (
          //   draftPE.deliveryTerms?.id &&
          //   vendors.find(
          //     (x) =>
          //       x.id === v.id && !x.details?.termsOfBusiness?.deliveryTerms?.id
          //   )
          // ) {
          //   v.terms.delivery.deliveryTerms = draftPE.deliveryTerms;
          // }
          // if (
          //   draftPE.preCarraigeBy &&
          //   vendors.find(
          //     (x) => x.id === v.id && !x.details?.termsOfBusiness?.preCarraigeBy
          //   )
          // ) {
          //   v.terms.delivery.preCarraigeBy = draftPE.preCarraigeBy;
          // }
          // if (
          //   draftPE.modeOfTransport?.id &&
          //   vendors.find(
          //     (x) =>
          //       x.id === v.id &&
          //       !x.details?.termsOfBusiness?.modeOfTransport?.id
          //   )
          // ) {
          //   v.terms.delivery.modeOfTransport = draftPE.modeOfTransport;
          // }
          // if (
          //   draftPE.placeOfReceiptByCarrier &&
          //   vendors.find(
          //     (x) =>
          //       x.id === v.id &&
          //       !x.details?.termsOfBusiness?.placeOfReceiptByCarrier
          //   )
          // ) {
          //   v.terms.delivery.placeOfReceiptByCarrier =
          //     draftPE.placeOfReceiptByCarrier;
          // }
          // if (
          //   draftPE.paymentTerms?.id &&
          //   vendors.find(
          //     (x) =>
          //       x.id === v.id && !x.details?.termsOfBusiness?.paymentTerms?.id
          //   )
          // ) {
          //   v.terms.payment.paymentTerms = draftPE.paymentTerms;
          // }
          // if (
          //   draftPE.creditPeriod &&
          //   vendors.find(
          //     (x) => x.id === v.id && !x.details?.termsOfBusiness?.creditPeriod
          //   )
          // ) {
          //   v.terms.payment.creditPeriod = draftPE.creditPeriod;
          // }
        });

        setShortListedVendors([...slv]);
      }
      // eslint-disable-next-line
    }, [draftPE]);

    useEffect(() => {
      if (purchaseRequisition.grade?.item?.id) {
        getVendors({
          itemId: purchaseRequisition.grade?.item?.id,
        });

        getPRs({
          itemId: purchaseRequisition.grade?.item?.id,
          status: 'open',
        });
      }
      // eslint-disable-next-line
    }, []);
    const Card = renderCardComponent();
    return (
      <div>
        <Card
          header={{
            title: (
              <>
                <span className='mx-1'>{draftPE.name}</span>
                <span className='mx-1'>{purchaseRequisition.grade?.name}</span>
                <span className='mx-1'>
                  {purchaseRequisition.grade?.item?.name}
                </span>
              </>
            ),
          }}
          body={{
            type: 'jsx-component',
            body: (
              <>
                <div className='flex'>
                  <div className='w-1/3'>
                    <Card
                      header={{
                        title: 'Open Purchase Requisitions',
                      }}
                      body={{
                        type: 'jsx-component',
                        body: (
                          <div>
                            <Table
                              header={[
                                [
                                  { name: 'Name' },
                                  { name: 'Target Date' },
                                  { name: 'Quantity' },
                                ],
                              ]}
                              body={(purchaseRequisitions || [])
                                .sort((a, b) => {
                                  if (
                                    a.details.targetDate &&
                                    b.details.targetDate
                                  ) {
                                    return moment(a.details.targetDate).diff(
                                      moment(b.details.targetDate)
                                    );
                                  }
                                  return 0;
                                })
                                ?.map((v) => {
                                  return {
                                    cells: [
                                      { value: v.name },
                                      { value: v.details.targetDate },
                                      { value: v.details.quantity },
                                    ],
                                  };
                                })}
                            />
                          </div>
                        ),
                      }}
                    />
                    <div className='border border-gray-200 p-1'>
                      <div className='flex flex-wrap justify-center'>
                        <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                          <div className='mx-1'>
                            <span className='font-bold'>Target Date: </span>
                            <span className='mx-1'>
                              <DateSelector
                                initialState={draftPE.date}
                                format='YYYY-MM-DD'
                                onChange={(date) =>
                                  setDraftPE((d) => ({
                                    ...d,
                                    ...{ date: date },
                                  }))
                                }
                              />
                            </span>
                          </div>
                        </div>
                        <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                          <div className='mx-1'>
                            <span className='font-bold'>Quantity: </span>
                            <span className='mx-1'>
                              <input
                                type='number'
                                className='w-16 border border-gray-200'
                                defaultValue={draftPE.quantity}
                                min={0}
                                onBlur={(evt) => {
                                  setDraftPE((d) => ({
                                    ...d,
                                    ...{ quantity: parseInt(evt.target.value) },
                                  }));
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                          <div className='mx-1'>
                            <span className='font-bold'>Validity Days: </span>
                            <span className='mx-1'>
                              <input
                                type='number'
                                className='w-16 border border-gray-200'
                                defaultValue={draftPE.validity}
                                min={0}
                                onBlur={(evt) => {
                                  setDraftPE((d) => ({
                                    ...d,
                                    ...{ validity: parseInt(evt.target.value) },
                                  }));
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Card
                      header={{
                        title: 'Delivery Schedule',
                        subheading: (
                          <div className='flex'>
                            <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                              <div className='mx-1'>
                                <span className='font-bold'>
                                  Total Quantity:{' '}
                                </span>
                                <span>{schSummary.delivery.total}</span>
                              </div>
                              <div className='mx-1'>
                                <span className='font-semibold'>
                                  Scheduled:{' '}
                                </span>
                                <span>{schSummary.delivery.scheduled}</span>
                              </div>
                            </div>
                          </div>
                        ),
                        actions: [
                          {
                            type: 'jsx',
                          },
                        ],
                      }}
                      body={{
                        type: 'jsx-component',
                        body: (
                          <DeliverySchedule
                            deliverySchedule={deliverySchedule}
                            setDeliverySchedule={setDeliverySchedule}
                          />
                        ),
                      }}
                    />
                    {/* <Card
                      header={{
                        title: 'Payment Schedule',
                        subheading: (
                          <div className='flex'>
                            <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                              <div className='mx-1'>
                                <span className='font-bold'>
                                  Total Payment:{' '}
                                </span>
                                <span>{schSummary.payment.total} %</span>
                              </div>
                              <div className='mx-1'>
                                <span className='font-semibold'>
                                  Scheduled:{' '}
                                </span>
                                <span>{schSummary.payment.scheduled} %</span>
                              </div>
                            </div>
                          </div>
                        ),
                      }}
                      body={{
                        type: 'jsx-component',
                        body: (
                          <PaymentSchedule
                            paymentSchedule={paymentSchedule}
                            setPaymentSchedule={setPaymentSchedule}
                          />
                        ),
                      }}
                    /> */}
                  </div>
                  <div className='w-2/3'>
                    <Card
                      header={{
                        title: 'Vendors Providing this Item',
                      }}
                      body={{
                        type: 'jsx-component',
                        body: (
                          <div>
                            <div className='my-1 border border-gray-100 p-1'>
                              {/* <div className='my-1 font-semibold'>
                                Default Terms
                              </div> */}
                              <div className='flex flex-wrap justify-center'>
                                {/* <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                                  <div className='mx-1'>
                                    <span className='font-bold'>
                                      Payment Terms:{' '}
                                    </span>
                                    <span className='mx-1'>
                                      <select
                                        className='w-16 border border-gray-200'
                                        defaultValue={draftPE.paymentTerms.id}
                                        onBlur={(evt) => {
                                          if (evt.target.value) {
                                            setDraftPE((d) => ({
                                              ...d,
                                              ...{
                                                paymentTerms: paymentTermsOptions.find(
                                                  (x) =>
                                                    x.id === evt.target.value
                                                ),
                                              },
                                            }));
                                          }
                                        }}
                                      >
                                        <option value=''>Select</option>
                                        {paymentTermsOptions.map((x) => (
                                          <option key={x.id} value={x.id}>
                                            {x.name}
                                          </option>
                                        ))}
                                      </select>
                                    </span>
                                  </div>
                                </div>
                                <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                                  <div className='mx-1'>
                                    <span className='font-bold'>
                                      Delivery Terms:{' '}
                                    </span>
                                    <span className='mx-1'>
                                      <select
                                        className='w-16 border border-gray-200'
                                        defaultValue={draftPE.deliveryTerms.id}
                                        onBlur={(evt) => {
                                          if (evt.target.value) {
                                            setDraftPE((d) => ({
                                              ...d,
                                              ...{
                                                deliveryTerms: deliveryTermsOptions.find(
                                                  (x) =>
                                                    x.id === evt.target.value
                                                ),
                                              },
                                            }));
                                          }
                                        }}
                                      >
                                        <option value=''>Select</option>
                                        {deliveryTermsOptions.map((x) => (
                                          <option key={x.id} value={x.id}>
                                            {x.name}
                                          </option>
                                        ))}
                                      </select>
                                    </span>
                                  </div>
                                </div> */}

                                {/* <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                                  <div className='mx-1'>
                                    <span className='font-bold'>Pre-Carraige By: </span>
                                    <span className='mx-1'>
                                      <input
                                        className='w-16 border border-gray-200'
                                        defaultValue={draftPE.preCarraigeBy}
                                        onBlur={(evt) => {
                                          setDraftPE((d) => ({
                                            ...d,
                                            ...{ preCarraigeBy: evt.target.value },
                                          }));
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div> */}

                                {/* <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                                  <div className='mx-1'>
                                    <span className='font-bold'>Credit Period: </span>
                                    <span className='mx-1'>
                                      <input
                                        type='number'
                                        className='w-16 border border-gray-200'
                                        defaultValue={draftPE.creditPeriod}
                                        min={0}
                                        onBlur={(evt) => {
                                          setDraftPE((d) => ({
                                            ...d,
                                            ...{ creditPeriod: parseInt(evt.target.value) },
                                          }));
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div> */}

                                {/* <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                                  <div className='mx-1'>
                                    <span className='font-bold'>
                                      Place of Receipt By Carrier:{' '}
                                    </span>
                                    <span className='mx-1'>
                                      <input
                                        className='w-14 border border-gray-200'
                                        defaultValue={draftPE.placeOfReceiptByCarrier}
                                        onBlur={(evt) => {
                                          setDraftPE((d) => ({
                                            ...d,
                                            ...{
                                              placeOfReceiptByCarrier: evt.target.value,
                                            },
                                          }));
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div> */}

                                {/* <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                                  <div className='mx-1'>
                                    <span className='font-bold'>
                                      Transport Mode:{' '}
                                    </span>
                                    <span className='mx-1'>
                                      <select
                                        className='w-16 border border-gray-200'
                                        defaultValue={
                                          draftPE.modeOfTransport.id
                                        }
                                        onBlur={(evt) => {
                                          if (evt.target.value) {
                                            setDraftPE((d) => ({
                                              ...d,
                                              ...{
                                                modeOfTransport: modeOfTransportOptions.find(
                                                  (x) =>
                                                    x.id === evt.target.value
                                                ),
                                              },
                                            }));
                                          }
                                        }}
                                      >
                                        <option value=''>Select</option>
                                        {modeOfTransportOptions.map((x) => (
                                          <option key={x.id} value={x.id}>
                                            {x.name}
                                          </option>
                                        ))}
                                      </select>
                                    </span>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <Table
                              actions={[
                                {
                                  name: 'Edit',
                                  behaviour: 'modal',
                                  show: () => true,
                                  modal: {
                                    title: 'Edit',
                                    content: ({
                                      data: { vendor },
                                      onClose,
                                    }) => {
                                      return (
                                        <EditVendorInEnquiry
                                          modeOfTransportOptions={
                                            modeOfTransportOptions
                                          }
                                          deliveryTermsOptions={
                                            deliveryTermsOptions
                                          }
                                          vendor={vendor}
                                          vendors={vendors || []}
                                          onSave={async (v) => {
                                            console.log(v);
                                            const newSLV = [
                                              ...shortListedVendors,
                                            ];
                                            const f = newSLV.find(
                                              (x) => x.id === v.id
                                            );
                                            if (f) {
                                              f.location = v.location;
                                              f.contact = v.contact;
                                              f.terms = v.terms;
                                              setShortListedVendors(newSLV);
                                            }
                                            onClose();
                                          }}
                                        />
                                      );
                                    },
                                  },
                                },
                              ]}
                              header={[
                                [
                                  { name: 'Name' },
                                  { name: 'Location' },
                                  { name: 'Contact' },
                                  { name: 'Payment Terms' },
                                  {
                                    name: 'Delivery Terms',
                                  },
                                  // {
                                  //   name: 'Pre-Carraige By',
                                  // },
                                  // {
                                  //   name: 'Credit Period, Days',
                                  // },
                                  {
                                    name: 'Mode of Transport',
                                  },
                                  // {
                                  //   name: 'Place of Receipt by Carrier',
                                  // },
                                  { name: '' },
                                ],
                              ]}
                              body={shortListedVendors?.map((v) => {
                                return {
                                  rowData: {
                                    vendor: v,
                                  },
                                  cells: [
                                    {
                                      value: (
                                        <>
                                          {v.name}
                                          {v.verified ? (
                                            <CheckBadgeIcon className='text-green-500 w-5 mx-1 inline' />
                                          ) : null}
                                        </>
                                      ),
                                    },
                                    {
                                      value: (
                                        <>
                                          <div>
                                            {v.location?.address?.addressLine1},
                                          </div>
                                          <div>
                                            {v.location?.address?.addressLine2},
                                          </div>
                                          <div>
                                            {v.location?.address?.city},
                                          </div>
                                        </>
                                      ),
                                    },
                                    {
                                      value: (
                                        <>
                                          <div>{v.contact?.name},</div>
                                          <div>{v.contact?.phones},</div>
                                          <div>{v.contact?.emails},</div>
                                        </>
                                      ),
                                    },
                                    {
                                      value: `${
                                        Array.isArray(
                                          v.terms?.payment?.paymentTerms
                                        )
                                          ? v.terms?.payment?.paymentTerms
                                              ?.map((x) => {
                                                return `${x.percent}% ${x.paymentType} - {if n>0 Then "${x.noOfDays}" Days}.`;
                                              })
                                              .join(', ') || '-'
                                          : '-'
                                      }`,
                                    },
                                    {
                                      value: `${
                                        v.terms?.delivery?.deliveryTerms
                                          ?.name || '-'
                                      }`,
                                    },
                                    // {
                                    //   value: `${v.terms?.delivery?.preCarraigeBy || '-'
                                    //     }`,
                                    // },
                                    // {
                                    //   value: `${v.terms?.payment?.creditPeriod || '-'
                                    //     }`,
                                    // },
                                    {
                                      value: `${
                                        v.terms?.delivery?.modeOfTransport
                                          ?.name || '-'
                                      }`,
                                    },
                                    // {
                                    //   value: `${v.terms?.delivery
                                    //     ?.placeOfReceiptByCarrier || '-'
                                    //     }`,
                                    // },
                                    {
                                      value: (
                                        <input
                                          type='checkbox'
                                          checked={v.selected}
                                          onChange={(evt) => {
                                            const found = shortListedVendors.find(
                                              (x) => x === v
                                            );
                                            if (found) {
                                              found.selected = !found.selected;
                                              setShortListedVendors([
                                                ...shortListedVendors,
                                              ]);
                                            }
                                          }}
                                        />
                                      ),
                                    },
                                  ],
                                };
                              })}
                            />
                          </div>
                        ),
                      }}
                    />
                  </div>
                </div>
              </>
            ),
          }}
        />
        <div className='flex justify-center my-2'>
          {deliverySchedule.length &&
          shortListedVendors.filter((x) => x.selected)?.length ? (
            <LoadingButton
              text='Create Draft Enquiry'
              behaviourParams={{}}
              behaviorFn={async () => {
                if (purchaseRequisitions?.length) {
                  const pe = await setPurchaseEnquiry({
                    name: draftPE.name,
                    purchaseRequisitions: [],
                    details: {
                      itemDetails: {
                        quantity:
                          draftPE.quantity ||
                          _.reduce(
                            purchaseRequisitions || [],
                            (prev, curr) => {
                              return prev + curr.details?.quantity || 0;
                            },
                            0
                          ),
                        grade: purchaseRequisitions[0].grade,
                        uom: purchaseRequisitions[0].itemDetails?.uom,
                      },
                      vendors: shortListedVendors
                        .filter((x) => x.selected)
                        .map((x) => {
                          return {
                            id: x.id,
                            name: x.name,
                            terms: x.terms,
                            location: x.location,
                            contact: x.contact,
                          };
                        }),
                      terms: {
                        // delivery: {
                        //   deliveryTerms: draftPE.deliveryTerms,
                        //   // preCarraigeBy: draftPE.preCarraigeBy,
                        //   modeOfTransport: draftPE.modeOfTransport,
                        //   // placeOfReceiptByCarrier:
                        //   // draftPE.placeOfReceiptByCarrier,
                        //   deliveryMode: draftPE.modeOfTransport,
                        // },
                        // payment: {
                        //   paymentTerms: draftPE.paymentTerms,
                        //   // creditPeriod: draftPE.creditPeriod,
                        // },
                      } as any,
                      schedule: {
                        delivery: deliverySchedule,
                        amountIn: 'per',
                        payment: paymentSchedule,
                      },
                      activity: [],
                      validity: draftPE.validity,
                    },
                    grade: purchaseRequisitions[0].grade,
                    status: 'enquiry-draft',
                    enquiryQuotes: [],
                  } as any);
                  if (pe.id) {
                    // TODO: Figure out why this doesnt work within PE request
                    for (const pr of purchaseRequisitions || []) {
                      if (pr.status === 'pr-open') {
                        await setPurchaseRequisition({
                          id: pr.id,
                          status: 'enquiry-draft',
                          purchaseEnquiry: {
                            id: pe.id,
                            name: pe.name,
                          },
                        } as any);
                      }
                    }
                  }

                  toast.success('Draft Purchase Enquiry Created');
                }
                onClose();
              }}
              defaultStyle='bg-green-500 text-white p-1 rounded'
            />
          ) : null}
        </div>
      </div>
    );
  };
}

function DeliverySchedule({
  deliverySchedule,
  setDeliverySchedule,
}: {
  deliverySchedule: any[];
  setDeliverySchedule: React.Dispatch<React.SetStateAction<any[]>>;
}): JSX.Element {
  const [selectedEvent, setSelectedEvent] = useState<any | null>(null);

  return (
    <div className='flex flex-wrap'>
      {deliverySchedule.map((s, idx) => (
        <div
          key={idx}
          className='m-1 p-1 border border-gray-200 rounded-md relative'
        >
          {selectedEvent?.idx === idx ? (
            <div>
              <CheckCircleIcon
                className='absolute top-0 right-0 w-5 h-5 text-green-500 cursor-pointer'
                onClick={() => {
                  deliverySchedule.splice(selectedEvent.idx, 1, {
                    name: selectedEvent.name,
                    days: selectedEvent.days,
                    quantity: selectedEvent.quantity,
                  });
                  setDeliverySchedule([...deliverySchedule]);
                }}
              />

              <div className='text-center font-bold'>
                Name:{' '}
                <input
                  defaultValue={selectedEvent.name}
                  onBlur={(evt) =>
                    setSelectedEvent((se) => ({
                      ...se,
                      ...{ name: evt.target.value },
                    }))
                  }
                />
              </div>
              <div className='text-center text-blue-500'>
                Days:{' '}
                <input
                  type='number'
                  defaultValue={selectedEvent.days}
                  onBlur={(evt) =>
                    setSelectedEvent((se) => ({
                      ...se,
                      ...{ days: parseInt(evt.target.value) },
                    }))
                  }
                />
              </div>
              <div>
                Quantity:{' '}
                <input
                  type='number'
                  defaultValue={selectedEvent.quantity}
                  onBlur={(evt) =>
                    setSelectedEvent((se) => ({
                      ...se,
                      ...{ quantity: parseInt(evt.target.value) },
                    }))
                  }
                />
              </div>
            </div>
          ) : (
            <>
              <PencilIcon
                className='absolute top-0 right-0 w-5 h-5 text-blue-500 cursor-pointer'
                onClick={() => {
                  setSelectedEvent({
                    idx: idx,
                    name: s.name,
                    days: s.days,
                    quantity: s.quantity,
                  });
                }}
              />
              <div className='text-center font-bold'>{s.name}</div>
              <div className='text-center text-blue-500'>{s.days} Days*</div>
              <div>Quantity: {s.quantity}</div>
            </>
          )}
        </div>
      ))}
      <PlusIcon
        className='inline my-auto w-6 h-6 text-green-500 cursor-pointer'
        onClick={() => {
          setDeliverySchedule((s) => [
            ...s,
            { event: '', days: 0, quantity: 0 },
          ]);
        }}
      />
    </div>
  );
}

// function PaymentSchedule({
//   paymentSchedule,
//   setPaymentSchedule,
// }: {
//   paymentSchedule: any[];
//   setPaymentSchedule: React.Dispatch<React.SetStateAction<any[]>>;
// }): JSX.Element {
//   const [selectedEvent, setSelectedEvent] = useState<any | null>(null);

//   return (
//     <div className='flex flex-wrap'>
//       {paymentSchedule.map((s, idx) => (
//         <div
//           key={idx}
//           className='m-1 p-1 border border-gray-200 rounded-md relative'
//         >
//           {selectedEvent?.idx === idx ? (
//             <div>
//               <CheckCircleIcon
//                 className='absolute top-0 right-0 w-5 h-5 text-green-500 cursor-pointer'
//                 onClick={() => {
//                   paymentSchedule.splice(selectedEvent.idx, 1, {
//                     name: selectedEvent.name,
//                     days: selectedEvent.days,
//                     payment: selectedEvent.payment,
//                   });
//                   setPaymentSchedule([...paymentSchedule]);
//                 }}
//               />

//               <div className='text-center font-bold'>
//                 Name:{' '}
//                 <input
//                   defaultValue={selectedEvent.name}
//                   onBlur={(evt) =>
//                     setSelectedEvent((se) => ({
//                       ...se,
//                       ...{ name: evt.target.value },
//                     }))
//                   }
//                 />
//               </div>
//               <div className='text-center text-blue-500'>
//                 Days:{' '}
//                 <input
//                   type='number'
//                   defaultValue={selectedEvent.days}
//                   onBlur={(evt) =>
//                     setSelectedEvent((se) => ({
//                       ...se,
//                       ...{ days: parseInt(evt.target.value) },
//                     }))
//                   }
//                 />
//               </div>
//               <div>
//                 Payment %:{' '}
//                 <input
//                   type='number'
//                   defaultValue={selectedEvent.payment}
//                   onBlur={(evt) =>
//                     setSelectedEvent((se) => ({
//                       ...se,
//                       ...{ payment: parseInt(evt.target.value) },
//                     }))
//                   }
//                 />
//               </div>
//             </div>
//           ) : (
//             <>
//               <PencilIcon
//                 className='absolute top-0 right-0 w-5 h-5 text-blue-500 cursor-pointer'
//                 onClick={() => {
//                   setSelectedEvent({
//                     idx: idx,
//                     name: s.name,
//                     days: s.days,
//                     payment: s.payment,
//                   });
//                 }}
//               />
//               <div className='text-center font-bold'>{s.name}</div>
//               <div className='text-center text-blue-500'>{s.days} Days*</div>
//               <div>Payment: {s.payment}</div>
//             </>
//           )}
//         </div>
//       ))}
//       <PlusIcon
//         className='inline my-auto w-6 h-6 text-green-500 cursor-pointer'
//         onClick={() => {
//           setPaymentSchedule((s) => [...s, { event: '', days: 0, payment: 0 }]);
//         }}
//       />
//     </div>
//   );
// }
