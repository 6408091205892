import { LocationType } from '@erp_core/erp-types/dist/modules/admin';
import {
  Godown,
  ItemBatchGodown,
} from '@erp_core/erp-types/dist/modules/inventory';
import _ from 'lodash';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import { useEffect, useState } from 'react';
import { UseCurrentLocation } from '../../../../hooks/admin/location-admin/use-current-location';
import { UseGodownsTree } from '../../../../hooks/inventory/godown-inventory/use-godowns-tree';
import { UseBatchItemGodown } from '../../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UseBatchItemGodowns } from '../../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { FileTransferInterface } from '../../../../models/interfaces/fileTransfer/fileTransfer';
import { TreeIcon } from '../../../../utils/common';
import { createItemBatchSideView } from './item-batch-side-view';
// import { createGodownSideView } from './item-batch-side-view';

type TreeNodeType = {
  type: string;
  key: string;
  isLeaf: boolean;
  children: Array<TreeNodeType>;
  title: string;
};

type RenderGodownTree = {
  useGodownsTree: UseGodownsTree;
  useBatchItems: UseBatchItemGodowns;
  useCurrentLocation: UseCurrentLocation;
  useBatchItem: UseBatchItemGodown;
  fileTransferService: FileTransferInterface;
};

export function renderGodownItemTree({
  useGodownsTree,
  useCurrentLocation,
  useBatchItem,
  useBatchItems,
  fileTransferService,
}: RenderGodownTree): () => JSX.Element {
  return function GodownTree() {
    const { data: godowns, getAll } = useGodownsTree();

    const { data: batchItems, getAll: getAllBatchItems } = useBatchItems();

    const { data: location } = useCurrentLocation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => getAllBatchItems(), []);

    const { data: currentBatch, get } = useBatchItem();

    const [nodeData, setNodeData] = useState<TreeNodeType>();

    useEffect(() => {
      if (godowns && batchItems) {
        setNodeData(
          renderTreeNode(location, _.sortBy(godowns, 'name'), batchItems)
        );
      }
    }, [godowns, batchItems, location]);

    const onRightClick = (event, node) => {
      // console.log(node);

      if (node.key && node.key !== 'root' && node.type === 'batch-item')
        get(node.key);
    };

    const ItemBatchSideView = createItemBatchSideView({
      useBatchItem,
      reload: () => {
        get(currentBatch.id);
        getAll();
      },
    });

    return (
      <div className='flex min-h-96'>
        {nodeData ? (
          <Tree
            onClick={onRightClick}
            onCheck={onRightClick}
            defaultExpandAll
            className='max-h-[600px] p-2 text-lg scrollbar-none overflow-y-auto w-3/5'
            treeData={[renderTree(nodeData)]}
          />
        ) : null}
        {currentBatch && currentBatch.id ? (
          <ItemBatchSideView itemBatchData={currentBatch} />
        ) : null}
      </div>
    );
  };
}

function renderTreeNode(
  location: LocationType,
  godowns: Array<Godown>,
  batchItems: Array<ItemBatchGodown>
) {
  const data: TreeNodeType = {
    type: 'root',
    key: 'root',
    isLeaf: false,
    children: [],
    title: location.name || 'Root',
  };

  // console.log('Batch Items', batchItems);

  const items = (parentGodown: string) => {
    return batchItems
      .filter((b) => b.godown.id === parentGodown)
      .map((i) => {
        return {
          type: 'item-batch',
          key: i.id,
          isLeaf: false,
          children: [],
          title: i.name,
        };
      });
  };

  const recursiveFun = (godowns: Godown[], parent): TreeNodeType[] => {
    return godowns
      .filter((g) => g.parentGodownId === parent)
      .map((g) => {
        return {
          type: 'godown',
          key: g.id,
          isLeaf: false,
          children: [...recursiveFun(godowns, g.id), ...items(g.id)],
          title: g.legacyGodownId || g.name,
        };
      });
  };
  data.children = recursiveFun(godowns, 'root');
  return data;
}

function renderTree(data: TreeNodeType) {
  const result = {
    key: data.key,
    children: data.children.map((d) => renderTree(d)),
    title: data.title,
    icon: (
      <div className='w-5 text-indigo-900 self-center'>
        <TreeIcon type={data.type} />
      </div>
    ),
    expandAction: 'click',
  };
  return result;
}
