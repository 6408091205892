import {
  Daybook,
  DaybookFilterType,
} from '@erp_core/erp-types/dist/modules/accounts';
import { HttpDaybookRepo } from '../datalayer/repository/http/accounts/daybook';
import { createUsePaginatedResources } from '../hooks/resource/use-paginated-resources';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import { DaybookInterface } from '../models/interfaces/accounts/daybook';
import { erpV2Api } from './admin';
import { ACCOUNTS_SERVER_URI } from './server-uri';

export const daybookService = new HttpDaybookRepo(
  ACCOUNTS_SERVER_URI,
  erpV2Api
);
export const useDaybook = createUseResource<
  Daybook,
  DaybookFilterType,
  DaybookInterface
>({ useService: () => daybookService });

export const useDaybooks = createUseResources<
  Daybook,
  DaybookFilterType,
  DaybookInterface
>({ useService: () => daybookService });

export const usePaginatedDaybook = createUsePaginatedResources<
  Daybook,
  DaybookFilterType,
  DaybookInterface
>({
  useService: () => daybookService,
});
