import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import {
  Parameter,
  SalaryParamGroup,
} from '@erp_core/erp-types/dist/types/modules/payroll/salary-param-group';
import {
  DateSelector,
  LoadingButton,
  ModalV2Props,
  renderCardComponent,
  renderModal,
} from '@erp_core/erp-ui-components';
import { Switch } from '@headlessui/react';
import {
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  CodeBracketIcon,
  DocumentArrowDownIcon,
  EyeSlashIcon,
  PencilIcon,
  PlayIcon,
} from '@heroicons/react/24/outline';
import { eval as expEval, parse } from 'expression-eval';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseCurrentCompanyGroup } from '../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseEmployeeCategories } from '../../../../hooks/admin/constants/employee-category/use-employee-categories';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseSalaryParam } from '../../../../hooks/hrd/salary-parameter/use-salary-parameter';
import { renderSalParamCode } from './components/code';
import { edit } from './components/edit';
import { renderParamDependencySummary } from './components/outer-dependency-summary';
import { renderSalarySlipTemplate } from './components/template';
import { UpDownDelete } from './components/up-down-delete';
import { outerDependencyCalculator } from './utils/outer-dependency';

export type CreateSalaryParametersDetailsPageProps = {
  useSalaryParam: UseSalaryParam;
  useEmployeeCategories: UseEmployeeCategories;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useFileTransfer: UseFileTransfer;
};

export type DependencySummary = {
  outerParams: Array<string>;
  innerParams: Array<string>;
  allParams: Array<string>;
  errors: Array<string>;
};

export type OuterParams = {
  [key: string]: {
    mapper: string;
    group: 'other' | 'earning' | 'deduction';
    metric: 'number' | 'money' | 'percentage' | 'string';
  };
};

export function createSalaryParametersDetailsPage({
  useSalaryParam,
  useEmployeeCategories,
  useCurrentCompanyGroup,
  useFileTransfer,
}: CreateSalaryParametersDetailsPageProps): () => JSX.Element {
  let Card = renderCardComponent();

  const Modal = renderModal();

  const Template = renderSalarySlipTemplate({
    useCurrentCompanyGroup,
    useFileTransfer,
  });

  const Code = renderSalParamCode();

  return function SalaryParametersDetailsPage(): JSX.Element {
    const { set: saveFile } = useFileTransfer();
    const { id } = useParams();
    const {
      data: selectedSalaryParam,
      get: getSalaryParam,
      syncSet: setSalaryParamSync,
    } = useSalaryParam();

    const [selectedTab, setSelectedTab] = useState<string>('inner');
    const [showSummary, setShowSummary] = useState<boolean>(false);

    const [lastSalParam, setLastSalParam] = useState<SalaryParamType | null>(
      null
    );

    useEffect(() => {
      if (id) {
        getSalaryParam(id);
      }
      // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
      if (selectedSalaryParam.details) {
        if (
          selectedSalaryParam.details.inner &&
          selectedSalaryParam.details.outer
        ) {
          setSalParams(selectedSalaryParam.details.inner);
          setOuterParams(selectedSalaryParam.details.outer);
        } else {
          setSalParams(selectedSalaryParam.details);
        }

        setLastSalParam(selectedSalaryParam);
      } else {
        setSalParams(
          JSON.parse(
            localStorage.getItem('salary-params') ||
              JSON.stringify({ name: 'Salary Params' })
          )
        );
      }
    }, [selectedSalaryParam]);

    const [salParams, setSalParams] = useState<SalaryParamGroup>(
      {} as SalaryParamGroup
      // JSON.parse(localStorage.getItem('salary-params') || JSON.stringify({ name: 'Salary Params' }))
    );

    const [outerParams, setOuterParams] = useState<OuterParams>({});

    const [dependencySummary, setDependencySummary] = useState<
      DependencySummary
    >({
      allParams: [],
      innerParams: [],
      outerParams: [],
      errors: [],
    });

    useEffect(() => {
      const res: Array<string> = [];
      const innerParams: Array<string> = [];
      const allParams: Array<string> = [];
      const errors: Array<string> = [];
      outerDependencyCalculator(salParams, res, innerParams, allParams, errors);

      setDependencySummary({
        allParams,
        innerParams,
        outerParams: res,
        errors,
      });
    }, [salParams]);

    const [modal, setModal] = useState<ModalV2Props>({
      isVisible: false,
      title: '',
      onClose() {
        setModal({ ...modal, isVisible: false, body: <div />, title: '' });
      },
      body: <div />,
    });

    if (!id) {
      return <div>Loading...</div>;
    }

    function renderOuterDependencies(s: DependencySummary): JSX.Element {
      return (
        <div className='border border-gray-200 p-2 rounded-lg'>
          {s.outerParams.map((o, idx) => (
            <div key={idx} className='even:bg-white odd:bg-gray-100 p-1 my-0.5 flex'>
              <div className='basis-1/4 font-bold'>{o}:</div>
              <div className='basis-1/4 p-1'>
                <input
                  value={outerParams[o]?.mapper || ''}
                  className='w-full'
                  type={'text'}
                  placeholder={`Enter mapper for ${o}`}
                  onChange={(e) =>
                    setOuterParams({
                      ...outerParams,
                      ...{
                        [o]: {
                          mapper: e.target.value,
                          metric: outerParams[o]?.metric || 'number',
                          group: outerParams[o]?.group || 'other',
                        },
                      },
                    })
                  }
                />
              </div>
              <div className='basis-1/4 p-1'>
                <select
                  value={outerParams[o]?.group || ''}
                  className='w-full'
                  onChange={(e) =>
                    setOuterParams({
                      ...outerParams,
                      ...{
                        [o]: {
                          mapper: outerParams[o]?.mapper,
                          metric: outerParams[o]?.metric || 'number',
                          group: (e.target.value as any) || 'other',
                        },
                      },
                    })
                  }
                >
                  <option value={''}>Select</option>
                  <option value={'other'}>Hidden</option>
                  <option value={'deduction'}>Deduction</option>
                  <option value={'earning'}>Earning</option>
                </select>
              </div>
              <div className='basis-1/4 p-1'>
                <select
                  value={outerParams[o]?.metric || ''}
                  className='w-full'
                  onChange={(e) =>
                    setOuterParams({
                      ...outerParams,
                      ...{
                        [o]: {
                          mapper: outerParams[o]?.mapper,
                          metric: (e.target.value as any) || 'number',
                          group: outerParams[o]?.group || 'other',
                        },
                      },
                    })
                  }
                >
                  <option value={''}>Select</option>
                  <option value={'number'}>Number</option>
                  <option value={'money'}>Money</option>
                  <option value={'percentage'}>Percentage</option>
                  <option value={'string'}>String</option>
                </select>
              </div>
            </div>
          ))}
        </div>
      );
    }

    function renderGroup(
      s: SalaryParamGroup,
      groupIndex: number,
      parent: Array<SalaryParamGroup> | null
    ) {
      return (
        <Card 
          header={{
            title: <div className='bold text-xl p-t rounded-lg group'>
              <span>{s.name}</span>
              <PencilIcon
                className='hidden group-hover:inline cursor-pointer text-gray-800 px-2  w-6 h-6'
                onClick={() =>
                  edit({
                    modal,
                    setModal,
                    salParams,
                    setSalParams,
                    useEmployeeCategories,
                    obj: s,
                    propName: 'name',
                    formType: 'text',
                  })
                }
              />
            </div>,
            subheading:  <div className='italic text-gray-700 p-b text-sm group'>
              {s.description || 'Add Description'}
              <PencilIcon
                className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                onClick={() =>
                  edit({
                    showTextArea: true,
                    modal,
                    setModal,
                    salParams,
                    setSalParams,
                    useEmployeeCategories,
                    obj: s,
                    propName: 'description',
                    formType: 'text',
                  })
                }
              />
            </div>,
            actions: [
              {
                type: 'jsx',
                jsx:  <LoadingButton
                  defaultStyle='bg-green-500 text-white mx-1 p-1'
                  behaviorFn={async () => {
                    s.subgroups
                      ? s.subgroups.push({
                          name: '',
                          subgroups: [],
                          parameters: [],
                        })
                      : (s.subgroups = [
                          { name: '', subgroups: [], parameters: [] },
                        ]);
                    setSalParams({ ...salParams });
                  }}
                  text='+ Sub-Group'
                />
              },
              {
                type: 'jsx',
                jsx: <LoadingButton
                  defaultStyle='bg-green-500 text-white mx-1 p-1'
                  behaviorFn={async () => {
                    s.parameters
                      ? s.parameters.push({ name: '' } as Parameter)
                      : (s.parameters = [{ name: '' } as Parameter]);
                    setSalParams({ ...salParams });
                  }}
                  text='+ Param'
                />
              },
              {
                show: () => Boolean(parent),
                type: 'jsx',
                jsx: <UpDownDelete
                  idx={groupIndex}
                  list={parent ? parent : []}
                  setSalParams={setSalParams}
                  salParams={salParams}
                  modal={modal}
                  setModal={setModal}
                  textSize={5}
                  resourceName={'Group'}
                />
              }

            ]            
          }}
          body={{
            type: 'jsx-component',
            body: <div className='p-4'>
            <div>
              {s.parameters?.length ? (
                <div className='p-2 border border-gray-100'>
                  <div className='text-lg'>Parameters</div>
                  {s.parameters.map((sp, idx) => (
                    <div
                      key={idx}
                      className={`${
                        idx % 2 ? 'bg-gray-100' : 'bg-white'
                      } text-sm border my-0.5 border-gray-100 rounded p-1`}
                    >
                      <div className='flex'>
                        {/* Param Type Icon */}
                        <div className='flex-none h-auto border border-gray-200 items-center'>
                          <div className='h-full items-center'>
                            <span
                              className='p-1 text-2xl cursor-pointer justify-center'
                              onClick={() =>
                                edit({
                                  modal,
                                  setModal,
                                  salParams,
                                  setSalParams,
                                  useEmployeeCategories,
                                  obj: sp,
                                  propName: 'group',
                                  formType: 'select',
                                  options: ['other', 'earning', 'deduction'],
                                })
                              }
                            >
                              {sp.group === 'earning' ? (
                                <ArrowTrendingUpIcon className='inline w-8 text-green-600' />
                              ) : null}
                              {sp.group === 'deduction' ? (
                                <ArrowTrendingDownIcon className='inline w-8 text-red-600' />
                              ) : null}
                              {!['earning', 'deduction'].includes(sp.group) ? (
                                <EyeSlashIcon className='inline w-8 text-blue-600' />
                              ) : null}
                            </span>
                          </div>
                        </div>
                        <div className='w-1/3'>
                          {/* Name */}
                          <div className='group mx-3'>
                            {sp.name ? (
                              <span className='bold'>{sp.name}</span>
                            ) : (
                              <span className='italic text-gray-700'>
                                Add Name
                              </span>
                            )}
                            <PencilIcon
                              className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                              onClick={() =>
                                edit({
                                  modal,
                                  setModal,
                                  salParams,
                                  setSalParams,
                                  useEmployeeCategories,
                                  obj: sp,
                                  propName: 'name',
                                  formType: 'text',
                                })
                              }
                            />
                            <span className='bg-indigo-200 border border-silver-500 rounded-lg text-xs px-1 inline ml-24 text-indigo-900 italic'>
                              {_.camelCase(sp.name)}
                            </span>
                          </div>
                          {/* Description */}
                          <div className='text-xs mx-3 group inline'>
                            {sp.description ? (
                              <span className=''>{sp.description}</span>
                            ) : (
                              <span className='italic text-gray-700'>
                                Add Description
                              </span>
                            )}
                            <PencilIcon
                              className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                              onClick={() =>
                                edit({
                                  modal,
                                  setModal,
                                  salParams,
                                  setSalParams,
                                  useEmployeeCategories,
                                  obj: sp,
                                  propName: 'description',
                                  formType: 'text',
                                  showTextArea: true,
                                })
                              }
                            />
                          </div>
                          {/* Value */}
                          {
                            sp.valueType !== 'derived' ? 
                              <div className='text-xs mx-3 group'>
                              <span className='bold'>Value:</span>{' '}
                              {sp.value ? (
                                <span className=''>{sp.value}</span>
                              ) : (
                                <span className='italic text-gray-700'>
                                  Add Value
                                </span>
                              )}
                              <PencilIcon
                                className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                onClick={() =>
                                  edit({
                                    modal,
                                    setModal,
                                    salParams,
                                    setSalParams,
                                    useEmployeeCategories,
                                    obj: sp,
                                    propName: 'value',
                                    formType: 'text',
                                  })
                                }
                              />
                            </div> : null
                          }
                        </div>

                        <div className='w-1/3'>
                          {/* Valuetype */}
                          <div className='group'>
                            <span className='bold'>Value Type:</span>{' '}
                            {sp.valueType ? (
                              <span className=''>{sp.valueType}</span>
                            ) : (
                              <span className='italic text-gray-700'>
                                Select Value Type
                              </span>
                            )}
                            <PencilIcon
                              className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                              onClick={() =>
                                edit({
                                  modal,
                                  setModal,
                                  salParams,
                                  setSalParams,
                                  useEmployeeCategories,
                                  obj: sp,
                                  propName: 'valueType',
                                  formType: 'select',
                                  options: ['fixed', 'derived'],
                                })
                              }
                            />
                          </div>
                          {/* Metric */}
                          <div className='group'>
                            <span className='bold'>Metric:</span>{' '}
                            {sp.metric ? (
                              <span className=''>{sp.metric}</span>
                            ) : (
                              <span className='italic text-gray-700'>
                                Select Metric
                              </span>
                            )}
                            <PencilIcon
                              className='hidden text-gray-700 px-2 group-hover:inline w-6 h-6'
                              onClick={() =>
                                edit({
                                  modal,
                                  setModal,
                                  salParams,
                                  setSalParams,
                                  useEmployeeCategories,
                                  obj: sp,
                                  propName: 'metric',
                                  formType: 'select',
                                  options: [
                                    'string',
                                    'number',
                                    'money',
                                    'percentage',
                                  ],
                                })
                              }
                            />
                          </div>

                          {/* Scale */}
                          {['number', 'money', 'percentage'].includes(
                              sp.metric
                            ) ? (
                              <div className='group'>
                                <span className='bold'>Scale:</span>{' '}
                                {(sp.scale as any) > -1 ? (
                                  <span className=''>{sp.scale}</span>
                                ) : (
                                  <span className='italic text-gray-700'>
                                    Select Scale
                                  </span>
                                )}
                                <PencilIcon
                                  className='hidden text-gray-700 px-2 group-hover:inline w-6 h-6'
                                  onClick={() =>
                                    edit({
                                      modal,
                                      setModal,
                                      salParams,
                                      setSalParams,
                                      useEmployeeCategories,
                                      obj: sp,
                                      propName: 'scale',
                                      formType: 'text',
                                    })
                                  }
                                />
                              </div>
                            ) : null}
                        </div>

                        <div className='flex-grow'>
                          <div className='group'>
                            <span className='bold'>
                              Export to Salary Revision
                            </span>{' '}
                            <input
                              type='checkbox'
                              checked={sp.exportToSalaryRevision}
                              onChange={(x) => {
                                sp.exportToSalaryRevision = x.target.checked;
                                setSalParams({ ...salParams });
                              }}
                            />
                          </div>
                        </div>

                        <div className='flex-none'>
                          <UpDownDelete
                              idx={idx}
                              list={s.parameters}
                              setSalParams={setSalParams}
                              salParams={salParams}
                              modal={modal}
                              setModal={setModal}
                              textSize={5}
                              resourceName={'Param'}
                            />
                        </div>
                      </div>
                      {sp.valueType === 'derived' ? (
                        <div className='p-2'>
                          <div className='text-right'>
                            <LoadingButton
                              defaultStyle='bg-green-500 text-white mx-1 p-1'
                              behaviorFn={async () => {
                                sp.conditions
                                  ? sp.conditions.push({
                                      conditionExpression: '',
                                      valueExpression: '',
                                      applicableTo: [],
                                    })
                                  : (sp.conditions = [
                                      {
                                        conditionExpression: '',
                                        valueExpression: '',
                                        applicableTo: [],
                                      },
                                    ]);
                                setSalParams({ ...salParams });
                              }}
                              text='+ Condition'
                            />
                          </div>
                          <table className='w-full border-collapse border border-gray-200'>
                            <thead>
                              <tr>
                                <th className='w-2 border-collapse border border-gray-200'>#</th>
                                <th className='w-3/12 border-collapse border border-gray-200'>Condition</th>
                                <th className='w-3/12 border-collapse border border-gray-200'>Value Formula</th>
                                <th className='w-4/12 border-collapse border border-gray-200'>Examples</th>
                                <th className='w-2/12 border-collapse border border-gray-200'>Applicable To</th>
                              </tr>
                            </thead>
                            <tbody>
                              {sp.conditions?.length ? (
                                <>
                                  {sp.conditions.map((c, idx) => {
                                    let results: Array<JSX.Element> = [];

                                    sp.examples?.forEach((ex) => {
                                      const conditionSelect = sp.conditions.find(
                                        (c) => {
                                          try {
                                            const expression = parse(
                                              c.conditionExpression
                                            );
                                            if (
                                              expEval(
                                                expression,
                                                JSON.parse(ex.input)
                                              ) === true
                                            ) {
                                              return true;
                                            }
                                            return false;
                                          } catch (e) {
                                            return false;
                                          }
                                        }
                                      );

                                      if (!conditionSelect) {
                                        results.push(
                                          <div className='text-red-600'>
                                            No Condition is relevant to input.
                                          </div>
                                        );
                                      } else {
                                        const valExp = parse(
                                          conditionSelect.valueExpression
                                        );
                                        try {
                                          const evalRes = expEval(
                                            valExp,
                                            JSON.parse(ex.input)
                                          );
                                          results.push(
                                            <div
                                              className={`${
                                                parseFloat(
                                                  evalRes.toString()
                                                ) ===
                                                parseFloat(ex.expectedOutput)
                                                  ? 'text-green-600'
                                                  : 'text-red-600'
                                              }`}
                                            >
                                              {`${parseFloat(
                                                evalRes
                                              )} was calculated using condition ${
                                                sp.conditions.findIndex(
                                                  (d) =>
                                                    d.conditionExpression ===
                                                    conditionSelect.conditionExpression
                                                ) + 1
                                              }`}
                                            </div>
                                          );
                                        } catch (e) {
                                          results.push(
                                            <div className='text-red-600'>
                                              Failed to Parse result for{' '}
                                              {
                                                conditionSelect.conditionExpression
                                              }
                                            </div>
                                          );
                                        }
                                      }
                                    });

                                    return (
                                      <tr key={idx} className='odd:bg-white even:bg-slate-50'>
                                        <td className='group border-collapse border border-gray-200'>
                                          {idx + 1}.
                                          <div className='hidden group-hover:inline'>
                                            <div className='flex'>
                                              <UpDownDelete
                                                idx={idx}
                                                list={sp.conditions}
                                                setSalParams={setSalParams}
                                                salParams={salParams}
                                                modal={modal}
                                                setModal={setModal}
                                                textSize={3}
                                                resourceName={'Condition'}
                                              />
                                            </div>
                                          </div>
                                        </td>

                                        <td className='group border-collapse border border-gray-200'>
                                          <div className='p-0.5'>
                                            {c.conditionExpression ? (
                                              <span className=''>
                                                {c.conditionExpression}
                                              </span>
                                            ) : (
                                              <span className='italic text-gray-700'>
                                                Add Conditions
                                              </span>
                                            )}
                                            <PencilIcon
                                              className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                              onClick={() =>
                                                edit({
                                                  modal,
                                                  setModal,
                                                  salParams,
                                                  setSalParams,
                                                  useEmployeeCategories,
                                                  obj: c,
                                                  propName:
                                                    'conditionExpression',
                                                  formType: 'text',
                                                  showTextArea: true,
                                                })
                                              }
                                            />
                                          </div>
                                        </td>
                                        <td className='group border-collapse border border-gray-200'>
                                          <div className='p-0.5'>
                                            {c.valueExpression ? (
                                              <span className=''>
                                                {c.valueExpression}
                                              </span>
                                            ) : (
                                              <span className='italic text-gray-700'>
                                                Add Value Formula
                                              </span>
                                            )}
                                            <PencilIcon
                                              className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                              onClick={() =>
                                                edit({
                                                  modal,
                                                  setModal,
                                                  salParams,
                                                  setSalParams,
                                                  useEmployeeCategories,
                                                  obj: c,
                                                  propName: 'valueExpression',
                                                  formType: 'text',
                                                  showTextArea: true,
                                                })
                                              }
                                            />
                                          </div>
                                        </td>
                                        {idx === 0 ? (
                                          <td rowSpan={sp.conditions.length} className='border-collapse border border-gray-200'>
                                            <div className='text-right'>
                                              <LoadingButton
                                                defaultStyle='bg-green-500 text-white mx-1 p-1'
                                                behaviorFn={async () => {
                                                  sp.examples
                                                    ? sp.examples.push({
                                                        input: '',
                                                        expectedOutput: '',
                                                      })
                                                    : (sp.examples = [
                                                        {
                                                          input: '',
                                                          expectedOutput: '',
                                                        },
                                                      ]);
                                                  setSalParams({
                                                    ...salParams,
                                                  });
                                                }}
                                                text='+ Example'
                                              />
                                            </div>
                                            {(sp.examples || []).map(
                                              (x, idx) => (
                                                <div
                                                  className='bg-slate-100 border border-gray-50 p-0.5 rounded mx-0.5'
                                                  key={idx}
                                                >
                                                  <div className='group'>
                                                    <span className='bold'>
                                                      Input:{' '}
                                                    </span>
                                                    {x.input}
                                                    <PencilIcon
                                                      className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                                      onClick={() =>
                                                        edit({
                                                          modal,
                                                          setModal,
                                                          salParams,
                                                          setSalParams,
                                                          useEmployeeCategories,
                                                          obj: x,
                                                          propName: 'input',
                                                          formType: 'text',
                                                          showTextArea: true,
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                  <div className='group'>
                                                    <span className='bold'>
                                                      Expected:{' '}
                                                    </span>
                                                    {x.expectedOutput}
                                                    <PencilIcon
                                                      className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-6 h-6'
                                                      onClick={() =>
                                                        edit({
                                                          modal,
                                                          setModal,
                                                          salParams,
                                                          setSalParams,
                                                          useEmployeeCategories,
                                                          obj: x,
                                                          propName:
                                                            'expectedOutput',
                                                          formType: 'text',
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                  <div>{results[idx]}</div>
                                                </div>
                                              )
                                            )}
                                          </td>
                                        ) : null}
                                        <td className='group border-collapse border border-gray-200'>
                                          {(c.applicableTo || []).map(
                                            (x, idx) => (
                                              <span
                                                className='bg-slate-100 p-0.5 rounded mx-0.5'
                                                key={idx}
                                              >
                                                {x.name}
                                              </span>
                                            )
                                          )}
                                          <PencilIcon
                                            className='hidden text-gray-700 px-2 group-hover:inline cursor-pointer w-8 h-8'
                                            onClick={() =>
                                              edit({
                                                modal,
                                                setModal,
                                                salParams,
                                                setSalParams,
                                                useEmployeeCategories,
                                                obj: c,
                                                propName: 'applicableTo',
                                                formType: 'searchable-select',
                                              })
                                            }
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              ) : null}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className='flex-none mx-3'>
                          
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div>
              {s.subgroups?.length ? (
                <div className='p-2 border border-gray-100'>
                  <div className='text-lg'>Sub Groups</div>
                  {s.subgroups.map((sg, idx) => (
                    <div key={idx}>{renderGroup(sg, idx, s.subgroups)}</div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
          }}
        />
      );
    }

    return (
      <div className='pb-52'>
        <Card
          header={{
            title: 'Salary Parameters',
            sticky: true,
            actions: [
              {
                type: 'button',
                button: {
                  style:
                    'bg-green-600 text-white p-1 w-16',
                  suffix: <PlayIcon className='inline w-4' />,
                  name: 'Save',
                  behaviour: 'modal',
                  modal: {
                    title: 'Save Salary Params',
                    content: ({ onClose }) => {
                      if (lastSalParam) {
                        const newOuterParams: any = {};
                        for (const key in outerParams) {
                          if (outerParams[key].mapper) {
                            newOuterParams[key] = outerParams[key];
                          }
                        }
                        const newSalParam = {
                          revisionNum:
                            parseInt(`${selectedSalaryParam.revisionNum}`) + 1,
                          id: selectedSalaryParam.id,
                          details: {
                            inner: salParams,
                            outer: newOuterParams,
                          },
                        } as SalaryParamType;
                        return (
                          <SaveNewParamRevision
                            setSalaryParamSync={setSalaryParamSync}
                            salaryParam={lastSalParam}
                            onClose={onClose}
                            saveFile={saveFile}
                            newSalaryParam={newSalParam}
                          />
                        );
                      }

                      return <></>;
                    },
                  },
                },
              },
              {
                type: 'button',
                button: {
                  style: 'bg-green-600 text-white p-1 w-24 inline',
                  suffix: <DocumentArrowDownIcon className='inline w-4' />,
                  name: 'Template',
                  behaviour: 'modal',
                  modal: {
                    title: `Edit Template ${selectedSalaryParam.name} ${selectedSalaryParam.revisionNum}`,
                    size: 'large',
                    content: ({ onClose }) => (
                      <Template
                        id={id}
                        onClose={() => {
                          onClose();
                        }}
                      />
                    ),
                  },
                },
              },
              {
                type: 'button',
                button: {
                  style: 'bg-green-600 text-white p-1 w-24 inline',
                  suffix: <CodeBracketIcon className='inline w-4' />,
                  name: 'Code',
                  behaviour: 'modal',
                  modal: {
                    title: `Edit Code ${selectedSalaryParam.name} ${selectedSalaryParam.revisionNum}`,
                    content: ({ onClose }) => (
                      <Code
                        incomingData={salParams}
                        saveParams={setSalParams}
                        onClose={onClose}
                      />
                    ),
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <div className='p-4 bg-gray-100'>
                  <Switch
                    checked={showSummary}
                    onChange={() => setShowSummary(!showSummary)}
                    className={`${showSummary ? 'bg-indigo-900' : 'bg-gray-300'}
                  relative inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-gray-100 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className='sr-only'>Use setting</span>

                    <span
                      aria-hidden='true'
                      className={`${
                        showSummary ? 'translate-x-3' : 'translate-x-0'
                      }
                      pointer-events-none inline-block h-[15px] w-[25px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                    </Switch>
                    <span className='italic'>Show Dependencies</span>
                  { showSummary
                    ? renderParamDependencySummary(dependencySummary)
                    : null
                    }
                </div>
                <div className='m-2 border border-gray-200 rounded-lg p-2'>
                  <div className='flex text-xl mt-2 cursor-pointer'>
                    <div
                      className={`basis-1/2 font-lg text-center ${
                        selectedTab === 'outer'
                          ? 'bg-slate-50 text-black border-2 border-b-indigo-900'
                          : 'bg-white text-gray-800'
                      }`}
                      onClick={() => setSelectedTab('outer')}
                    >
                      Outer Params
                    </div>
                    <div
                      className={`basis-1/2 font-lg text-center ${
                        selectedTab === 'inner'
                          ? 'bg-slate-50 text-black border-2 border-b-indigo-900'
                          : 'bg-white text-gray-800'
                      }`}
                      onClick={() => setSelectedTab('inner')}
                    >
                      Inner Params
                    </div>
                  </div>
                  {selectedTab === 'inner'
                    ? renderGroup(salParams, 0, null)
                    : renderOuterDependencies(dependencySummary)}
                </div>
              </div>
            ),
          }}
        />
        <Modal {...modal} />
      </div>
    );
  };
}

function SaveNewParamRevision({
  salaryParam,
  onClose,
  saveFile,
  setSalaryParamSync,
  newSalaryParam,
}: {
  newSalaryParam: SalaryParamType;
  salaryParam: SalaryParamType;
  onClose: () => void;
  saveFile: (path: string, data: File, ext: string) => Promise<any>;
  setSalaryParamSync: (s: SalaryParamType) => Promise<SalaryParamType>;
}) {
  const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'));
  return (
    <div>
      <div className='text-center font-bold'>
        Are you sure you want to save Salary Param ?
      </div>
      <div className='text-center font-semibold'>
        A new revision v{parseInt(`${salaryParam.revisionNum}`) + 1} will be
        created if you proceed.
      </div>
      <div className='my-2 text-center'>
        New Effective Date:{' '}
        <DateSelector
          initialState={date}
          format='YYYY-MM-DD'
          onChange={(date) => setDate(date)}
        />
      </div>

      <div className='flex'>
        <LoadingButton
          text='Save'
          behaviourParams={{}}
          behaviorFn={async () => {
            console.log(date);
            console.log(salaryParam);
            const path = `${salaryParam.companyGroup.id.toUpperCase()}/${salaryParam.company.id.toUpperCase()}/salary-params/${salaryParam.id.toUpperCase()}/${
              salaryParam.revisionNum
            }/${salaryParam.id}`;
            const dataToSave = JSON.stringify(salaryParam);
            const file = new File([dataToSave], `${salaryParam.id}.json`, {
              type: 'application/json',
            });
            await saveFile(path, file, 'json');
            newSalaryParam.date = date;
            await setSalaryParamSync(newSalaryParam);
            onClose();
          }}
        />
      </div>
    </div>
  );
}
