import { Vendor } from '@erp_core/erp-types/dist/modules/order';
import { Registrations } from '@erp_core/erp-types/dist/types/modules/order/purchase/vendor';
import { UseCurrentCompany } from '../../../../../hooks/admin/company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseUserAuthorization } from '../../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { RenderVendorBankDetails } from './vendor-bank-details';
import { RenderVendorRegistration } from './vendor-registration';

type CreateItemCardType = {
  useFileTransfer: UseFileTransfer;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useCurrentCompany: UseCurrentCompany;
  useUserAuthorization: UseUserAuthorization;
  vendor: Vendor;
  setVendor: (s: Vendor) => Promise<Vendor>;
};

export function renderVendorRegistrations({
  vendor,
  setVendor,
  useFileTransfer,
  useCurrentCompany,
  useCurrentCompanyGroup,
  useUserAuthorization,
}: CreateItemCardType): () => JSX.Element {
  return function VendorRegistrations(): JSX.Element {
    const { data: company } = useCurrentCompany();
    const { currentCompanyGroup: companyGroup } = useCurrentCompanyGroup();

    const editRegistration = (property: keyof Registrations, value: any) => {
      const saveData = { id: vendor.id, details: vendor.details };
      saveData.details = {
        ...saveData.details,
        registrations: {
          ...(vendor.details?.registrations || {}),
          [property]: value,
        } as Registrations,
      };
      setVendor(saveData as Vendor);
    };

    const onEditVendorBankDetails = async (form) => {
      editRegistration('bankDetails', { ...form });
    };

    return (
      <div className='w-full space-y-3'>
        <RenderVendorRegistration
          data={vendor}
          setVendor={setVendor}
          useFileTransfer={useFileTransfer}
          company={company}
          companyGroup={companyGroup}
        />

        <br />

        <RenderVendorBankDetails
          data={vendor}
          useFileTransfer={useFileTransfer}
          useUserAuthorization={useUserAuthorization}
          onEditVendorBankDetails={onEditVendorBankDetails}
          company={company}
          companyGroup={companyGroup}
        />
      </div>
    );
  };
}
