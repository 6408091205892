import { Vendor } from '@erp_core/erp-types/dist/modules/order';
import { useEffect, useState } from 'react';
import { UseCurrentCompany } from '../../../../../hooks/admin/company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseUsers } from '../../../../../hooks/admin/user-admin/use-users-admin';
import { UseUserAuthorization } from '../../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseDeliveryTerms } from '../../../../../hooks/constants/delivery-term/use-delivery-terms';
import { UsePaymentTerms } from '../../../../../hooks/constants/payment-term/use-payment-terms';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../../../../hooks/file-transfer/use-public-transfer';
import { UseItem } from '../../../../../hooks/inventory/item/use-item';
import { UseItems } from '../../../../../hooks/inventory/item/use-items';
import { UseVendor } from '../../../../../hooks/inventory/purchase/vendors/use-vendor';
import { UseVendorItem } from '../../../../../hooks/inventory/purchase/vendors/use-vendor-item';
import { UseStockGroup } from '../../../../../hooks/inventory/stockgroup/use-stockgroup';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item';
import { renderBrochure } from '../brochure';
import { renderContact } from '../contact';
import { renderVendorProfile } from '../profile';
import { renderVendorRegistrations } from '../registrations';
import { renderVendorTerms } from '../terms';
import { renderVendorItemsList } from '../vendor-items/vendor-items';

export type VendorProfileProp = {
  useVendor: UseVendor;
  id: string;
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useItems: UseItems;
  useVendorItem: UseVendorItem;
  useUserAuthorization: UseUserAuthorization;
  useCurrentCompany: UseCurrentCompany;
  usePaymentTerms: UsePaymentTerms;
  useItem: UseItem;
  useStockGroup: UseStockGroup;
  useDeliveryTerms: UseDeliveryTerms;
  useUsers: UseUsers;
  vendor: Vendor;
  loading: boolean;
  setVendor: (s: Vendor) => Promise<Vendor>;
  itemService: ItemInterface;
};

export function renderVendorProfileTabs(): (
  x: VendorProfileProp
) => JSX.Element {
  return function VendorProfileTabs({
    vendor,
    loading,
    useVendor,
    id,
    useFileTransfer,
    usePublicTransfer,
    useCurrentCompanyGroup,
    useItems,
    useVendorItem,
    useUserAuthorization,
    useCurrentCompany,
    usePaymentTerms,
    useItem,
    useStockGroup,
    useDeliveryTerms,
    useUsers,
    setVendor,
    itemService,
  }: VendorProfileProp): JSX.Element {
    const [currentTab, setCurrentTab] = useState<string>('');
    const [tabs, setTabs] = useState<Array<string>>([]);
    const [body, setBody] = useState<JSX.Element>(<div></div>);

    useEffect(() => {
      let tabs: Array<string> = [];

      tabs = ['Basic Info', 'Items', 'Terms', 'Registrations', 'Brochure'];
      setTabs(tabs);

      if (tabs.length) {
        setCurrentTab(tabs[0]);
      }
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (currentTab) {
        let Props = <div></div>;

        switch (currentTab) {
          case 'Basic Info': {
            const Profile = renderVendorProfile({
              usePublicTransfer,
              useCurrentCompanyGroup,
              setVendor,
              vendor,
              loading,
            });
            const Contact = renderContact({ vendor, setVendor });
            Props = (
              <div className='w-full space-y-3 flex flex-col'>
                <div className='w-full space-y-3'>
                  <Profile />
                  <Contact />
                </div>
              </div>
            );
            break;
          }
          case 'Items': {
            const VendorItemsList = renderVendorItemsList({
              useItems,
              useVendorItem,
              useUserAuthorization,
              usePublicTransfer,
              useCurrentCompany,
              usePaymentTerms,
              useFileTransfer,
              useCurrentCompanyGroup,
              useItem,
              useStockGroup,
              useDeliveryTerms,
              vendor,
              setVendor,
              loading,
              itemService,
            });
            Props = <VendorItemsList />;
            break;
          }
          case 'Terms': {
            const Terms = renderVendorTerms({
              vendor,
              loading,
              setVendor,
              useDeliveryTerms,
              usePaymentTerms,
            });

            Props = <Terms />;
            break;
          }
          case 'Registrations': {
            const Registrations = renderVendorRegistrations({
              vendor,
              setVendor,
              useCurrentCompany,
              useCurrentCompanyGroup,
              useUserAuthorization,
              useFileTransfer,
            });

            Props = <Registrations />;
            break;
          }
          case 'Brochure': {
            const Brochure = renderBrochure({
              vendor,
              setVendor,
              usePublicTransfer,
              useCurrentCompanyGroup,
              useVendor,
            });

            Props = <Brochure />;
            break;
          }
          default: {
            const Profile = renderVendorProfile({
              loading,
              usePublicTransfer,
              useCurrentCompanyGroup,
              vendor,
              setVendor,
            });
            Props = <Profile />;
          }
        }

        setBody(Props);
      }
      // eslint-disable-next-line
    }, [currentTab, id]);

    const selectedTabStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 bg-white shadow text-blue-700 border-b-2 border-b-blue-600';
    const nonSelectedStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 hover:bg-white/[0.12] hover:border-b-2 hover:border-b-gray-400 hover:text-blue-700';

    return (
      <div>
        <div className='flex w-full'>
          {tabs.map((x, idx) => (
            <div
              key={x || idx}
              onClick={() => {
                if (x !== currentTab) {
                  setCurrentTab(x);
                }
              }}
              className={currentTab === x ? selectedTabStyle : nonSelectedStyle}
            >
              {x}
            </div>
          ))}
        </div>
        <div className='w-full'>
          <div className='w-full flex flex-wrap'>{body}</div>
        </div>
      </div>
    );
  };
}
