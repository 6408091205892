import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
// import {
//   EmployeeProfileType,
//   PremiumsType,
// } from '@erp_core/erp-types/dist/modules/hrd';
// import { MisconductType } from '@erp_core/erp-types/dist/types/modules/disciplinary/mis-conduct';
// import { LoanFilter } from '@erp_core/erp-types/dist/types/modules/hrd/loan';
// import { SalaryAdvanceType } from '@erp_core/erp-types/dist/types/modules/hrd/salary-advance';
// import {
//   RepaymentScheduleFilter,
//   RepaymentScheduleType,
// } from '@erp_core/erp-types/dist/types/modules/loan/repayment-schedule';
// import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import { SalaryParamGroup } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param-group';
// import { SalaryRevisionType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import _ from 'lodash';
import moment from 'moment';
// import { LoanV2Type } from '../../loan-management/types/loan-v2-type';
// import { calculateSalary } from '../../salary/components/bulk-simulate';

export type CalESIC = {
  salRoute: string;
  value: string;
  reason: string;
  data: {
    value: 'Yes' | 'No' | 'Unknown';
    until?: string;
  };
};

// async function calculate({
//   employee,
//   selectedParams,
//   formData,
//   parentSalaryRevision,
//   cgSetting,
//   monthDetails,
//   misconducts,
//   salaryAdvances,
//   premiums,
//   getLoans,
//   getRepaymentSchedules,
// }: {
//   employee: EmployeeProfileType;
//   selectedParams: SalaryParamType[];
//   formData: any;
//   cgSetting: CompanyGroupSetting | null | undefined;
//   parentSalaryRevision: SalaryRevisionType | undefined;
//   monthDetails: {
//     month: number;
//     year: number;
//     monthName: string;
//     totalDays: number;
//     totalOfficialDays: number;
//   };
//   misconducts: MisconductType[];
//   salaryAdvances: SalaryAdvanceType[];
//   premiums: PremiumsType[];
//   getLoans: (filter?: LoanFilter | undefined) => Promise<LoanV2Type[]>;
//   getRepaymentSchedules: (
//     filter?: RepaymentScheduleFilter
//   ) => Promise<RepaymentScheduleType[]>;
// }): Promise<{
//   esic: CalESIC;
//   salParams: any;
// }> {
//   const calculatedEsicApplicable: CalESIC = {
//     value: 'unknown',
//     reason: 'not yet calculated',
//     salRoute: 'unknown',
//     data: {
//       value: 'Unknown',
//     },
//   };
//   const selectedParam = selectedParams.find(
//     (x) => x.name === employee.details.salaryParam?.name
//   );
//   const ctc = parseFloat(formData.ctcAnnual as string);
//   const upperThreshold = cgSetting?.details?.hrd?.salaryRules
//     ?.upperESICThreshold
//     ? parseInt(cgSetting?.details?.hrd?.salaryRules?.upperESICThreshold)
//     : 0;
//   const lowerThreshold = cgSetting?.details?.hrd?.salaryRules
//     ?.lowerESICThreshold
//     ? parseInt(cgSetting?.details?.hrd?.salaryRules?.lowerESICThreshold)
//     : 0;

//   const dates = calculateESICDates(cgSetting);

//   const parentCTC = parseInt(parentSalaryRevision?.details.ctcAnnual);

//   if (['G01', 'G02'].includes(employee.details?.grade?.id)) {
//     // In this case the esic applicable will always be false
//     calculatedEsicApplicable.value = 'Always False';
//     calculatedEsicApplicable.reason = 'As employee is an apprentice';
//     calculatedEsicApplicable.data = { value: 'No' };
//   } else if (ctc < lowerThreshold) {
//     calculatedEsicApplicable.value = 'Always True';
//     calculatedEsicApplicable.reason =
//       'As employees previous and current CTC are below lower threshold';
//     calculatedEsicApplicable.data = { value: 'Yes' };
//   } else if (ctc > upperThreshold) {
//     calculatedEsicApplicable.value = 'Always False';
//     calculatedEsicApplicable.reason =
//       'As employees previous and current CTC are above upper threshold';
//     calculatedEsicApplicable.data = { value: 'No' };
//   } else {
//     const date = `${moment().format('YYYY-MM')}-01`;
//     if (
//       !parentSalaryRevision &&
//       ctc < upperThreshold &&
//       ctc > lowerThreshold &&
//       selectedParam &&
//       cgSetting
//     ) {
//       const currSalSimulation = await calculateSalary({
//         selectedParam,
//         selectedSalRev: { details: formData } as any,
//         employee,
//         companyGroupSetting: cgSetting,
//         attendanceData: [],
//         simulate: true,
//         date,
//         monthDetails,
//         misconducts,
//         salaryAdvances,
//         premiums,
//         getLoans,
//         getRepaymentSchedules,
//       });
//       calculatedEsicApplicable.salRoute = currSalSimulation.other.salRouteTaken;
//       const zEsicCtc = currSalSimulation?.other?.zEsicCtc;
//       if (!zEsicCtc) {
//         calculatedEsicApplicable.value = 'Cannot compute';
//         calculatedEsicApplicable.reason = 'Salary Simulation failed';
//         calculatedEsicApplicable.data = { value: 'Unknown' };
//       } else {
//         if (ctc >= zEsicCtc) {
//           calculatedEsicApplicable.value = 'False';
//           calculatedEsicApplicable.reason = `Since ctc > zEsicCtc (${zEsicCtc}) and we dont have prev salary rev`;
//           calculatedEsicApplicable.data = { value: 'No' };
//         } else {
//           calculatedEsicApplicable.value = 'True';
//           calculatedEsicApplicable.reason = `Since ctc < zEsicCtc (${zEsicCtc})`;
//           calculatedEsicApplicable.data = { value: 'Yes' };
//         }
//       }
//     } else {
//       if (
//         parentCTC > lowerThreshold &&
//         parentCTC < upperThreshold &&
//         selectedParam &&
//         cgSetting
//       ) {
//         const prevSalSimulation = await calculateSalary({
//           selectedParam,
//           selectedSalRev: parentSalaryRevision,
//           employee,
//           companyGroupSetting: cgSetting,
//           attendanceData: [],
//           simulate: true,
//           date,
//           monthDetails,
//           misconducts,
//           salaryAdvances,
//           premiums,
//           getLoans,
//           getRepaymentSchedules,
//         });
//         const prevZEsicCtc = prevSalSimulation?.other?.zEsicCtc;

//         const currSalSimulation = await calculateSalary({
//           selectedParam,
//           selectedSalRev: { details: formData } as any,
//           employee,
//           companyGroupSetting: cgSetting,
//           attendanceData: [],
//           simulate: true,
//           date,
//           monthDetails,
//           misconducts,
//           salaryAdvances,
//           premiums,
//           getLoans,
//           getRepaymentSchedules,
//         });
//         console.log(currSalSimulation);
//         calculatedEsicApplicable.salRoute =
//           currSalSimulation.other.salRouteTaken;

//         const zEsicCtc = currSalSimulation?.other?.zEsicCtc;
//         if (!zEsicCtc) {
//           calculatedEsicApplicable.value = 'Cannot compute';
//           calculatedEsicApplicable.reason = 'Salary Simulation failed';
//           calculatedEsicApplicable.data = { value: 'Unknown' };
//         } else {
//           if (ctc >= zEsicCtc) {
//             if (parentCTC < prevZEsicCtc) {
//               calculatedEsicApplicable.value = 'True initially then false';
//               calculatedEsicApplicable.reason = `Since ctc > zEsicCtc (${zEsicCtc}) but previous Ctc < zEsicCtc (${prevZEsicCtc})`;
//               calculatedEsicApplicable.data = {
//                 value: 'Yes',
//                 until: calculateUntil(dates, formData.date),
//               };
//             } else {
//               calculatedEsicApplicable.value = 'False';
//               calculatedEsicApplicable.reason = `Since ctc < zEsicCtc (${zEsicCtc})`;
//               calculatedEsicApplicable.data = {
//                 value: 'No',
//               };
//             }
//           } else {
//             calculatedEsicApplicable.value = 'True';
//             calculatedEsicApplicable.reason = `Since ctc < zEsicCtc (${zEsicCtc})`;
//             calculatedEsicApplicable.data = {
//               value: 'Yes',
//             };
//           }
//         }
//       } else {
//         if (selectedParam && cgSetting) {
//           const currSalSimulation = await calculateSalary({
//             selectedParam,
//             selectedSalRev: { details: formData } as any,
//             employee,
//             companyGroupSetting: cgSetting,
//             attendanceData: [],
//             simulate: true,
//             date,
//             monthDetails,
//             misconducts,
//             salaryAdvances,
//             premiums,
//             getLoans,
//             getRepaymentSchedules,
//           });
//           calculatedEsicApplicable.salRoute =
//             currSalSimulation.other.salRouteTaken;
//           const zEsicCtc = currSalSimulation?.other?.zEsicCtc;

//           if (ctc >= zEsicCtc) {
//             calculatedEsicApplicable.value = 'False';
//             calculatedEsicApplicable.reason = `Since ctc < zEsicCtc (${zEsicCtc})`;
//             calculatedEsicApplicable.data = {
//               value: 'No',
//             };
//           } else {
//             calculatedEsicApplicable.value = 'True';
//             calculatedEsicApplicable.reason = `Since ctc < zEsicCtc (${zEsicCtc})`;
//             calculatedEsicApplicable.data = {
//               value: 'Yes',
//             };
//           }
//         } else {
//           calculatedEsicApplicable.value = 'unknown';
//           calculatedEsicApplicable.reason = 'unhandled condition';
//           calculatedEsicApplicable.data = {
//             value: 'Unknown',
//           };
//         }
//       }
//     }
//   }

//   const salParamsData: any = {
//     earning: {},
//     deduction: {},
//     other: {},
//   };

//   if (cgSetting && selectedParam) {
//     const simulatedSalary = await calculateSalary({
//       selectedParam,
//       selectedSalRev: { details: formData } as any,
//       employee,
//       companyGroupSetting: cgSetting,
//       attendanceData: [],
//       simulate: true,
//       date: `${moment().format('YYYY-MM')}-01`,
//       monthDetails,
//       misconducts,
//       salaryAdvances,
//       premiums,
//       getLoans,
//       getRepaymentSchedules,
//     });

//     const propertiesToSave: { property: string; group: string }[] = [];

//     findExtractableProperties(selectedParam.details.inner, propertiesToSave);
//     console.log(propertiesToSave);

//     propertiesToSave.forEach((p) => {
//       if (simulatedSalary[p.group][p.property]) {
//         salParamsData[p.group][p.property] =
//           simulatedSalary[p.group][p.property];
//       }
//     });

//     // if (simulatedSalary?.other?.basicDaForSalRev) {
//     //   console.log(selectedParam.details.inner);
//     //   basicDaForSalRev = simulatedSalary.other.basicDaForSalRev;
//     // }
//   }

//   return {
//     salParams: salParamsData,
//     esic: calculatedEsicApplicable,
//   };
// }

export function findExtractableProperties(
  subgroup: SalaryParamGroup,
  propertiesToSave: { property: string; group: string }[]
) {
  if (subgroup.parameters) {
    subgroup.parameters.forEach((p) => {
      if (p.exportToSalaryRevision) {
        propertiesToSave.push({
          property: _.camelCase(p.name),
          group: p.group,
        });
      }
    });
  }

  if (subgroup.subgroups) {
    subgroup.subgroups.forEach((sg) => {
      findExtractableProperties(sg, propertiesToSave);
    });
  }
}

// function calculateUntil(dates, startDate): string {
//   console.log('cal until', dates, startDate);
//   if (dates.length === 0 || startDate === '') {
//     return '';
//   }
//   if (moment(startDate).isBefore(moment(dates[0]))) {
//     return dates[0];
//   }
//   const date = dates.find((x, idx) => {
//     if (idx === 0) {
//       // ALways skip the first
//       return false;
//     }

//     if (
//       moment(startDate).isBetween(
//         moment(dates[idx - 1]),
//         moment(x).add(1, 'day')
//       )
//     ) {
//       return true;
//     }

//     return false;
//   });

//   if (date) {
//     return date;
//   }

//   return '';
// }

export function calculateESICDates(
  cgSetting: CompanyGroupSetting | null | undefined
): Array<string> {
  let dates = cgSetting?.details?.hrd?.salaryRules?.cutOffESICDates
    ? cgSetting?.details?.hrd?.salaryRules?.cutOffESICDates
        .split(',')
        .map((x) => {
          return moment(x.trim(), 'MM-DD').format('YYYY-MM-DD');
        })
    : [];
  if (dates.length) {
    const nextYearDates = dates.map((x) =>
      moment(x, 'YYYY-MM-DD').add('1', 'year').format('YYYY-MM-DD')
    );
    dates = [...dates, ...nextYearDates];
  }

  return dates;
}
