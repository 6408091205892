import { XlsxDownloadMapper } from '@erp_core/erp-ui-components';

export const BonusRetentionMapper: XlsxDownloadMapper = [
  {
    columnName: 'Employee Id',
    dataBinding: {
      property: 'empid',
    },
  },
  {
    columnName: 'Name',
    dataBinding: { property: 'name' },
  },
  {
    columnName: 'Bonus Deductions',
    dataBinding: { property: 'bonusdeductions' },
  },
  {
    columnName: 'Ex-Gratia Paid (Monthly)',
    dataBinding: { property: 'exgratiamonthly' },
  },
  {
    columnName: 'Ex-Gratia Paid (Quarterly)',
    dataBinding: { property: 'exgratiaquarterly' },
  },
];
