/* eslint-disable no-unused-vars */
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import {
  CompanyGroupSetting,
  CompanyGroupSettingDetails,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCompanyGroupSetting } from '../../../../../hooks/admin/company-group-setting/use-company-group-setting';

type Props = {
  comGrpSetDetails: CompanyGroupSettingDetails;
  closeModal: () => void;
  useCompanyGroupSetting: UseCompanyGroupSetting;
};

export function renderEditPEDefaultValidityForm({
  closeModal,
  useCompanyGroupSetting,
}: Props) {
  return function ({
    comGrpSet,
  }: {
    comGrpSet?: CompanyGroupSetting;
  }): JSX.Element {
    const { syncSet: setCompanyGroupSetting } = useCompanyGroupSetting();

    if (!comGrpSet) return <Recat className='h-5 inline animate-pulse mx-4' />;

    const save = async (form: FormType) => {
      const finalData: CompanyGroupSetting = {
        id: comGrpSet?.id,
        details: {
          purchase: {
            ...comGrpSet?.details?.purchase,
            purchaseEnquiry: {
              ...comGrpSet?.details?.purchase?.purchaseEnquiry,
              defaultValidity: form.defaultValidity,
            },
          },
        } as CompanyGroupSettingDetails,
      } as CompanyGroupSetting;
      await setCompanyGroupSetting(finalData);
      closeModal();
    };

    type FormType = {
      defaultValidity: number;
    };

    const formProps: FormProps<FormType> = {
      fieldsData: [
        {
          property: 'defaultValidity',
          label: 'Default Purchase Enquiry Validity days',
          type: 'number',
          required: true,
        },
      ],
      initialFormState: {
        defaultValidity:
          comGrpSet.details.purchase?.purchaseEnquiry?.defaultValidity || '',
      },
      mapTToU: mapPFDToP,
      style: '',
      onSubmit: save,
    };

    const Form = renderFormV2<any>(formProps);

    return (
      <div>
        <Form />
        <div className='font-bold text-md mt-1'>Guidelines</div>
        <div>Default Purchase Enquiry Validity days.</div>
      </div>
    );

    function mapPFDToP(b: FormDataType<FormType>): FormType {
      return b as FormType;
    }
  };
}
