import { SalaryParamGroup } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param-group';
import { ModalV2Props } from '@erp_core/erp-ui-components';
import { UseEmployeeCategories } from '../../../../../hooks/admin/constants/employee-category/use-employee-categories';
import { EditValueForm } from './edit-value-form';

export function edit({
  obj,
  propName,
  formType,
  options,
  showTextArea,
  modal,
  setModal,
  setSalParams,
  salParams,
  useEmployeeCategories,
}: {
  obj: any;
  propName: string;
  formType: 'text' | 'select' | 'searchable-select';
  options?: Array<string>;
  useEmployeeCategories?: UseEmployeeCategories;
  showTextArea?: boolean;
  modal: ModalV2Props;
  setModal: (value: React.SetStateAction<ModalV2Props>) => void;
  setSalParams: (value: React.SetStateAction<SalaryParamGroup>) => void;
  salParams: SalaryParamGroup;
}) {
  setModal({
    ...modal,
    isVisible: true,
    body: (
      <EditValueForm
        formType={formType}
        options={options}
        showTextArea={showTextArea}
        onClose={(val) => {
          obj[propName] = val;
          setSalParams({ ...salParams });
          setModal({ ...modal, isVisible: false, body: <div />, title: '' });
        }}
        useEmployeeCategories={useEmployeeCategories}
        initialValue={obj[propName]}
        propName={propName}
      />
    ),

    title: `Edit ${propName.toUpperCase()}`,
  });
}
