/* eslint-disable no-unused-vars */
import { Stockgroup } from '@erp_core/erp-icons/icons/web/stock-group';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderInlineInput,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseCurrentCompanyGroup } from '../../../../hooks/admin/company-group-admin/use-current-company-group';
import { UseCompanyGroupSettings } from '../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { UseAppearances } from '../../../../hooks/admin/constants/appearance/use-appearances';
import { UseColors } from '../../../../hooks/admin/constants/color/use-colors';
import { UseGsts } from '../../../../hooks/admin/constants/gst/use-gsts';
import { UseMetrics } from '../../../../hooks/admin/constants/metrics/use-metrics';
import { UseOdours } from '../../../../hooks/admin/constants/odour/use-odours';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseRoles } from '../../../../hooks/admin/role-admin/use-roles-admin';
import { UseCurrentUser } from '../../../../hooks/admin/user-admin/use-current-user';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseRevision } from '../../../../hooks/inventory/revision/use-revision';
import { UseRevisions } from '../../../../hooks/inventory/revision/use-revisions';
import { UseStockGroup } from '../../../../hooks/inventory/stockgroup/use-stockgroup';
import { StockGroupInterface } from '../../../../models/interfaces/inventory/stock-group';
import { renderUploadStockgroupItems } from './components/upload-sg-item';
import { renderStockgroupTabs } from './new-tabs';

type RenderStockGroupProfile = {
  hooks: {
    useStockGroup: UseStockGroup;
    useFileTransfer: UseFileTransfer;
    useCurrentCompanyGroup: UseCurrentCompanyGroup;
    useCompanyGroupSettings: UseCompanyGroupSettings;
    useMetrics: UseMetrics;
    useGsts: UseGsts;
    useColors: UseColors;
    useAppearances: UseAppearances;
    useOdours: UseOdours;
    useCurrentUserRoles: UseCurrentUserRoles;
    useRevision: UseRevision;
    useCurrentUser: UseCurrentUser;
    useRevisions: UseRevisions;
    useRoles: UseRoles;
    useUsers: UseUsers;
    useEmployees: UseEmployeeProfiles;
  };
  services: {
    stockGroupService: StockGroupInterface;
  };
};

export function renderStockGroupProfile({
  hooks: {
    useStockGroup,
    useFileTransfer,
    useCurrentCompanyGroup,
    useCompanyGroupSettings,
    useMetrics,
    useGsts,
    useAppearances,
    useColors,
    useOdours,
    useCurrentUserRoles,
    useRevision,
    useCurrentUser,
    useRevisions,
    useRoles,
    useUsers,
    useEmployees,
  },
  services: { stockGroupService },
}: RenderStockGroupProfile): () => JSX.Element {
  const Card = renderCardComponent();
  const StockgroupTabs = renderStockgroupTabs({
    useCurrentCompanyGroup,
    useFileTransfer,
    useStockGroup,
    useMetrics,
    useGsts,
    useAppearances,
    useColors,
    useOdours,
    useEmployees,
    useCurrentUserRoles,
    useCurrentUser,
    useRevisions,
    useRoles,
    useUsers,
  });

  return function ItemProfile(): JSX.Element {
    const { id } = useParams();

    const { data: stockGroup, get, syncSet: setStockGroup } = useStockGroup();
    const { syncSet: setRevision } = useRevision();

    const InlineInput = renderInlineInput({
      useResource: useStockGroup,
      id: id || '',
    });

    useEffect(() => {
      if (id) {
        get(id);
      }
      // eslint-disable-next-line
    }, [id]);

    const [header, setHeader] = useState<CardHeader>({
      title: `Stock Group Profile: ${stockGroup.name || id}`,
    });

    useEffect(() => {
      if (stockGroup.name) {
        document.title += stockGroup.name;
        setHeader({
          title: (
            <>
              <span>{stockGroup.name || id}</span>{' '}
              <span className='italic font-thin'>
                version {stockGroup.version || '0.0.0'}
              </span>
            </>
          ),
          icon: <Stockgroup className='text-gray-800 w-24 self-center' />,
          subheading: (
            <>
              <div className='text-gray-700 italic'>
                <InlineInput
                  name='description'
                  value={
                    stockGroup.description ||
                    'Add a description for the stockgroup...'
                  }
                />
              </div>
              <div className='text-gray-700'>
                created by{' '}
                <a
                  className='font-bold'
                  href={`/users/profile/${stockGroup.createdBy?.id}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <UserIcon className='w-4 inline' />{' '}
                  {stockGroup.createdBy?.name || '??'}
                </a>
                &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
                <a
                  className='font-bold'
                  href={`/users/profile/${stockGroup.createdBy?.id}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <UserIcon className='w-4 inline' />{' '}
                  {stockGroup.lastModifiedBy?.name || '??'}
                </a>{' '}
                at {moment.utc(stockGroup.lastModifiedAt).fromNow()}
              </div>
            </>
          ),
          actions: [
            {
              type: 'button',
              button: {
                name: 'Download',
                behaviour: 'click',
                onClick: async () => {
                  stockGroupService.getSheetItemStockgroup(stockGroup.id);
                },
              },
            },
            {
              type: 'button',
              button: {
                name: 'Upload',
                behaviour: 'modal',
                modal: {
                  size: 'large',
                  title: 'Upload Stockgroup Items',
                  content: ({ onClose }) => {
                    const Upload = renderUploadStockgroupItems({
                      stockGroupService,
                      useRevision,
                      useCurrentUser,
                    });
                    return (
                      <div>
                        <Upload id={stockGroup.id} onClose={onClose} />
                      </div>
                    );
                  },
                },
              },
            },
          ],
        });
      }
      // eslint-disable-next-line
    }, [stockGroup]);

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <StockgroupTabs
            useCompanyGroupSettings={useCompanyGroupSettings}
            sgService={stockGroupService}
            stockGroup={stockGroup}
            setStockGroup={setStockGroup}
            setRevision={setRevision}
          />
        </div>
      ),
    };

    return <Card header={header} body={body} />;
  };
}
