import { LoadingButton, ModalV2Props } from '@erp_core/erp-ui-components';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export function deleteResource({
  obj,
  index,
  resource,
  setModal,
  modal,
}: {
  obj: any;
  index: number;
  resource: string;
  modal: ModalV2Props;
  setModal: (value: React.SetStateAction<ModalV2Props>) => void;
}) {
  setModal({
    ...modal,
    isVisible: true,
    body: (
      <div>
        <div className='text-center text-2xl md-3'>
          <ExclamationCircleIcon className='text-red-500 w-6 h-6 inline' /> Are
          you sure you want to delete {resource} {obj[index].name || ''} ?
        </div>
        <div className='text-center mx-auto'>
          <LoadingButton
            defaultStyle='bg-green-500 px-3 mx-2 text-white p-2 border rounded-lg'
            behaviorFn={async () => {
              obj.splice(index, 1);
              setModal({
                ...modal,
                isVisible: false,
                body: <div />,
                title: '',
              });
            }}
            text='Yes'
          />
          <LoadingButton
            defaultStyle='bg-gray-500 px-3 mx-2 text-white p-2 border rounded-lg'
            behaviorFn={async () => {
              setModal({
                ...modal,
                isVisible: false,
                body: <div />,
                title: '',
              });
            }}
            text='No'
          />
        </div>
      </div>
    ),

    title: `Delete ${resource} ${obj[index].name || ''}`,
  });
}
