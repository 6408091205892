import { Pod } from '@erp_core/erp-icons/icons/web/pod';
import { ModuleType } from '@erp_core/erp-types/dist/modules/admin';
import {
  CardBody,
  CardHeader,
  ModalV2Props,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseModuleAdmin } from '../../../hooks/admin/module-admin/use-module-admin';
import { UseModules } from '../../../hooks/admin/module-admin/use-modules-admin';
import { renderEditModuleForm } from './edit-module-form';

export type RenderModuleAdminProps = {
  useModules: UseModules;
  useModuleAdmin: UseModuleAdmin;
};

export function renderModuleAdmin({
  useModules,
  useModuleAdmin,
}: RenderModuleAdminProps): () => JSX.Element {
  return function ModuleAdmin(): JSX.Element {
    const { data, getAll: getModules } = useModules();

    useEffect(() => {
      getModules();
      // eslint-disable-next-line
    }, []);

    const closeEditModal = () => {
      setEditModuleModal({
        isVisible: false,
        title: 'Edit Module',
        onClose: closeEditModal,
        body: <div />,
      });
      getModules();
    };

    const EditModuleForm = renderEditModuleForm({
      useModuleAdmin,
      closeEditModal,
    });

    const { delete: deleteModuleAdmin } = useModuleAdmin();

    const [editModuleModal, setEditModuleModal] = useState<ModalV2Props>({
      isVisible: false,
      title: 'Edit Module',
      onClose: closeEditModal,
      body: <div />,
    });

    useEffect(() => {
      if (!editModuleModal.isVisible) {
        getModules();
      }
      // eslint-disable-next-line
    }, [editModuleModal]);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Module Admin Screen',
      icon: (
        <Pod className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Module',
            behaviour: 'modal',
            modal: {
              title: 'Add Module',
              content: ({ onClose }) => {
                const role: Partial<ModuleType> = {
                  id: '',
                  name: '',
                  description: '',
                  shortName: '',
                  isSystemModule: false,
                };

                return (
                  <EditModuleForm
                    r={role}
                    onClose={() => {
                      getModules();
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [{ name: 'Name' }, { name: 'Short Name' }, { name: 'Description' }],
    ];

    const tableBody: TableBody = data
      ? data.map((r) => ({
          rowData: { module: r },
          cells: [
            { value: r.name },
            { value: r.shortName },
            { value: r.description },
          ],
        }))
      : [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Edit',
                  show: (p) => !p.module.isSystemModule,
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit',
                    content: ({ data, onClose }) => {
                      return (
                        <EditModuleForm
                          r={data.module}
                          onClose={() => {
                            getModules();
                            onClose();
                          }}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Delete',
                  show: (p) => !p.module.isSystemModule,
                  behaviour: 'confirm',
                  onConfirm: ({ module }) => {
                    return {
                      title: `Are you sure you want to delete this Module '${module.name}'? This cannot be undone.`,
                      type: 'warning',
                      onConfirm: async () => {
                        deleteModuleAdmin(module.id);
                        getModules();
                      },
                    };
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
