import {
  RotationalShiftDayType,
  ShiftScheduleType,
} from '@erp_core/erp-types/dist/modules/planning';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { LoadingButton, renderFormV2 } from '@erp_core/erp-ui-components';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useState } from 'react';
import { UseEmployeeProfiles } from '../../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseRotationalShiftDays } from '../../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-days';

//
// Replica of existing to avoid breaking
//

type ReplacementWeeklyOffFunType = (
  s1: RotationalShiftDayType,
  s2: RotationalShiftDayType,
  replaceS1: RotationalShiftDayType,
  replaceS2: RotationalShiftDayType,
  reason: string,
  type: string
) => void;

export const shiftSwitchTypeList = [
  {
    value: 'company-mandated',
    text: 'Company Mandated',
  },
  {
    value: 'individual',
    text: 'Individual',
  },
];

type FormData = {
  date: string;
  employee: IdName;
  replacementDate: string;
  reason: string;
  type: string;
};

export const SelectSwitchWeeklyOffEmployeeForm = ({
  date,
  useRotationalShiftDays,
  useEmployees,
  schedules,
  replaceWeeklyOffs,
  emp1Shift,
}: {
  date: string;
  useRotationalShiftDays: UseRotationalShiftDays;
  useEmployees: UseEmployeeProfiles;
  schedules: ShiftScheduleType[] | undefined;
  replaceWeeklyOffs: ReplacementWeeklyOffFunType;
  emp1Shift: RotationalShiftDayType;
}) => {
  const {
    data: switchEmployeeShiftDays,
    getAll: getAllSwitchEmployeeShiftDays,
  } = useRotationalShiftDays();
  const {
    data: switchEmp1ShiftReplacementDays,
    getAll: getSwitchEmp1ShiftReplacementDays,
  } = useRotationalShiftDays();
  const {
    data: switchEmp2ShiftReplacementDays,
    getAll: getSwitchEmp2ShiftReplacementDays,
  } = useRotationalShiftDays();

  const [formData, setFormData] = useState<FormData>({
    employee: { id: '', name: '' },
    date: date,
    replacementDate: moment(date).add(6, 'd').format('YYYY-MM-DD'),
    reason: '',
    type: '',
  });

  const getData = async (employee1, employee2, date, replacementDate) => {
    getAllSwitchEmployeeShiftDays({
      employeeId: `equal::${employee2?.id}` || '',
      date,
    });

    getSwitchEmp1ShiftReplacementDays({
      employeeId: `equal::${employee1?.id}` || '',
      date: replacementDate,
    });

    getSwitchEmp2ShiftReplacementDays({
      employeeId: `equal::${employee2?.id}` || '',
      date: replacementDate,
    });
  };

  const Form = renderFormV2<any>({
    fieldsData: [
      {
        type: 'date',
        property: 'date',
        readonly: true,
        label: 'Date of weekly off switch',
      },
      {
        type: 'searchable-select',
        property: 'employee',
        label: 'Select Employee to switch weekly off with',
        searchOptions: {
          useSearch: useEmployees,
          filter: { crossGroup: 'true' },
          onSearchValueSelect: () => {},
        },
      },
      {
        type: 'date',
        property: 'replacementDate',
        label: 'Selected Employees weekly off replacement date',
      },
      {
        type: 'input',
        property: 'reason',
        required: true,
        label: 'Reason to change shift',
      },
      {
        type: 'select',
        property: 'type',
        label: 'Select type',
        required: true,
        options: [{ value: '', text: 'select' }, ...shiftSwitchTypeList],
      },
    ],
    onSubmit: async (data) => {
      setFormData(data);
      getData(
        emp1Shift.employee,
        data.employee,
        data.date,
        data.replacementDate
      );
    },
    initialFormState: formData,
    style: 'w-1/2',
    button: {
      text: 'Search',
      style: 'w-fit text-xs',
    },
    allowUnchanged: true,
    mapTToU: (t) => {
      return t as any;
    },
  });

  const sch1 = schedules?.find((x) => x.id === emp1Shift.details.shiftId?.id);

  return (
    <div>
      <Form />
      <div className='rounded p-1'>
        <div className='font-bold text-lg uppercase italic text-center'>
          Date: {moment(formData.date).format('ddd DD-MM-YYYY')}
        </div>
        <div className='flex'>
          <div className='w-1/2 p-2 border'>
            <div>Current Employee Shift</div>
            <div>
              <div>
                <b>Employee Name:</b> {emp1Shift.employee.name}
              </div>
              <div>
                <b>Date:</b> {emp1Shift.date}
              </div>
              <div className='flex'>
                <div>
                  <b>Shift:</b> {sch1?.name}
                </div>
                <div className='text-xs text-gray-800 font-thin'>
                  ({sch1?.startTime || '?'} to {sch1?.endTime || '?'})
                </div>
              </div>
              <div>
                <b>Attendance status:</b> {emp1Shift.attendance}
              </div>
              <div>
                <b>Work Area:</b> {emp1Shift.details.workArea?.name}
              </div>
              <div>
                <b>Roles:</b> {emp1Shift.details.roleName}
              </div>
            </div>
          </div>

          <div className='w-1/2 p-2 border'>
            <div>Selected Employee Shift</div>
            <div>
              {switchEmployeeShiftDays?.length &&
              switchEmployeeShiftDays?.length > 0 ? (
                <RenderEmpInfo
                  s={switchEmployeeShiftDays[0]}
                  sch1={sch1}
                  schedules={schedules}
                  emp1Shift={emp1Shift}
                />
              ) : (
                <div>
                  {formData.employee.id ? 'No shift found to switch' : ''}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='font-bold text-lg uppercase italic text-center'>
          Replacement Date:{' '}
          {moment(formData.replacementDate).format('ddd DD-MM-YYYY')}
        </div>
        <RenderPart2
          switchEmp1ShiftReplacementDays={switchEmp1ShiftReplacementDays}
          switchEmp2ShiftReplacementDays={switchEmp2ShiftReplacementDays}
          schedules={schedules}
        />

        <div className='mt-2'>
          <SwitchButton
            emp1Shift={emp1Shift}
            emp2Shifts={switchEmployeeShiftDays}
            emp1ReplacementShifts={switchEmp1ShiftReplacementDays}
            emp2ReplacementShifts={switchEmp2ShiftReplacementDays}
            formData={formData}
            onSave={replaceWeeklyOffs}
          />
        </div>
      </div>
    </div>
  );
};

const canWeSwitch = (
  s1: RotationalShiftDayType,
  s2: RotationalShiftDayType,
  rs1: RotationalShiftDayType,
  rs2: RotationalShiftDayType
) => {
  if (s1.employee.id === s2.employee.id)
    return 'Can not switch, Both Employees are same';
  if (rs1.employee.id === rs2.employee.id)
    return 'Can not switch, Both replacement Employees are same';

  if (s1.attendance !== 'working')
    return 'Can not switch as employee1 is already on weekly off';
  if (s2.attendance === 'working')
    return 'Can not switch as employee2 is working';

  if (rs1.attendance === 'working')
    return `Can not switch as replacement employee1 is working on ${rs1.date}`;
  if (rs2.attendance !== 'working')
    return `Can not switch as replacement employee2 is on weekly off on ${rs2.date}`;

  return null;
};

const SwitchButton = ({
  emp1Shift,
  emp2Shifts,
  emp1ReplacementShifts,
  emp2ReplacementShifts,
  onSave,
  formData,
}: {
  emp1Shift?: RotationalShiftDayType;
  emp2Shifts?: RotationalShiftDayType[];
  emp1ReplacementShifts?: RotationalShiftDayType[];
  emp2ReplacementShifts?: RotationalShiftDayType[];
  formData: FormData;
  onSave: ReplacementWeeklyOffFunType;
}) => {
  if (
    emp1Shift &&
    emp2Shifts &&
    emp2Shifts.length > 0 &&
    emp1ReplacementShifts &&
    emp1ReplacementShifts.length > 0 &&
    emp2ReplacementShifts &&
    emp2ReplacementShifts.length > 0
  ) {
    const cws = canWeSwitch(
      emp1Shift,
      emp2Shifts[0],
      emp1ReplacementShifts[0],
      emp2ReplacementShifts[0]
    );
    if (cws) {
      return (
        <div className='flex justify-center items center font-semibold'>
          <ExclamationTriangleIcon className='text-red-500 w-5 h-5 mr-2' />{' '}
          {cws}
        </div>
      );
    }
    return (
      <div>
        {/* <button
          className='bg-green-700 hover:bg-green-800 p-2 rounded text-white font-semibold'
          onClick={() => {
            onSave(
              emp1Shift,
              emp2Shifts[0],
              emp1ReplacementShifts[0],
              emp2ReplacementShifts[0],
              formData.reason,
              formData.type
            );
          }}
        >
          Allow Switch shifts
        </button> */}

        {/* Replica one having Initial of Shifts in Uppercase */}
        <LoadingButton
          defaultStyle='bg-green-700 hover:bg-green-800 p-2 rounded text-white font-semibold'
          behaviorFn={async () => {
            onSave(
              emp1Shift,
              emp2Shifts[0],
              emp1ReplacementShifts[0],
              emp2ReplacementShifts[0],
              formData.reason,
              formData.type
            );
          }}
          text='Allow Switch Shifts'
        />
      </div>
    );
  }
  return null;
};

const RenderPart2 = ({
  switchEmp1ShiftReplacementDays,
  switchEmp2ShiftReplacementDays,
  schedules,
}: {
  switchEmp1ShiftReplacementDays?: RotationalShiftDayType[];
  switchEmp2ShiftReplacementDays?: RotationalShiftDayType[];
  schedules: ShiftScheduleType[] | undefined;
}) => {
  if (
    switchEmp1ShiftReplacementDays &&
    switchEmp1ShiftReplacementDays.length > 0 &&
    switchEmp2ShiftReplacementDays &&
    switchEmp2ShiftReplacementDays.length > 0
  ) {
    const employee1ReplacementShift = switchEmp1ShiftReplacementDays[0];
    const employee2ReplacementShift = switchEmp2ShiftReplacementDays[0];

    const schRep1 = schedules?.find(
      (x) => x.id === employee1ReplacementShift.details.shiftId?.id
    );
    const schRep2 = schedules?.find(
      (x) => x.id === employee2ReplacementShift.details.shiftId?.id
    );

    return (
      <div className='flex'>
        <div className='w-1/2 p-2 border'>
          <div>Current Employee Replacement Shift</div>
          <div>
            <div
              className={`w-fit ${
                employee1ReplacementShift.employee.id ===
                employee2ReplacementShift.employee.id
                  ? 'bg-red-100'
                  : ''
              }`}
            >
              <b>Employee Name:</b> {employee1ReplacementShift.employee.name}
              {employee1ReplacementShift.employee.id ===
              employee2ReplacementShift.employee.id ? (
                <ExclamationTriangleIcon
                  title='Selected employee and current employee are same'
                  className='w-5 h-5 inline-block text-red-500'
                />
              ) : null}
            </div>

            <div>
              <b>Date:</b> {employee1ReplacementShift.date}
            </div>

            <div className={'flex w-fit'}>
              <div>
                <b>Shift:</b> {schRep1?.name}
              </div>
              <div className='text-xs text-gray-800 font-thin'>
                ({schRep1?.startTime || '?'} to {schRep1?.endTime || '?'})
              </div>
            </div>

            <div
              className={`w-fit ${
                employee1ReplacementShift.attendance !== 'weekly-off'
                  ? 'bg-red-100'
                  : ''
              }`}
            >
              <b>Attendance status:</b> {employee1ReplacementShift.attendance}
              {employee1ReplacementShift.attendance === 'working' ? (
                <ExclamationTriangleIcon
                  title='Selected employee id not weekly off!'
                  className='w-5 h-5 inline-block text-red-500'
                />
              ) : null}
            </div>

            <div
              className={`w-fit ${
                employee1ReplacementShift.details.workArea?.id !==
                employee2ReplacementShift.details.workArea?.id
                  ? 'bg-red-100'
                  : ''
              }`}
            >
              <b>Work Area:</b>{' '}
              {employee1ReplacementShift.details.workArea?.name}
              {employee1ReplacementShift.details.workArea?.id !==
              employee2ReplacementShift.details.workArea?.id ? (
                <ExclamationTriangleIcon
                  title='Work are not matching!'
                  className='w-5 h-5 inline-block text-red-500'
                />
              ) : null}
            </div>

            <div
              className={`w-fit ${
                employee1ReplacementShift.details.roleName !==
                employee2ReplacementShift.details.roleName
                  ? 'bg-red-100'
                  : ''
              }`}
            >
              <b>Roles:</b> {employee1ReplacementShift.details.roleName}
              {employee1ReplacementShift.details.roleName !==
              employee2ReplacementShift.details.roleName ? (
                <ExclamationTriangleIcon
                  title='Role is not matching'
                  className='w-5 h-5 inline-block text-red-500'
                />
              ) : null}
            </div>
          </div>
        </div>

        <div className='w-1/2 p-2 border'>
          <div>Selected Employee Replacement Shift</div>
          <div>
            <div
              className={`w-fit ${
                employee2ReplacementShift.employee.id ===
                employee1ReplacementShift.employee.id
                  ? 'bg-red-100'
                  : ''
              }`}
            >
              <b>Employee Name:</b> {employee2ReplacementShift.employee.name}
              {employee2ReplacementShift.employee.id ===
              employee1ReplacementShift.employee.id ? (
                <ExclamationTriangleIcon
                  title='Selected employee and current employee are same'
                  className='w-5 h-5 inline-block text-red-500'
                />
              ) : null}
            </div>

            <div>
              <b>Date:</b> {employee2ReplacementShift.date}
            </div>

            <div className={'flex w-fit'}>
              <div>
                <b>Shift:</b> {schRep2?.name}
              </div>
              <div className='text-xs text-gray-800 font-thin'>
                ({schRep2?.startTime || '?'} to {schRep2?.endTime || '?'})
              </div>
            </div>

            <div
              className={`w-fit ${
                employee2ReplacementShift.attendance !== 'working'
                  ? 'bg-red-100'
                  : ''
              }`}
            >
              <b>Attendance status:</b> {employee2ReplacementShift.attendance}
              {employee2ReplacementShift.attendance !== 'working' ? (
                <ExclamationTriangleIcon
                  title='Selected employee id on weekly off!'
                  className='w-5 h-5 inline-block text-red-500'
                />
              ) : null}
            </div>

            <div
              className={`w-fit ${
                employee2ReplacementShift.details.workArea?.id !==
                employee1ReplacementShift.details.workArea?.id
                  ? 'bg-red-100'
                  : ''
              }`}
            >
              <b>Work Area:</b>{' '}
              {employee2ReplacementShift.details.workArea?.name}
              {employee2ReplacementShift.details.workArea?.id !==
              employee1ReplacementShift.details.workArea?.id ? (
                <ExclamationTriangleIcon
                  title='Work are not matching!'
                  className='w-5 h-5 inline-block text-red-500'
                />
              ) : null}
            </div>

            <div
              className={`w-fit ${
                employee2ReplacementShift.details.roleName !==
                employee1ReplacementShift.details.roleName
                  ? 'bg-red-100'
                  : ''
              }`}
            >
              <b>Roles:</b> {employee2ReplacementShift.details.roleName}
              {employee2ReplacementShift.details.roleName !==
              employee1ReplacementShift.details.roleName ? (
                <ExclamationTriangleIcon
                  title='Role is not matching'
                  className='w-5 h-5 inline-block text-red-500'
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const RenderEmpInfo = ({
  s,
  emp1Shift,
  schedules,
  sch1,
}: {
  s: RotationalShiftDayType;
  emp1Shift: RotationalShiftDayType;
  schedules: ShiftScheduleType[] | undefined;
  sch1: ShiftScheduleType | undefined;
}) => {
  const sch = schedules?.find((x) => x.id === s.details.shiftId?.id);
  return (
    <div key={s.id}>
      <div
        className={`w-fit ${
          s.employee.id === emp1Shift.employee.id ? 'bg-red-100' : ''
        }`}
      >
        <b>Employee Name:</b> {s.employee.name}
        {s.employee.id === emp1Shift.employee.id ? (
          <ExclamationTriangleIcon
            title='Selected employee and current employee are same'
            className='w-5 h-5 inline-block text-red-500'
          />
        ) : null}
      </div>

      <div>
        <b>Date:</b> {s.date}
      </div>

      <div className={'flex w-fit'}>
        <div>
          <b>Shift:</b> {sch?.name}
        </div>
        <div className='text-xs text-gray-800 font-thin'>
          ({sch?.startTime || '?'} to {sch?.endTime || '?'})
        </div>
      </div>

      <div
        className={`w-fit ${s.attendance !== 'weekly-off' ? 'bg-red-100' : ''}`}
      >
        <b>Attendance status:</b> {s.attendance}
        {s.attendance === 'working' ? (
          <ExclamationTriangleIcon
            title='Selected employee id not weekly off!'
            className='w-5 h-5 inline-block text-red-500'
          />
        ) : null}
      </div>

      <div
        className={`w-fit ${
          s.details.workArea?.id !== emp1Shift.details.workArea?.id
            ? 'bg-red-100'
            : ''
        }`}
      >
        <b>Work Area:</b> {s.details.workArea?.name}
        {s.details.workArea?.id !== emp1Shift.details.workArea?.id ? (
          <ExclamationTriangleIcon
            title='Work are not matching!'
            className='w-5 h-5 inline-block text-red-500'
          />
        ) : null}
      </div>

      <div
        className={`w-fit ${
          s.details.roleName !== emp1Shift.details.roleName ? 'bg-red-100' : ''
        }`}
      >
        <b>Roles:</b> {s.details.roleName}
        {s.details.roleName !== emp1Shift.details.roleName ? (
          <ExclamationTriangleIcon
            title='Role is not matching'
            className='w-5 h-5 inline-block text-red-500'
          />
        ) : null}
      </div>
    </div>
  );
};
