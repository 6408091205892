import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { SalaryType } from '@erp_core/erp-types/dist/types/modules/payroll/salary';
import { LoadingButton } from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { SRType } from './create-statutory-reports';

export function CreateBatch({
  data,
  setSalary,
  onClose,
}: {
  data: SRType;
  setSalary: (s: SalaryType) => Promise<SalaryType>;
  onClose: () => void;
}): JSX.Element {
  const [batchName, setBatchName] = useState<string>('');
  return (
    <div>
      <div className='text-center'>
        Create Batch of {data.data.length} employees
      </div>

      <div>
        Batch Name:{' '}
        <input
          className='border border-gray-200 p-1 rounded-md'
          defaultValue={batchName}
          onBlur={(evt) => setBatchName(evt.target.value)}
        />
      </div>
      <div className='justify-center'>
        {batchName ? (
          <LoadingButton
            text='Create'
            behaviourParams={{}}
            behaviorFn={async () => {
              for (const extSal of data.data) {
                await setSalary({
                  id: extSal.salary.id,
                  releaseDetails: { batchNumber: batchName },
                } as any);
              }
              onClose();
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export function CreateRecalculateSalaryBatch({
  list,
  setSalary,
  onClose,
}: {
  list: {
    employee: EmployeeProfileType;
    salary: SalaryType;
  }[];
  setSalary: (s: SalaryType) => Promise<SalaryType>;
  onClose: () => void;
}): JSX.Element {
  const [batchName, setBatchName] = useState<string>('');
  return (
    <div>
      <div className='text-center'>
        Create Recalculate Batch of {list.length} employees
      </div>

      <div>
        Recalculate Batch Name:{' '}
        <input
          className='border border-gray-200 p-1 rounded-md'
          defaultValue={batchName}
          onBlur={(evt) => setBatchName(evt.target.value)}
        />
      </div>
      <div className='justify-center'>
        {batchName ? (
          <LoadingButton
            text='Create'
            behaviourParams={{}}
            behaviorFn={async () => {
              for (const entry of list) {
                await setSalary({
                  id: entry.salary.id,
                  releaseDetails: { batchNumber: batchName },
                } as any);
              }
              onClose();
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
