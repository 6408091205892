import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  ItemProperty,
  ItemPropertyValue,
} from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { Vendor } from '@erp_core/erp-types/dist/types/modules/order/purchase/vendor';
import {
  CardBody,
  CardHeader,
  ModalV2Props,
  renderCardComponent,
  renderModal,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { PencilIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { UseGsts } from '../../../../../../hooks/admin/constants/gst/use-gsts';
import { UseMetrics } from '../../../../../../hooks/admin/constants/metrics/use-metrics';
import { UseVendor } from '../../../../../../hooks/inventory/purchase/vendors/use-vendor';
import { UseVendors } from '../../../../../../hooks/inventory/purchase/vendors/use-vendors';
import { ItemInterface } from '../../../../../../models/interfaces/inventory/item';
import { renderEditArrayObjectPropertyValue } from './forms/edit-array-object-property-value';
import { renderEditObjectPropertyValue } from './forms/edit-object-property-value';
import {
  EditPropertyValFormType,
  renderEditPropertyValue,
} from './forms/edit-property-value';
import {
  EditPropertyIdNameValFormType,
  renderEditSearchableSelectPropertyValue,
} from './forms/edit-searchable-select-property-value';
import { renderEditSelectPropertyValue } from './forms/edit-select-property-value';

type RenderVendorItemSpecProps = {
  itemService: ItemInterface;
  useMetrics: UseMetrics;
  useGsts: UseGsts;
  useVendors: UseVendors;
  useVendor: UseVendor;
};

export function renderVendorItemSpec({
  itemService,
  useMetrics,
  useGsts,
  useVendors,
  useVendor,
}: RenderVendorItemSpecProps): () => JSX.Element {
  return function VendorItemSpec(): JSX.Element {
    const { id } = useParams(); //itemid
    const { vendorId } = useParams(); //vendorId

    const [properties, setProperties] = useState<Array<ItemPropertyValue>>([]);
    const closeModal = () => {
      setModalState((ms) => ({ ...ms, isVisible: false }));
    };

    const { data: vendors, getAll: getVendors } = useVendors();

    const { syncSet: setVendor } = useVendor();

    const Modal = renderModal();

    const [modalState, setModalState] = useState<ModalV2Props>({
      title: 'Edit Value',
      isVisible: false,
      onClose: () => closeModal(),
      body: <div className='hidden' />,
      modalSize: 'large',
    });

    useEffect(() => {
      if (id) {
        itemService.getProperties(id).then((c) => {
          setProperties(c);
        });
      }
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      // This effect uses the `value` variable,
      // so it "depends on" `value`.
      getVendors();

      // eslint-disable-next-line
    }, [properties]);

    // useEffect(() => {
    //     const props = properties.filter((x) =>
    //     x.classifiers?.find((y) => y.name === 'Specification')
    // )
    // }, [props]);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Vendor Item Specifications',
    };

    const vendor = vendors?.find((x) => x.id === vendorId);

    async function updateValue({
      itemProperty,
      property,
      value,
      propType,
      propOptions,
      searchSelectOptions,
    }: {
      itemProperty: ItemProperty;
      property;
      value:
        | {
            data?: string | number | IdName | undefined | any;
            owner?: {
              type: 'stockgroup' | 'item';
              id: string;
              name: string;
            };
          }
        | undefined;
      propType?: string;
      propOptions?: string | Array<string>;
      searchSelectOptions?: string;
    }) {
      const currentValue = value?.data || '';
      const currentIdNameValue = ((value?.data as unknown) as IdName) || {};
      // const vendor = vendors?.find(x => x.id === vendorId)?.items.find(y => y.item.id === id)?.item

      const EditPropertyVal = renderEditPropertyValue();
      const EditSelectPropertyVal = renderEditSelectPropertyValue();
      const EditSearchSelectPropertVal = renderEditSearchableSelectPropertyValue(
        {
          useMetrics,
          useGsts,
        }
      );
      const EditObjectPropertVal = renderEditObjectPropertyValue({
        useMetrics,
        useGsts,
      });
      const EditArrayObjectProperyVal = renderEditArrayObjectPropertyValue({
        useMetrics,
        useGsts,
      });

      const handleEditProperty = async (
        form: Partial<EditPropertyValFormType>
      ) => {
        if (form.newValue !== '' && form.newValue !== currentValue) {
          try {
            const itemSpecificationDetails = {
              ...vendor?.items?.find((x) => x.item.id === id)?.details
                ?.itemSpecificationDetails,
              ...{ [property]: form.newValue },
            };
            if (vendor) {
              const save: Vendor = {
                id: vendor.id,
                items: vendor.items.map((i) => {
                  if (i.item.id === id) {
                    return {
                      id: i.id,
                      item: { id: id },
                      vendor: { id: vendorId },
                      details: {
                        itemSpecificationDetails: itemSpecificationDetails,
                      },
                    };
                  }
                  return i;
                }),
              } as Vendor;
              await setVendor(save);
            }
            toast('New Value updated successfully');
            modalState.onClose();
          } catch (e) {
            toast('Something went wrong');
          }
        }
      };

      const handleEditIdNameProperty = async (
        form: Partial<EditPropertyIdNameValFormType>
      ) => {
        if (form.newValue?.name !== '' && form.newValue !== currentValue) {
          try {
            const itemSpecificationDetails = {
              ...vendor?.items?.find((x) => x.item.id === id)?.details
                ?.itemSpecificationDetails,
              ...{ [property]: form.newValue },
            };
            if (vendor) {
              const save: Vendor = {
                id: vendor.id,
                items: vendor.items.map((i) => {
                  if (i.item.id === id) {
                    return {
                      id: i.id,
                      item: { id: id },
                      vendor: { id: vendorId },
                      details: {
                        itemSpecificationDetails: itemSpecificationDetails,
                      },
                    };
                  }
                  return i;
                }),
              } as Vendor;
              await setVendor(save);
            }
            toast('New Value updated successfully');
            modalState.onClose();
          } catch (e) {
            toast('Something went wrong');
          }
        }
      };

      const renderCurrentSelectn = () => {
        switch (propType) {
          case 'input':
          case 'number':
            return (
              <EditPropertyVal
                propType={propType}
                currentValue={String(currentValue)}
                onSave={handleEditProperty}
              />
            );
          case 'select':
            return (
              <EditSelectPropertyVal
                propOptions={propOptions || ''}
                currentValue={String(currentValue)}
                onSave={handleEditProperty}
              />
            );
          case 'searchable-select':
            return (
              <EditSearchSelectPropertVal
                currentValue={currentIdNameValue}
                onSave={handleEditIdNameProperty}
                useResources={searchSelectOptions || ''}
              />
            );
          case 'object': {
            return (
              <EditObjectPropertVal
                itemProperty={itemProperty}
                currentValue={currentIdNameValue}
                onSave={handleEditIdNameProperty}
                useResources={searchSelectOptions || ''}
              />
            );
          }
          case 'fixed-array-object':
          case 'array-object': {
            return (
              <EditArrayObjectProperyVal
                itemProperty={itemProperty}
                currentValue={currentIdNameValue}
                onSave={handleEditIdNameProperty}
                useResources={searchSelectOptions || ''}
              />
            );
          }
          default:
            return <div>Unknown Property Type</div>;
        }
      };

      setModalState((ms) => ({
        ...ms,
        modalSize: 'large',
        isVisible: true,
        body: renderCurrentSelectn(),
      }));
    }

    function ValueRenderer({ x }: { x: ItemPropertyValue }): JSX.Element {
      if (x.value?.data?.name) {
        return <>{x.value.data.name}</>;
      }

      if (x.value?.data) {
        if (_.isObject(x.value?.data)) {
          return renderComplexProperties(x);
        }

        return x.value.data;
      }

      return <>-</>;
    }

    function renderComplexProperties(x: ItemPropertyValue) {
      if (Array.isArray(x.value?.data)) {
        const length = _.keys(x.childrenListProperties).length;
        return (
          <div>
            <div className='flex'>
              {_.keys(x.childrenListProperties)?.map((key) => {
                return (
                  <div
                    key={key}
                    className={`text-center font-bold w-1/${length} border border-gray-300`}
                  >
                    <div>{key}</div>
                    {x.childrenListProperties &&
                    x.childrenListProperties[key] &&
                    x.childrenListProperties[key].childrenProperties &&
                    _.keys(x.childrenListProperties[key].childrenProperties)
                      .length ? (
                      <div className='flex'>
                        {_.keys(
                          x.childrenListProperties[key].childrenProperties
                        ).map((innerKey) => (
                          <div
                            key={innerKey}
                            className={`text-center font-bold w-1/${
                              x.childrenListProperties &&
                              _.keys(
                                x.childrenListProperties[key].childrenProperties
                              ).length
                            } border border-gray-300`}
                          >
                            {innerKey}
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            {x.value?.data.map((d, idx) => (
              <div key={idx} className='flex'>
                {_.keys(x.childrenListProperties)?.map((key, iidx) => {
                  if (
                    x.childrenListProperties &&
                    x.childrenListProperties[key].type === 'object'
                  ) {
                    return (
                      <div
                        className={`flex w-1/${length} border border-gray-300`}
                      >
                        {_.keys(
                          x.childrenListProperties &&
                            x.childrenListProperties[key].childrenProperties
                        ).map((childProp) => {
                          return (
                            <div
                              key={childProp}
                              className={`text-center w-1/${
                                _.keys(
                                  x.childrenListProperties &&
                                    x.childrenListProperties[key]
                                      .childrenProperties
                                ).length
                              } border border-gray-300`}
                            >
                              {d[key] && d[key][childProp] ? (
                                <>
                                  {_.isObject(d[key][childProp]) &&
                                  d[key][childProp].name
                                    ? d[key][childProp].name
                                    : d[key][childProp]}
                                </>
                              ) : (
                                '-'
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );

                    // x.childrenListProperties[key].
                  }

                  return (
                    <div
                      key={`${idx}-${iidx}`}
                      className={`w-1/${length} border border-gray-300`}
                    >
                      {typeof d[key] === 'object' && d[key].name
                        ? d[key].name
                        : typeof d[key] === 'object'
                        ? JSON.stringify(d[key])
                        : d[key]}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        );
        // return <div>Idhar Table ayega</div>
      }

      return (
        <>
          {_.keys(x.childrenProperties)?.map((key, idx) => {
            return (
              <div className='flex' key={key}>
                <div className='w-1/2 font-bold border border-gray-300'>
                  {key}
                </div>
                <div key={idx} className='w-1/2 border border-gray-300'>
                  {x.value?.data[key]}
                </div>
              </div>
            );
          })}
        </>
      );

      // return JSON.stringify(x.value?.data || {})
    }

    const getPropertyValues = (name: string, r: ItemPropertyValue) => {
      return (
        <div>
          <ValueRenderer x={r as ItemPropertyValue} />
        </div>
      );
      // }
    };

    const Table = renderTableComponent();

    const TableHeader: TableHeader = [
      [
        { name: 'Name', style: 'w-1/6' },
        { name: 'In-house Value', style: 'w-1/6' },
        { name: 'Value', style: 'w-1/6' },
      ],
    ];

    const tableData =
      properties
        .filter((x) => x.classifiers?.find((y) => y.name === 'Specification'))
        ?.map((r, idx) => {
          const cloneR = JSON.parse(JSON.stringify(r));

          delete cloneR.value;
          const specDetls = vendor?.items?.find((x) => x.item.id === id)
            ?.details?.itemSpecificationDetails;
          const specValue = specDetls && specDetls[r.name];
          if (specValue) {
            cloneR.value = { data: specValue };
          }

          return {
            cells: [
              {
                value: (
                  <div>
                    <div className='font-semibold text-lg'>{r.name}</div>
                    <div className='text-gray-700 italic text-sm'>
                      {r.description}
                    </div>
                  </div>
                ),
              },
              {
                value: (
                  <div>
                    <div className='text-md font-medium text-gray-900'>
                      <div className='flex-auto'>
                        <div className='flex'>
                          <div className='flex-auto'>
                            <ValueRenderer x={r} />
                          </div>
                          <div className='flex-none'>
                            {r.name === 'pubchem-cid' &&
                            r.value?.data &&
                            _.isString(r.value?.data) ? (
                              <img
                                src={`https://pubchem.ncbi.nlm.nih.gov/image/imgsrv.fcgi?cid=${r.value.data}&t=l`}
                                alt={r.value.data}
                                className='w-32 h-32'
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {r.value?.owner ? (
                      <div className='text-gray-700 text-right italic text-sm'>
                        set at {r.value?.owner?.name}
                      </div>
                    ) : null}
                  </div>
                ),
              },
              {
                value: (
                  <div className='my-auto flex-none'>
                    <>{getPropertyValues(r.name, cloneR)}</>

                    {
                      <PencilIcon
                        className='inline ml-2 w-5 h-5 text-gray-400 cursor-pointer'
                        onClick={() => {
                          updateValue({
                            itemProperty: r,
                            property: r.name,
                            value: cloneR.value,
                            propType: (r?.type as string) || '',
                            propOptions: r?.selectOptions || '',
                            searchSelectOptions:
                              (r?.searchSelectOptions as string) || '',
                          });
                        }}
                      />
                    }
                  </div>
                ),
              },
            ],
          };
        }) || [];

    const tableBody: TableBody = tableData;

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table header={TableHeader} body={tableBody} />
          </div>
        </div>
      ),
    };

    return (
      <>
        <div>
          <Card header={cardHeader} body={cardBody} />
          <Modal
            title={modalState.title}
            isVisible={modalState.isVisible}
            modalStyle={modalState.modalStyle}
            body={modalState.body}
            onClose={modalState.onClose}
          />
        </div>
      </>
    );
  };
}
