import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import {
  LoadingButton,
  ModalV2Props,
  OpenConfirm,
  renderModal,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { AddItemShiftRolesForm } from './forms/addRolesForm';
import { AddItemShiftSkillsForm } from './forms/addSkillsForm';

type Props = {
  item: ItemRes;
  setItem: (data: ItemRes, options?: any) => Promise<ItemRes>;
  openConfirm: OpenConfirm;
};

export const renderItemShiftConfig = () => {
  const Table = renderTableComponent();
  const Modal = renderModal();

  return function ({ item, setItem, openConfirm }: Props) {
    const [modal, setModal] = useState<ModalV2Props>({
      isVisible: false,
      title: '',
      onClose: () => {
        setModal((current) => {
          return { ...current, isVisible: false, title: '', body: <div /> };
        });
      },
      body: <div />,
    });

    const itemDetails: any = item.details;

    const addItemSkills = async (form) => {
      const finalData = {
        id: item.id,
        details: {
          shiftConfig: {
            ...itemDetails?.shiftConfig,
            skills: [...(itemDetails?.shiftConfig?.skills || []), form],
          },
        },
      };
      await setItem((finalData as unknown) as ItemRes);
    };

    const addItemRoles = async (form) => {
      const finalData = {
        id: item.id,
        details: {
          shiftConfig: {
            ...itemDetails?.shiftConfig,
            roles: [...(itemDetails?.shiftConfig?.roles || []), form],
          },
        },
      };
      await setItem((finalData as unknown) as ItemRes);
    };

    const editItemSkills = async (form, index) => {
      const skills = itemDetails?.shiftConfig?.skills || [];
      skills[index] = form;
      const finalData = {
        id: item.id,
        details: {
          shiftConfig: {
            ...itemDetails?.shiftConfig,
            skills: skills,
          },
        },
      };
      await setItem((finalData as unknown) as ItemRes);
    };

    const editItemRoles = async (form, index) => {
      const roles = itemDetails?.shiftConfig?.roles || [];
      roles[index] = form;
      const finalData = {
        id: item.id,
        details: {
          shiftConfig: {
            ...itemDetails?.shiftConfig,
            roles,
          },
        },
      };
      await setItem((finalData as unknown) as ItemRes);
    };

    const addSkill = () => {
      setModal({
        ...modal,
        isVisible: true,
        title: 'Add Skill',
        body: <AddItemShiftSkillsForm save={addItemSkills} item={item} />,
      });
    };

    const addRole = () => {
      setModal({
        ...modal,
        isVisible: true,
        title: 'Add Role',
        body: <AddItemShiftRolesForm save={addItemRoles} item={item} />,
      });
    };

    const editSkill = (
      data: { name: string; min: number; max: number },
      index: number
    ) => {
      setModal({
        ...modal,
        isVisible: true,
        title: 'Edit Skill',
        body: (
          <AddItemShiftSkillsForm
            save={async (form) => {
              editItemSkills(form, index);
            }}
            item={item}
            data={data}
          />
        ),
      });
    };

    const editRoles = (
      data: {
        name: string;
        min: number;
        max: number;
        level: 'beginner' | 'intermediate' | 'professional';
      },
      index: number
    ) => {
      setModal({
        ...modal,
        isVisible: true,
        title: 'Edit Roles',
        body: (
          <AddItemShiftRolesForm
            save={async (form) => {
              await editItemRoles(form, index);
            }}
            item={item}
            data={data}
          />
        ),
      });
    };

    const deleteSkill = (index: number) => {
      openConfirm({
        onConfirm: async () => {
          let skills: any[] = itemDetails.shiftConfig.skills || [];
          skills.splice(index, 1);
          const finalData = {
            id: item.id,
            details: {
              shiftConfig: {
                ...itemDetails.shiftConfig,
                skills: skills,
              },
            } as any,
          };
          await setItem(finalData as ItemRes);
        },
      });
    };

    const deleteRole = (index: number) => {
      openConfirm({
        onConfirm: async () => {
          let roles: any[] = itemDetails.shiftConfig.roles || [];
          roles.splice(index, 1);
          const finalData = {
            id: item.id,
            details: {
              shiftConfig: {
                ...itemDetails.shiftConfig,
                roles,
              },
            } as any,
          };
          await setItem(finalData as ItemRes);
        },
      });
    };

    const skillsTableBody: TableBody =
      itemDetails?.shiftConfig?.skills?.map((s, index) => {
        return {
          cells: [
            { value: s?.name },
            { value: s?.min },
            { value: s?.max },
            {
              value: (
                <div className='flex space-x-1'>
                  <PencilIcon
                    onClick={() => {
                      editSkill(s, index);
                    }}
                    className='w-5 h-5 hover:cursor-pointer text-blue-500 inline-block'
                  />
                  <TrashIcon
                    onClick={() => deleteSkill(index)}
                    className='w-5 h-5 text-red-500 inline-block'
                  />
                </div>
              ),
            },
          ],
        };
      }) || [];

    const rolesTableBody: TableBody =
      itemDetails?.shiftConfig?.roles?.map((s, index) => {
        return {
          cells: [
            { value: s?.name },
            { value: s?.level || '' },
            { value: s?.min },
            { value: s?.max },
            {
              value: (
                <div className='flex space-x-1'>
                  <PencilIcon
                    onClick={() => {
                      editRoles(s, index);
                    }}
                    className='w-5 h-5 hover:cursor-pointer text-blue-500 inline-block'
                  />
                  <TrashIcon
                    onClick={() => deleteRole(index)}
                    className='w-5 h-5 text-red-500 inline-block'
                  />
                </div>
              ),
            },
          ],
        };
      }) || [];

    return (
      <div className='flex flex-col space-y-4'>
        <div>
          <div className='flex justify-between pb-2'>
            <div className='font-bold'>Skills required</div>
            <LoadingButton
              defaultStyle='bg-blue-500 p-2 rounded shadow text-white font-semibold hover:bg-blue-600'
              behaviorFn={async () => addSkill()}
              text='Add skills'
            />
          </div>
          <div>
            <Table
              header={[
                [
                  { name: 'name' },
                  { name: 'min' },
                  { name: 'max' },
                  { name: 'Action' },
                ],
              ]}
              body={skillsTableBody}
            />
          </div>
        </div>

        <div>
          <div className='flex justify-between pb-2'>
            <div className='font-bold'>Roles required</div>
            <LoadingButton
              defaultStyle='bg-blue-500 p-2 rounded shadow text-white font-semibold hover:bg-blue-600'
              behaviorFn={async () => addRole()}
              text='Add Role'
            />
          </div>
          <div>
            <Table
              header={[
                [
                  { name: 'Name' },
                  { name: 'Level' },
                  { name: 'Min' },
                  { name: 'Max' },
                  { name: 'Action' },
                ],
              ]}
              body={rolesTableBody}
            />
          </div>
        </div>
        <Modal {...modal} />
      </div>
    );
  };
};
