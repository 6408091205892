import { AttendanceType, Punch } from '@erp_core/erp-types/dist/modules/hrd';

export async function deleteManualPunch({
  d,
  p,
  setAttendance,
  getData,
}: {
  d: AttendanceType;
  p: Punch;
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  getData: () => Promise<void>;
}) {
  const punches = d.details?.punches.filter((x) => x.timeStamp !== p.timeStamp);

  // remove attendance from current day
  const finalData: AttendanceType = {
    id: d.id,
    details: {
      punches: punches,
    },
  } as AttendanceType;

  await setAttendance(finalData);
  getData();
}
