import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import {
  AttendanceType,
  CombinedAttDataType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { OvertimeType } from '@erp_core/erp-types/dist/types/modules/hrd/overtime';
import { LoadingButton, ModalV2Props } from '@erp_core/erp-ui-components';
import { renderAuthWrapper } from '../../../../../../components/auth';
import { UseUserAuthorization } from '../../../../../../hooks/admin/user-authorization/use-user-authorization';
import { renderLateMarkForm } from '../../../forms/late-mark';

export function renderLateMarkButton({
  useUserAuthorization,
  setModal,
  modal,
  attendance,
  setAttendance,
  currentUser,
  refreshData,
}: {
  getOvertimesSync: (filter?: any) => Promise<OvertimeType[]>;
  refreshData: () => void;
  useUserAuthorization: UseUserAuthorization;
  modal: ModalV2Props;
  attendance: CombinedAttDataType;
  setModal: (value: React.SetStateAction<ModalV2Props>) => void;
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  currentUser: CurrentUserType;
}): () => JSX.Element {
  const AuthWrapper = renderAuthWrapper({ useUserAuthorization });

  return function LateMarkButton(): JSX.Element {
    const saveLateMark = (form) => {
      let lateMark: any = {
        status: form.status,
        reason: form.reason,
      };
      if (form.status === 'approved') {
        lateMark.approvedBy = currentUser;
      } else {
        lateMark.rejectedBy = currentUser;
      }
      setAttendance({
        id: attendance.id,
        details: {
          lateMark,
        },
      } as any);
      refreshData();
    };

    if (!attendance.isLateForShift) {
      return <></>;
    }
    if (
      attendance.isLateForShift &&
      attendance.details?.lateMark &&
      !attendance.details?.lateMark?.status
    ) {
      return <></>;
    }
    if (attendance.finalized) {
      return <></>;
    }
    const LateMarkForm = renderLateMarkForm();
    if (
      attendance.employeeProfile.details?.authorizations?.attendanceAuthorizer
        ?.id === currentUser.id
    ) {
      return (
        <LoadingButton
          // type='button'
          defaultStyle='hover:text-black font-semibold text-gray-800 mb-1 text-left'
          behaviorFn={async () => {
            setModal({
              ...modal,
              isVisible: true,
              title: `Late Mark for ${attendance.employee.name} on ${attendance.date}`,
              body: <LateMarkForm onSave={saveLateMark} />,
            });
          }}
          text='Late Mark'
        />
      );
    }
    return (
      <AuthWrapper
        action='UI:BTN-ATTEN-FINALIZE:VIEW'
        children={
          <LoadingButton
            // type='button'
            defaultStyle='hover:text-black font-semibold text-gray-800 mb-1 text-left'
            behaviorFn={async () => {
              setModal({
                ...modal,
                isVisible: true,
                title: `Late Mark for ${attendance.employee.name} on ${attendance.date}`,
                body: <LateMarkForm onSave={saveLateMark} />,
              });
            }}
            text='Late Mark'
          />
        }
      />
    );
  };
}

export function renderLateMarkForms({
  setAttendance,
  currentUser,
  refreshData,
}: {
  refreshData: () => void;
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  currentUser: CurrentUserType;
}): ({ data, onClose }: { data: any; onClose: () => void }) => JSX.Element {
  return function LateMarkButton({
    data: { attendance },
    onClose,
  }: {
    data: { attendance: CombinedAttDataType };
    onClose: () => void;
  }): JSX.Element {
    const saveLateMark = (form) => {
      let lateMark: any = {
        status: form.status,
        reason: form.reason,
      };
      if (form.status === 'approved') {
        lateMark.approvedBy = currentUser;
      } else {
        lateMark.rejectedBy = currentUser;
      }
      setAttendance({
        id: attendance.id,
        details: {
          lateMark,
        },
      } as any);
      refreshData();
      onClose();
    };

    const LateMarkForm = renderLateMarkForm();

    return <LateMarkForm onSave={saveLateMark} />;
  };
}
