/* eslint-disable react-hooks/exhaustive-deps */
import {
  UserAdminInterface,
  UserAuthorization,
} from '../../../models/interfaces/admin/user-admin';
import { UseCurrentUser } from '../user-admin/use-current-user';

export type UseUserAuthorization = () => {
  get(): UserAuthorization;
  apiCall(id: string): void;
};

export const createUseUserAuthorization = ({
  userService,
  useCurrentUser,
}: {
  userService: UserAdminInterface;
  useCurrentUser: UseCurrentUser;
}): UseUserAuthorization => {
  return function useUserAuthorization() {
    const { data } = useCurrentUser();

    const apiCall = async (id) => {
      if (data.id) {
        const res = await userService.getAuthorization(id);
        if (res) {
          localStorage.setItem('authorization', JSON.stringify(res));
        }
      }
    };

    // TODO: should be removed from here and call when loge in user

    const get = () => {
      const data = localStorage.getItem('authorization');
      return JSON.parse(data || '{}') as UserAuthorization;
    };

    return {
      get() {
        return get();
      },
      apiCall(id: string) {
        apiCall(id);
      },
    };
  };
};
