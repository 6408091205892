/* eslint-disable no-unused-vars */
import { LoadingButton } from '@erp_core/erp-ui-components';
import { CubeIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { UseCurrentUser } from '../../../../hooks/admin/user-admin/use-current-user';
import { UseBatchItemAllocate } from '../../../../hooks/inventory/item/batch/use-batch-item-allocate';
import { UseBatchItemGodowns } from '../../../../hooks/inventory/item/batch/use-batch-item-godowns';

export type AllocateFormRequestType = {
  id: string;
  rawItem: {
    id: string;
    name: string;
    quantity: number;
    uom: string;
  };
  schedule?: {
    batchQuantity?: string;
    batchNeededBy?: string;
  }[];
};

export function AllocationForm({
  enquiryItemData,
  onSave,
  onCancel,
  useBatchItemGodowns,
  useBatchGodownItemAllocate,
  useCurrentUser,
}: {
  enquiryItemData: AllocateFormRequestType;
  onSave: any;
  onCancel: any;
  useBatchItemGodowns: UseBatchItemGodowns;
  useBatchGodownItemAllocate: UseBatchItemAllocate;
  useCurrentUser: UseCurrentUser;
}) {
  const { data: currentUser } = useCurrentUser();
  const { data: batchItems, getAll } = useBatchItemGodowns();
  const {
    data: allocatedBatchItems,
    getAll: allocatedGetAll,
  } = useBatchItemGodowns();
  const [userAllotedValues, setUserAllotedValues] = useState<
    Array<{
      id: string;
      quantity: number;
    }>
  >([]);
  const [userAllotedErrors, setUserAllotedErrors] = useState<
    Array<{
      error: boolean;
      reason: string;
    }>
  >([]);

  const { set: setAllocate } = useBatchGodownItemAllocate();

  useEffect(() => {
    getAll({
      item_id: enquiryItemData.rawItem.id,
      reference_id: 'null',
    });
    allocatedGetAll({
      item_id: enquiryItemData.rawItem.id,
      reference_id: enquiryItemData.id,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (batchItems && allocatedBatchItems) {
      const values: Array<{
        quantity: number;
        id: string;
      }> = _.times(batchItems.length).map((i) => ({
        id: batchItems[i].id,
        quantity: 0,
      }));
      setUserAllotedValues(values);

      const errors: Array<{
        error: boolean;
        reason: string;
      }> = _.times(batchItems.length).map((i) => ({
        error: false,
        reason: '',
      }));
      setUserAllotedErrors(errors);
    }
  }, [batchItems, allocatedBatchItems]);

  useEffect(() => {
    if (userAllotedValues.length && batchItems?.length) {
      const updatedErrors: Array<{
        error: boolean;
        reason: string;
      }> = _.times(batchItems.length).map((i) => ({
        error: false,
        reason: '',
      }));
      // eslint-disable-next-line
      let updated: boolean = false;

      userAllotedValues.forEach((val, idx) => {
        if (batchItems && val.quantity > batchItems[idx].quantity) {
          updated = true;
          updatedErrors[idx].error = true;
          updatedErrors[
            idx
          ].reason = `can't exceed ${batchItems[idx].quantity}`;
        }
      });
      setUserAllotedErrors(updatedErrors);
    }
    // eslint-disable-next-line
  }, [userAllotedValues]);

  const handleSaveClick = () => {
    userAllotedValues
      .filter((u) => u.quantity !== 0)
      .forEach((v) => {
        setAllocate({
          ...v,
          referenceId: enquiryItemData.id,
          currentUser,
          referenceType: 'sales-enquiry',
          allocationComplete: false, // TODO: This has to be true when entire allocation is done
        });
      });
    onSave();
  };

  const availableQuantity = batchItems?.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  );
  const physicallyAllocated =
    allocatedBatchItems?.reduce((prev, curr) => prev + curr.quantity, 0) || 0;
  const totalAllocatedQuantity =
    userAllotedValues?.reduce((prev, curr) => prev + curr.quantity, 0) +
    physicallyAllocated;
  const balanceRequiredQuantity =
    userAllotedValues?.reduce(
      (prev, curr) => prev - curr.quantity,
      enquiryItemData.rawItem.quantity - physicallyAllocated
    ) || enquiryItemData.rawItem.quantity - physicallyAllocated;

  return (
    <div className='p-5 flex flex-col space-y-4'>
      <div>
        <div className='text-gray-800 text-md font-bold mb-2'> Schedule</div>
        {enquiryItemData.schedule?.map((s, i) => (
          <div key={i}>
            {' '}
            Qty:{' '}
            <span className='text-gray-800 font-bold'>{s.batchQuantity}</span> |
            Required On{' '}
            <span className='text-gray-800 font-bold'>{s.batchNeededBy}</span>
          </div>
        ))}
      </div>
      <div>
        {enquiryItemData.rawItem.quantity === physicallyAllocated && (
          <div className='flex space-x-2 justify-center'>
            <InformationCircleIcon className='w-6 h-6 text-green-400' />
            Total required Items are already allocated.
          </div>
        )}
      </div>
      <div className='flex flex-row'>
        <div className='border p-1 m-1'>
          <label className='text-gray-800 text-md font-bold mb-2'>
            Available:&nbsp;
          </label>
          <span className='text-gray-500'>{availableQuantity}</span>
        </div>
        <div
          className={`p-1 m-1 ${
            balanceRequiredQuantity < 0 ? 'border-2 border-red-600' : 'border'
          }`}
        >
          <label className='text-gray-800 text-md font-bold mb-2'>
            Total Allocated:&nbsp;
          </label>
          <span className='text-gray-500'>{totalAllocatedQuantity}</span>
        </div>
        <div
          className={`p-1 m-1 ${
            balanceRequiredQuantity < 0 ? 'border-2 border-red-600' : 'border'
          }`}
        >
          <label className='text-gray-800 text-md font-bold mb-2'>
            Balance Required:&nbsp;
          </label>
          <span className='text-gray-500'>{balanceRequiredQuantity}</span>
        </div>
      </div>

      <form>
        <div className='flex flex-row flex-wrap'>
          {batchItems?.map((i, index) => (
            <div key={index} className='border p-1 w-1/4'>
              <div
                className={`p-1 mx-auto text-center m-1 bg-blue-100 ${
                  userAllotedErrors[index]?.error
                    ? 'border-2 border-red-600'
                    : ''
                }`}
              >
                <CubeIcon className='inline-block w-8 h-8 text-blue-500' />
                <sup className='bg-green-200 text-green-800 rounded sups text-lg'>
                  {i.quantity}
                </sup>
                <input
                  className='mb-3 ml-1 shadow appearance-none border rounded w-14  text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  id='alloted'
                  onChange={(e) => {
                    const { value } = e.target;
                    setUserAllotedValues((val) => {
                      const res = [...val];
                      res[index].quantity = parseInt(value || '0', 10);
                      return res;
                    });
                  }}
                  type='number'
                  value={userAllotedValues[index]?.quantity || 0}
                />
                {userAllotedErrors[index]?.error ? (
                  <label className='block text-red-700 text-sm font-bold mb-2'>
                    {userAllotedErrors[index]?.reason}
                  </label>
                ) : null}
              </div>

              <div className='mx-auto text-center'>
                <label className='block text-blue-600 text-md font-bold'>
                  {i.name}
                </label>
                <label className='block text-gray-800 text-md font-bold mb-2'>
                  {i.godown.name}
                </label>
              </div>
            </div>
          ))}
        </div>

        {balanceRequiredQuantity < 0 ? (
          <div className='mx-auto w-1/2 text-center'>
            <label className='block text-red-700 text-sm font-bold mb-2'>
              Total allocation cannot exceed {enquiryItemData.rawItem.quantity}
            </label>
          </div>
        ) : null}
        <div className='flex items-center mt-12 justify-end'>
          <LoadingButton
            defaultStyle='bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            behaviorFn={onCancel}
            text='Cancel'
          />
          <button
            className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            type='button'
            disabled={!!userAllotedErrors.find((a) => a.error)}
            onClick={handleSaveClick}
          >
            Allocate
          </button>
        </div>
      </form>
    </div>
  );
}
