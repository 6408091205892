import {
  ModuleType,
  ResourceType,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { CircleStackIcon } from '@heroicons/react/24/outline';
import { UseModules } from '../../../hooks/admin/module-admin/use-modules-admin';
import { UsePaginatedResourcesAdmin } from '../../../hooks/admin/use-resource-admin/use-paginated-resources-admin';
import { UseResourceAdmin } from '../../../hooks/admin/use-resource-admin/use-resource-admin';
import { UseResourcesAdmin } from '../../../hooks/admin/use-resource-admin/use-resources-admin';
import { ResourceAdminFilterType } from '../../../models/interfaces/admin/resource-admin';
import { renderEditResourceAdminForm } from './edit-resource-form';

export type RenderResourceAdminProps = {
  useResourcesAdmin: UseResourcesAdmin;
  useResourceAdmin: UseResourceAdmin;
  useModules: UseModules;
  usePaginatedResourcesAdmin: UsePaginatedResourcesAdmin;
};

export function renderResourceAdmin({
  useResourceAdmin,
  useModules,
  useResourcesAdmin,
  usePaginatedResourcesAdmin,
}: RenderResourceAdminProps): () => JSX.Element {
  return function ResourceAdmin(): JSX.Element {
    const EditResourceForm = renderEditResourceAdminForm({
      useResourceAdmin,
      useModules,
    });

    const { delete: deleteResourceAdmin } = useResourceAdmin();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Resource Admin',
      icon: <CircleStackIcon className='stroke-indigo-900 w-8 self-center' />,
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Resource',
            behaviour: 'modal',
            modal: {
              title: 'Add Resource',
              content: ({ onClose }) => {
                const resource: Partial<ResourceType> = {
                  id: '',
                  name: '',
                  isLocationSpecific: false,
                  description: '',
                  shortName: '',
                  isSystemModule: false,
                  module: { id: '', name: '' } as ModuleType,
                };

                return (
                  <EditResourceForm
                    r={resource}
                    onClose={() => {
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Table = renderTableWithMapperComponent<
      ResourceType,
      ResourceAdminFilterType
    >();

    const tableHeader: TableHeader = [
      [{ name: 'Name' }, { name: 'Short Name' }, { name: 'Module' }],
    ];

    const bodyMapper = (r: ResourceType) => {
      const cells: Array<TableCell> = [
        { value: r.name },
        { value: r.shortName },
        { value: `${r.module?.name} (${r.module.shortName})` },
      ];

      return {
        rowData: { resource: r },
        cells,
      };
    };

    const filter: Filter<ResourceAdminFilterType> = {
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },

        {
          key: 'shortName',
          type: 'text',
          value: '',
        },
      ],
      sortFields: [
        {
          key: 'Name',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'ShortName',
          value: 'shortName',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterMapper: (filterSelection: ResourceAdminFilterType) => {
        const filterData: ResourceAdminFilterType = {};
        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }
        if (
          filterSelection.shortName !== 'all' &&
          filterSelection.shortName !== ''
        ) {
          filterData.shortName = filterSelection.shortName;
        }
        return filterData as ResourceAdminFilterType;
      },
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              actions={[
                {
                  name: 'Edit',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit',
                    content: ({ data, onClose }) => {
                      return (
                        <EditResourceForm r={data.resource} onClose={onClose} />
                      );
                    },
                  },
                },
                {
                  name: 'Delete',
                  show: () => true,
                  behaviour: 'confirm',
                  onConfirm: ({ resource }) => {
                    return {
                      title: `Are you sure you want to delete this Resource ${resource.name}? This cannot be undone.`,
                      type: 'warning',
                      onConfirm: async () => {
                        deleteResourceAdmin(resource.id);
                      },
                    };
                  },
                },
              ]}
              useResources={() => {
                return {
                  data: [],
                  getAll: () => {},
                  getAllSync: async (filter) => [],
                  loading: false,
                };
              }}
              filter={filter}
              pagination={{
                enabled: true,
                usePaginatedResources: usePaginatedResourcesAdmin,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
