import {
  Godown,
  GodownTransfer,
  ItemBatchGodown,
} from '@erp_core/erp-types/dist/modules/inventory';
import {
  ManufacturingVoucher,
  ManufacturingVoucherFilter,
} from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseUsers } from '../../../../../hooks/admin/user-admin/use-users-admin';
import { UseGodownTransfer } from '../../../../../hooks/inventory/godown-transfer/use-godown-transfer';
import { UseItems } from '../../../../../hooks/inventory/item/use-items';
import { UseBoms } from '../../../../../hooks/order/work-order/bom/use-boms';
import { UseManufacturingVouchers } from '../../../../../hooks/order/work-order/mf-voucher/use-manufacturing-vouchers';
import { UsePaginatedManufacturingVouchers } from '../../../../../hooks/order/work-order/mf-voucher/use-paginated-manufacturing-vouchers';
import { BatchItemGodownInterface } from '../../../../../models/interfaces/inventory/item/batch/batch-item-godown';
import { MachineInterface } from '../../../../../models/interfaces/planning/production/machine';
import { createGodownTransferForm } from '../../../../inventory/godown-transfer-form';

type Props = {
  hooks: {
    useManufacturingVouchers: UseManufacturingVouchers;
    useGodownTransfer: UseGodownTransfer;
    useItems: UseItems;
    useUsers: UseUsers;
    useBoms: UseBoms;
    usePaginatedManufacturingVouchers: UsePaginatedManufacturingVouchers;
  };
  services: {
    batchItemService: BatchItemGodownInterface;
    machineService: MachineInterface;
  };
};

export const createManufacturingVoucherList = ({
  hooks: {
    useGodownTransfer,
    useItems,
    useUsers,
    useBoms,
    usePaginatedManufacturingVouchers,
    useManufacturingVouchers,
  },
  services: { batchItemService, machineService },
}: Props) =>
  function () {
    const { data: boms } = useBoms();

    const Card = renderCardComponent();
    const { id } = useParams();

    const { syncSet: setGodownTransfer } = useGodownTransfer();

    const Table = renderTableWithMapperComponent<
      ManufacturingVoucher,
      ManufacturingVoucherFilter
    >();

    // Manufacturing voucher Table Header
    const tableHeader: TableHeader = [
      [
        { name: 'Manufacturing voucher id' },
        { name: 'Finished product(item)' },
        { name: 'Raw item(s)' },
        { name: 'Quantity' },
        { name: 'Machine' },
        { name: 'Expected by' },
        { name: 'Created By' },
        { name: 'Status' },
        { name: 'Actions' },
      ],
    ];

    const bodyMapper = (m: ManufacturingVoucher) => {
      const cells: Array<TableCell> = [
        {
          value: `MV-${moment(m.expectedBy).format('YYYY-MM-DD')}`,
          style: '',
          link: `/production/manufacturing-voucher/details/${m.id}`,
        }, // TODO: Add MV_NAme in DB
        { value: m.item.name, style: '' },
        {
          value: (
            <div>
              {m.details?.rawItems?.map((rm) => (
                <span>{rm.name}, </span>
              ))}
            </div>
          ),
          style: '',
        },
        {
          value: (
            <div>
              {m.manufacturingVoucherItems?.reduce(
                (crr, prev) => crr + prev.quantity,
                0
              )}
            </div>
          ),
          style: '',
        },
        { value: m.machineId, style: '' },
        { value: m.expectedBy, style: '' },
        { value: m.createdBy.name, style: '' },
        { value: m.status, style: '' },
      ];
      return {
        rowData: {
          manuVoucher: m,
        },
        cells,
      };
    };

    const filter: Filter<ManufacturingVoucherFilter> = {
      sortFields: [],
      filterFields: [
        {
          key: 'expectedBy',
          type: 'text',
          value: '',
        },
        {
          key: 'itemId',
          type: 'search-select',
          value: '',
        },
        {
          key: 'createdBy',
          type: 'search-select',
          value: '',
        },
        {
          key: 'status',
          value: 'all',
          type: 'drop-down',
          options: [
            { text: 'all', value: 'all' },
            { text: 'planned', value: 'planned' },
            { text: 'bom-created', value: 'bom-created' },
            { text: 'ready-to-collect', value: 'ready-to-collect' },
            { text: 'ready-to-manufacture', value: 'ready-to-manufacture' },
            { text: 'manufacturing', value: 'manufacturing' },
            { text: 'manufactured', value: 'manufactured' },
            { text: 'canceled', value: 'canceled' },
          ],
        },
      ],
      filterMapper: (filterSelection: ManufacturingVoucherFilter) => {
        const filterData: ManufacturingVoucherFilter = {};

        if (filterSelection.status !== 'all') {
          filterData.status = filterSelection.status;
        }
        if (
          filterSelection.expectedBy !== 'all' &&
          filterSelection.expectedBy !== ''
        ) {
          filterData.expectedBy = filterSelection.expectedBy;
        }
        if (filterSelection.createdBy) {
          filterData.createdBy = filterSelection.createdBy;
        }
        if (filterSelection.itemId) {
          filterData.itemId = filterSelection.itemId;
        }
        return {
          ...filterSelection,
          ...filterData,
        } as ManufacturingVoucherFilter;
      },
      filterResources: {
        createdBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
        itemId: {
          searchOptions: {
            useSearch: useItems,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              actions={[
                {
                  name: 'Create Bom',
                  show: ({ manuVoucher }) => manuVoucher?.status === 'planned',
                  behaviour: 'modal',
                  modal: {
                    title: 'Create Bom',
                    content: () => {
                      return (
                        <>Disabled old Bom form</>
                        // Disabling on aug 4
                        // <BomForm
                        //   hooks={{
                        //     useBom,
                        //     useBoms,
                        //     useGodownsTree,
                        //     useItems,
                        //     useUsers,
                        //     usePhysicalStock,
                        //     useVirtualStock,
                        //     useCompanyGroupSettings,
                        //     useBomEstimate,
                        //   }}
                        //   bomType={'P-BOM'}
                        //   services={{ itemV2Service }}
                        //   onClose={async (i) => {
                        //     setModal({ ...modal, isVisible: false, body: <div /> });
                        //     if (i.created) {
                        //       await setMv({
                        //         id: manufacturingVoucherData.id,
                        //         status: 'bom-created',
                        //       } as any);
                        //       getWithPagination();
                        //     }
                        //   }}
                        //   defaultFormData={{
                        //     item: manufacturingVoucherData.item,
                        //     engineer: data,
                        //     dTo: {
                        //       id: 'E5621EC8-3557-4D3C-A5B1-5306BF9023D2',
                        //       name: 'Default Godown',
                        //     },
                        //     quantity: Number(
                        //       manufacturingVoucherData.manufacturingVoucherItems.reduce(
                        //         (prev, current) => prev + current.quantity,
                        //         0
                        //       )
                        //     ),
                        //     priority: 'medium',
                        //     manufacturingId: manufacturingVoucherData.id,
                        //     targetDate: moment(moment.utc(), 'DD-MM-YYYY')
                        //       .add(30, 'days')
                        //       .format(),
                        //   }}
                        // />
                      );
                    },
                  },
                },
                {
                  name: 'Collect',
                  show: ({ manuVoucher }) =>
                    manuVoucher?.status === 'ready-to-collect',
                  behaviour: 'modal',
                  modal: {
                    title: 'Collect Raw Material',
                    content: ({ data: { manuVoucher } }) => {
                      return (
                        <CollectRM
                          setGodownTransfer={setGodownTransfer}
                          boms={boms}
                          manuVoucher={manuVoucher}
                          machineService={machineService}
                          batchItemService={batchItemService}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Start Manufacturing',
                  show: ({ manuVoucher }) =>
                    manuVoucher?.status === 'ready-to-manufacture',
                  behaviour: 'click',
                  onClick: async () => {
                    console.log('Start Manufactruing');
                  },
                },
                {
                  name: 'Manufacturing completed',
                  show: ({ manuVoucher }) =>
                    manuVoucher?.status === 'manufactoring',
                  behaviour: 'click',
                  onClick: async () => {
                    console.log('Complete');
                  },
                },
              ]}
              filter={filter}
              defaultFilter={{ itemId: id }}
              pagination={{
                enabled: true,
                usePaginatedResources: usePaginatedManufacturingVouchers,
              }}
              useResources={useManufacturingVouchers}
            />
          </div>
        </div>
      ),
    };

    return (
      <div className='relative'>
        <Card
          header={{
            title: 'Manufacturing Voucher List',
          }}
          body={cardBody}
        />
      </div>
    );
  };

function CollectRM({
  setGodownTransfer,
  boms,
  manuVoucher,
  machineService,
  batchItemService,
}) {
  const transferBoms = async (
    data: { batch: ItemBatchGodown; godown: Godown }[]
  ) => {
    // Here write the logic to transfer batched
    for (let i = 0; i < data.length; i++) {
      await setGodownTransfer({
        name: '',
        from: data[i].batch.godown,
        to: data[i].godown,
        status: 'un-approved',
        batch: data[i].batch,
        details: {
          approved: false,
        },
        // TODO: We may need to pass company/location/companyGroup
        referenceType: 'M-REQ',
        reason: '',
        referenceId: data[i].batch.id,
      } as GodownTransfer);
    }
  };

  const GodownTransferForm = createGodownTransferForm();
  // TODO: Getting all bom s and filtering as filter on json not available on POSTGRESS
  const myBoms = boms
    ? boms.filter((b: any) => b.details?.manufacturingId === manuVoucher.id)
    : [];
  const [defaultData, setDefaultData] = useState<
    { batch: ItemBatchGodown; godown: Godown }[]
  >([]);

  useEffect(() => {
    new Promise((res) => res(true)).then(async () => {
      const newDefaultData: { batch: ItemBatchGodown; godown: Godown }[] = [];
      const machineGodown = await machineService.get(manuVoucher.machineId);
      for (let b = 0; b < myBoms.length; b++) {
        const batch = (await batchItemService.getAll({
          reference_id: myBoms[b].id,
        })) as ItemBatchGodown[];
        for (let bt = 0; bt < batch.length; bt++) {
          newDefaultData.push({
            batch: batch[bt],
            godown: (await batchItemService.get(machineGodown.itemBatchId))
              .godown,
          });
        }
      }
      setDefaultData(newDefaultData);
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <GodownTransferForm
        onSubmit={() => transferBoms(defaultData)}
        defaultData={defaultData}
      />
    </div>
  );
}
